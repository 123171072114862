import {getProjectDocumentsOdataQuery, getDocumentDetailsOdataQuery, getDocumentRevisionOdataQuery, getDocumentFilesOdataQuery, getEngOrderIdByIdsOdataQuery, getStakeHolderIdsByCompaniesQuery} from './odataDocumentQueries'
import {configuration} from '../configuration'
import { Filters } from '../models/filters';
import { Status } from '../models/status';
import { HistoryPathType } from './costants';
import { PermissionElement } from '../models/permissions';

// GET
export const getProjectDocumentsUrl = (projectId: number, filters: Filters, orderColumn: {name: string, direction: string}, skip: number, allStatus: Status[], historyName: HistoryPathType, documentIds?: number[], onlyIds?: boolean, supplierCompany?: string, partnerId?: number, customerId?: number): string => {
    const query = getProjectDocumentsOdataQuery(projectId, filters, orderColumn, skip, allStatus, historyName, documentIds, onlyIds, supplierCompany, partnerId, customerId) as string;
    return `${configuration.api_endpoint}/Documents${(query) ? query : ''}`;
}; 
export const getDocumentDetailsUrl = (projectId: number, documentId: number): string => {
    const query = getDocumentDetailsOdataQuery(projectId, documentId) as string;
    return `${configuration.api_endpoint}/Documents${(query) ? query : ''}`;
}; 
export const getDocumentRevisionsUrl = (projectId: number, currentRevision: string,  agileNumber: string, sheet: number, isCustomer: boolean): string => {
    const query = getDocumentRevisionOdataQuery(projectId, currentRevision, agileNumber, sheet, isCustomer) as string;
    return `${configuration.api_endpoint}/Documents${(query) ? query : ''}`;
}; 
export const getDocumentFilesUrl = (projectId: number, documentId: number): string => {
    const query = getDocumentFilesOdataQuery(projectId, documentId) as string;
    return `${configuration.api_endpoint}/Documents${(query) ? query : ''}`;
}; 
export const downloadFileUrl = (documentId: string): string => {
    return `${configuration.api_endpoint}/ExodItem/${documentId}` ;
}; 
export const getEngOrderIdByIdsUrl = (projectId: number, documentNumbers: string[]): string => {
    const query = getEngOrderIdByIdsOdataQuery(projectId, documentNumbers) as string;
    return `${configuration.api_endpoint}/Documents${(query) ? query : ''}`;
}; 
export const getStakeholderIdsByCompaniesUrl = (companies: string[]): string => {
    const query = getStakeHolderIdsByCompaniesQuery(companies) as string;
    return `${configuration.api_endpoint}/StakeHolders${(query) ? query : ''}`;
}; 

// POST
export const uploadFilesUrl = (): string => {
     return `${configuration.api_endpoint}/ExodItem` ;
}; 
export const addCommentUrl = (): string => {
    return `${configuration.api_endpoint}/Comment` ;
}; 
export const downloadFilesUrl = (): string => {
    return `${configuration.api_endpoint}/DownloadAllFiles` ;
}; 
export const downloadFilesTestUrl = (): string => {
    return `${configuration.api_endpoint}/Exod/DownloadFiles` ;
}; 
export const downloadFilesSupplierUrl = (): string => {
    return `${configuration.api_endpoint}/DownloadAllCommentFiles` ;
}; 
export const downloadCommentsUrl = (): string => {
    return `${configuration.api_endpoint}/DownloadComments` ;
}; 
export const downloadExcelReportUrl = (): string => {
    return `${configuration.api_endpoint}/CreateReport` ;
}; 
export const downloadSupplierTemplateUrl = (): string => {
    return `${configuration.api_endpoint}/DownloadTemplate` ;
}; 
export const changeStatusUrl = (): string => {
    return `${configuration.api_endpoint}/StatusHistory` ;
}; 
export const importZipSupplierUrl = (): string => {
    return `${configuration.api_endpoint}/ImportZip` ;
}; 
export const importZipPartnerUrl = (): string => {
    return `${configuration.api_endpoint}/ImportZipPartner` ;
}; 
export const supplierUploadUrl = (): string => {
    return `${configuration.api_endpoint}/SupplierDocument` ;
}; 
export const partnerUploadUrl = (): string => {
    return `${configuration.api_endpoint}/PartnerDocument` ;
}; 
export const reserveNumbersUrl = (): string => {
    return `${configuration.api_endpoint}/PartnerReserve` ;
}; 
export const newSheetUrl = (): string => {
    return `${configuration.api_endpoint}/CreateSheet` ;
}; 
export const newRevisionUrl = (): string => {
    return `${configuration.api_endpoint}/CreateRevision` ;
}; 
export const newEeoRevisionUrl = (): string => {
    return `${configuration.api_endpoint}/CreateEEORevision` ;
}; 
export const newEditDeleteFolderUrl = (folderId?: string): string => {
    return `${configuration.api_endpoint}/Folder` + ((folderId) ? ('/' + folderId) : '') ;
}; 
export const addDocumentsToFolderUrl = (): string => {
    return `${configuration.api_endpoint}/DocumentFolder`;
}; 
export const removeDocumentsFromFolderUrl = (): string => {
    return `${configuration.api_endpoint}/RemoveDocumentFolder`;
}; 

// PUT
export const editDocumentUrl = (): string => {
    return `${configuration.api_endpoint}/Document` ;
}; 
export const editDocumentsUrl = (): string => {
    return `${configuration.api_endpoint}/BatchEditDocuments` ;
}; 
export const replaceFileUrl = (replaceId: string): string => {
    return `${configuration.api_endpoint}/ExodItem/${replaceId}` ;
}; 

// DELETE
export const deleteFileUrl = (fileId: string): string => {
    return `${configuration.api_endpoint}/ExodItem/${fileId}` ;
}; 
export const deleteDocumentsUrl = (): string => {
    return `${configuration.api_endpoint}/Document` ;
}; 

