import {AuthConfigActions, ApiConfigActions, MicrosoftApiConfigActions, GraphQlConfigActions, RoleModels} from "@pwbapps/reduxcore";
import {CommandActions, GET_STAKEHOLDER_ID} from "../../actions/initialization";
import {CommandActions as LoaderCommandActions} from "../../actions/loader";
import {CommandActions as SharedDataCommandActions} from "../../actions/sharedData";
import {CommandActions as AdministrationCommandActions} from "../../actions/administrationModal";
import { getCloseFiltersPanel, getHistoryName } from "../../../utils/functions";
import _ from "lodash";
import { DocumentActions as UiDocumentActions } from "../../actions/ui";
import { AccountInfo } from "@azure/msal-browser";
import { InternalRoleActionsType, InternalRolesType, ExternalRolesType, ExternalRoleActionsType, isExternalApp, graphApiScopes, clientId, InternalRoleActionsValues, ExternalRoleActionsValues} from "../../../utils/costants";
import { CommandActions as DocumentsCommandActions } from "../../actions/documents";
import SignalrConfigurator from "../../../signalr_config";
import { CommandActions as NotifySettingsCommandActions } from "../../actions/notifySettings";

export const initializationMiddlewareHistory = (history: any) => {
    return initializationMiddleware(history);
}

export const initializationMiddleware = (history: any) => ({dispatch, getState}: {dispatch: any, getState: any}) => (next: any) => (action: CommandActions | AuthConfigActions.Actions | ApiConfigActions.EventActions | MicrosoftApiConfigActions.EventActions | GraphQlConfigActions.EventActions) => {
    next(action);
    switch (action.type) {

        case AuthConfigActions.LOGIN_COMPLETED:
            const {account} = action.payload;
            if(account){
                let idToken = ((account as AccountInfo).idTokenClaims) ? (account as AccountInfo).idTokenClaims as any : undefined;
                if(idToken && idToken.roles){
                    let roleNames: any = idToken.roles;
                    let roles: RoleModels.Role[] = (roleNames && roleNames.length > 0) ? _.map(roleNames, rn => {return getExodRole(rn)}) : [];
                    next(AuthConfigActions.DocumentActions.setRoles({roles: roles}));
                    if(isExternalApp){
                        let company = idToken.company;
                        //let company = 'partner';
                        next(CommandActions.setCompany({value: company}));
                    }
                }
                SignalrConfigurator.init((ids: number[]) => {dispatch(DocumentsCommandActions.getDocumentsByIdAndUpdateGrid({documentIds: ids}))});
                initializeLandingPage(dispatch, next, getState, history);
            }   
            break;

        case ApiConfigActions.API_SUCCESS:
            apiSuccessMiddleware(history, dispatch, getState, next, action);         
            break;
    
        case ApiConfigActions.API_ERROR:
            apiErrorMiddleware(history, dispatch, getState, next, action);         
            break;

        default:
            break;
    }
};

const apiSuccessMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiSuccessAction) => {
    switch(action.meta.feature) {

        case GET_STAKEHOLDER_ID: {
            let stakeholder = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value[0] : undefined
            if(stakeholder)
                next(CommandActions.setStakeHolderId({value: stakeholder.stakeHolderId}));
            break;
        }
    }
}

const apiErrorMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiErrorAction) => {
    switch(action.meta.feature) {
        case GET_STAKEHOLDER_ID: {
           
            break;
        }
    }
}

const getExodRole = (name: string): RoleModels.Role => {
     return (!isExternalApp) ? getInternalRole(name) : getExternalRole(name);
}

const getInternalRole = (name: string): RoleModels.Role => {
    //name = '999999_READER';
    let role: RoleModels.Role = {name: '', actions: []};
    let projectCode = (name && name.includes('_')) ? name.split('_')[0] : undefined;
    let roleName: InternalRolesType | undefined = (name && name.includes('_')) ? name.split('_')[1] as InternalRolesType : (name as InternalRolesType === 'SUPERADMIN' ? 'SUPERADMIN' : undefined);
    let actions: InternalRoleActionsType[] = [];
    if((projectCode && roleName) || roleName === 'SUPERADMIN'){
        role.name = name;
        switch(roleName) {
            case 'SUPERADMIN': {
                actions = [...InternalRoleActionsValues];
                break;
            }
            case 'ADMINISTRATOR': {
                actions = ['Enable', 'ShowHome', 'ShowFolders', 'CreateFolders', 'ShowDashboard', 'ReadDashboard', 'DeleteDashboard', 'EditDashboard', 'ChangeStatusDashboard', 'UploadAttachmentDashboard', 'UploadCommentFileDashboard', 'InsertTextCommentDashboard',
                           'ShowQualityCheck', 'ReadQualityCheck', 'DeleteQualityCheck', 'EditQualityCheck', 'UploadAttachmentQualityCheck', 'UploadCommentFileQualityCheck', 'InsertTextCommentQualityCheck', 'ReadyForPublishQualityCheck',
                           'ShowPublish', 'ReadPublish', 'DeletePublish', 'EditPublish', 'UploadAttachmentPublish', 'UploadCommentFilePublish', 'InsertTextCommentPublish', 'PublishPublish',
                           'ShowPending', 'ReadPending', 'ApprovePending', 'UploadAttachmentPending', 'UploadCommentFilePending', 'InsertTextCommentPending',
                           'ShowReservedNumbers', 'ReadReservedNumbers',
                           'ShowSupplierMaterial', 'ReadSupplierMaterial', 'InsertTextCommentSupplierMaterial', 'PublishSupplierMaterial', 'DeclineSupplierMaterial',
                           'ShowCreateRevision', 'ReadCreateRevision', 'ShowClientNumber', 'InsertTextCommentCreateRevision'
                           ];
                break;
            }
            case 'PUBLISHER': {
                actions = ['ShowHome','ShowFolders', 'ShowDashboard', 'ReadDashboard', 'UploadAttachmentDashboard', 'UploadCommentFileDashboard', 'InsertTextCommentDashboard',
                           'ShowQualityCheck', 'ReadQualityCheck', 'ReadyForPublishQualityCheck',
                           'ShowPublish', 'ReadPublish', 'InsertTextCommentPublish', 'PublishPublish'];
                break;
            }
            case 'EEOHANDLER': {
                actions = ['ShowHome', 'ShowFolders', 'ShowDashboard', 'ReadDashboard', 'InsertTextCommentDashboard',
                           'ShowReservedNumbers', 'ReadReservedNumbers', 'InsertTextCommentReservedNumbers',
                           'ShowSupplierMaterial', 'ReadSupplierMaterial', 'InsertTextCommentSupplierMaterial', 'PublishSupplierMaterial', 'DeclineSupplierMaterial',
                           'ShowCreateRevision', 'ReadCreateRevision', 'InsertTextCommentCreateRevision'];
                break;
            }
            case 'READER': {
                actions = ['ShowHome','ShowFolders', 'ShowDashboard', 'ReadDashboard'];
                break;
            }
            default : {
                role.actions = [];
                break;
            }
        }
        role.actions = actions;
    }
    return role;
}

const getExternalRole = (name: string): RoleModels.Role => {
    //name = '999999_PARTNER-COMMENTATOR';
    let role: RoleModels.Role = {name: '', actions: []};
    let projectCode = (name && name.includes('_')) ? name.split('_')[0] : undefined;
    let roleName: ExternalRolesType | undefined = (name && name.includes('_')) ? name.split('_')[1] as ExternalRolesType : (name as ExternalRolesType === 'SUPERADMIN' ? 'SUPERADMIN' : undefined);
    let actions: ExternalRoleActionsType[] = [];
    if((projectCode && roleName) || roleName === 'SUPERADMIN'){
        role.name = name;
        switch(roleName) {
            case 'SUPERADMIN': {
                actions = [...ExternalRoleActionsValues];
                break;
            }
            case 'SUPPLIER': {
                actions = ['ShowReservedNumbers', 'ReadReservedNumbers', 'UploadParentReservedNumbers',
                           'ShowPublishMaterial', 'ReadPublishMaterial', 'PublishPublishMaterial', 'DeclinePublishMaterial',
                           'FilterDocumentsBySupplierCompany' ];
                break;
            }
            case 'PARTNER': {
                actions = ['ShowHome', 'ShowDashboard', 'ReadDashboard', 'DeleteDashboard', 'EditDashboard', 'ChangeStatusDashboard', 'UploadAttachmentDashboard', 'UploadCommentFileDashboard', 'InsertTextCommentDashboard', 'ReserveNumbersDashboard', 'UploadParentDashboard',
                           'ShowPublish', 'ReadPublish', 'DeletePublish', 'EditPublish', 'UploadAttachmentPublish', 'UploadCommentFilePublish', 'InsertTextCommentPublish', 'PublishPublish', 'DeclinePublish',
                           'ShowQualityCheck', 'ReadQualityCheck', 'DeleteQualityCheck', 'EditQualityCheck', 'UploadAttachmentQualityCheck', 'UploadCommentFileQualityCheck', 'InsertTextCommentQualityCheck', 'ReadyForPublishQualityCheck', 'DeclineQualityCheck',
                           'FilterDocumentsByPartnerId' ];
                break;
            }
            case 'PARTNER-COMMENTATOR': {
                actions = ['ShowDashboard', 'ReadDashboard', 'InsertTextCommentDashboard',                                                    
                           'FilterDocumentsByPartnerId' ];
                break;
            }
            case 'APPROVER': {
                actions = ['ShowHome', 'ShowFolders', 'ShowDashboard', 'ReadDashboard', 'UploadCommentFileDashboard', 'InsertTextCommentDashboard',
                           'ShowPending', 'ReadPending', 'ApprovePending', 'DeclinePending', 'UploadCommentFilePending', 'InsertTextCommentPending',
                            'FilterDocumentsByCustomerId', 'CheckOtherPermissions' ];
                break;
            }
            case 'CHECKER': {
                actions = ['ShowHome', 'ShowFolders', 'ShowDashboard', 'ReadDashboard', 'UploadCommentFileDashboard', 'InsertTextCommentDashboard',
                           'ShowPending', 'ReadPending', 'CheckPending', 'UploadCommentFilePending', 'InsertTextCommentPending',
                           'FilterDocumentsByCustomerId' ];
                break;
            }
            case 'READER': {
                actions = ['ShowHome', 'ShowFolders', 'ShowDashboard', 'ReadDashboard', 'FilterDocumentsByCustomerId'];
                break;
            }
            default : {
                role.actions = [];
                break;
            }
        }
        role.actions = actions;
    }
    return role;
}


const initializeLandingPage = (dispatch: any, next: any, getState: any, history: any) => {
    let historyName = getHistoryName(history);
    next(UiDocumentActions.setDrawerKey({key: history.location.pathname}));
    const closePanel = getCloseFiltersPanel(historyName);
    next(UiDocumentActions.setFilterPanelOpened({open: closePanel ? false : true}));
    if(historyName !== 'landing')
        dispatch(LoaderCommandActions.setLoading({ feature: historyName, loading: true}));
    next(SharedDataCommandActions.getSharedDataAndLoadProjects());
    if(!isExternalApp){
        next(AdministrationCommandActions.fetchInternalRoles());
        next(AdministrationCommandActions.fetchExternalRoles());
    }
}