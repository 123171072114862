import { FEATURE, Actions, CommandActions, EventActions, DocumentActions, FETCH_DOCUMENTS, UPLOAD_DOCUMENTS, CHANGE_STATUS, OPEN_EDIT_MODAL, IMPORT_ZIP, GET_IDS_AND_DO_SOMETHING, RESERVE_NUMBERS, UPLOAD_PARTNER_FILE,
         DOWNLOAD_COMMENTS, GET_DOCUMENTS_BY_ID_AND_UPDATE_GRID, DELETE_FILE, DELETE_DOCUMENTS, EDIT_DOCUMENT, SET_GRID_OR_MODAL_LOADERS_AND_NOTIFICATION, UPLOAD_SUPPLIER_FILE, CHECK_EXISTING_ENG_ORDERS, NEW_REVISION, NEW_SHEET, UPDATE_PUBLICATIONS,
         } from "../../actions/documents";
import {ApiConfigActions} from "@pwbapps/reduxcore";
import { DocumentActions as NotificationDocumentActions} from "../../actions/notification";
import { CommandActions as LoaderCommandActions, DocumentActions as LoaderDocumentActions} from "../../actions/loader";
import _ from 'lodash';
import { MessageBarType } from '@fluentui/react';
import { getBlob, getHistoryName, replaceFileExtension } from "../../../utils/functions";
import { deleteFileUrl, deleteDocumentsUrl, uploadFilesUrl, editDocumentUrl, changeStatusUrl, replaceFileUrl, importZipSupplierUrl, supplierUploadUrl, editDocumentsUrl, getEngOrderIdByIdsUrl, reserveNumbersUrl, partnerUploadUrl, importZipPartnerUrl, getProjectDocumentsUrl, newSheetUrl, newRevisionUrl, newEeoRevisionUrl } from "../../../utils/documentsUrls";
import { Document, EditDocumentForm, FileForm, FileGrid, ReserveNumberForm } from "../../../models/document";
import { Status, UploadFilesForm } from "../../../models/status";
import { getSelectedProjectId } from "../../reducers/project";
import { getDocuments, getOrderColumn, getSelectedItemsIds, getTotal } from "../../reducers/documents";
import { getEmail } from "@pwbapps/reduxcore/dist/redux/reducers/auth";
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";
import { getLoader } from "../../selectors/loader";
import { v4 as uuidv4 } from 'uuid';
import { getFilters } from "../../reducers/filters";
import { getContextualDocument, getContextualDocuments, getSelectedDocuments } from "../../selectors/documents";
import { getAllStakeHolders, getDisciplines, getEngPhases, getEntities, getPbs, getPublications, getPurposes, getStakeHolders, getStatusValues, getTypes } from "../../reducers/sharedData";
import { CommandActions as FiltersCommandActions } from "../../actions/filters";
import {  getOpenModal } from "../../reducers/document";
import { CommandActions as DocumentCommandActions } from "../../actions/document";
import { CommentForm } from "../../../models/comment";
import { documentsReportMiddlewareFunction } from "./documents_reports";
import SignalrConfigurator from "../../../signalr_config";
import { getSelectedProjectUserActions } from "../../selectors/auth";
import { getCompany, getStakeHolderId, getUserPermissions } from "../../reducers/initialization";
import { getSelectedProject, getSelectedProjectDocColumns, getSelectedProjectDwgColumns } from "../../selectors/project";
import { Modal } from "../../../models/modal";
import { Project } from "../../../models/project";
import { getSelectedProjectCustomerStakeholders } from "../../selectors/administration";
import { Purpose } from "../../../models/purpose";
import { documentsFoldersMiddlewareFunction } from "./documents_folders";
import { t } from "i18next";
import { SharedDataCommandActions } from "../../actions/sharedData";
import { HistoryPathType, isExternalApp } from "../../../utils/costants";
import { ApplicationStakeHolder } from "../../../models/stakeHolder";


export const documentsMiddlewareHistory = (history: any) => {
    return documentsMiddleware(history);
}

export const documentsMiddleware = (history: any) => ({dispatch, getState}: {dispatch: any, getState: any}) => (next: any) => (action: Actions | ApiConfigActions.EventActions ) => {
    next(action);
    switch (action.type) {
 
        case FETCH_DOCUMENTS: {
            const historyName = getHistoryName(history);
            const orderColumn = getOrderColumn(getState());
            const skip = (action.payload.skip) ? action.payload.skip : 0;
            const adding = action.payload.adding;
            const supplierCompany = (_.some(getSelectedProjectUserActions(getState()), a => a === 'FilterDocumentsBySupplierCompany')) ? getCompany(getState()) : undefined;
            let partnerId = undefined;
            let customerId = undefined;
            if(_.some(getSelectedProjectUserActions(getState()), a => a === 'FilterDocumentsByPartnerId')){
                partnerId = getStakeHolderId(getState());
                if(!partnerId){
                    const stakeHolders = getAllStakeHolders(getState());
                    const company = getCompany(getState());
                    const stakeHolder = (stakeHolders.length > 0 && company) ? _.find(stakeHolders, (s: ApplicationStakeHolder) => s.companyName === company) : undefined;
                    customerId = (stakeHolder) ? stakeHolder.stakeHolderId : undefined;
                    if(!customerId){
                        setTimeout(() => { dispatch(CommandActions.fetchDocuments({setDefaultFilters: action.payload.setDefaultFilters}))}, 500);
                        break;
                    }
                }
            }     
            else if(_.some(getSelectedProjectUserActions(getState()), a => a === 'FilterDocumentsByCustomerId')){
                customerId = getStakeHolderId(getState());
                if(!customerId){
                    const stakeHolders = getAllStakeHolders(getState());
                    const company = getCompany(getState());
                    const stakeHolder = (stakeHolders.length > 0 && company) ? _.find(stakeHolders, (s: ApplicationStakeHolder) => s.companyName === company) : undefined;
                    customerId = (stakeHolder) ? stakeHolder.stakeHolderId : undefined;
                    if(!customerId){
                        setTimeout(() => { dispatch(CommandActions.fetchDocuments({setDefaultFilters: action.payload.setDefaultFilters}))}, 500);
                        break;
                    }
                }
            }        
            if(action.payload.setDefaultFilters)
                dispatch(FiltersCommandActions.setDefaultFilters({reset: false}));
            if(!adding && !getLoader(historyName)(getState()).loadingContent)
                dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: true}));
            else if(adding && !getLoader(historyName)(getState()).loadingScroll)
                dispatch(LoaderCommandActions.setLoadingScroll({ feature: historyName, loading: true}));
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: (isExternalApp && historyName === 'pending') ? 'POST' : 'GET', body: (isExternalApp && historyName === 'pending') ? {projectId: getSelectedProjectId(getState()) as number, email: getEmail(getState())} : undefined, url: getProjectDocumentsUrl(getSelectedProjectId(getState()) as number, getFilters(getState()), orderColumn, skip, getStatusValues(getState()), historyName, undefined, undefined, supplierCompany, partnerId, customerId), feature: FETCH_DOCUMENTS, returnObject: {skip, adding}}}));    
            break; 
        }

        case GET_DOCUMENTS_BY_ID_AND_UPDATE_GRID: {
            const historyName = getHistoryName(history);
            const orderColumn = getOrderColumn(getState());
            const skip = 0;
            const documentIds = action.payload.documentIds;
            const gridIds = _.map(getDocuments(getState()), d => d.id as number);
            const supplierCompany = (_.some(getSelectedProjectUserActions(getState()), a => a === 'FilterDocumentsBySupplierCompany')) ? getCompany(getState()) : undefined;
            const partnerId = (_.some(getSelectedProjectUserActions(getState()), a => a === 'FilterDocumentsByPartnerId')) ? getStakeHolderId(getState()) : undefined;
            const customerId = (_.some(getSelectedProjectUserActions(getState()), a => a === 'FilterDocumentsByCustomerId')) ? getStakeHolderId(getState()) : undefined;
            if(_.some(documentIds, id => _.find(gridIds, gId => gId === id))){
                if(!getLoader(historyName)(getState()).loadingContent)
                    dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: true}));
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: (isExternalApp && historyName === 'pending') ? 'POST' : 'GET', body: (isExternalApp && historyName === 'pending') ? {projectId: getSelectedProjectId(getState()) as number, email: getEmail(getState())} : undefined, url: getProjectDocumentsUrl(getSelectedProjectId(getState()) as number, getFilters(getState()), orderColumn, skip, getStatusValues(getState()), historyName, documentIds, undefined, supplierCompany, partnerId, customerId), feature: GET_DOCUMENTS_BY_ID_AND_UPDATE_GRID, returnObject: {documentIds}}}));    
            }                      
            break; 
        }
        
        case UPLOAD_DOCUMENTS: {
            let uploadFileForm: {fileForm: FileForm, files: any[]} = (!action.payload.uploadFileForm) ? ((getGenericModal('uploadDocuments')(getState())) ? (getGenericModal('uploadDocuments')(getState()) as any).contextItem : undefined) : action.payload.uploadFileForm;
            const documents = (!action.payload.documents) ? getContextualDocuments(getState()) as Document[] : action.payload.documents;
            let files = (uploadFileForm && uploadFileForm.files && uploadFileForm.files.length > 0) ? uploadFileForm.files : [];
            let total = (action.payload.total) ? action.payload.total : files.length;
            let file = files[0];
            let blobPromise = getBlob(file);
            
            if(total === files.length){
                dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true }));
                next(CommandActions.setDocumentsToUploadTotal({value: total}));       
                next(CommandActions.setDocumentsToUpload({value: total}));           
            }

            const replaceId: string | undefined = (getGenericModal('uploadDocuments')(getState())) && (getGenericModal('uploadDocuments')(getState()) as any).optional ? (getGenericModal('uploadDocuments')(getState()) as any).optional.replaceId : undefined;

            blobPromise.then(blob => {
                const documentIds =  _.map(documents, d => d.id as number);
                uploadFileForm.fileForm.documentIds = documentIds;
                uploadFileForm.fileForm.createdBy = getEmail(getState());
                uploadFileForm.fileForm.fileName = (!uploadFileForm.fileForm.fileName) ? file.name : replaceFileExtension(uploadFileForm.fileForm.fileName, file.name); // se c'è il filename vuol dire che voglio rimpiazzare il parent -> stesso filename ma estensioni possono essere diverse
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: { 
                        method: (!replaceId) ? 'POST' : 'PUT', 
                        attachmentName: 'file', 
                        files: [blob], 
                        body: uploadFileForm.fileForm, 
                        url: (!replaceId) ? uploadFilesUrl() : replaceFileUrl(replaceId), 
                        feature: UPLOAD_DOCUMENTS, 
                        returnObject: {
                            fileName: file.name, 
                            failedFiles: action.payload.failedFiles,
                            documents,
                            uploadFileForm: {...uploadFileForm, files: _.filter(files, f => f.name !== file.name)},
                            total,
                            statusChanged: action.payload.statusChanged
                        }
                    }
                }));    
            });
            break; 
        }

        case IMPORT_ZIP : {
            let file = action.payload.file;
            let blobPromise = getBlob(file);
            let project = getSelectedProject(getState());
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true }));
            next(CommandActions.setDocumentsToUploadTotal({value: 1}));       
            next(CommandActions.setDocumentsToUpload({value: 1}));           
            
            blobPromise.then(blob => {        
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: (!action.payload.isPartnerFile) ? 
                    { 
                        method: 'POST', 
                        attachmentName: 'file', 
                        files: [blob], 
                        body: {
                            creator: getEmail(getState()),
                            projectId: getSelectedProjectId(getState()) as number,
                        }, 
                        url: importZipSupplierUrl(), 
                        feature: IMPORT_ZIP, 
                        returnObject: { fileName: file.name}
                    } : 
                    { 
                        method: 'POST', 
                        attachmentName: 'file', 
                        files: [blob], 
                        body: {
                            creator: getEmail(getState()),
                            projectId: getSelectedProjectId(getState()) as number,
                            isInternalCheckActive: (project) ? project.isInternalCheckActive : false
                        }, 
                        url: importZipPartnerUrl(), 
                        feature: IMPORT_ZIP, 
                        returnObject: { fileName: file.name}
                    }
                }));    
            });
            break;
        }

        case UPLOAD_SUPPLIER_FILE : {
            const parentFileForm: {files: any[], fileForm: FileForm} = (getGenericModal('uploadParent')(getState())) ? (getGenericModal('uploadParent')(getState()) as any).contextItem : undefined;
            const fileForm = parentFileForm.fileForm;
            let file = (parentFileForm && parentFileForm.files && parentFileForm.files.length > 0) ? parentFileForm.files[0] : undefined;
            if(file){
                let documents = getContextualDocuments(getState());
                let dwgColumns = getSelectedProjectDwgColumns(getState());
                let docColumns = getSelectedProjectDocColumns(getState());
    
                let canImport = false;
                if(documents.length > 0 && documents[0].type && documents[0].type.name === 'DWG')
                    canImport = (dwgColumns.length > 0) ? _.every(dwgColumns, c => !!(fileForm as any)[c]) : true;      
                else if(documents.length > 0 && documents[0].type && documents[0].type.name === 'DOC')
                    canImport =  (docColumns.length > 0) ? _.every(docColumns, c => !!(fileForm as any)[c]) : true;   
                
                if(parentFileForm && parentFileForm.fileForm && canImport) {              
                    let blobPromise = getBlob(file);         
                    dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true }));
                    next(CommandActions.setDocumentsToUploadTotal({value: 1}));       
                    next(CommandActions.setDocumentsToUpload({value: 1}));           
                    blobPromise.then(blob => {        
                        dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                            request: { 
                                method: 'POST', 
                                attachmentName: 'file', 
                                files: [blob], 
                                body: {
                                    creator: getEmail(getState()),
                                    documentIds: fileForm.documentIds,
                                    supplierDocumentNumber: fileForm.supplierDocumentNumber,
                                    format: fileForm.format,
                                    scale: fileForm.scale,
                                    titleLine4Pl: fileForm.title4Pl,
                                    titleLine5Pl: fileForm.title5Pl,
                                    titleLine6Pl: fileForm.title6Pl,
                                    titleLine4En: fileForm.title4,
                                    titleLine5En: fileForm.title5,
                                    titleLine6En: fileForm.title6,
                                    fileName: file.name
                                }, 
                                url: supplierUploadUrl(), 
                                feature: UPLOAD_SUPPLIER_FILE, 
                                returnObject: { fileName: file.name, documentId: fileForm.documentIds[0],}
                            }
                        }));    
                    });
                }
                else if(parentFileForm && parentFileForm.fileForm && !canImport)
                    dispatch(ModalActions.CommandActions.openModal({id: 'warning', contextItem: t('Some mandatory fields are unfilled')}));
            }
            else
                dispatch(ModalActions.CommandActions.openModal({id: 'warning', contextItem: t('The file is missing')}));

            break;
        }

        case UPLOAD_PARTNER_FILE : {
            const parentFileForm: {files: any[], fileForm: FileForm} = (getGenericModal('uploadParent')(getState())) ? (getGenericModal('uploadParent')(getState()) as any).contextItem : undefined;
            const fileForm = parentFileForm.fileForm;
            let file = (parentFileForm && parentFileForm.files && parentFileForm.files.length > 0) ? parentFileForm.files[0] : undefined;
            if(file){
                let canImport = (parentFileForm && parentFileForm.fileForm && parentFileForm.fileForm.type && parentFileForm.fileForm.engPhase && parentFileForm.fileForm.entity && parentFileForm.fileForm.purpose) ? true : false;
                if(parentFileForm && parentFileForm.fileForm && canImport) {              
                    let blobPromise = getBlob(file);         
                    dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true }));
                    next(CommandActions.setDocumentsToUploadTotal({value: 1}));       
                    next(CommandActions.setDocumentsToUpload({value: 1}));           
                    blobPromise.then(blob => {        
                        dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                            request: { 
                                method: 'POST', 
                                attachmentName: 'file', 
                                files: [blob], 
                                body: {
                                    documentIds: fileForm.documentIds,
                                    creator: getEmail(getState()),
                                    fileName: file.name,
                                    typeId: fileForm.type ? fileForm.type.id : undefined,
                                    purposeId: fileForm.type ? fileForm.type.id : undefined,
                                    engineeringPhaseId: fileForm.engPhase ? fileForm.engPhase.id : undefined,
                                    entityId: fileForm.entity ? fileForm.entity.id : undefined,
                                    isInternalCheckActive: (getSelectedProject(getState()) as Project).isInternalCheckActive                                
                                }, 
                                url: partnerUploadUrl(), 
                                feature: UPLOAD_PARTNER_FILE, 
                                returnObject: { fileName: file.name, documentId: fileForm.documentIds[0]}
                            }
                        }));    
                    });
                }
                else if(parentFileForm && parentFileForm.fileForm && !canImport)
                    dispatch(ModalActions.CommandActions.openModal({id: 'warning', contextItem: t('Some mandatory fields are unfilled')}));
            }
            else
                dispatch(ModalActions.CommandActions.openModal({id: 'warning', contextItem: t('The file is missing')}));

            break;
        }

        case CHANGE_STATUS: {
            const historyName = getHistoryName(history);
            let documents = [...action.payload.documents];
            let document = documents[0];
            const changeStatusForm: { newStatus: Status, fileForm?: FileForm, files?: any[] } = (getGenericModal('changeStatus')(getState())) ? (getGenericModal('changeStatus')(getState()) as any).contextItem : undefined;
            const oldStatus = document.status as Status; // tutti i documenti hanno lo stesso stato di partenza
            let modalOpened = getOpenModal(getState());
            if(modalOpened)
            next(DocumentCommandActions.setOpenModal({value: false}));
            if(changeStatusForm.newStatus.comment){
                _.forEach([...documents], d => {
                    let commentForm: CommentForm = { documentId: d.id, createdBy: getEmail(getState()), commentText: changeStatusForm.newStatus.comment };
                    next(DocumentCommandActions.addComment({commentForm, hideUiLoadsAndNots: true}));
                });
            }
            let stakeHolderIds: number[] = [];
            if(changeStatusForm.newStatus.name === 'published'){
                const documentPurposes = (documents && documents.length > 0 && !_.some(documents, d => !d.purpose)) ? _.map(documents, d => d.purpose as Purpose) : [];
                const noApprovalNeeded = (documentPurposes.length > 0) ? _.every(documentPurposes, p => !p.isWithApproval) : true;
                const customerSelection: 'multiple' | 'single' =  (noApprovalNeeded && getSelectedProjectCustomerStakeholders(getState()).length > 1) ? 'multiple' : 'single';
                if(customerSelection === 'single' && changeStatusForm.fileForm && changeStatusForm.fileForm.stakeholders && changeStatusForm.fileForm.stakeholders.length === 0){
                    dispatch(ModalActions.CommandActions.openModal({id: 'warning', contextItem: 'Please select one customer'}));
                    break;
                }            
                const stakeHolders = (changeStatusForm.fileForm && changeStatusForm.fileForm.stakeholders && changeStatusForm.fileForm.stakeholders.length > 0) ? [...changeStatusForm.fileForm.stakeholders] : [...getSelectedProjectCustomerStakeholders(getState())];
                stakeHolderIds = _.map(_.filter(stakeHolders, s => s.isActive), s => s.stakeHolderId as number);
            }
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: true}));
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                request: { 
                    method: 'POST',
                    body: {
                        originalStatusId : oldStatus.id,
                        destinationStatusId : changeStatusForm.newStatus.id,
                        statusUser: getEmail(getState()),
                        documentIds: _.map(documents, d => d.id as number),
                        ...(stakeHolderIds ? { stakeHolderIds } : {}), 
                    },
                    url: changeStatusUrl(),
                    feature: CHANGE_STATUS,
                    returnObject: {
                        documents,
                        uploadFilesForm: (changeStatusForm.files && changeStatusForm.files.length > 0 && changeStatusForm.fileForm) ? new UploadFilesForm({files: changeStatusForm.files as any[], total: (changeStatusForm.files as any[]).length,  fileForm: changeStatusForm.fileForm, documents}) : undefined
                    }
                }
            }));    
            break; 
        }

        case EDIT_DOCUMENT: {
            let form = action.payload.editForm;
            if(form.documentIds.length === 1 && form.purpose && form.clientNumber){
                let modalOpened = getOpenModal(getState());
                if(modalOpened)
                    next(DocumentCommandActions.setOpenModal({value: false}));
                const historyName = getHistoryName(history);
                dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: true}));
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: { 
                        method: 'PUT', 
                        body: {
                            documentIds: [form.documentIds[0]],
                            supplierDocumentNumber: form.supplierDocumentNumber,
                            purposeId: form.purpose.id,
                            clientNumber: form.clientNumber,
                            engineeringPhaseId: (form.engPhase) ? form.engPhase.id : undefined,
                            entityId: (form.entity) ? form.entity.id : undefined,
                            isForInternalUse: (form.isForInternalUse) ? true : false
                        },
                        url: editDocumentUrl(), 
                        feature: EDIT_DOCUMENT, 
                        returnObject: {form}
                    }
                }));    
            }
            else if(form.documentIds.length > 1 && (form.purpose || form.engPhase)){
                let modalOpened = getOpenModal(getState());
                if(modalOpened)
                    next(DocumentCommandActions.setOpenModal({value: false}));
                const historyName = getHistoryName(history);
                dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: true}));
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: { 
                        method: 'PUT', 
                        body: {
                            documentIds: [...form.documentIds],
                            purposeId: (form.purpose) ? form.purpose.id : undefined,
                            engineeringPhaseId: (form.engPhase) ? form.engPhase.id : undefined,
                        },
                        url: editDocumentsUrl(), 
                        feature: EDIT_DOCUMENT, 
                        returnObject: {form}
                    }
                }));    
            }
            break; 
        }

        case DELETE_DOCUMENTS: {
            let ids = _.map(getSelectedDocuments(getState()), d => d.id as number);
            const historyName = getHistoryName(history);
            if(ids && ids.length > 0){
                dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: true}));
                next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.info, message: t('Deleting') + ' ' + _.join(ids, ', ') + '...'}));  
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'DELETE', body: {documentIds: ids}, url: deleteDocumentsUrl(), feature: DELETE_DOCUMENTS, returnObject: {ids, documentNumbers: _.map(getSelectedDocuments(getState()), d => d.documentNumber as string)}}}));    
            }
            break;
        }

        case DELETE_FILE: {
            let file = action.payload.file as FileGrid;
            let documentId = action.payload.documentId;
            if(!action.payload.hideUiLoadsAndNots){
                dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true, message:  t('Deleting') + ' ' + file.fileName + '...', messageType: MessageBarType.info }));
            }
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'DELETE', url: deleteFileUrl(file.id as string), feature: DELETE_FILE, returnObject: {file, documentId, hideUiLoadsAndNots: action.payload.hideUiLoadsAndNots}}}));    
            break;
        }
   
        case OPEN_EDIT_MODAL: {
            let documents = getContextualDocuments(getState());
            if(documents && documents.length > 0){
                let editDocumentForm: EditDocumentForm = (documents.length === 1) ?  new EditDocumentForm(documents[0]) : new EditDocumentForm(undefined, _.map(documents, d => d.id as number));
                dispatch(ModalActions.CommandActions.openModal({id: 'editDocument', contextItem: editDocumentForm}));
            }
            break; 
        }

        case CHECK_EXISTING_ENG_ORDERS: {
            let rows = action.payload.rows;
            let documentNumbers = _.map(rows, r => r.documentNumber as string);
            if(documentNumbers && documentNumbers.length > 0){
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getEngOrderIdByIdsUrl(getSelectedProjectId(getState()) as number, documentNumbers), feature: CHECK_EXISTING_ENG_ORDERS, returnObject: {rows, adding: action.payload.adding}}}));    
            }
            break;
        }

        case RESERVE_NUMBERS: {
            let reserveNumbersForm: ReserveNumberForm | undefined = ((getGenericModal('reserveNumber')(getState())) ? (getGenericModal('reserveNumber')(getState()) as any).contextItem : undefined);
            if(reserveNumbersForm){
                next(ModalActions.DocumentActions.setContextItemProperty({id: 'reserveNumber', name: 'errors', value: {...((getGenericModal('reserveNumber')(getState()) as Modal).contextItem as ReserveNumberForm).errors, number: (!reserveNumbersForm.number) ? t('This field is mandatory') : undefined}}));
                next(ModalActions.DocumentActions.setContextItemProperty({id: 'reserveNumber', name: 'errors', value: {...((getGenericModal('reserveNumber')(getState()) as Modal).contextItem as ReserveNumberForm).errors, engPhase: (!reserveNumbersForm.engPhase) ? t('This field is mandatory') : undefined}}));
                next(ModalActions.DocumentActions.setContextItemProperty({id: 'reserveNumber', name: 'errors', value: {...((getGenericModal('reserveNumber')(getState()) as Modal).contextItem as ReserveNumberForm).errors, purpose: (!reserveNumbersForm.purpose) ? t('This field is mandatory') : undefined}}));
                // next(ModalDocumentActions.setContextItemProperty({id: 'reserveNumber', name: 'errors', value: {...((getGenericModal('reserveNumber')(getState()) as Modal).contextItem as ReserveNumberForm).errors, entity: (!reserveNumbersForm.entity) ? t('This field is mandatory') : undefined}}));
                next(ModalActions.DocumentActions.setContextItemProperty({id: 'reserveNumber', name: 'errors', value: {...((getGenericModal('reserveNumber')(getState()) as Modal).contextItem as ReserveNumberForm).errors, pbs: (!reserveNumbersForm.pbs) ? t('This field is mandatory') : undefined}}));
                next(ModalActions.DocumentActions.setContextItemProperty({id: 'reserveNumber', name: 'errors', value: {...((getGenericModal('reserveNumber')(getState()) as Modal).contextItem as ReserveNumberForm).errors, discipline: (!reserveNumbersForm.discipline) ? t('This field is mandatory') : undefined}}));
                next(ModalActions.DocumentActions.setContextItemProperty({id: 'reserveNumber', name: 'errors', value: {...((getGenericModal('reserveNumber')(getState()) as Modal).contextItem as ReserveNumberForm).errors, docType: (!reserveNumbersForm.docType) ? t('This field is mandatory') : undefined}}));
                next(ModalActions.DocumentActions.setContextItemProperty({id: 'reserveNumber', name: 'errors', value: {...((getGenericModal('reserveNumber')(getState()) as Modal).contextItem as ReserveNumberForm).errors, type: (!reserveNumbersForm.type) ? t('This field is mandatory') : undefined}}));           
                
                reserveNumbersForm = (getGenericModal('reserveNumber')(getState()) as any).contextItem as ReserveNumberForm;

                if(!reserveNumbersForm.errors || !_.some(reserveNumbersForm.errors, e => !!e)){
                    dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true }));
                    dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                        request: { 
                            method: 'POST', 
                            body: {
                                creator: getEmail(getState()),
                                pbs: (reserveNumbersForm.pbs) ? reserveNumbersForm.pbs.name : undefined,
                                disdoctype: (reserveNumbersForm.discipline && reserveNumbersForm.docType) ? (reserveNumbersForm.discipline.code + '-' + reserveNumbersForm.docType.docTypeCode) : undefined,
                                projectId: getSelectedProjectId(getState()) as number,
                                typeId: (reserveNumbersForm.type) ? reserveNumbersForm.type.id : undefined,
                                quantity: reserveNumbersForm.number,
                                engineeringPhaseId: (reserveNumbersForm.engPhase) ? reserveNumbersForm.engPhase.id : undefined,
                                // entityId: (reserveNumbersForm.entity) ? reserveNumbersForm.entity.id : undefined,
                                purposeId: (reserveNumbersForm.purpose) ? reserveNumbersForm.purpose.id : undefined,
                                titleLine1Pl: reserveNumbersForm.titleLine1Pl,
                                titleLine2Pl: reserveNumbersForm.titleLine2Pl,
                                titleLine3Pl: reserveNumbersForm.titleLine3Pl,
                                titleLine4Pl: reserveNumbersForm.titleLine4Pl,
                                titleLine5Pl: reserveNumbersForm.titleLine5Pl,
                                titleLine6Pl: reserveNumbersForm.titleLine6Pl,
                                stakeHolderId: getStakeHolderId(getState()) as number
                            },
                            url: reserveNumbersUrl(), 
                            feature: RESERVE_NUMBERS
                        }
                    }));    
                }
            }
            break;
        }

        case NEW_REVISION: {
            let document = getContextualDocument(getState());
            if(document){
                const historyName: HistoryPathType = getHistoryName(history);
                dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true }));
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'POST', body: { creator: getEmail(getState()), documentIds: [document.id as number]}, url: (historyName !== 'createRevision') ? newRevisionUrl() : newEeoRevisionUrl(), feature: NEW_REVISION, returnObject: {document}}}));    
            }
            break;
        }

        case NEW_SHEET: {
            let document = getContextualDocument(getState());
            let sheetNumber: number | undefined = ((getGenericModal('sheet')(getState())) ? (getGenericModal('sheet')(getState()) as any).contextItem as number : undefined);
            if(document && sheetNumber !== undefined && sheetNumber > 0){
                dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true }));
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'POST', body: { creator: getEmail(getState()), documentIds: [document.id as number], quantity: sheetNumber}, url: newSheetUrl(), feature: NEW_SHEET, returnObject: {document}}}));    
            }
            break;
        }

        case GET_IDS_AND_DO_SOMETHING: {
            const historyName = getHistoryName(history);
            const documentIds = getSelectedItemsIds(getState());
            const supplierCompany = (_.some(getSelectedProjectUserActions(getState()), a => a === 'FilterDocumentsBySupplierCompany')) ? getCompany(getState()) : undefined;
            const partnerId = (_.some(getSelectedProjectUserActions(getState()), a => a === 'FilterDocumentsByPartnerId')) ? getStakeHolderId(getState()) : undefined;
            const customerId = (_.some(getSelectedProjectUserActions(getState()), a => a === 'FilterDocumentsByCustomerId')) ? getStakeHolderId(getState()) : undefined;
            if(documentIds.length > 0)
                dispatch(action.meta.callback(_.map(documentIds, id => Number(id))));
            else
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: (isExternalApp && historyName === 'pending') ? 'POST' : 'GET', body: (isExternalApp && historyName === 'pending') ? {projectId: getSelectedProjectId(getState()) as number, email: getEmail(getState())} : undefined, url: getProjectDocumentsUrl(getSelectedProjectId(getState()) as number, getFilters(getState()), getOrderColumn(getState()), 0, getStatusValues(getState()), historyName, undefined, true, supplierCompany, partnerId, customerId), feature: GET_IDS_AND_DO_SOMETHING, returnObject: {callback: action.meta.callback}}}));    
            break;
        }

        case SET_GRID_OR_MODAL_LOADERS_AND_NOTIFICATION: {
            let historyName = getHistoryName(history);
            const modalOpened = getOpenModal(getState());
            if(!modalOpened)
                dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: action.payload.value}));
            else
                dispatch(LoaderCommandActions.setLoading({ feature: 'document', loading: action.payload.value}));
            if(action.payload.message){
                next(NotificationDocumentActions.setNotificationStatus({name: (!modalOpened) ? historyName : 'document', show: true, type: action.payload.messageType, message: action.payload.message})); 
                if(action.payload.closeAutomatically)
                    setTimeout(() => { next(NotificationDocumentActions.setNotificationStatus({name: (!modalOpened) ? historyName : 'document', show: false, type: MessageBarType.info, message: ''})); }, 5000);
            }
            break;
        }

        case UPDATE_PUBLICATIONS: {
            const documents = getDocuments(getState());
            const publications = getPublications(getState());
            dispatch(SharedDataCommandActions.setPublications({value: _.orderBy(_.uniq([...publications, ..._.map(_.filter(documents, dd => dd.publication) as Document[], d => d.publication as number)]), x => x, 'desc')}));
            break;
        }

        case ApiConfigActions.API_SUCCESS:
            apiSuccessMiddleware(history, dispatch, getState, next, action);         
            break;
    
        case ApiConfigActions.API_ERROR:
            apiErrorMiddleware(history, dispatch, getState, next, action);         
            break;

        default:
            break;
    }
    documentsReportMiddlewareFunction(history, dispatch, getState, next, action);
    documentsFoldersMiddlewareFunction(history, dispatch, getState, next, action);
};

const apiSuccessMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiSuccessAction) => {
    switch(action.meta.feature){

        case FETCH_DOCUMENTS:
            const historyName = getHistoryName(history);
            let total: number = (action.payload) ? action.payload['@odata.count'] : 0;
            let skip = action.meta.returnObject.skip as number;
            let adding = action.meta.returnObject.adding as boolean | undefined;
            let documents = (action.payload) ? action.payload.value: [];
            let documentsMapped = _.map(documents, p => {return new Document(p, getStatusValues(getState()), getTypes(getState()), getPurposes(getState()), getDisciplines(getState()), getEngPhases(getState()), getEntities(getState()), getPbs(getState()), getUserPermissions(getState()), getStakeHolders(getState()), historyName)});
            next(CommandActions.setSkip({value: skip}));
            if(historyName !== 'createRevision' || documentsMapped.length === 0){
                next(CommandActions.setTotal({value: total}));
                documentsMapped = (adding === true) ? [...getDocuments(getState()), ...documentsMapped] : documentsMapped;
                next(DocumentActions.setData({data: documentsMapped, feature: FEATURE}));
                next(LoaderDocumentActions.setMainLoaders({ feature: historyName, loading: false}));
            }
            else
               dispatch(CommandActions.checkExistingEngOrders({rows: documentsMapped, adding}));       
            dispatch(EventActions.updatePublications());
            break; 

        case GET_DOCUMENTS_BY_ID_AND_UPDATE_GRID: {
            const historyName = getHistoryName(history);
            let documentIds = action.meta.returnObject.documentIds as number[];
            let documents = (action.payload) ? action.payload.value: [];
            let documentsMapped = _.map(documents, p => {return new Document(p, getStatusValues(getState()), getTypes(getState()), getPurposes(getState()), getDisciplines(getState()), getEngPhases(getState()), getEntities(getState()), getPbs(getState()), getUserPermissions(getState()), getStakeHolders(getState()), historyName)});
            let missingIds: number[] = [];
            let documentsUpdated: Document[] = [];
            _.forEach(documentIds, id => {
                let filteredDocument = _.find(documentsMapped, dm => dm.id as number === id);
                missingIds = (!filteredDocument) ? [...missingIds, id] : missingIds;
            });
            let gridDocuments = getDocuments(getState());
            _.forEach(gridDocuments, d => {
                let updatedDocument = _.find(documentsMapped, ud => ud.id as number === d.id as number);
                documentsUpdated = (updatedDocument) ? [...documentsUpdated, updatedDocument] : ((!_.find(missingIds, id => d.id as number === id)) ? [...documentsUpdated, d] : documentsUpdated);
            });
            next(CommandActions.setTotal({value: (getTotal(getState())) - missingIds.length}));
            next(DocumentActions.setData({data: documentsUpdated, feature: FEATURE}));
            next(CommandActions.setFetchGuid({value: uuidv4()}));
            next(LoaderDocumentActions.setMainLoaders({ feature: historyName, loading: false}));
            break; 
        }

        case UPLOAD_DOCUMENTS: {
            let failedFiles = action.meta.returnObject.failedFiles as string[];
            let documents = action.meta.returnObject.documents as Document[];
            let uploadFileForm = action.meta.returnObject.uploadFileForm as {fileForm: FileForm, files: any[]};
            let statusChanged = action.meta.returnObject.statusChanged as boolean | undefined;
            const documentIds =  _.map(documents, d => d.id as number);
            let total = action.meta.returnObject.total as number;
            if(uploadFileForm.files && uploadFileForm.files.length > 0){
                next(CommandActions.setDocumentsToUpload({value: (total - (total - uploadFileForm.files.length))}));       
                dispatch(CommandActions.uploadDocuments({uploadFileForm: {...uploadFileForm, fileForm: {...uploadFileForm.fileForm, fileName: uploadFileForm.files[0].name}}, total, failedFiles, documents, statusChanged}));
            }
            else{
                next(CommandActions.setDocumentsToUploadTotal({value: 0}));       
                next(CommandActions.setDocumentsToUpload({value: 0}));
                dispatch(ModalActions.CommandActions.closeModal({id: 'uploadDocuments'}));
                const modalOpened = getOpenModal(getState());
                if(!modalOpened)
                    dispatch(CommandActions.getDocumentsByIdAndUpdateGrid({documentIds}));
                else
                    dispatch(DocumentCommandActions.getDocumentFiles({documentId: documentIds[0]}));
                if(failedFiles && failedFiles.length > 0)
                    dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: t('Cannot upload these files') + ": " + _.toString(failedFiles) + '. ' + t('Please retry') + '.', messageType: MessageBarType.error }));       
                else
                    dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: !(statusChanged) ? t('Files uploaded successfully') : t('Status changed and files uploaded successfully'), messageType: MessageBarType.success, closeAutomatically: true })); 
                SignalrConfigurator.refreshIds(getEmail(getState()), documentIds);                               
            }
            break; 
        }

        case UPLOAD_SUPPLIER_FILE: {
            let documentId = action.meta.returnObject.documentId as number;
            next(CommandActions.setDocumentsToUploadTotal({value: 0}));       
            next(CommandActions.setDocumentsToUpload({value: 0}));
            dispatch(ModalActions.CommandActions.closeModal({id: 'uploadParent'}));
            dispatch(CommandActions.getDocumentsByIdAndUpdateGrid({documentIds: [documentId]}));
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: t('File uploaded successfully'), messageType: MessageBarType.success, closeAutomatically: true }));         
            SignalrConfigurator.refreshIds(getEmail(getState()), [documentId]);        
            break; 
        }

        case UPLOAD_PARTNER_FILE: {
            let documentId = action.meta.returnObject.documentId as number;
            next(CommandActions.setDocumentsToUploadTotal({value: 0}));       
            next(CommandActions.setDocumentsToUpload({value: 0}));
            dispatch(ModalActions.CommandActions.closeModal({id: 'uploadParent'}));
            dispatch(CommandActions.getDocumentsByIdAndUpdateGrid({documentIds: [documentId]}));
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: t('File uploaded successfully'), messageType: MessageBarType.success, closeAutomatically: true }));         
            SignalrConfigurator.refreshIds(getEmail(getState()), [documentId]);        
            break; 
        }

        case IMPORT_ZIP: {
            next(CommandActions.setDocumentsToUploadTotal({value: 0}));       
            next(CommandActions.setDocumentsToUpload({value: 0}));
            dispatch(ModalActions.CommandActions.closeModal({id: 'importZip'}));
            dispatch(CommandActions.fetchDocuments({}));
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: t('File uploaded successfully'), messageType: MessageBarType.success, closeAutomatically: true }));         
            break; 
        }

        case CHANGE_STATUS: {
            let documents = action.meta.returnObject.documents as Document[];
            let uploadFilesForm = action.meta.returnObject.uploadFilesForm as UploadFilesForm | undefined;
            const historyName = getHistoryName(history);
            if(!uploadFilesForm){
                dispatch(CommandActions.getDocumentsByIdAndUpdateGrid({documentIds: [..._.map(documents, d => d.id as number)]}));
                next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.success, message: t('Status changed successfully')}));  
                setTimeout(() => { next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: false, type: MessageBarType.info, message: ''})); }, 5000);
                SignalrConfigurator.refreshIds(getEmail(getState()), [..._.map(documents, d => d.id as number)]);
            }
            else
                dispatch(CommandActions.uploadDocuments({statusChanged: true, uploadFileForm: {fileForm: uploadFilesForm.fileForm, files: uploadFilesForm.files as any[] }, total: uploadFilesForm.total, documents: uploadFilesForm.documents}));              
    
            break; 
        }

        case EDIT_DOCUMENT: {
            let form = action.meta.returnObject.form as EditDocumentForm;
            const historyName = getHistoryName(history);
            dispatch(CommandActions.getDocumentsByIdAndUpdateGrid({documentIds: form.documentIds}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.success, message: (form.documentIds.length === 1) ? t('Document edited successfully') : t('Documents edited successfully')}));  
            setTimeout(() => { next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: false, type: MessageBarType.info, message: ''})); }, 5000);
            SignalrConfigurator.refreshIds(getEmail(getState()), form.documentIds);
            break;
        }

        case DELETE_DOCUMENTS: {
            let ids = action.meta.returnObject.ids as number[];
            let documentNumbers = action.meta.returnObject.documentNumbers as string[];
            const historyName = getHistoryName(history);
            dispatch(CommandActions.getDocumentsByIdAndUpdateGrid({documentIds: ids}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.success, message: _.join(documentNumbers, ', ') + ' ' +  t('deleted successfully')}));  
            setTimeout(() => { next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: false, type: MessageBarType.info, message: ''})); }, 5000);
            SignalrConfigurator.refreshIds(getEmail(getState()), ids); 
            break;
        }

        case DELETE_FILE: {
            let documentId = action.meta.returnObject.documentId as number;
            let file = action.meta.returnObject.file as FileGrid;
            let hideUiLoadsAndNots = action.meta.returnObject.hideUiLoadsAndNots as boolean | undefined;
            const modalOpened = getOpenModal(getState());
            if(!modalOpened)
                dispatch(CommandActions.getDocumentsByIdAndUpdateGrid({documentIds: [documentId]}));
            else
                dispatch(DocumentCommandActions.getDocumentFiles({documentId}));
            if(!hideUiLoadsAndNots){
                dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message:  file.fileName + ' ' +  t('deleted successfully'), messageType: MessageBarType.success, closeAutomatically: true }));
            }
            SignalrConfigurator.refreshIds(getEmail(getState()), [documentId]); 
            break;
        }

        case CHECK_EXISTING_ENG_ORDERS: {
            let rows = action.meta.returnObject.rows as Document[];
            let checkedDocuments = (action.payload) ? action.payload.value: [];
            let checkedDocumentsMapped: Document[] = _.map(checkedDocuments, d => {return {id: d.documentId, documentNumber: d.documentNumber}}) as Document[];
            const historyName = getHistoryName(history);
            let documents: Document[] = [];
            let adding = action.meta.returnObject.adding as boolean | undefined;
            if(rows && rows.length > 0 && checkedDocumentsMapped && checkedDocumentsMapped.length > 0){     
                _.forEach(rows, r => {
                    documents = (_.some(checkedDocumentsMapped, cd => cd.documentNumber === r.documentNumber)) ? ((documents.length > 0) ? [...documents, r] : [r]) : documents;
                }); 
            }
            documents = (adding === true) ? [...getDocuments(getState()), ...documents] : documents; 
            next(CommandActions.setTotal({value: documents.length})); 
            next(DocumentActions.setData({data: documents, feature: FEATURE}));
            next(LoaderDocumentActions.setMainLoaders({ feature: historyName, loading: false}));
            break;
        }

        case RESERVE_NUMBERS: {
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: t('Operation completed successfully'), messageType: MessageBarType.success, closeAutomatically: true }));      
            dispatch(CommandActions.fetchDocuments({}));
            dispatch(ModalActions.CommandActions.closeModal({id: 'reserveNumber'}));
            break;
        }

        case NEW_REVISION: {
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: t('Revision created successfully'), messageType: MessageBarType.success, closeAutomatically: true }));      
            dispatch(CommandActions.fetchDocuments({}));
            break;
        }

        case NEW_SHEET: {
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: t('Sheet addedd successfully'), messageType: MessageBarType.success, closeAutomatically: true }));      
            dispatch(CommandActions.fetchDocuments({}));
            break;
        }

        case GET_IDS_AND_DO_SOMETHING: {
            let callback: (documentIds: number[]) => any = action.meta.returnObject.callback;
            let documents = (action.payload) ? action.payload.value: [];
            let documentIds = _.map(documents, p => {return p.documentId as number});
            dispatch(callback(documentIds));
            break; 
        }
       
        default:
            break;
    }
}

const apiErrorMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiErrorAction) => {
    switch(action.meta.feature){

        case FETCH_DOCUMENTS: {
            const historyName = getHistoryName(history);
            next(LoaderDocumentActions.setMainLoaders({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot get the documents for this project') +  t('Please refresh the page')}));
            break; 
        }

        case CHECK_EXISTING_ENG_ORDERS: {
            const historyName = getHistoryName(history);
            next(LoaderDocumentActions.setMainLoaders({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot get the documents for this project') +  t('Please refresh the page')}));
            break; 
        }

        case GET_DOCUMENTS_BY_ID_AND_UPDATE_GRID: {
            const historyName = getHistoryName(history);
            next(LoaderDocumentActions.setMainLoaders({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot get updated document') + t('Please refresh the page')}));
            break; 
        }

        case UPLOAD_DOCUMENTS: {
            let fileName = (action.meta.returnObject) ? action.meta.returnObject.fileName : undefined;
            let failedFiles = action.meta.returnObject.failedFiles as string[];
            let documents = action.meta.returnObject.documents as Document[];
            let uploadFileForm = action.meta.returnObject.uploadFileForm as {fileForm: FileForm, files: any[]};
            let statusChanged = action.meta.returnObject.statusChanged as boolean | undefined;
            const documentIds =  _.map(documents, d => d.id as number);
            let total = action.meta.returnObject.total as number;
            if(uploadFileForm.files && uploadFileForm.files.length > 0){
                next(CommandActions.setDocumentsToUpload({value: (total - (total - uploadFileForm.files.length))}));       
                dispatch(CommandActions.uploadDocuments({uploadFileForm: {...uploadFileForm, fileForm: {...uploadFileForm.fileForm, fileName: uploadFileForm.files[0].name}}, total, failedFiles: (failedFiles) ? [...failedFiles, fileName]: [fileName], documents, statusChanged}));
            }
            else{
                failedFiles = (failedFiles) ? [...failedFiles, fileName] : [fileName];
                next(CommandActions.setDocumentsToUploadTotal({value: 0}));       
                next(CommandActions.setDocumentsToUpload({value: 0}));
                dispatch(ModalActions.CommandActions.closeModal({id: 'uploadDocuments'}));
                const modalOpened = getOpenModal(getState());
                if(!modalOpened)
                    dispatch(CommandActions.getDocumentsByIdAndUpdateGrid({documentIds}));
                else
                    dispatch(DocumentCommandActions.getDocumentFiles({documentId: documentIds[0]}));
                dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: t('Cannot upload these files') + ': ' + _.toString(failedFiles) + '. ' + t('Please retry'), messageType: MessageBarType.error }));       
                SignalrConfigurator.refreshIds(getEmail(getState()), documentIds);                               
            }
            break; 
        }

        case IMPORT_ZIP: {
            let error = (action.payload && action.payload.response && action.payload.response.body) ? action.payload.response.body : '';
            const historyName = getHistoryName(history);
            next(CommandActions.setDocumentsToUploadTotal({value: 0}));       
            next(CommandActions.setDocumentsToUpload({value: 0}));  
            dispatch(ModalActions.CommandActions.closeModal({id: 'importZip'}));
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: (error && error.DetailMessage) ? error.DetailMessage : t('Cannot upload this file') + '. ' +  t('Please retry')}));  
            break; 
        }

        case UPLOAD_SUPPLIER_FILE: {
            const historyName = getHistoryName(history);
            next(CommandActions.setDocumentsToUploadTotal({value: 0}));       
            next(CommandActions.setDocumentsToUpload({value: 0}));  
            dispatch(ModalActions.CommandActions.closeModal({id: 'uploadParent'}));
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot upload this file') + '. ' + t('Please retry')}));  
            break; 
        }

        case UPLOAD_PARTNER_FILE: {
            const historyName = getHistoryName(history);
            next(CommandActions.setDocumentsToUploadTotal({value: 0}));       
            next(CommandActions.setDocumentsToUpload({value: 0}));  
            dispatch(ModalActions.CommandActions.closeModal({id: 'uploadParent'}));
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot upload this file') + '. ' + t('Please retry')}));  
            break; 
        }

        case CHANGE_STATUS: {
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot change the status') + '. ' + t('Please retry')}));
            break;
        }

        case EDIT_DOCUMENT: {
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot edit this document') + '. ' + t('Please retry')}));
            break;
        }

        case DELETE_FILE: {
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message:  t('Cannot delete this file') + '. ' + t('Please retry'), messageType: MessageBarType.error }));
            break;
        }

        case DELETE_DOCUMENTS: {
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot delete') + '. ' + t('Please retry')}));
            break;
        }

        case DOWNLOAD_COMMENTS: {
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot download comments') + '. ' + t('Please retry')}));
            break;
        }

        case GET_IDS_AND_DO_SOMETHING: {
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot get all document ids') + '. ' + t('Please retry')}));
            break;
        }

        case RESERVE_NUMBERS: {
            const historyName = getHistoryName(history);
            dispatch(ModalActions.CommandActions.closeModal({id: 'reserveNumber'}));
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot reserve numbers') + '. ' + t('Please retry')}));
            break;
        }

        case NEW_REVISION: {
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot create new revision') + '. ' + t('Please retry')}));
            break;
        }

        case NEW_SHEET: {
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot add new sheet') + '. ' + t('Please retry')}));
            break;
        }

        default:
            break;
    }
}

const resetVerticalScroll = (): void => {
    var scrollbarDiv = document.getElementById('documents_scrollbar');
    if(scrollbarDiv && scrollbarDiv.children.length > 0)
        scrollbarDiv.children[0].scrollTop = 0; 
}