import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getClassNames } from '../index.classNames';
import { Label, TextField, Text } from '@fluentui/react';
import _ from 'lodash';
import ScrollablePaneHistory from '../../../../components/scrollablePaneHistory/index';
import { Document } from '../../../../models/document';
import { NumericFormat } from 'react-number-format';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface DetailsProps extends RouteComponentProps, WithTranslation {
    document: Document
}

class Details extends React.Component<DetailsProps> {
    constructor(props: DetailsProps) {
        super(props);   
    }

    render(){
        const { document, t } = this.props;
        const styles = getClassNames();
        return (
            <>
                <div className={styles.tabContainer}>           
                    <div style={{marginBottom: 30}}><Text variant='xLarge' nowrap>{t('Details')}</Text></div>    
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: 10}}>
                        {document.totalSheet !== undefined && document.totalSheet > 0 &&
                            <div>
                                <Label>{t('Total Sheet')}</Label>
                                <NumericFormat
                                    key="sheet"
                                    readOnly
                                    customInput={TextField}
                                    decimalScale={0}
                                    value={(document.totalSheet) ? document.totalSheet : ''}
                                    autoComplete={'off'}
                                    title={document.totalSheet.toString()}
                                    styles={{root: {minWidth: 256}}}
                                />   
                            </div>
                        }
                        {document.format &&
                            <TextField 
                                readOnly
                                label={t("Format")} 
                                autoComplete={'off'} 
                                value={document.format} 
                                title={document.format}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.scale &&
                            <TextField 
                                readOnly
                                label={t("Scale")} 
                                autoComplete={'off'} 
                                value={document.scale} 
                                title={document.scale}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.revision &&
                            <TextField 
                                readOnly
                                label={t("Revision")}
                                autoComplete={'off'} 
                                value={document.revision} 
                                title={document.revision}
                                styles={{root: {minWidth: 256}}}
                            />        
                        }
                        {document.clientNumber &&
                            <TextField 
                                readOnly
                                label={t("Client number")}
                                autoComplete={'off'} 
                                value={document.clientNumber} 
                                title={document.clientNumber}
                                styles={{root: {minWidth: 256}}}
                            />        
                        }
                        {document.customerRevision &&
                            <TextField 
                                readOnly
                                label={t("Customer revision")}
                                autoComplete={'off'} 
                                value={document.customerRevision} 
                                title={document.customerRevision}
                                styles={{root: {minWidth: 256}}}
                            />        
                        }
                        {document.supplierDocumentNumber &&
                            <TextField 
                                readOnly
                                label={t("Supplier Doc.Number")}
                                autoComplete={'off'} 
                                value={document.supplierDocumentNumber} 
                                title={document.supplierDocumentNumber}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.engineeringElement &&
                            <TextField 
                                readOnly
                                label={t("Engineering Element")} 
                                autoComplete={'off'} 
                                value={document.engineeringElement} 
                                title={document.engineeringElement}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.type &&
                            <TextField 
                                readOnly
                                label={t("Type")} 
                                autoComplete={'off'} 
                                value={document.type.name} 
                                title={document.type.name}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.tdNumber &&
                            <TextField 
                                readOnly
                                label={t("TD Number")} 
                                autoComplete={'off'} 
                                value={document.tdNumber} 
                                title={document.tdNumber}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.purpose &&
                            <TextField 
                                readOnly
                                label={t("Purpose")} 
                                autoComplete={'off'} 
                                value={document.purpose.name + ' - ' + document.purpose.description} 
                                title={document.purpose.name + ' - ' + document.purpose.description}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.pbs &&
                            <TextField 
                                readOnly
                                label="PBS" 
                                autoComplete={'off'} 
                                value={document.pbs} 
                                title={document.pbs}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.plant &&
                            <TextField 
                                readOnly
                                label={t("Plant")} 
                                autoComplete={'off'} 
                                value={document.plant.code + ' - ' + document.plant.description} 
                                title={document.plant.code + ' - ' + document.plant.description} 
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.plantUnit &&
                            <TextField 
                                readOnly
                                label={t("Plant unit")} 
                                autoComplete={'off'} 
                                value={document.plantUnit.code + ' - ' + document.plantUnit.description} 
                                title={document.plantUnit.code + ' - ' + document.plantUnit.description} 
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.plantSection &&
                            <TextField 
                                readOnly
                                label={t("Plant section")} 
                                autoComplete={'off'} 
                                value={document.plantSection.code + ' - ' + document.plantSection.description} 
                                title={document.plantSection.code + ' - ' + document.plantSection.description} 
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.equipment &&
                            <TextField 
                                readOnly
                                label={t("Equipment")} 
                                autoComplete={'off'} 
                                value={document.equipment.code + ' - ' + document.equipment.description} 
                                title={document.equipment.code + ' - ' + document.equipment.description} 
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.discipline &&
                            <TextField 
                                readOnly
                                 label={t("Discipline")} 
                                autoComplete={'off'} 
                                value={document.discipline.code + ' - ' + document.discipline.name} 
                                title={document.discipline.code + ' - ' + document.discipline.name}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.docType &&
                            <TextField 
                                readOnly
                                label={t("Doc Type")} 
                                autoComplete={'off'} 
                                value={document.docType.code + ' - ' + document.docType.name} 
                                title={document.docType.code + ' - ' + document.docType.name}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.engPhase &&
                            <TextField 
                                readOnly
                                label={t("Eng.phase")} 
                                autoComplete={'off'} 
                                value={document.engPhase.code + ' - ' + document.engPhase.name} 
                                title={document.engPhase.code + ' - ' + document.engPhase.name}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.itemNumber &&
                            <TextField 
                                readOnly
                                label={t("Item Number")} 
                                autoComplete={'off'} 
                                value={document.itemNumber} 
                                title={document.itemNumber}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.materialNumber &&
                            <TextField 
                                readOnly
                                label={t("Material Number")} 
                                autoComplete={'off'} 
                                value={document.materialNumber} 
                                title={document.materialNumber}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.designation &&
                            <TextField 
                                readOnly
                                label={t("Designation")} 
                                autoComplete={'off'} 
                                value={document.designation} 
                                title={document.designation}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.appendix &&
                            <TextField 
                                readOnly
                                label="Appendix" 
                                autoComplete={'off'} 
                                value={document.appendix} 
                                title={document.appendix}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.entity &&
                            <TextField 
                                readOnly
                                 label={t("Entity")} 
                                autoComplete={'off'} 
                                value={document.entity.name} 
                                title={document.entity.name}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                        {document.internalOrderNumber &&
                            <div>
                                <Label>{t('Internal Order Number')}</Label>
                                <NumericFormat
                                    key="internalOrderNumber"
                                    readOnly
                                    customInput={TextField}
                                    decimalScale={0}
                                    value={(document.internalOrderNumber) ? document.internalOrderNumber : ''}
                                    title={document.internalOrderNumber}
                                    autoComplete={'off'}
                                />   
                            </div>       
                        }
                          {document.supplierDocumentNumber &&
                            <TextField 
                                readOnly
                                 label={t("Supplier Doc.Number")} 
                                autoComplete={'off'} 
                                value={document.supplierDocumentNumber} 
                                title={document.supplierDocumentNumber}
                                styles={{root: {minWidth: 256}}}
                            />          
                        }
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({

});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
     
    }, dispatch);
}


export default connect(undefined, matchDispatchToProps)(withTranslation()(withRouter(Details)));