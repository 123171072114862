import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from '../index.classNames';
import { getClassNames as getTagStyles } from './index.classNames';
import { Checkbox, ComboBox, IComboBoxOption, KeyCodes, Label, Text, TextField} from '@fluentui/react';
import _ from 'lodash';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { CommandActions as ProjectCommandActions } from '../../../../redux/actions/project';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getAllProjects, getDarkModeEnabled, getIsEdit, getProjectFormAutomaticApprovalDays, getProjectFormId, getProjectFormIsActiveInAgile, getProjectFormIsAgileTdCoverPageInternalUse, getProjectFormIsApproveWithCommentsStatusActive, getProjectFormIsClientNumGeneratorPageActive, getProjectFormIsEnabled, getProjectFormIsExternalCheckActive, getProjectFormIsFolderPageActive, getProjectFormIsInternalCheckActive, getProjectFormMandatoryDocumentColumns, getProjectFormMandatoryDrawingColumns, getProjectFormName, getProjectFormTemplate } from '../../../../redux/reducers/project';
import { Project } from '../../../../models/project';
import { Tag, WithContext as ReactTags } from 'react-tag-input';
import { getTheme } from '../../../../utils/costants';

export interface BodyProps extends RouteComponentProps { 
    mandatoryDrawingColumns: string[],
    mandatoryDocumentColumns: string[],
    template?: string,
    allProjects: Project[],
    isEdit: boolean,
    darkModeEnabled: boolean,
    setProjectFormMandatoryDrawingColumns: typeof ProjectCommandActions.setProjectFormMandatoryDrawingColumns,
    setProjectFormMandatoryDocumentColumns: typeof ProjectCommandActions.setProjectFormMandatoryDocumentColumns,
    setProjectFormTemplate: typeof ProjectCommandActions.setProjectFormTemplate,
}

class Body extends React.Component<BodyProps> {
    constructor(props: BodyProps) {
        super(props); 
    }

    addDrawingTag = (tag: Tag) => {
        const { setProjectFormMandatoryDrawingColumns, mandatoryDrawingColumns } = this.props;
        setProjectFormMandatoryDrawingColumns({value: (tag && tag.id) ? [...mandatoryDrawingColumns, tag.id] : mandatoryDrawingColumns});
    }
    removeDrawingTag = (index: number) => {
        const { setProjectFormMandatoryDrawingColumns, mandatoryDrawingColumns } = this.props;
        setProjectFormMandatoryDrawingColumns({value: _.filter(mandatoryDrawingColumns, (dn, i) =>  index !== i)});
    }

    addDocumentTag = (tag: Tag) => {
        const { setProjectFormMandatoryDocumentColumns, mandatoryDocumentColumns } = this.props;
        setProjectFormMandatoryDocumentColumns({value: (tag && tag.id) ? [...mandatoryDocumentColumns, tag.id] : mandatoryDocumentColumns});
    }
    removeDocumentTag = (index: number) => {
        const { setProjectFormMandatoryDocumentColumns, mandatoryDocumentColumns } = this.props;
        setProjectFormMandatoryDocumentColumns({value: _.filter(mandatoryDocumentColumns, (dn, i) =>  index !== i)});
    }

    render(){
        const { mandatoryDrawingColumns, mandatoryDocumentColumns, template, darkModeEnabled, setProjectFormTemplate } = this.props;
        const styles = getClassNames();
        const tagStyles = getTagStyles(getTheme(darkModeEnabled));
        const template_options: IComboBoxOption[] =  [{key: 'Title456', text: 'Title456'}, {key: 'Title456ML', text: 'Title456ML'}];
        const drawingTags: Tag[] = (mandatoryDrawingColumns.length > 0) ? _.map(mandatoryDrawingColumns, c => {return {id: c, text: c}}) : [];
        const documentTags: Tag[] = (mandatoryDocumentColumns.length > 0) ? _.map(mandatoryDocumentColumns, c => {return {id: c, text: c}}) : [];
        return ( 
            <>         
                <div className={styles.tabContainer}>    
                    <div style={{marginBottom: 30}}><Text variant='xLarge' nowrap >EEO</Text></div>   
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: 10, alignItems: 'center'}}>
                        <div style={{marginRight: 20, height: 98}}>
                            <Label>Mandatory drawing columns</Label>
                            <ReactTags
                                tags={drawingTags}
                                delimiters={[KeyCodes.enter]}
                                handleDelete={this.removeDrawingTag}
                                handleAddition={this.addDrawingTag}
                                placeholder="Press enter to add a new column"
                                inputFieldPosition="top"
                                allowDragDrop={false}
                                autofocus={false}
                                classNames={{
                                    tagInputField: tagStyles.inputField,
                                    selected: tagStyles.tagInput,
                                    tag: tagStyles.tag,
                                    remove: tagStyles.remove
                                }}
                            />
                        </div>
                        <div style={{height: 98}}>
                            <Label>Mandatory document columns</Label>
                            <ReactTags
                                tags={documentTags}
                                delimiters={[KeyCodes.enter]}
                                handleDelete={this.removeDocumentTag}
                                handleAddition={this.addDocumentTag}
                                placeholder="Press enter to add a new column"
                                inputFieldPosition="top"
                                allowDragDrop={false}
                                autofocus={false}
                                classNames={{
                                    tagInputField: tagStyles.inputField,
                                    selected: tagStyles.tagInput,
                                    tag: tagStyles.tag,
                                    remove: tagStyles.remove
                                }}
                            />
                        </div>
                    </div>     
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: 10, alignItems: 'center'}}>
                        <ComboBox
                            required
                            label="Template" 
                            placeholder="Select a template..."
                            selectedKey={template}
                            options={template_options}
                            onChange={(e, value) => setProjectFormTemplate({value: (value as IComboBoxOption).key as string})}                           
                            useComboBoxAsMenuWidth={true}         
                            calloutProps={{calloutMaxHeight: 320}}
                            styles={{root: {minWidth: 256}}}
                        />                
                    </div>
                </div>                                                                                                                 
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    allProjects: getAllProjects,
    isEdit: getIsEdit,
    mandatoryDrawingColumns: getProjectFormMandatoryDrawingColumns,
    mandatoryDocumentColumns: getProjectFormMandatoryDocumentColumns,
    template: getProjectFormTemplate,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        setProjectFormMandatoryDrawingColumns: ProjectCommandActions.setProjectFormMandatoryDrawingColumns,
        setProjectFormMandatoryDocumentColumns: ProjectCommandActions.setProjectFormMandatoryDocumentColumns,
        setProjectFormTemplate: ProjectCommandActions.setProjectFormTemplate,
     }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Body));