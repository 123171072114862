import { mergeStyleSets } from '@fluentui/react';

export interface IComponentClassNames {
    resultContainer: string,
    mainContainer: string
}
  
export const getClassNames = (largerPadding: boolean): IComponentClassNames => {
    return mergeStyleSets({
        resultContainer: {
            paddingTop: 5,
            paddingBottom: 10,
            paddingLeft: 100,
            paddingRight: (largerPadding) ? 400 : 100,
            
            // selectors:{
            //     '@media only screen and (max-width: 1279px)': {
            //         paddingRight: (largerPadding) ? 100 : 10,
            //     }
            // }
        },
        mainContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }

            
    });
};