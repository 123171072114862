import React from 'react'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { Switch, Redirect } from 'react-router-dom'
import './App.css';
import PrivateRoute from './components/private_route/private_route';
import { loadTheme } from '@fluentui/react';
import {ExternalRoleActionsType, getTheme, InternalRoleActionsType, isExternalApp} from './utils/costants';
import MasterPage from './containers/master_page/index';
import Logo from './containers/logo/index';
import Settings from './containers/login/settings';
import Drawer from './containers/drawer/index';
import {AuthConfigActions, AuthConfigReducer} from '@pwbapps/reduxcore';
import ErrorBox from './components/error_box/index';
import Home from './containers/main/index';
import Search from './containers/search/index';
import Pending from './containers/pending/index';
import Landing from './containers/landing/index';
import ProjectsConfiguration from './containers/projects/index';
import ReservedNumbers from './containers/reservedNumbers/index';
import PublishMaterial from './containers/publishMaterial/index';
import SupplierMaterial from './containers/supplierMaterial/index';
import CreateRevision from './containers/createRevision/index';
import Publish from './containers/publish/index';
import QualityCheck from './containers/qualityCheck/index';
import { getLoader } from './redux/selectors/loader';
import { getSelectedProjectUserRole } from './redux/selectors/auth';
import { getDarkModeEnabled, getSelectedProjectId } from './redux/reducers/project';
import { getSelectedProjectUserActions } from './redux/selectors/auth';
import { AccountInfo } from '@azure/msal-browser';
import { Role } from '@pwbapps/reduxcore/dist/models/role';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { getRoles } from '@pwbapps/reduxcore/dist/redux/reducers/auth';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';

initializeIcons();
initializeFileTypeIcons();

export interface AppProps {
	account: AccountInfo,
	loading: boolean,
	selectedProjectUserRole?: Role,
	roles: Role[],
	selectedProjectId?: number,
	userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
	i18n: any,
	darkModeEnabled: boolean
	initUserData: typeof AuthConfigActions.CommandActions.initUserData
}

class App extends React.Component<AppProps> {
	constructor(props: AppProps) {
		super(props);   
		loadTheme(getTheme(props.darkModeEnabled));
    }

	componentDidMount = () => {
		const {initUserData, i18n} = this.props;
		if(!isExternalApp && i18n.resolvedLanguage !== 'en')
			i18n.changeLanguage('en');
		initUserData({});
	}
	
	render() {
		const { roles, account, loading, selectedProjectUserRole, selectedProjectId, userProjectActions } = this.props;
		const isSuperAdmin = (roles && roles.length > 0 && _.some(roles, r => r.name === 'SUPERADMIN'));
		const authorized = (roles && roles.length > 0 && !loading) ? true : false;
		return (
			<>
				
					{!!account && !!authorized &&
						<>
							{!!selectedProjectId && 
								
									<MasterPage 
										leftComponent={<Logo/>}
										rightComponent={<Settings/>} 
										bodyComponent={
											<Switch>
												<PrivateRoute exact path="/" component={() => <Redirect to={{pathname: (!!_.find(userProjectActions, a => a === 'ShowHome')) ? "/home" : "/landing"}}/>} />
												<PrivateRoute exact path="/landing" component={Landing} />	
												<PrivateRoute isAuthorized={!!_.find(userProjectActions, a => a === 'ShowHome')} exact path="/home" component={Home} />	
												<PrivateRoute isAuthorized={!!_.find(userProjectActions, a => a === 'ShowDashboard')} exact path="/dashboard" component={Search} />	
												<PrivateRoute isAuthorized={!!_.find(userProjectActions, a => a === 'ShowQualityCheck')} exact path="/administration/qualityCheck" component={QualityCheck} />	
												<PrivateRoute isAuthorized={!!_.find(userProjectActions, a => a === 'ShowPublish')} exact path="/administration/publish" component={Publish} />	
												<PrivateRoute isAuthorized={!!_.find(userProjectActions, a => a === 'ShowPending')} exact path="/administration/pending" component={Pending} />	
												<PrivateRoute isAuthorized={!!_.find(userProjectActions, a => a === 'ShowPublishMaterial')} exact path="/administration/publishMaterial" component={PublishMaterial} />	
												<PrivateRoute isAuthorized={!!_.find(userProjectActions, a => a === 'ShowReservedNumbers')} exact path="/eeo/reservedNumbers" component={ReservedNumbers} />	
												<PrivateRoute isAuthorized={!!_.find(userProjectActions, a => a === 'ShowSupplierMaterial')} exact path="/eeo/supplierMaterial" component={SupplierMaterial} />	
												<PrivateRoute isAuthorized={!!_.find(userProjectActions, a => a === 'ShowCreateRevision')} exact path="/eeo/createRevision" component={CreateRevision} />	
												<PrivateRoute isAuthorized={isSuperAdmin} exact path="/projectsConfiguration" component={ProjectsConfiguration} />	
												<PrivateRoute exact path="*" component={() => <Redirect to={{pathname: (!!_.find(userProjectActions, a => a === 'ShowHome')) ? "/home" : "/landing"}}/>} />
											</Switch>
										}
										navigation={<Drawer/>}
									/>
								
							}
							{!selectedProjectId && 
							
									<MasterPage 
										leftComponent={<Logo/>}
										rightComponent={<Settings/>} 
										bodyComponent={
											<Switch>
												<PrivateRoute exact path="/" component={() => <Redirect to={{pathname: "/landing"}}/>} />
												<PrivateRoute exact path="/landing" component={Landing} />	
												<PrivateRoute exact path="*" component={() => <Redirect to={{pathname: "/landing"}}/>} />
											</Switch>
										}
										navigation={<Drawer/>}
									/>
								
							}
						</>			
					}
					{!!account && !authorized &&
		
							<MasterPage 
								leftComponent={<Logo/>}
								rightComponent={<Settings/>} 
								bodyComponent={
									<ErrorBox text='User not authorized' grey={true} />
								}
							/>

					}	
				
			</>
		)
	}
}


const mapStateToProps = createStructuredSelector({
	account: AuthConfigReducer.getAccount,
	loading: (state) => getLoader('project')(state).loading,
	selectedProjectUserRole: getSelectedProjectUserRole,
	roles: getRoles,
	selectedProjectId: getSelectedProjectId,
	userProjectActions: getSelectedProjectUserActions,
	darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ initUserData: AuthConfigActions.CommandActions.initUserData }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(App));