export class ChartData {
    id: string
    value: number
    constructor(object: any){
        this.id = object.id;
        this.value = object.value;
    }
}

export class PublicationStatusChart {
    totalDocumentLatestRevision: ChartData
    totalDocumentUploaded: ChartData
    totalDocumentReadyForPublishing: ChartData
    totalDocumentPublishedOrChecked: ChartData
    totalDocumentPublishedNoApproval: ChartData

    constructor(object: any) {
        this.totalDocumentLatestRevision = {id: 'Latest Revision', value: object.totalDocumentLatestRevision};
        this.totalDocumentUploaded = {id: 'Uploaded', value: object.totalDocumentUploaded};
        this.totalDocumentReadyForPublishing = {id: 'Ready for Publishing', value: object.totalDocumentReadyForPublishing};
        this.totalDocumentPublishedOrChecked = {id: 'Published or Checked', value: object.totalDocumentPublishedOrChecked};
        this.totalDocumentPublishedNoApproval = {id: 'Published no Approval', value: object.totalDocumentPublishedNoApproval};
    }
}

export class ClientStatusChart {
    totalUploaded: ChartData
    totalReadyForPublishing: ChartData
    totalPublishedWithApproval: ChartData
    totalPublishedWithoutApproval: ChartData
    totalChecked: ChartData
    totalApproved: ChartData
    totalApprovedWithComments: ChartData
    totalDeclinedWithComment: ChartData
    totalCanceled: ChartData

    constructor(object: any) {
        this.totalUploaded = {id: 'Uploaded', value: object.totalUploaded};
        this.totalReadyForPublishing = {id: 'Ready for Publishing', value: object.totalReadyForPublishing};
        this.totalPublishedWithApproval = {id: 'Published with Approval', value: object.totalPublishedWithApproval};
        this.totalPublishedWithoutApproval = {id: 'Published without Approval', value: object.totalPublishedWithoutApproval};
        this.totalChecked = {id: 'Checked', value: object.totalChecked};
        this.totalApproved = {id: 'Approved', value: object.totalApproved};
        this.totalApprovedWithComments = {id: 'Approved with Comments', value: object.totalApprovedWithComments};
        this.totalDeclinedWithComment = {id: 'Declined with Comments', value: object.totalDeclinedWithComment};
        this.totalCanceled = {id: 'Canceled', value: object.totalCanceled};
    }
}

export class FinalPurposesChart {
    totalDocumentsFi: ChartData
    totalDocumentsAb: ChartData
    totalDocumentsFc: ChartData
    totalDocumentsFa: ChartData
    totalDocumentsFm: ChartData
    totalDocumentsFr: ChartData
    totalDocumentsIo: ChartData
    totalDocumentsFib: ChartData
    totalDocumentsFcb: ChartData
    totalDocumentsFab: ChartData
    totalDocumentsAbb: ChartData
    totalDocumentsAmb: ChartData
    totalDocumentsFmb: ChartData
    totalDocumentsAm: ChartData

    constructor(object: any) {
        this.totalDocumentsFi = {id: 'For information', value: object.totalDocumentsFi};
        this.totalDocumentsAb = {id: 'As built', value: object.totalDocumentsAb};
        this.totalDocumentsFc = {id: 'For construction', value: object.totalDocumentsFc};
        this.totalDocumentsFa = {id: 'For approval', value: object.totalDocumentsFa};
        this.totalDocumentsFm = {id: 'For manufactioring', value: object.totalDocumentsFm};
        this.totalDocumentsFr = {id: 'For review', value: object.totalDocumentsFr};
        this.totalDocumentsIo = {id: 'For internal use only', value: object.totalDocumentsIo};
        this.totalDocumentsFib = {id: 'For information and billing', value: object.totalDocumentsFib};
        this.totalDocumentsFcb = {id: 'For construction and billing', value: object.totalDocumentsFcb};
        this.totalDocumentsFab = {id: 'For approval and billing', value: object.totalDocumentsFab};
        this.totalDocumentsAbb = {id: 'As built and billing', value: object.totalDocumentsAbb};
        this.totalDocumentsAmb = {id: 'As manufactered and billing', value: object.totalDocumentsAmb};
        this.totalDocumentsFmb = {id: 'For manufactoring and biling', value: object.totalDocumentsFmb};
        this.totalDocumentsAm = {id: 'As manufactered', value: object.totalDocumentsAm};
    
    }
}