import {createAction, ActionsUnion} from '@pwbapps/reduxcore';
import { Document, OtherRevision } from '../../models/document';
import { CommentForm } from '../../models/comment';

export const GET_DOCUMENT_AND_OPEN_MODAL = `[DOCUMENT] GET DOCUMENT AND OPEN MODAL`;
export const GET_DETAILS_AND_OPEN_MODAL = `[DOCUMENT] GET DETAILS AND OPEN MODAL`;
export const GET_DOCUMENT_REVISIONS = `[DOCUMENT] GET DOCUMENT REVISIONS`;
export const GET_DOCUMENT_FILES = `[DOCUMENT] GET DOCUMENT FILES`;
export const SET_VALUE = `[DOCUMENT] SET VALUE`;
export const RESET_AND_CLOSE_MODAL = `[DOCUMENT] RESET AND CLOSE MODAL`;
export const ADD_COMMENT = `[DOCUMENT] ADD COMMENT`;
export const DOWNLOAD_FILE = `[DOCUMENT] DOWNLOAD FILE`;


export const DocumentCommandActions = {
    getDocumentAndOpenModal: ({documentId, changingRevision}: {documentId: number, changingRevision?: boolean}) => createAction(GET_DOCUMENT_AND_OPEN_MODAL, {documentId, changingRevision}),
    getDetailsAndOpenModal: ({document}: {document: Document}) => createAction(GET_DETAILS_AND_OPEN_MODAL, {document}),
    getDocumentRevisions: ({documentId, revision, agileNumber, sheet}: {documentId: number, revision: string, agileNumber: string, sheet: number}) => createAction(GET_DOCUMENT_REVISIONS, {documentId, revision, agileNumber, sheet}),
    getDocumentFiles: ({documentId}: {documentId: number}) => createAction(GET_DOCUMENT_FILES, {documentId}),
    resetAndCloseModal: () => createAction(RESET_AND_CLOSE_MODAL),
    downloadFile: ({documentId, fileName}: {documentId: string, fileName: string}) => createAction(DOWNLOAD_FILE, {documentId, fileName}),
    setDocument: ({value}: {value?: Document}) => createAction(SET_VALUE, {name: 'document', value}),
    setOpenModal: ({value}: {value: boolean}) => createAction(SET_VALUE, {name: 'openModal', value}),
    setOtherRevisions: ({value}: {value: OtherRevision[]}) => createAction(SET_VALUE, {name: 'otherRevisions', value}),
    addComment: ({commentForm, hideUiLoadsAndNots}: {commentForm?: CommentForm, hideUiLoadsAndNots?: boolean}) => createAction(ADD_COMMENT, {commentForm, hideUiLoadsAndNots}),
}

export const DocumentEventActions = {

}
 
export const DocumentDocumentActions = {
    setValue: ({name, value}: {name: string, value?: any}) => createAction(SET_VALUE, {name, value}),
}

export const CommandActions = {...DocumentCommandActions};
export const EventActions = {...DocumentEventActions};
export const DocumentActions = {...DocumentDocumentActions};
export const Actions = {...CommandActions, ...EventActions, ...DocumentActions};

// group actions
export type CommandActions = ActionsUnion<typeof CommandActions>
export type EventActions = ActionsUnion<typeof EventActions>
export type DocumentActions = ActionsUnion<typeof DocumentActions>
export type Actions = ActionsUnion<typeof Actions>