import {DocumentActions, SET_VALUE, SET_FILTER_VALUE } from '../actions/filters';
import _ from 'lodash';
import {Filters} from '../../models/filters'
import { FiltersType } from '../../utils/costants';

interface FiltersState  {
    largerPanel: boolean
    showCallout: boolean
    elementRef?: HTMLElement
    selectedFilter?: FiltersType
    filters: Filters
    
}

export const initialFilters: Filters = {
    status: [],
    documentNumber: [],
    commented: false,
    isForInternalUse: false,
    showAllVersions: false,
    defaultPurposesList: [],
    defaultStatusList: [],
    isEngineeringOrder: false,
    ignorePurposes: false
}

const initialState: FiltersState = {
    largerPanel: false,
    showCallout: false,
    elementRef: undefined,
    selectedFilter: undefined,
    filters: initialFilters   
}

export const filtersReducer = (filters = initialState, action : DocumentActions): FiltersState => {
    switch (action.type) {
        
        case SET_FILTER_VALUE:
            return {...filters, filters: {...filters.filters, [action.payload.name]: action.payload.value }};

        case SET_VALUE:
            return {...filters, [action.payload.name]: action.payload.value };

        default:
            return filters;
    }
};

//select from state (read)
const selectFiltersState = (state: any) => state.filters as FiltersState;
export const getLargerPanel = (state: any) => selectFiltersState(state).largerPanel;
export const getFilters = (state: any) => selectFiltersState(state).filters;
export const getStatus = (state: any) => selectFiltersState(state).filters.status;
export const getShowCallout = (state: any) => selectFiltersState(state).showCallout;
export const getElementRef = (state: any) => selectFiltersState(state).elementRef;
export const getSelectedFilter = (state: any) => selectFiltersState(state).selectedFilter;
export const getFilterDocumentNumber = (state: any) => selectFiltersState(state).filters.documentNumber;
export const getFilterAgileNumber = (state: any) => selectFiltersState(state).filters.agileNumber;
export const getFilterSheet = (state: any) => selectFiltersState(state).filters.sheet;
export const getFilterRevision = (state: any) => selectFiltersState(state).filters.revision;
export const getFilterClientNumber = (state: any) => selectFiltersState(state).filters.clientNumber;
export const getFilterTdNumber = (state: any) => selectFiltersState(state).filters.tdNumber;
export const getFilterTransmittalNumber = (state: any) => selectFiltersState(state).filters.publication;
export const getFilterPwFilename = (state: any) => selectFiltersState(state).filters.pwFilename;
export const getFilterStatus = (state: any) => selectFiltersState(state).filters.status;
export const getFilterType = (state: any) => selectFiltersState(state).filters.type;
export const getFilterPurpose = (state: any) => selectFiltersState(state).filters.purpose;
export const getFilterEntity = (state: any) => selectFiltersState(state).filters.entity;
export const getFilterEngPhase = (state: any) => selectFiltersState(state).filters.engPhase;
export const getFilterFrom = (state: any) => selectFiltersState(state).filters.from;
export const getFilterTo = (state: any) => selectFiltersState(state).filters.to;
export const getFilterDiscipline = (state: any) => selectFiltersState(state).filters.discipline;
export const getFilterDocType = (state: any) => selectFiltersState(state).filters.docType;
export const getFilterPbs = (state: any) => selectFiltersState(state).filters.pbs;
export const getFilterIsForInternalUse = (state: any) => selectFiltersState(state).filters.isForInternalUse;
export const getFilterShowAllVersions = (state: any) => selectFiltersState(state).filters.showAllVersions;
export const getFilterCommented = (state: any) => selectFiltersState(state).filters.commented;
export const getFilterFolder = (state: any) => selectFiltersState(state).filters.folder;
export const getFilterPartner = (state: any) => selectFiltersState(state).filters.partner;
export const getFilterCustomer = (state: any) => selectFiltersState(state).filters.customer;
export const getFilterTitleLine4Pl = (state: any) => selectFiltersState(state).filters.titleLine4Pl;
export const getFilterTitleLine5Pl = (state: any) => selectFiltersState(state).filters.titleLine5Pl;
export const getFilterTitleLine6Pl = (state: any) => selectFiltersState(state).filters.titleLine6Pl;
export const getFilterGeneric = (state: any) => selectFiltersState(state).filters._generic;
export const getFilterIgnorePurposes = (state: any) => selectFiltersState(state).filters.ignorePurposes;
export const getFilterPartnerNumber = (state: any) => selectFiltersState(state).filters.partnerNumber;
export const getFilterApprovalDate = (state: any) => selectFiltersState(state).filters.approvalDate;
export const getFilterSupplier = (state: any) => selectFiltersState(state).filters.supplier;
export const getFilterSupplierDocumentNumber = (state: any) => selectFiltersState(state).filters.supplierDocumentNumber;