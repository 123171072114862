import _ from 'lodash';
import {getSelectedProjectId} from '../reducers/project'
import {getInternalAppRoles, getExternalAppRoles} from '../reducers/administrationModal'
import {Role} from '../../models/administration'
import { getSelectedProject } from './project';
import { StakeHolder } from '../../models/stakeHolder';
import { getStakeHolders } from '../reducers/sharedData';
import { getUiRules } from '../reducers/notifySettings';
import { NotifyRule } from '../../models/notifyRule';

export const getSelectedProjectInternalAppRoles = (state: any): Role[] => {
    const projectId = getSelectedProjectId(state);
    const roles = getInternalAppRoles(state);
    return (projectId && roles && roles.length > 0) ? _.filter(roles, r => (r.azureName as string).includes(projectId.toString()) || r.name === 'NONE') : [];  
}

export const getSelectedProjectExternalAppRoles = (state: any): Role[] => {
    const project = getSelectedProject(state);
    const roles = getExternalAppRoles(state);
    let extRoles = (project && project.projectId && roles && roles.length > 0) ? _.filter(roles, r => (r.azureName as string).includes(project.projectId.toString()) || r.name === 'NONE') : [];  
    if(extRoles.length > 0 && project && !project.isExternalCheckActive)
        extRoles = _.filter(extRoles, r => r.name !== 'CHECKER');
    return extRoles;
}

export const getSelectedProjectCustomerStakeholders = (state: any): StakeHolder[] => {
    const stakeholders = getStakeHolders(state);
    const customers = (stakeholders && stakeholders.length > 0 && _.some(stakeholders, s => s.isCustomer)) ? _.filter(stakeholders, s => s.isCustomer) : []
    return customers;
}


export const getAllAdminNotifications = (state: any): NotifyRule[] => {
    let notifications = _.map(getUiRules(state), r => {return {...r, eventTypeRuleId: undefined, ruleJson: undefined, msChat: true, mail: true}}) as NotifyRule[];
    return notifications;
}
