import {DocumentActions, SET_SELECTED_PROJECT_ID, SET_PROJECTS, SET_ALL_PROJECTS, SET_MODAL_OPENED, SET_PROJECT_FORM, SET_PROJECT_FORM_VALUE, SET_IS_EDIT, SET_IS_WITH_APPROVAL, SET_STAKEHOLDER_VALUE, SET_DARK_MODE, SET_CAN_COMMENT, SET_HAS_PARTNER} from '../actions/project';
import { createTransform } from 'redux-persist';
import _ from 'lodash';
import { Project, ProjectForm } from '../../models/project';
import { StakeHolder } from '../../models/stakeHolder';

interface ProjectState  {
    projects: Project[],
    allProjects: Project[],
    selectedProjectId?: number,
    modalOpened: boolean,
    projectForm?: ProjectForm,
    isEdit: boolean,
    darkModeEnabled: boolean,
    hasPartner: boolean
}

const initialState: ProjectState = {
    projects: [],
    allProjects: [],
    selectedProjectId: undefined,
    modalOpened: false,
    projectForm: undefined,
    isEdit: false,
    darkModeEnabled: false,
    hasPartner: false
} 

export const SetTransformProject = createTransform(
    (inboundState: any, key) => { 
     if(key == "selectedProjectId")
        return inboundState;

    },
    (outboundState: any, key) => { 
        if(key == "selectedProjectId")
            return outboundState;
    },
);


export const projectReducer = (project = initialState, action : DocumentActions): ProjectState => {
    switch (action.type) {
        
        case SET_PROJECTS:
            return {...project, projects: action.payload.projects };

        case SET_ALL_PROJECTS:
            return {...project, allProjects: action.payload.projects };

        case SET_SELECTED_PROJECT_ID:
            return {...project, selectedProjectId: action.payload.id };

        case SET_MODAL_OPENED:
            return {...project, modalOpened: action.payload.value };

        case SET_IS_EDIT:
            return {...project, isEdit: action.payload.value };

        case SET_PROJECT_FORM:
            return {...project, projectForm: action.payload.projectForm };

        case SET_STAKEHOLDER_VALUE:
            return {...project, projectForm:  (project.projectForm) ? {...project.projectForm, stakeHolders: _.map([...project.projectForm.stakeHolders], s => {return (s.stakeHolderId === action.payload.id) ? {...s, [action.payload.name]: action.payload.value} : s}) } : undefined}

        case SET_PROJECT_FORM_VALUE:
            return {...project, projectForm: (project.projectForm) ? {...project.projectForm, [action.payload.name]: action.payload.value} : undefined };

        case SET_IS_WITH_APPROVAL:
            return {...project, projectForm: (project.projectForm) ? {...project.projectForm, purposeList: _.map([...project.projectForm.purposeList], p => {return (p.purposeId === action.payload.purposeId) ? {...p, isWithApproval: action.payload.value} : p})} : undefined};

        case SET_CAN_COMMENT:
            return {...project, projectForm: (project.projectForm) ? {...project.projectForm, purposeList: _.map([...project.projectForm.purposeList], p => {return (p.purposeId === action.payload.purposeId) ? {...p, canBeCommented: action.payload.value} : p})} : undefined};

        case SET_DARK_MODE:
            return {...project, darkModeEnabled: action.payload.value };

        case SET_HAS_PARTNER:
            return {...project, hasPartner: action.payload.value };


        default:
            return project;
    }
};

//select from state (read) 
const selectProjectState = (state: any) => state.project as ProjectState;
export const getProjects = (state: any) => selectProjectState(state).projects;
export const getAllProjects = (state: any) => selectProjectState(state).allProjects;
export const getSelectedProjectId = (state: any) => selectProjectState(state).selectedProjectId;
export const getModalOpened = (state: any) => selectProjectState(state).modalOpened;
export const getIsEdit = (state: any) => selectProjectState(state).isEdit;
export const getHasPartner = (state: any) => selectProjectState(state).hasPartner;
export const getDarkModeEnabled = (state: any) => selectProjectState(state).darkModeEnabled;
export const getProjectForm = (state: any) => selectProjectState(state).projectForm;
export const getProjectFormId = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).projectId : undefined;
export const getProjectFormName = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).projectName : undefined;
export const getProjectFormIsActiveInAgile = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).isActivatedInAgile : false;
export const getProjectFormIsEnabled = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).isEnabled : false;
export const getProjectFormIsFolderPageActive = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).isFolderPageActive : false;
export const getProjectFormIsClientNumGeneratorPageActive = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).isClientNumGeneratorPageActive : false;
export const getProjectFormIsInternalCheckActive = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).isInternalCheckActive : false;
export const getProjectFormIsExternalCheckActive = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).isExternalCheckActive : false;
export const getProjectFormIsApproveWithCommentsStatusActive = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).isApproveWithCommentsStatusActive : false;
export const getProjectFormIsAgileTdCoverPageInternalUse = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).isAgileTdCoverPageInternalUse : false;
export const getProjectFormAutomaticApprovalDays = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).automaticApprovalDays : 0;
export const getProjectFormMandatoryDrawingColumns= (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).mandatoryDrawingColumns : [];
export const getProjectFormMandatoryDocumentColumns= (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).mandatoryDocumentColumns : [];
export const getProjectFormTemplate= (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).template : undefined;
export const getProjectFormPurposes= (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).purposeList : [];
export const getProjectFormStakeholders = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).stakeHolders : [];
export const getProjectFormReports = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).reports : [];
export const getProjectPlants= (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).plants : [];
export const getProjectFormDaysToApproval = (state: any) => (selectProjectState(state).projectForm) ? (selectProjectState(state).projectForm as ProjectForm).daysToApproval : 0;