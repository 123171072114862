import { IPersonaProps } from '@fluentui/react';
import _ from "lodash";
import moment from "moment";
import { ExternalRolesType, HistoryPathType, previewsAllowed } from "./costants";
import { Role } from "../models/administration";
import { Purpose } from "../models/purpose";
import { Entity } from "../models/entity";
import { EngPhase } from "../models/engPhase";
import { Discipline, ReserveNumberDisciplineDocType } from "../models/discipline";
import { Type } from "../models/type";
import { FilterComponent } from "../models/filters";
import { StakeHolder } from "../models/stakeHolder";
import { Report } from "../models/report";
import { NotifyRule } from "../models/notifyRule";
import { EventType } from "../models/eventType";
import {Buffer} from 'buffer';
import { Pbs } from '../models/pbs';

export const getHistoryName = (history: any): HistoryPathType => {
    if(history.location.pathname.includes('home'))
        return 'home';
    else if(history.location.pathname.includes('folders'))
        return 'folders';
    else if(history.location.pathname.includes('dashboard'))
        return 'dashboard';
    else if(history.location.pathname.includes('administration/qualityCheck'))
        return 'qualityCheck';
    else if(history.location.pathname.includes('administration/publishMaterial'))
        return 'publishMaterial';
    else if(history.location.pathname.includes('administration/publish'))
        return 'publish';
    else if(history.location.pathname.includes('administration/pending'))
        return 'pending';
    else if(history.location.pathname.includes('eeo/reservedNumbers'))
        return 'reservedNumbers';
    else if(history.location.pathname.includes('eeo/supplierMaterial'))
        return 'supplierMaterial';
    else if(history.location.pathname.includes('eeo/createRevision'))
        return 'createRevision';
    else if(history.location.pathname.includes('generators/clientNumber'))
        return 'clientNumber';
    else if(history.location.pathname.includes('projectsConfiguration'))
        return 'projectsConfiguration';
    else
        return "home";  
}

export const getPossibleExternalRolesByCompany = (company: string, stakeHolders: StakeHolder[], currentRole?: string): ExternalRolesType[] => {
    const stakeHolder = _.find(stakeHolders, s => s.companyName === company) as StakeHolder;
    let roles: ExternalRolesType[] = [];
    if(stakeHolder){
        if(stakeHolder.isPartner)
            roles = [...roles, 'PARTNER', 'PARTNER-COMMENTATOR'];
        if(stakeHolder.isSupplier)
            roles = [...roles, 'SUPPLIER'];
        if(stakeHolder.isCustomer)
            roles = [...roles, 'APPROVER', 'CHECKER', 'READER'];
    }
    return roles;
}

export const getContextualFilters = (filters: FilterComponent[], historyName: HistoryPathType): FilterComponent[] => {
    if(historyName !== 'dashboard' && historyName !== 'reservedNumbers')
        filters = _.filter(filters, f => f.id !== 'status');

    if(historyName === 'reservedNumbers')
        filters = _.filter(filters, f => f.id !== 'pwFilename' && f.id !== 'publication' && f.id !== 'tdNumber' && f.id !== 'isForInternalUse');
    else if(historyName === 'supplierMaterial')
        filters = _.filter(filters, f => f.id !== 'pwFilename' && f.id !== 'publication' && f.id !== 'tdNumber' && f.id !== 'isForInternalUse' && f.id !== 'showAllVersions');
    else if(historyName === 'createRevision')
        filters = _.filter(filters, f => f.id !== 'showAllVersions' && f.id !== 'isForInternalUse');
    return filters;
}

export const getEngPhaseDropdownOptions = (engPhases: EngPhase[], isReservingNumbers?: boolean): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(engPhases, (c) => {
        options = [...options, ...(!isReservingNumbers || (isReservingNumbers && (c.code === 'BE' || c.code === 'DE'))) ? [{ key: c.id as number, text: ((c.code as string) + ' - ' + c.name) }] : []]
    });
    return options;
}

export const getTypesDropdownOptions = (values: Type[]): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(values, (v) => {
        options = [...options, { key: v.id as number, text: v.name as string}]
    });
    return options;
}

export const getDisciplineDropdownOptions = (disciplines: Discipline[]): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(disciplines, (c) => {
        options = [...options, { key: c.id as number, text: c.code + ' - ' + c.name as string }]
    });
    return options;
}

export const getPublicationDropdownOptions = (publications: number[]): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(publications, (c) => {
        options = [...options, { key: c as number, text: c.toString() as string }]
    });
    return options;
}

export const getReservedNumbersDisciplineDropdownOptions = (disciplines: ReserveNumberDisciplineDocType[]): {key: string, text: string}[] => {
    let options: {key: string, text: string}[] = [];
    _.forEach(disciplines, (c) => {
        if(!_.find(options, (o: {key: string, text: string}) => o.key == c.code))
            options = [...options, { key: c.code as string, text: c.code + ' - ' + c.name as string }]
    });
    return options;
}

export const getDocTypesDropdownOptions = (discipline: Discipline): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(discipline.docTypes, (c) => {
        options = [...options, { key: c.id as number, text: c.code + ' - ' + c.name as string }]
    });
    return options;
}

export const getReservedNumbersDocTypesDropdownOptions = (disciplines: ReserveNumberDisciplineDocType[], discipline: ReserveNumberDisciplineDocType): {key: string, text: string}[] => {
    let options: {key: string, text: string}[] = [];
    let disciplineDocTypes = _.filter(disciplines, d => d.code === discipline.code && d.name === discipline.name);
    _.forEach(disciplineDocTypes, (c) => {
        options = [...options, { key: c.docTypeCode as string, text: c.docTypeCode + ' - ' + c.docTypeName as string }]
    });
    return options;
}


export const getPurposeDropdownOptions = (values: Purpose[], historyName: HistoryPathType, showAllDocs?: boolean): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(values, (v) => {
        if(!showAllDocs)
            options = (historyName !== 'pending' || ((historyName ==='pending' && v.isWithApproval))) ? [...options, { key: v.id as number, text: v.name + ' - ' + v.description as string}] : options;
        else
            options = [...options, { key: v.id as number, text: v.name + ' - ' + v.description as string}];
    });
    return options;
}

export const getEntityDropdownOptions = (values: Entity[]): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(values, (v) => {
        options = [...options, { key: v.id as number, text: v.name as string}]
    });
    return options;
}

export const getAppRolesDropdownOptions = (roles: Role[]): {key: string, text: string, azureName: string}[] => {
    let options: {key: string, text: string, azureName: string}[] = [];
    _.forEach(roles, (r) => {
        options = [...options, { key: r.id as string, text: r.name as string, azureName: r.azureName as string }]
    });
    return [...options];
}

export const getStakeHoldersDropdownOptions = (values: StakeHolder[]): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(values, (v) => {
        options = [...options, { key: v.stakeHolderId as number, text: v.companyName as string}]
    });
    return options;
}

export const getReportsDropdownOptions = (reports: Report[]): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(reports, (c) => {
        options = [...options, { key: c.id as number, text: c.name as string }]
    });
    return options;
}

export const getNotificationsDropdownOptions = (notifications: NotifyRule[], eventTypes: EventType[], isExternal: boolean): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(notifications, (n) => {
        var e = _.find(eventTypes, e => e.eventTypeId === n.eventTypeId) as EventType;
        options = (!_.some(options, o => o.key === n.eventTypeId) && (!isExternal || (isExternal && e.name !== "TdCreated" && e.name !== "SupplierExodToAgile"))) ? [...options, { key: n.eventTypeId as number, text: e.name as string }] : options;
    });
    return options;
}

export const isMainPage = (historyName: HistoryPathType): boolean => {
    return true;
}

export const getPersonaFromMicrosoftUserObject = (object: any): IPersonaProps => {
    let personaProps: IPersonaProps & { key: string | number }  = {
        text: object.displayName,
        secondaryText: object.mail, // email
        tertiaryText: object.onPremisesSamAccountName || undefined, // windows login
        presence: undefined,
        imageInitials: (!!object.givenName && !!object.surname) ? object.surname.substring(0,1) + object.givenName.substring(0,1) : object.displayName.substring(0,1),
        id: object.id,
        imageShouldFadeIn: true,
        showInitialsUntilImageLoads: true,
        key: object.id
    }
    return personaProps
}

export const formatDate = (date?: Date): string => {
    if(date)
        return moment(date).format('DD/MM/YYYY');
    else
        return '';
}

export const formatDateTime = (date?: Date): string => {
    if(date)
        return moment(date).format('DD/MM/YYYY HH:mm');
    else
        return '';
}

export const closeWindow = () =>{
    window.open('','_parent','');
    window.close();
}

export const isValidUrl = (str: string) => {
    try { new URL(str); } catch (_) { return false; }  
    return true;
} 

export const areStringArraysEqual = (a: string[], b: string[]) =>{
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    a = [..._.sortBy(a)];
    b = [..._.sortBy(b)];
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
}

export const isPrimitive = (element: any)  => {
    return element !== Object(element);
}

export const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

export const getFileExtension = (filename: string): string | undefined => {
    var re = /(?:\.([^.]+))?$/;
    if(filename) {
        let r = re.exec(filename);
        return (r) ? r[1] : undefined;
    }
    return undefined;
}

export const replaceFileExtension = (sourceFilename: string, destinationFilename: string): string | undefined => {
    if(sourceFilename && destinationFilename) {
        let sourceExt = getFileExtension(sourceFilename);
        let destinationExt = getFileExtension(destinationFilename);
        if(sourceExt && destinationExt){
            return sourceFilename.replace('.' + sourceExt, '.' + destinationExt);
        }
    }
    return undefined;
}

export const getCloseFiltersPanel = (historyName: HistoryPathType): boolean => {  
    return (historyName === 'home' || historyName === 'projectsConfiguration');
}

export const showStatusFilter = (historyName: HistoryPathType): boolean => {
    return historyName === 'dashboard' || historyName === 'reservedNumbers';
}

export const showParentFileChangeIcon = (historyName: HistoryPathType): boolean => {
    return historyName === 'dashboard' || historyName === 'publish' || historyName === 'publishMaterial' || historyName === 'qualityCheck';
}

export const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getBlob = (file: File): Promise<Blob> => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.onload = () => {
            var fileBuffer = Buffer.from(reader.result as any);
            //let attachment: Attachment = {name: file.name, fileBuffer: fileBuffer};
            resolve(new Blob([fileBuffer]));
        };

        reader.onabort = () => {
     
        };
        reader.onerror = () => {
        
        };

        reader.readAsArrayBuffer(file);
    });
}

export const canBePreviewed = (ext: string): boolean => {
    return (previewsAllowed.includes(ext)) ? true : false;
}

export const getPbsPart = (input: string, position: number, pbsList: Pbs[]): string | undefined => {
    const parts = input.split('-');

    // Controlla se la posizione è valida
    if (position < 1 || position > parts.length) {
        return undefined;
    }

    let description = undefined;
    if(pbsList.length > 0){
        description = _.find(pbsList, (pbs: Pbs) => pbs.id === input)?.description;
    }

    return (parts[position - 1]) + ' - ' + description;
}
