import React from 'react';
import { IColumn } from '@fluentui/react';
import {AppUser, Role} from '../../../../../models/administration';
import { ComboBox, IComboBoxOption } from '@fluentui/react';
import { FontIcon } from '@fluentui/react';
import { IRenderFunction, getId } from '@fluentui/react';
import { CommandActions, DocumentActions } from '../../../../../redux/actions/administrationModal';
import { getAppRolesDropdownOptions, getDisciplineDropdownOptions, getNotificationsDropdownOptions, getPossibleExternalRolesByCompany, getReservedNumbersDisciplineDropdownOptions } from '../../../../../utils/functions';
import _ from 'lodash';
import { Link } from '@fluentui/react';
import { Discipline, ReserveNumberDisciplineDocType } from '../../../../../models/discipline';
import { PermissionElement, Permissions } from '../../../../../models/permissions';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { DirectionalHint, FontSizes, ITextFieldProps, Label, TextField, TooltipHost } from '@fluentui/react';
import { getParentFileForm } from '../../../../../models/document';
import { Popup as HierarchyExplorer } from '@pwbapps/hierarchyexplorer';
import { Pbs } from '../../../../../models/pbs';
import { CommandActions as SharedDataCommandActions } from '../../../../../redux/actions/sharedData';

const _hostId: string = getId('tooltipHost');

export const getColumns = (disciplines: ReserveNumberDisciplineDocType[], documentDisciplines: Discipline[], pbs: Pbs[], loadingAllPbsDisciplines: boolean, permissionIdsToRemove: number[], setContextItemProperty: typeof ModalActions.DocumentActions.setContextItemProperty, setPermissionIdsToRemove: typeof SharedDataCommandActions.setPermissionIdsToRemove, permissionsForm?: Permissions) => {

      const setPbsValueFunction = (_index: number, id?: string| number, disciplineCode?: string, item?: any, checked?: any) => {      
        if(checked){
          if(permissionsForm){
            const alreadyIn = (permissionsForm && item && !_.some(permissionsForm.permissions, (p: PermissionElement) => (_index != p._index) && p.pbs === item.name && p.disciplineCode === disciplineCode )) ? false : true;
            if(!alreadyIn)
              setContextItemProperty({id: 'permissions', name: 'permissions', value: _.map(permissionsForm.permissions, p => { return (_index === p._index) ? {...p, pbs: item.name} : {...p} })});
          }
        }
        else if(permissionsForm){
            const alreadyIn = (permissionsForm && item && !_.some(permissionsForm.permissions, (p: PermissionElement) => (_index != p._index) && p.disciplineCode === disciplineCode )) ? false : true;
            if(!alreadyIn)
              setContextItemProperty({id: 'permissions', name: 'permissions', value: _.map(permissionsForm.permissions, p => { return (_index === p._index) ? {...p, pbs: undefined} : {...p} })});
        }
      }
      const setDisciplineValueFunction = (_index: number, id?: string| number, pbs?: string, item?: any) => {      
        
        if(permissionsForm && item){
          const alreadyIn = (permissionsForm && item && !_.some(permissionsForm.permissions, (p: PermissionElement) => ((id && p.id !== id) || (!id)) && p.pbs === pbs && p.disciplineCode === item )) ? false : true;
          if(!alreadyIn){
            const disciplineObject = _.find(disciplines, (d: ReserveNumberDisciplineDocType) => d.code === item);
            setContextItemProperty({id: 'permissions', name: 'permissions', value: _.map(permissionsForm.permissions, p => { return (_index === p._index) ? {...p, disciplineCode: item, disciplineName: (disciplineObject) ? disciplineObject.name : undefined} : {...p} })});
          }
        }
        else if(permissionsForm && !item){
            item = (item === "") ? undefined : item;
            const alreadyIn = (permissionsForm && !_.some(permissionsForm.permissions, (p: PermissionElement) => (_index != p._index) && p.pbs === pbs )) ? false : true;
            if(!alreadyIn)
              setContextItemProperty({id: 'permissions', name: 'permissions', value: _.map(permissionsForm.permissions, p => { return (_index === p._index) ? {...p, discipline: undefined, disciplineCode: undefined, disciplineName: undefined} : {...p} })});
        }
      }
 
      const columns: IColumn[] = [
        {
          key: 'removePerm',
          name: 'removePerm',
          fieldName: 'removePerm',
          minWidth: 5,
          maxWidth: 5,
          isResizable: false,
          isIconOnly: true,
          //onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'number',
          isPadded: false,
          onRender: (item: PermissionElement) => {
            if(item){
              return (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <FontIcon iconName={'Delete'} title={'Remove'} 
                       onClick={() => {
                        if(permissionsForm){
                          setContextItemProperty({id: 'permissions', name: 'permissions', value: _.filter(permissionsForm.permissions, (p: PermissionElement) => p._index !== item._index)});
                          if(item.id)
                            setPermissionIdsToRemove({value: [...permissionIdsToRemove, item.id as number]});
                        }
                      }
                    } style={{fontSize: FontSizes.xLarge, color: '#A4262C', cursor: 'pointer'}} ></FontIcon>
                </div>
              );              
            }
          }
        }, 
        {
          key: 'pbs',
          name: 'PBS',
          fieldName: 'pbs',
          minWidth: 320,
          maxWidth: 320,
          isResizable: true,
          data: 'string',
         //  onColumnClick: (handleClick) ? handleClick : () => {},
          isPadded: true,        
          // isSorted: (orderedColumn && orderedColumn.name === 'name') ? true : false,
          // isSortedDescending: (orderedColumn && orderedColumn.name === 'name' && orderedColumn.type === 'desc') ? true : false,
          onRender: (item: PermissionElement) => {
            return(
              <HierarchyExplorer                              
                  readOnly={false}
                  title={"PBS selector"}
                  items={pbs}
                  onSelect={(pbsItem, checked) => setPbsValueFunction(item._index as number, item.id, item.disciplineCode, pbsItem, checked)}                                             
                  selected={(item && item.pbs) ? [item.pbs] : []}
                  selectedProperty={"name"}
                  isSelectable={() => {return true}}
              >
                  {(ref, onClick) => (
                      <>
                          <div style={{width: '100%'}}>

                              <div ref={ref} onClick={onClick} onFocus={onClick} >
                                  <TextField 
                                      readOnly={true} 
                                      autoComplete={'off'} 
                                      value={(item && item.pbs) ? item.pbs : undefined} 
                                      title={(item && item.pbs) ? item.pbs : undefined} 
                                      autoFocus={false}
                                      styles={{root: {minWidth: 260}}}  
                                  />      
                              </div>
                          </div>
                      </>
                  )}
              </HierarchyExplorer>
            );
          }
        },
        {
          key: 'discipline',
          name: 'Discipline',
          fieldName: 'discipline',
          minWidth: 260,
          maxWidth: 260,
          isResizable: true,
        //  onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: true,
          onRender: (item: PermissionElement) => {
            let discipline_options: IComboBoxOption[] = item && disciplines ? [{key: 'ALL', text: 'ALL'}, ...getReservedNumbersDisciplineDropdownOptions(disciplines)] : [];
            return(
              <ComboBox                                
                  options={discipline_options}
                  onChange={(e, value) => {
                    setDisciplineValueFunction(item._index as number, item.id, item.pbs, (value) ? value.key : undefined, )
                  }}
                  selectedKey={(item && item.disciplineCode) ? item.disciplineCode : null}    
                  useComboBoxAsMenuWidth={true}         
                  calloutProps={{calloutMaxHeight: 220}}
                  styles={{root: {minWidth: 260}}}    
              />
            );
          }
          
        },
        {
          key: 'canWrite',
          name: 'Can write',
          fieldName: 'canWrite',
          minWidth: 70,
          maxWidth: 70,
          isResizable: true,
        //  onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: true,
          onRender: (item: PermissionElement) => {
            let canWrite_options: IComboBoxOption[] = [{key: 1, text: 'Yes' }, {key: 0, text: 'No' }];
            return(
              <ComboBox                                  
                  options={canWrite_options}
                  onChange={(e, value) => {
                    if(permissionsForm)
                      setContextItemProperty({id: 'permissions', name: 'permissions', value: _.map(permissionsForm.permissions, p => { return (item._index === p._index) ? {...p, canWrite: (value && value.key === 1) ? true : false} : {...p} })});
                  }}
                  selectedKey={(item && item.canWrite) ? 1 : 0}         
                  calloutProps={{calloutMaxHeight: 220}}
                  useComboBoxAsMenuWidth={true}  
                  styles={{root: {maxWidth: 70}}}    
              />
            );
          }
          
        },

    ];

    return columns;
}




