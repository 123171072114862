import _ from "lodash";
import { DocType } from "./docType";

export class Discipline {
    id?: number
    code?: string
    name?: string
    docTypes: DocType[]
    constructor(object: any){
        this.id = object.disciplineId;
        this.code = object.disciplineCode;
        this.name = object.disciplineName;
        this.docTypes = (object.docTypes && object.docTypes.length > 0) ? _.map(object.docTypes, dt => new DocType(dt)) : [];
    }
}

export class ReserveNumberDisciplineDocType {
    
    code?: string
    name?: string
    docTypeCode?: string
    docTypeName?: string
    type?: string
    constructor(object: any){
        this.code = object.disciplineCode;
        this.name = object.disciplineName;
        this.docTypeCode = object.docTypeCode;
        this.docTypeName = object.docTypeName;
        this.type = object.type;
    }
}