import * as React from 'react';
import { Panel, PanelType, IPanelProps } from '@fluentui/react';
import {bindActionCreators} from 'redux';
import { getFilterPanelOpened } from '../../redux/reducers/ui';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {  CommandActions as UiCommandActions, DocumentActions as UiDocumentActions } from '../../redux/actions/ui';
import { CommandActions, DocumentActions } from '../../redux/actions/filters';
import { getClassNames } from './index.classNames';
import FiltersContent from './filters/index';
import { IRenderFunction } from '@fluentui/react';
import { Text } from '@fluentui/react';
import { IconButton, Icon} from '@fluentui/react';
import { getFilters, getLargerPanel, getSelectedFilter } from '../../redux/reducers/filters';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Filters } from '../../models/filters';
import _ from 'lodash';
import { FiltersType, getTheme } from '../../utils/costants';
import { CommandActions as DocumentsCommandActions } from '../../redux/actions/documents';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getDarkModeEnabled } from '../../redux/reducers/project';

export interface PanelProps extends RouteComponentProps, WithTranslation { 
    filterPanelOpened: boolean, 
    largerPanel: boolean, 
    filters: Filters,
    selectedFilter?: FiltersType,
    darkModeEnabled: boolean,
    setFilterPanelOpened: typeof UiDocumentActions.setFilterPanelOpened,
    setLargerPanel: typeof CommandActions.setLargerPanel,
    resetFilters: typeof CommandActions.resetFilters,
    fetchDocuments: typeof DocumentsCommandActions.fetchDocuments,
}


class RightPanel extends React.Component<PanelProps & RouteComponentProps> {
    debouncedReloadItems: Function
    constructor(props: PanelProps) {
        super(props);

        this.debouncedReloadItems = _.debounce(() => {
            const { fetchDocuments } = this.props;
            fetchDocuments({});
		}, 500);
    }

    hidePanel = () => {
        const {setFilterPanelOpened} = this.props;
        setFilterPanelOpened({open: false});
    };

    resetAndReload = () =>{
        const {resetFilters } = this.props;
        resetFilters();
        this.debouncedReloadItems();
    }

    onRenderNavigationContent: IRenderFunction<IPanelProps> = ((props, defaultRender) => {
        const {largerPanel, filters, t, setLargerPanel, resetFilters, darkModeEnabled } = this.props;
        const styles = getClassNames(getTheme(darkModeEnabled));
        return (
            <>
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Icon iconName={(!largerPanel) ? "DecreaseIndentArrow" : "IncreaseIndentArrow"} title={(!largerPanel) ? "Expand panel" : "Decrease panel"} styles={{root: styles.iconClickable }} onClick={() => {setLargerPanel({value: !largerPanel})}} />                                
                        <Text className={styles.headerLabel} variant='xLarge' nowrap>{t('Filters')}</Text>
                    </div>
                </div>
                {(defaultRender && props) ? defaultRender({
                ...props,        
            }) : null}
            </>
        );
    });
    
    render() {
        const {filterPanelOpened, largerPanel, history, selectedFilter, filters, darkModeEnabled} = this.props;
        const styles = getClassNames(getTheme(darkModeEnabled));
        return (
            <Panel
                id={'panelId'}
                className={styles.panel}
                styles={{ navigation: styles.header, scrollableContent: styles.scrollableContent, content:{ paddingLeft: 0, paddingRight: 0}}}
                isOpen={filterPanelOpened}
                isBlocking={false}
                type={(!largerPanel) ? PanelType.smallFixedFar : PanelType.medium}
                onDismiss={this.hidePanel}
                headerText=""
                //closeButtonAriaLabel="Close"
                onRenderNavigationContent={this.onRenderNavigationContent}
                isHiddenOnDismiss={true}
            >  
                <FiltersContent selectedFilter={selectedFilter}/>
            </Panel>
        );
    }
}


const mapStateToProps = createStructuredSelector({
    filterPanelOpened: getFilterPanelOpened,
    largerPanel: getLargerPanel,
    filters: getFilters,
    selectedFilter: getSelectedFilter,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        setFilterPanelOpened: UiDocumentActions.setFilterPanelOpened, 
        setLargerPanel: CommandActions.setLargerPanel,
        resetFilters: CommandActions.resetFilters,
        fetchDocuments: DocumentsCommandActions.fetchDocuments,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)((withTranslation()(withRouter(RightPanel))));
