import { FEATURE, Actions, CommandActions, EventActions, DOWNLOAD_FILE,
         DOWNLOAD_COMMENTS, DOWNLOAD_FILES, DOWNLOAD_EXCEL_REPORT,
         DOWNLOAD_TEMPLATE, DOWNLOAD_OR_SHOW_FILE,
         DOWNLOAD_FILES_TEST} from "../../actions/documents";
import {ApiConfigActions} from "@pwbapps/reduxcore";
import { DocumentActions as NotificationDocumentActions} from "../../actions/notification";
import { CommandActions as LoaderCommandActions, DocumentActions as LoaderDocumentActions} from "../../actions/loader";
import _ from 'lodash';
import { MessageBarType } from '@fluentui/react';
import { canBePreviewed, getFileExtension, getHistoryName } from "../../../utils/functions";
import { downloadFileUrl, downloadFilesUrl, downloadCommentsUrl, downloadExcelReportUrl, downloadSupplierTemplateUrl, downloadFilesSupplierUrl, downloadFilesTestUrl } from "../../../utils/documentsUrls";
import { getDocuments, getSelectedItemsIds } from "../../reducers/documents";
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";
import { saveAs } from 'file-saver';
import { getSelectedProjectTemplate } from "../../selectors/project";
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { PreviewObject } from "../../../models/files";
import { t } from "i18next";
import { isExternalApp } from "../../../utils/costants";
import { getSelectedProjectUserActions } from "../../selectors/auth";
import { getSelectedDocuments } from "../../selectors/documents";


export const documentsReportMiddlewareFunction = (history: any, dispatch: any, getState: any, next: any, action: Actions | ApiConfigActions.EventActions) => {

    switch (action.type) {

        case DOWNLOAD_OR_SHOW_FILE: {
            let id = action.payload.id;
            let fileName = action.payload.fileName;
            let clientNumber = action.payload.clientNumber;
            const showPreview = canBePreviewed(getFileExtension(fileName) as string);    
            if(!showPreview)
                dispatch(CommandActions.downloadFile({id, fileName}));
            else{
                dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true }));
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', headers: [{name: 'Accept', value: 'application/octet-stream'}], responseType: 'arraybuffer', url: downloadFileUrl(id), feature: DOWNLOAD_FILE, returnObject: {id, fileName, showPreview, clientNumber}}}));         
            }
            break;
        }
 
        case DOWNLOAD_FILE: {
            let id = action.payload.id;
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true, message:  'Downloading ' + action.payload.fileName + '...', messageType: MessageBarType.info })); 
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', headers: [{name: 'Accept', value: 'application/octet-stream'}], responseType: 'arraybuffer', url: downloadFileUrl(id), feature: DOWNLOAD_FILE, returnObject: {id, fileName: action.payload.fileName}}}));    
            break;
        }

        case DOWNLOAD_FILES: {
            let downloadForm: {fileName?: string, isParent?: boolean, useClientNumberAsFilename?: boolean} = ((getGenericModal('fileName')(getState())) && (getGenericModal('fileName')(getState()) as any).contextItem) ? (getGenericModal('fileName')(getState()) as any).contextItem : 'Documents_Files';
            downloadForm = (downloadForm && !downloadForm.fileName) ? {...downloadForm, fileName: 'Documents_Files'} : downloadForm;
            const documentId: number | undefined = ((getGenericModal('fileName')(getState())) && (getGenericModal('fileName')(getState()) as any).optional) ? (getGenericModal('fileName')(getState()) as any).optional as number : undefined;
            let selectedItemIds = (!documentId) ? getSelectedItemsIds(getState()) : [documentId];
            selectedItemIds = (selectedItemIds.length === 0) ? _.map(getDocuments(getState()), d => (d.id as number).toString()) : selectedItemIds;
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true, message:  'Downloading files...', messageType: MessageBarType.info }));         
            if(!isExternalApp || (isExternalApp && !_.some(getSelectedProjectUserActions(getState()), a => a === 'FilterDocumentsBySupplierCompany'))){
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: {
                         method: 'POST', 
                         body: {
                            documentIds: [...selectedItemIds],
                            fileName: downloadForm.fileName,
                            isParent: downloadForm.isParent,
                            useClientNumberAsFilename: downloadForm.useClientNumberAsFilename
                         },
                         headers: [{name: 'Accept', value: 'application/octet-stream'}],
                         responseType: 'arraybuffer',
                         url: downloadFilesUrl(),
                         feature: DOWNLOAD_FILES,
                         returnObject: {fileName: downloadForm.fileName}
                    }
                }));    
            }
            else if (isExternalApp && _.some(getSelectedProjectUserActions(getState()), a => a === 'FilterDocumentsBySupplierCompany')){ // if external and SUPPLIER
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: {
                         method: 'POST', 
                         body: {
                            documentIds: [...selectedItemIds],
                            fileName: downloadForm.fileName,
                         },
                         headers: [{name: 'Accept', value: 'application/octet-stream'}],
                         responseType: 'arraybuffer',
                         url: downloadFilesSupplierUrl(),
                         feature: DOWNLOAD_FILES,
                         returnObject: {fileName: downloadForm.fileName}
                    }
                }));  
            }
            break;
        }    
        
        case DOWNLOAD_COMMENTS: {
            const fileName: string = ((getGenericModal('comments')(getState())) && (getGenericModal('comments')(getState()) as any).optional) ? (getGenericModal('comments')(getState()) as any).optional as string : 'Documents_Comments';
            let documentIds = action.payload.documentIds;
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: true}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.info, message: t('Downloading comments') + '...'}));  
            if(documentIds.length > 0){
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: {
                         method: 'POST', 
                         body: {
                            documentIds: [...documentIds],
                            fileName
                         },
                         headers: [{name: 'Accept', value: 'application/octet-stream'}],
                         responseType: 'arraybuffer',
                         url: downloadCommentsUrl(),
                         feature: DOWNLOAD_COMMENTS,
                         returnObject: {fileName}
                    }
                }));  
            }
           
            break;
        }

        case DOWNLOAD_EXCEL_REPORT: {
            const fileName: string = ((getGenericModal('excelReport')(getState())) && (getGenericModal('excelReport')(getState()) as any).optional) ? (getGenericModal('excelReport')(getState()) as any).optional as string : 'Documents_Report';
            let documentIds = action.payload.documentIds;
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: true}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.info, message: t('Downloading report') + '...'}));  
            if(documentIds.length > 0){
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: {
                         method: 'POST', 
                         body: {
                            documentIds: [...documentIds],
                            fileName
                         },
                         headers: [{name: 'Accept', value: 'application/octet-stream'}],
                         responseType: 'arraybuffer',
                         url: downloadExcelReportUrl(),
                         feature: DOWNLOAD_EXCEL_REPORT,
                         returnObject: {fileName}
                    }
                }));  
            }
            break;
        }

        case DOWNLOAD_TEMPLATE: {
            const template = getSelectedProjectTemplate(getState());
            let documentIds = action.payload.documentIds;
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: true}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.info, message: t('Downloading template') + '...'}));  
            if(documentIds.length > 0 && template){
                
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: {
                         method: 'POST', 
                         body: {
                            documentIds: [...documentIds],
                            template
                         },
                         headers: [{name: 'Accept', value: 'application/octet-stream'}],
                         responseType: 'arraybuffer',
                         url: downloadSupplierTemplateUrl(),
                         feature: DOWNLOAD_TEMPLATE,
                         returnObject: {fileName: 'Template'}
                    }
                }));  
            }
            break;
        }

        case DOWNLOAD_FILES_TEST: {
            let downloadForm: {fileName?: string, isParent?: boolean, useClientNumberAsFilename?: boolean} = ((getGenericModal('fileNameTest')(getState())) && (getGenericModal('fileNameTest')(getState()) as any).contextItem) ? (getGenericModal('fileNameTest')(getState()) as any).contextItem : 'Docs';
            downloadForm = (downloadForm && !downloadForm.fileName) ? {...downloadForm, fileName: 'Docs'} : downloadForm;
            const documents = getSelectedDocuments(getState());
            const selectedDocNumbers = _.map(documents, d => d.documentNumber);
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true, message:  'Downloading files...', messageType: MessageBarType.info }));         
            if(!isExternalApp){
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: {
                         method: 'POST', 
                         body: {
                            documentNumbers: [...selectedDocNumbers],
                            fileName: downloadForm.fileName,
                            useClientNumberAsFilename: false
                         },
                         headers: [{name: 'Accept', value: 'application/octet-stream'}],
                         responseType: 'arraybuffer',
                         url: downloadFilesTestUrl(),
                         feature: DOWNLOAD_FILES_TEST,
                         returnObject: {fileName: downloadForm.fileName}
                    }
                }));    
            }
            break;
        }    

       
        case ApiConfigActions.API_SUCCESS:
            apiSuccessMiddleware(history, dispatch, getState, next, action);         
            break;
    
        case ApiConfigActions.API_ERROR:
            apiErrorMiddleware(history, dispatch, getState, next, action);         
            break;

        default:
            break;
    }
};

const apiSuccessMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiSuccessAction) => {
    switch(action.meta.feature){
      
        case DOWNLOAD_FILE: {
            let document = action.payload;
            let id = action.meta.returnObject.id as number;
            let fileName = action.meta.returnObject.fileName as string;
            let showPreview = action.meta.returnObject.showPreview as boolean | undefined;
            let clientNumber = action.meta.returnObject.clientNumber as string | undefined;
            if(!showPreview && document && fileName) {
                saveAs(new Blob([document]), `${(!clientNumber) ? fileName : clientNumber}`);
                dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message:  t('Download completed'), messageType: MessageBarType.success, closeAutomatically: true }));
            }
            else if (showPreview && document && fileName){
                dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false }));
                let ext = getFileExtension(fileName);
                let blob = new Blob([document]);
                blob = (ext === 'pdf') ? blob.slice(0, blob.size, "application/pdf") : blob;
                let objectURL = URL.createObjectURL(blob);
                dispatch(ModalActions.CommandActions.openModal({id: 'preview', contextItem: new PreviewObject({url: objectURL, ext, id, fileName: (!clientNumber) ? fileName : (clientNumber + '.' + ext)})}));
            }
            break;
        }

        case DOWNLOAD_FILES: 
        case DOWNLOAD_FILES_TEST: {
            let zip = action.payload;
            let fileName = action.meta.returnObject.fileName as string;
            if(zip) 
                saveAs(new Blob([zip]),`${fileName}.zip`);
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message:  t('Download completed'), messageType: MessageBarType.success, closeAutomatically: true }));
            break;
        }

        case DOWNLOAD_TEMPLATE:
        case DOWNLOAD_COMMENTS:
        case DOWNLOAD_EXCEL_REPORT: {
            let xlsx = action.payload;
            let fileName = action.meta.returnObject.fileName as string;
            const historyName = getHistoryName(history);
            if(xlsx) 
                saveAs(new Blob([xlsx]),`${fileName}.xlsx`);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.success, message: t('Download completed')}));  
            setTimeout(() => { next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: false, type: MessageBarType.info, message: ''})); }, 5000);
            break;
        }
  
        default:
            break;
    }
}

const apiErrorMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiErrorAction) => {
    switch(action.meta.feature){

        case DOWNLOAD_COMMENTS: {
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot download comments. Please retry.')}));
            break;
        }

        case DOWNLOAD_FILE: {
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message:  t('Cannot download this file. Please retry.'), messageType: MessageBarType.error }));
            break;
        }

        case DOWNLOAD_FILES: 
        case DOWNLOAD_FILES_TEST: {
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message:  t('Cannot download this files. Please retry.'), messageType: MessageBarType.error }));
            break;
        }

        case DOWNLOAD_TEMPLATE: {
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot download template. Please retry.')}));
            break;
        }

        case DOWNLOAD_EXCEL_REPORT: {
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot download excel report. Please retry.')}));
            break;
        }
      
        default:
            break;
    }
}