import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _, { String } from 'lodash';
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";
import { EditDocumentForm } from '../../../models/document';
import { Checkbox, Dropdown, Label, Text, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { getLoader } from '../../../redux/selectors/loader';
import {  getEngPhaseDropdownOptions, getEntityDropdownOptions, getHistoryName, getPurposeDropdownOptions } from '../../../utils/functions';
import { getEngPhases, getEntities, getPurposes } from '../../../redux/reducers/sharedData';
import { EngPhase } from '../../../models/engPhase';
import { Entity } from '../../../models/entity';
import { Purpose } from '../../../models/purpose';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getFilterIgnorePurposes } from '../../../redux/reducers/filters';

export interface EditDocumentProps extends RouteComponentProps, WithTranslation {
    editDocumentForm: EditDocumentForm,
    loadingContent: boolean,
    engPhases: EngPhase[],
    entities: Entity[],
    purposes: Purpose[],
    loadingEntities: boolean,
    loadingPurposes: boolean,
    showAllDocs: boolean,
    setContextItemProperty: typeof ModalActions.DocumentActions.setContextItemProperty
}

export interface EditDocumentState {

}

class EditDocument extends React.Component<EditDocumentProps & RouteComponentProps, EditDocumentState> {
    constructor(props: EditDocumentProps) {
        super(props);   
        this.state = {

        }
    }


    render(){
        const { editDocumentForm, loadingContent, engPhases, setContextItemProperty, entities, loadingEntities, purposes, loadingPurposes, history, t, showAllDocs } = this.props;
        let engPhase_options: {key: number, text: string}[] = [{key: -1, text: 'None'}, ...getEngPhaseDropdownOptions(engPhases)];
        let entities_options: {key: number, text: string}[] = [{key: -1, text: 'None'}, ...getEntityDropdownOptions(entities)];
        let purposes_options: {key: number, text: string}[] = [{key: -1, text: 'None'}, ...getPurposeDropdownOptions(purposes, getHistoryName(history), showAllDocs)];
        return (
            <>
                { editDocumentForm && 
                    <>
                        {editDocumentForm.documentIds && editDocumentForm.documentIds.length === 1 &&
                            <>
                                <TextField 
                                    required
                                    label={t('Client number')}
                                    autoComplete={'off'} 
                                    value={editDocumentForm.clientNumber} 
                                    onChange={(e, value) => { setContextItemProperty({id: 'editDocument', name: 'clientNumber', value})}}
                                    errorMessage={(editDocumentForm && !editDocumentForm.clientNumber) ? t('This field is mandatory') : undefined}
                                />   
                                <TextField 
                                    label={t("Supplier doc number")}
                                    autoComplete={'off'} 
                                    value={editDocumentForm.supplierDocumentNumber} 
                                    onChange={(e, value) => { setContextItemProperty({id: 'editDocument', name: 'supplierDocumentNumber', value})}}
                                /> 
                                <div>
                                    {!loadingEntities && <Label>{t('Entity')}</Label>}
                                    {loadingEntities && 
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <Label style={{marginRight: 10}}>{t('Entity')}</Label>
                                            <Spinner size={SpinnerSize.small}/>
                                        </div>
                                    }
                                    <Dropdown
                                        placeholder={t('Select an entity') + '...'}
                                        disabled={loadingEntities}
                                        options={entities_options}
                                        onChange={(e, value) => { setContextItemProperty({id: 'editDocument', name: 'entity', value: (value && value.key !== -1) ? _.find(entities, e => e.id === value.key) : undefined})}}
                                        selectedKey={(editDocumentForm.entity) ? editDocumentForm.entity.id : null}                              
                                        styles={{root: {minWidth: 260}}}
                                    />   
                                </div> 
                            </>
                        }
                        <Dropdown
                            key={'engPhase'}
                            label={t('Eng.phase')}
                            placeholder={t('Select an Eng.phase') + '...'}
                            disabled={false}
                            options={engPhase_options}
                            onChange={(e, value) => {
                                setContextItemProperty({id: 'editDocument', name: 'engPhase', value: (value && value.key !== -1) ? _.find(engPhases, ep => ep.id === value.key as number) : undefined});
                            }}
                            selectedKey={(editDocumentForm.engPhase) ? editDocumentForm.engPhase.id : null}                           
                            styles={{root: {minWidth: 260}}}
                        />
                        <div>
                            {!loadingPurposes && 
                                <>
                                    {editDocumentForm.documentIds && editDocumentForm.documentIds.length === 1 && 
                                        <Label style={{marginRight: 10}}>{t('Purpose')}<Text style={{color: '#A4262C', fontWeight: 600}}> *</Text></Label>
                                    }  
                                    {editDocumentForm.documentIds && editDocumentForm.documentIds.length > 1 && 
                                        <Label style={{marginRight: 10}}>{t('Purpose')}</Label>
                                    } 
                                </>  
                            }
                            {loadingPurposes && 
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {editDocumentForm.documentIds && editDocumentForm.documentIds.length === 1 && 
                                        <Label style={{marginRight: 10}}>{t('Purpose')} <Text style={{color: '#A4262C', fontWeight: 600}}> *</Text></Label>
                                    }  
                                    {editDocumentForm.documentIds && editDocumentForm.documentIds.length > 1 && 
                                        <Label style={{marginRight: 10}}>{t('Purpose')}</Label>
                                    }   
                                    <Spinner size={SpinnerSize.small}/>
                                </div>
                            }
                            <Dropdown                               
                                placeholder={t('Select a purpose') + '...'}
                                disabled={loadingPurposes}
                                options={purposes_options}
                                onChange={(e, value) => { setContextItemProperty({id: 'editDocument', name: 'purpose', value: (value && value.key !== -1) ? _.find(purposes, e => e.id === value.key) : undefined})}}
                                selectedKey={(editDocumentForm.purpose) ? editDocumentForm.purpose.id : null}                              
                                styles={{root: {minWidth: 260}}}
                                errorMessage={(editDocumentForm.documentIds && editDocumentForm.documentIds.length === 1 && editDocumentForm && !editDocumentForm.purpose) ? t('This field is mandatory') : undefined}
                            />   
                        </div>
                        {editDocumentForm.documentIds && editDocumentForm.documentIds.length === 1 && 
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                                <Label styles={{root: {marginRight: 5}}}>{t('Internal use only')}</Label>
                                <Checkbox 
                                    checked={(editDocumentForm.isForInternalUse) ? editDocumentForm.isForInternalUse : false} 
                                    onChange={(e, value) => {
                                        setContextItemProperty({id: 'editDocument', name: 'isForInternalUse', value: (value) ? value : undefined});
                                    }} 
                                />                           
                            </div>                         
                        }
                    </>
                }
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    editDocumentForm: (state) => (getGenericModal('editDocument')(state)) ? (getGenericModal('editDocument')(state) as any).contextItem : undefined,
    loadingEntities: (state) => getLoader('projectShared')(state).loadingEntities,
    loadingPurposes: (state) => getLoader('projectShared')(state).loadingPurposes,
    engPhases: getEngPhases,
    entities: getEntities,
    purposes: getPurposes,
    showAllDocs: getFilterIgnorePurposes,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItemProperty: ModalActions.DocumentActions.setContextItemProperty
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(EditDocument)));