import {DocumentActions, SET_SHARED_VALUE} from "../actions/sharedData";
import {Status} from '../../models/status';
import {Purpose} from '../../models/purpose';
import {Entity} from '../../models/entity';
import {EngPhase} from '../../models/engPhase';
import {Type} from '../../models/type';
import {Discipline, ReserveNumberDisciplineDocType} from '../../models/discipline';
import { Pbs } from "../../models/pbs";
import { Folder } from "../../models/folder";
import { ApplicationStakeHolder, StakeHolder } from "../../models/stakeHolder";
import { Report } from "../../models/report";
import { PermissionElement, Permissions } from "../../models/permissions";

interface SharedDataState  {
    statusValues: Status[]
    types: Type[],
    allPurposes: Purpose[],
    purposes: Purpose[],
    entities: Entity[],
    engPhases: EngPhase[],
    disciplines: Discipline[],
    pbs: Pbs[],
    folders: Folder[],
    stakeHolders: StakeHolder[],
    allStakeHolders: ApplicationStakeHolder[],
    reserveNumbersPbs: Pbs[],
    reserveNumbersDisciplines: ReserveNumberDisciplineDocType[],
    allReports: Report[],
    reports: Report[],
    publications: number[],
    permissions: Permissions[]
    permissionIdsToRemove: number[]
    allZeroUsers: string[]
}

const initialState: SharedDataState = {
    statusValues: [],
    types: [],
    allPurposes: [],
    purposes: [],
    entities: [],
    engPhases: [],
    disciplines: [],
    pbs: [],
    folders: [],
    allStakeHolders: [],
    stakeHolders: [],
    reserveNumbersPbs: [],
    reserveNumbersDisciplines: [],
    allReports: [],
    reports: [],
    publications: [],
    permissions: [],
    permissionIdsToRemove: [],
    allZeroUsers: []
}

export const sharedDataReducer = (sharedData = initialState, action : DocumentActions): SharedDataState => {
    switch (action.type) {
        
        case SET_SHARED_VALUE:
            return {...sharedData, [action.payload.name]: action.payload.value };

        default:
            return sharedData;
    }
};

// select from state (read)
const selectSharedDataState = (state: any) => state.sharedData as SharedDataState;
export const getStatusValues= (state: any) => selectSharedDataState(state).statusValues;
export const getTypes= (state: any) => selectSharedDataState(state).types;
export const getAllPurposes= (state: any) => selectSharedDataState(state).allPurposes;
export const getPurposes= (state: any) => selectSharedDataState(state).purposes;
export const getEntities= (state: any) => selectSharedDataState(state).entities;
export const getEngPhases= (state: any) => selectSharedDataState(state).engPhases;
export const getDisciplines= (state: any) => selectSharedDataState(state).disciplines;
export const getPbs= (state: any) => selectSharedDataState(state).pbs;
export const getFolders= (state: any) => selectSharedDataState(state).folders;
export const getAllStakeHolders= (state: any) => selectSharedDataState(state).allStakeHolders;
export const getStakeHolders= (state: any) => selectSharedDataState(state).stakeHolders;
export const getAllPbs= (state: any) => selectSharedDataState(state).reserveNumbersPbs;
export const getAllDisciplines= (state: any) => selectSharedDataState(state).reserveNumbersDisciplines;
export const getAllReports= (state: any) => selectSharedDataState(state).allReports;
export const getReports= (state: any) => selectSharedDataState(state).reports;
export const getPublications= (state: any) => selectSharedDataState(state).publications;
export const getPermissions= (state: any) => selectSharedDataState(state).permissions;
export const getPermissionIdsToRemove= (state: any) => selectSharedDataState(state).permissionIdsToRemove;
export const getAllZeroUsers= (state: any) => selectSharedDataState(state).allZeroUsers;