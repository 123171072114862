import {createAction, ActionsUnion} from '@pwbapps/reduxcore';

export const SET_LOADER= `[LOADING] SET LOADER`;
export const SET_LOADER_ARRAY= `[LOADING] SET LOADER ARRAY`;
export const SET_MAP= `[LOADING] SET MAP`;
export const SET_MAIN_LOADERS= `[LOADING] SET MAIN LOADERS`;
export const SET_SHARED_LOADERS= `[LOADING] SET SHARED LOADERS`;
export const SET_PROJECT_SHARED_LOADERS= `[LOADING] SET PROJECT SHARED LOADERS`;

export const LoadingCommandActions = {
    setLoading: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loading', loading}),
    setLoadingContent: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingContent', loading}),
    setLoadingScroll: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingScroll', loading}),
    setLoadingMicrosoft: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingMicrosoft', loading}),
    setLoadingInternal: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingInternal', loading}),
    setLoadingExternal: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingExternal', loading}),
    setLoadingInternalRemove: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingInternalRemove', loading}),
    setLoadingExternalRemove: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingExternalRemove', loading}),
    setLoadingInternalNotifications: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingInternalNotifications', loading}),
    setLoadingExternalNotifications: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingExternalNotification', loading}),
    setLoadingInternalNotificationsRemove: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingInternalNotificationsRemove', loading}),
    setLoadingExternalNotificationsRemove: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingExternalNotificationRemove', loading}),
    setLoadingStatus: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingStatus', loading}),
    setLoadingTypes: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingTypes', loading}),
    setLoadingPurposes: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingPurposes', loading}),
    setLoadingEntities: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingEntities', loading}),
    setLoadingEngPhases: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingEngPhases', loading}),
    setLoadingDisciplinesAndDocTypes: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingDisciplinesAndDocTypes', loading}),
    setLoadingPbs: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingPbs', loading}),
    setLoadingRevisions: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingRevisions', loading}),
    setLoadingUi: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingUi', loading}),
    setLoadingPublications: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingPublications', loading}),
    setLoadingClient: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingClient', loading}),
    setLoadingPurpose: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingPurpose', loading}),
    setLoadingPurposesConfig: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingPurposesConfig', loading}),
    setLoadingEntitiesConfig: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingEmtitiesConfig', loading}),
    setLoadingPbsConfig: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingPbsConfig', loading}),
    setLoadingFolders: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingFolders', loading}),
    setLoadingStakeHolders: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingStakeHolders', loading}),
    setLoadingAllStakeHolders: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingAllStakeHolders', loading}),
    setLoadingAllReports: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingAllReports', loading}),
    setLoadingProjectReports: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingProjectReports', loading}),
    setLoadingProjectPermissions: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingProjectPermissions', loading}),
    setLoadingAllPbsDisciplines: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingAllPbsDisciplines', loading}),
    setLoadingUserNotifications: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_LOADER, {feature, name: 'loadingUserNotifications', loading}),
}

export const LoadingDocumentActions = {
    setLoader: ({feature, name, loading}: {feature: string, name: string, loading: boolean }) => createAction(SET_LOADER, {feature, name, loading}),
    setLoaderArray: ({feature, name, id}: {feature: string, name: string, id: string }) => createAction(SET_LOADER_ARRAY, {feature, name, id}),
    setMainLoaders: ({feature, loading}: {feature: string, loading: boolean }) => createAction(SET_MAIN_LOADERS, {feature, loading}),
    setSharedLoaders: ({loading}: {loading: boolean }) => createAction(SET_SHARED_LOADERS, {loading}),
    setProjectSharedLoaders: ({loading}: {loading: boolean }) => createAction(SET_PROJECT_SHARED_LOADERS, {loading})
}


export const CommandActions = {...LoadingCommandActions};
export const DocumentActions = {...LoadingDocumentActions};
export const Actions = {...CommandActions, ...DocumentActions};

// group actions
export type CommandActions = ActionsUnion<typeof CommandActions>
export type DocumentActions = ActionsUnion<typeof DocumentActions>
export type Actions = ActionsUnion<typeof Actions>