import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import { getClassNames } from './index.classNames';
import { DocumentActions as UiDocumentActions} from '../../../redux/actions/ui';
import { CommandActions as DocumentCommandActions} from '../../../redux/actions/document';
import { CommandActions as DocumentsCommandActions} from '../../../redux/actions/documents';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import _ from 'lodash'
import { getFilterPanelOpened } from '../../../redux/reducers/ui';
import { Document, getEmptyFileForm } from '../../../models/document';
import { getTotal } from '../../../redux/reducers/documents';
import { getStatusValues } from '../../../redux/reducers/sharedData';
import { Status } from '../../../models/status';
import { getStatusSubMenuItems } from '../../../utils/statusFunctions';
import { IContextualMenuItem } from '@fluentui/react';
import { InternalRoleActionsType, ExternalRoleActionsType, isExternalApp } from '../../../utils/costants';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getContextualDocuments, getSelectedDocument } from '../../../redux/selectors/documents';
import { getSelectedProject } from '../../../redux/selectors/project';
import { Project } from '../../../models/project';
import { getHistoryName } from '../../../utils/functions';
import { SharedDataCommandActions } from '../../../redux/actions/sharedData';
import { getSelectedProjectCustomerStakeholders } from '../../../redux/selectors/administration';
import { StakeHolder } from '../../../models/stakeHolder';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getSelectedProjectUserRole } from '../../../redux/selectors/auth';
import { Role } from '@pwbapps/reduxcore/dist/models/role';

export interface ContactsProps extends RouteComponentProps, WithTranslation  {
    filterPanelOpened: boolean, 
    selectedDocuments: Document[],
    total: number,
    loading: boolean,
    loadingContent: boolean,
    loadingScroll: boolean,
    statusValues: Status[],
    selectedDocument?: Document,
    selectedProject?: Project,
    customerStakeholders: StakeHolder[],
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    userProjectRole: Role | undefined
    setFilterPanelOpened: typeof UiDocumentActions.setFilterPanelOpened,
    openEditModal: typeof DocumentsCommandActions.openEditModal,
    setOpenModal: typeof DocumentCommandActions.setOpenModal,
    openGenericModal: typeof ModalActions.CommandActions.openModal,
    fetchDocuments: typeof DocumentsCommandActions.fetchDocuments,
    fetchReserveNumbersValues: typeof SharedDataCommandActions.fetchReserveNumbersValues,
}

class DocumentsCommandBar extends React.Component<ContactsProps & RouteComponentProps> {
    constructor(props: ContactsProps) {
        super(props);
    }   


    getButtons = () => {
        const { selectedDocuments, total, openGenericModal, openEditModal, fetchDocuments, fetchReserveNumbersValues, loadingContent, loading, loadingScroll, statusValues, userProjectActions, selectedDocument, selectedProject, history, customerStakeholders, userProjectRole, t } = this.props;
        const selectedDocumentIds = _.map(selectedDocuments, d => d.id as number);
        let buttons: ICommandBarItemProps[] = [];
        let historyName = getHistoryName(history);
        const singleCustomer = (customerStakeholders.length === 1) ? customerStakeholders[0] : undefined;
        let statusSubMenuItems: IContextualMenuItem[] = (selectedProject && selectedDocument && selectedDocument.status) ? (getStatusSubMenuItems(selectedDocument.status, statusValues, selectedProject, historyName, openGenericModal, singleCustomer) as IContextualMenuItem[]) : [];
        const commentsExist = (selectedDocuments && selectedDocuments.length > 0) ? _.some(selectedDocuments, d => d.comments && d.comments.length > 0) : true;
        const firstFoldersIds = (selectedDocuments && selectedDocuments.length > 0) ? selectedDocuments[0].folderIds : [];
        const removeFromFolderEnabled = (firstFoldersIds.length > 0 && selectedProject && selectedProject.isFolderPageActive && _.find(userProjectActions, a => a === 'ShowFolders')) ? _.every(selectedDocuments, d => _.isEqual(d.folderIds, firstFoldersIds))  : false
        buttons = [
             ...(_.find(userProjectActions, a => a === 'ReserveNumbersDashboard') ? [{
                key: 'reserveNumber',
                name: t('Reserve'),
                disabled: (loadingContent || loading || loadingScroll),
                onClick: () => { fetchReserveNumbersValues() },
                iconProps: {
                    iconName: 'NumberField'
                }
            }] : []),    
            ...(_.find(userProjectActions, a => a === 'ReserveNumbersDashboard') ? [{
                key: 'newRevision',
                name: t('New Revision'),
                disabled: (selectedDocumentIds.length !== 1 || (selectedDocument && (!selectedDocument.isCurrent || !selectedDocument.editable)) || loadingContent || loading || loadingScroll),
                onClick: () => { openGenericModal({id: 'revision' }) },
                iconProps: {
                    iconName: 'OpenInNewTab'
                }
            }] : []),    
            ...(_.find(userProjectActions, a => a === 'ReserveNumbersDashboard') ? [{
                key: 'newSheet',
                name: t('New Sheet'),
                disabled: (selectedDocumentIds.length !== 1 || (selectedDocument && (!selectedDocument.isCurrent || !selectedDocument.editable)) || loadingContent || loading || loadingScroll),
                onClick: () => { openGenericModal({id: 'sheet', contextItem: 1 }) },
                iconProps: {
                    iconName: 'Document'
                }
            }] : []),    
            ...(_.find(userProjectActions, a => a === 'ChangeStatusDashboard') ? [{
                key: 'changeStatus',
                name: t('Change Status'),
                disabled: (selectedDocumentIds.length !== 1 || (selectedDocument && (!selectedDocument.isCurrent || !selectedDocument.editable))  || statusSubMenuItems.length === 0 || loadingContent || loading || loadingScroll),
                iconProps: {
                    iconName: 'Sync'
                },
                subMenuProps: {
                    items: (statusSubMenuItems.length > 0) ? statusSubMenuItems : []
                } 
            }] : []),                    
            ...(_.find(userProjectActions, a => a === 'EditDashboard') ? [{
                key: 'editDocument',
                name: t('Edit'),
                disabled: (selectedDocumentIds.length !== 1 || (selectedDocument && (!selectedDocument.isCurrent || !selectedDocument.editable)) || (selectedDocument && selectedDocument.status && selectedDocument.status.name === 'canceled') || loadingContent || loading || loadingScroll),
                onClick: () => {openEditModal()},
                iconProps: {
                    iconName: 'Edit'
                },
            }] : []),  
            ...(_.find(userProjectActions, a => a === 'DeleteDashboard') ? [{
                key: 'delete',
                name: t('Delete'),
                disabled: (selectedDocumentIds.length === 0 || _.some(selectedDocuments, sd => !sd.editable) || !(_.every(selectedDocuments, d => d.isCurrent && d.status && (d.status.name === 'uploaded' || d.status.name === 'ready-for-publishing'))) || loadingContent || loading || loadingScroll),
                onClick: () => {openGenericModal({id: 'deleteDocument'})},
                iconProps: {
                    iconName: 'Cancel',
                    style: {color: "red"}
                },
            }] : []),         
            ...(_.find(userProjectActions, a => (a === 'UploadAttachmentDashboard' || a === 'UploadCommentFileDashboard') && !_.some(userProjectActions, a => a === 'ReserveNumbersDashboard')) ? [{
                key: 'upload',
                name: t('Upload'),
                disabled: (selectedDocumentIds.length !== 1 || !(selectedDocument && selectedDocument.isCurrent && selectedDocument.status && selectedDocument.status.name !== 'canceled') || (selectedDocument && (!selectedDocument.files || selectedDocument.files.length === 0 || !_.some(selectedDocument.files, f => f.isParent))) || (isExternalApp && selectedDocument.purpose && !selectedDocument.purpose.canBeCommented) || loadingContent || loading || loadingScroll),
                onClick: () => {openGenericModal({id: 'uploadDocuments', contextItem: {files: [], fileForm: getEmptyFileForm((_.find(userProjectActions, a => a === 'UploadAttachmentDashboard')) ? true : false, (_.find(userProjectActions, a => a === 'UploadCommentFileDashboard')) ? true : false)}})},
                iconProps: {
                    iconName: 'BulkUpload'
                },
            }] : []),   
            ...(_.find(userProjectActions, a => a === 'ReserveNumbersDashboard') ? [{
                key: 'upload',
                name: t('Upload'),
                disabled: (loadingContent || loading || loadingScroll),
                iconProps: {
                    iconName: 'BulkUpload'
                },
                subMenuProps: {
                    items: [
                        {
                            key: 'upload',
                            name: t('Attachments and Comments'),
                            disabled: (selectedDocumentIds.length !== 1 || !(selectedDocument && (selectedDocument.isCurrent && selectedDocument.status && selectedDocument.status.name !== 'canceled')) || (!selectedDocument.files || selectedDocument.files.length === 0 || !_.some(selectedDocument.files, f => f.isParent)) || loadingContent || loading || loadingScroll),
                            onClick: () => {openGenericModal({id: 'uploadDocuments', contextItem: {files: [], fileForm: getEmptyFileForm((_.find(userProjectActions, a => a === 'UploadAttachmentDashboard')) ? true : false, (_.find(userProjectActions, a => a === 'UploadCommentFileDashboard')) ? true : false)}})},
                            iconProps: {
                                iconName: 'Attach'
                            },
                        },
                        {
                            key: 'import',
                            name: 'Zip',
                            disabled: (total === 0 || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'importZip'}) },
                            iconProps: {
                                iconName: 'ZipFolder',
                                style: {color: "#C2891E"}
                            },
                        },
                    ]
                } 
            }] : []),   
            ...(_.find(userProjectActions, a => a === 'ReadDashboard') ? [{
                key: 'downlaod',
                name: t('Download'),
                disabled: (loadingContent || loading || loadingScroll),
                iconProps: {
                    iconName: 'Download',
                },
                subMenuProps: {
                    items: [
                        {
                            key: 'downlaodFiles',
                            name: t('Files'),
                            disabled: (total === 0 || !_.some(selectedDocuments, d => d.files.length > 0) || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'fileName', contextItem: {fileName: 'Documents Files', isParent: false}}) },
                            iconProps:  {
                                iconName: 'DownloadDocument'
                            } 
                        },
                        {
                            key: 'downloadComments',
                            name: t('Comments'),
                            disabled: (total === 0 || !commentsExist || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'comments', optional: 'Documents Comments'}) },
                            iconProps: {
                                iconName: 'Comment',
                            },
                        },
                        ...(userProjectRole && userProjectRole.name && userProjectRole.name.includes('SUPERADMIN') ? [{
                            key: 'downlaodDocNumbers',
                            name: t('Files (TEST)'),
                            disabled: (total === 0 || !_.some(selectedDocuments, d => d.files.length > 0) || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'fileNameTest', contextItem: {fileName: 'Docs', isParent: false}}) },
                            iconProps:  {
                                iconName: 'DownloadDocument'
                            } 
                        }] : []),   
                    ]
                }          
            }] : []),    
            ...(selectedProject && selectedProject.isFolderPageActive && _.find(userProjectActions, a => a === 'ShowFolders') ? [{
                key: 'folders',
                name: 'Folders',
                disabled: (loadingContent || loading || loadingScroll),
                iconProps: {
                    iconName: 'FabricFolder',
                },
                subMenuProps: {
                    items: [
                        {
                            key: 'addToFolder',
                            name: 'Add to folder',
                            disabled: (selectedDocumentIds.length === 0 || (selectedDocument && (!selectedDocument.editable)) || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'folder', contextItem: {folder: undefined, isAdding: true, isRemoving: false}}) },
                            iconProps: {
                                iconName: 'FabricSyncFolder',
                            },
                        },
                        {
                            key: 'removeFromFolder',
                            name: 'Remove from folder',
                            disabled: (selectedDocumentIds.length === 0 || (selectedDocument && (!selectedDocument.editable)) || !removeFromFolderEnabled || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'folder', contextItem: {folder: undefined, isAdding: false, isRemoving: true}}) },
                            iconProps: {
                                iconName: 'FabricUnsyncFolder',
                            },
                        }
                    ]
                }        
            }] : []),   
            //...(!isExternalApp || _.find(userProjectActions, a => a === 'ReserveNumbersDashboard') ? [
            {
                key: 'excelReport',
                name: t('Report'),
                disabled: (total === 0 || loadingContent || loading || loadingScroll),
                onClick: () => { openGenericModal({id: 'excelReport', optional: 'Documents Report'}) },
                iconProps: {
                    iconName: 'ExcelDocument',
                    style: {color: "#008000"}
                },
            },
            //] : []), 
            {
                key: 'refresh',
                name: t('Refresh'),
                disabled: (loadingContent || loading || loadingScroll),
                onClick: () => { fetchDocuments({}) },
                iconProps: {
                    iconName: 'Refresh',
                },
            }   
        ]
        return buttons;
    };

    getFarButtons = () => {
      const {setFilterPanelOpened, filterPanelOpened} = this.props;
      return [
     
        {
          key: 'openPanel',
          name: 'Filters',
          iconProps: {
            iconName: 'Filter'
          },
          ariaLabel: 'Filters',    
          onClick: () => {setFilterPanelOpened({open: !filterPanelOpened})}      
        },          
      ]; 
  };

    render() {
        const styles = getClassNames();
        return (
            <>
                <CommandBar
                    styles={{root: styles.root, secondarySet: {borderTop: 1}}}
                    items={this.getButtons()}
                    farItems={this.getFarButtons()}
                />
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    filterPanelOpened: getFilterPanelOpened,
    selectedDocuments: getContextualDocuments,
    total: getTotal,
    statusValues: getStatusValues,
    selectedDocument: getSelectedDocument,
    selectedProject: getSelectedProject,
    customerStakeholders: getSelectedProjectCustomerStakeholders,
    userProjectRole: getSelectedProjectUserRole
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setFilterPanelOpened: UiDocumentActions.setFilterPanelOpened,
        setOpenModal: DocumentCommandActions.setOpenModal,
        openGenericModal: ModalActions.CommandActions.openModal,
        openEditModal: DocumentsCommandActions.openEditModal,
        fetchDocuments: DocumentsCommandActions.fetchDocuments,
        fetchReserveNumbersValues: SharedDataCommandActions.fetchReserveNumbersValues,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(DocumentsCommandBar)));
