import React from 'react';
import { IColumn } from '@fluentui/react';
import { getId } from '@fluentui/react';
import _ from 'lodash';
import { PermissionElement, Permissions } from '../../../models/permissions';

const _hostId: string = getId('tooltipHost');

export const getColumns = () => {


      const columns: IColumn[] = [
        {
          key: 'approvers',
          name: 'Email',
          fieldName: 'iapproverstem',
          minWidth: 320,
          maxWidth: 320,
          isResizable: true,
          data: 'string',
          isPadded: true,        
          onRender: (item: string) => {
            return(
              <div>{item}</div>
            );
          }  
        },

    ];

    return columns;
}




