import {createAction, ActionsUnion} from '@pwbapps/reduxcore';
import { PermissionElement } from '../../models/permissions';

export const GET_EXTERNAL_USER_DETAILS = `[INITIALIZATION] GET EXTERNAL USER DETAILS`;
export const SET_VALUE = `[INITIALIZATION] SET VALUE`;
export const GET_STAKEHOLDER_ID = `[INITIALIZATION] GET STAKEHOLDER ID`;

export const InitializationCommandActions = {
    setCompany: ({value}: {value: string}) => createAction(SET_VALUE, {name: 'company', value}),
    setCountry: ({value}: {value: string}) => createAction(SET_VALUE, {name: 'country', value}),
    setStakeHolderId: ({value}: {value: number}) => createAction(SET_VALUE, {name: 'stakeHolderId', value}),
    setUserPermissions: ({value}: {value: PermissionElement[]}) => createAction(SET_VALUE, {name: 'userPermissions', value}),
    getStakeHolderId: () => createAction(GET_STAKEHOLDER_ID),
}

export const InitializationEventActions = {

}

export const InitializationDocumentActions = {
    setValue: ({name, value}: {name: string, value?: any}) => createAction(SET_VALUE, {name, value}),
}


export const CommandActions = {...InitializationCommandActions};
export const EventActions = {...InitializationEventActions};
export const DocumentActions = {...InitializationDocumentActions};
export const Actions = {...CommandActions, ...EventActions, ...DocumentActions};

// group actions
export type CommandActions = ActionsUnion<typeof CommandActions>
export type EventActions = ActionsUnion<typeof EventActions>
export type DocumentActions = ActionsUnion<typeof DocumentActions>
export type Actions = ActionsUnion<typeof Actions>
