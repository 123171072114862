import {Actions, CommandActions, EventActions, DocumentActions, RESET_AND_CLOSE_MODAL, GET_DETAILS_AND_OPEN_MODAL, ADD_COMMENT, DOWNLOAD_FILE, GET_DOCUMENT_REVISIONS, GET_DOCUMENT_AND_OPEN_MODAL, GET_DOCUMENT_FILES} from "../../actions/document";
import {ApiConfigActions} from "@pwbapps/reduxcore";
import { DocumentActions as NotificationDocumentActions} from "../../actions/notification";
import { CommandActions as LoaderCommandActions} from "../../actions/loader";
import _ from 'lodash';
import { MessageBarType } from '@fluentui/react';
import { getLoader } from "../../selectors/loader";
import moment from "moment";
import { Document, FileGrid, OtherRevision } from "../../../models/document";
import { addCommentUrl, downloadFileUrl, getDocumentDetailsUrl, getDocumentFilesUrl, getDocumentRevisionsUrl, getProjectDocumentsUrl } from "../../../utils/documentsUrls";
import { getSelectedProjectId } from "../../reducers/project";
import { getHistoryName } from "../../../utils/functions";
import { getDocuments, getOrderColumn } from "../../reducers/documents";
import { Comment, CommentForm } from "../../../models/comment";
import { StatusHistory } from "../../../models/status";
import { getDocument, getOpenModal } from "../../reducers/document";
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";
import { Modal } from "../../../models/modal";
import { getEmail } from "@pwbapps/reduxcore/dist/redux/reducers/auth";
import saveAs from "file-saver";
import { getFilters } from "../../reducers/filters";
import { getStatusValues, getTypes, getPurposes, getDisciplines, getEngPhases, getEntities, getPbs, getStakeHolders } from "../../reducers/sharedData";
import { getContextualDocument, getContextualDocuments } from "../../selectors/documents";
import { CommandActions as DocumentsCommandActions, DocumentActions as DocumentsDocumentActions, EventActions as DocumentsEventActions, FEATURE } from "../../actions/documents";
import { t } from "i18next";
import { getUserPermissions } from "../../reducers/initialization";
import { isExternalApp } from "../../../utils/costants";
import { getSelectedProjectUserActions } from "../../selectors/auth";

export const documentMiddlewareHistory = (history: any) => {
    return documentMiddleware(history);
}

export const documentMiddleware = (history: any) => ({dispatch, getState}: {dispatch: any, getState: any}) => (next: any) => (action: Actions | ApiConfigActions.EventActions ) => {
    next(action);

    switch (action.type) {

        case GET_DOCUMENT_AND_OPEN_MODAL: {   
            let filters = {...getFilters(getState()), showAllVersions: true}; // se voglio una revisione vecchia devo poterla prendere con showAllVersions = true
            const orderColumn = getOrderColumn(getState());
            const skip = 0;
            const documentId = action.payload.documentId;
            const historyName = getHistoryName(history);
            dispatch(DocumentsEventActions.setGridOrModalLoadersAndNotification({value: true}));   
            if(action.payload.changingRevision)   
                dispatch(LoaderCommandActions.setLoadingUi({ feature: 'document', loading: true}));
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: (isExternalApp && historyName === 'pending') ? 'POST' : 'GET', body: (isExternalApp && historyName === 'pending') ? {projectId: getSelectedProjectId(getState()) as number, email: getEmail(getState())} : undefined, url: getProjectDocumentsUrl(getSelectedProjectId(getState()) as number, filters, orderColumn, skip, getStatusValues(getState()), historyName, [documentId]), feature: GET_DOCUMENT_AND_OPEN_MODAL, returnObject: {documentId}}}));    
            break;
        }

        case GET_DETAILS_AND_OPEN_MODAL:           
            let document = action.payload.document;
            dispatch(CommandActions.getDocumentRevisions({documentId: document.id as number, agileNumber: document.agileNumber as string, revision: document.revision as string, sheet: document.sheet as number})); 
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getDocumentDetailsUrl(getSelectedProjectId(getState()) as number, document.id as number), feature: GET_DETAILS_AND_OPEN_MODAL, returnObject: {document}}}));     
            break;

        case GET_DOCUMENT_REVISIONS: {
            dispatch(LoaderCommandActions.setLoadingRevisions({ feature: 'document', loading: true}));      
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getDocumentRevisionsUrl(getSelectedProjectId(getState()) as number, action.payload.revision as string, action.payload.agileNumber as string, action.payload.sheet as number, _.some(getSelectedProjectUserActions(getState()), a => a === 'FilterDocumentsByCustomerId') ), feature: GET_DOCUMENT_REVISIONS}}));     
            break;
        }

        case GET_DOCUMENT_FILES: {
            if(!getLoader('document')(getState()).loading)
                dispatch(LoaderCommandActions.setLoading({ feature: 'document', loading: true}));      
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getDocumentFilesUrl(getSelectedProjectId(getState()) as number, action.payload.documentId as number), feature: GET_DOCUMENT_FILES}}));     
            break;
        }

        case ADD_COMMENT: {
            let commentForm = (action.payload.commentForm) ? action.payload.commentForm : ((getGenericModal('newComment')(getState())) ? (getGenericModal('newComment')(getState()) as Modal).contextItem as CommentForm : undefined);
            if(commentForm){
                if(!commentForm.createdBy && !commentForm.documentId){
                    const document = getContextualDocument(getState()) as Document;
                    commentForm.createdBy = getEmail(getState());
                    commentForm.documentId = document.id;
                }
                if(!action.payload.hideUiLoadsAndNots)
                    dispatch(LoaderCommandActions.setLoading({ feature: 'document', loading: true}));     
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'POST', body: {...commentForm}, url: addCommentUrl(), feature: ADD_COMMENT, returnObject: {commentForm, hideUiLoadsAndNots: action.payload.hideUiLoadsAndNots}}}));     
            }
            break;
        }

        case DOWNLOAD_FILE: {
            let documentId = action.payload.documentId;
            dispatch(LoaderCommandActions.setLoading({ feature: 'document', loading: true}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'document', show: true, type: MessageBarType.info, message: t('Downloading') + ' ' + action.payload.fileName + '...'}));  
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', headers: [{name: 'Accept', value: 'application/octet-stream'}], responseType: 'arraybuffer', url: downloadFileUrl(documentId), feature: DOWNLOAD_FILE, returnObject: {fileName: action.payload.fileName}}}));    
            break;
        }

        case RESET_AND_CLOSE_MODAL:
            next(CommandActions.setOpenModal({value: false}));    
            next(CommandActions.setDocument({value: undefined}));    
            break;
         
        case ApiConfigActions.API_SUCCESS:
            apiSuccessMiddleware(history, dispatch, getState, next, action);         
            break;
    
        case ApiConfigActions.API_ERROR:
            apiErrorMiddleware(history, dispatch, getState, next, action);         
            break;

        default:
            break;
    }
};

const apiSuccessMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiSuccessAction) => {
    switch(action.meta.feature){

        case GET_DOCUMENT_AND_OPEN_MODAL: {
            const historyName = getHistoryName(history);
            let document = (action.payload) ? action.payload.value[0]: [];
            let documentMapped = new Document(document, getStatusValues(getState()), getTypes(getState()), getPurposes(getState()), getDisciplines(getState()), getEngPhases(getState()), getEntities(getState()), getPbs(getState()), getUserPermissions(getState()), getStakeHolders(getState()), historyName);
            dispatch(CommandActions.getDetailsAndOpenModal({document: documentMapped})); 
            break; 
        }

        case GET_DETAILS_AND_OPEN_MODAL:
            const details = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value[0] : undefined;
            let document = action.meta.returnObject.document as Document; 
            if(document){
                document.comments = _.map(details.comments, c => new Comment(c));
                document.statusHistory = _.map(details.statusHistories, c => new StatusHistory(c));        
            }
            next(CommandActions.setDocument({value: document}));
            dispatch(DocumentsEventActions.setGridOrModalLoadersAndNotification({value: false})); 
            const modalOpened = getOpenModal(getState());
            if(!modalOpened)
                next(CommandActions.setOpenModal({value: true}));           
            dispatch(LoaderCommandActions.setLoadingUi({ feature: 'document', loading: false}));
            break;

        case GET_DOCUMENT_REVISIONS:
            const otherRevisions = (action.payload && action.payload.value) ? action.payload.value : [];
            let otherRevisionsMapped = _.map(otherRevisions, o => {return new OtherRevision(o)});        
            next(CommandActions.setOtherRevisions({value: otherRevisionsMapped}));
            dispatch(LoaderCommandActions.setLoadingRevisions({ feature: "document", loading: false}));
            break;

        case GET_DOCUMENT_FILES: {
            const files = (action.payload && action.payload.value && action.payload.value[0] && action.payload.value[0].exodItems) ? action.payload.value[0].exodItems : [];
            let filesMapped = _.map(files, f => {return new FileGrid(f)}); 
            let document = getDocument(getState());
            if(document){
                next(CommandActions.setDocument({value: {...document, files: filesMapped}}));
                let gridDocument = _.find(getDocuments(getState()), d => document && d.id === document.id) as Document | undefined;
                if(gridDocument)
                    next(DocumentsDocumentActions.setData({data: _.map(getDocuments(getState()), d => {return (gridDocument && d.id === gridDocument.id) ? {...gridDocument, files: filesMapped} : d}), feature: FEATURE}))
            }       
            dispatch(LoaderCommandActions.setLoading({ feature: "document", loading: false}));
            break;
        }

        case ADD_COMMENT: {
            let commentForm = action.meta.returnObject.commentForm as CommentForm;
            let hideUiLoadsAndNots = action.meta.returnObject.hideUiLoadsAndNots as boolean | undefined;
            if(!hideUiLoadsAndNots){
                let newComment: Comment = {createdBy: commentForm.createdBy, text: commentForm.commentText, creationDate: moment().toDate(), type: 'comment'};
                dispatch(LoaderCommandActions.setLoading({ feature: 'document', loading: false}));  
                let document = getContextualDocument(getState()) as Document;
                document = {...document, comments: (document.comments && document.comments.length > 0) ? [newComment, ...document.comments] : [newComment]};
                next(CommandActions.setDocument({value: document}));
                let documents: Document[] = _.map([...getDocuments(getState())], d => {return (d.id === document.id) ? document : d});
                next(DocumentsDocumentActions.setData({data: documents, feature: FEATURE}));
                next(NotificationDocumentActions.setNotificationStatus({name: 'document', show: true, type: MessageBarType.success, message: t('Comment added successfully.')}));
                setTimeout(() => { next(NotificationDocumentActions.setNotificationStatus({name: 'document', show: false, type: MessageBarType.info, message: ''})); }, 5000);
            }
            break;
        }

        case DOWNLOAD_FILE: {
            let document = action.payload;
            let fileName = action.meta.returnObject.fileName as string;
            if(document && fileName) 
                saveAs(new Blob([document]), `${fileName}`);
            dispatch(LoaderCommandActions.setLoading({ feature: 'document', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'document', show: true, type: MessageBarType.success, message: t('Download completed')}));  
            setTimeout(() => { next(NotificationDocumentActions.setNotificationStatus({name: 'document', show: false, type: MessageBarType.info, message: ''})); }, 5000);
            break;
        }
    

        default:
            break;
    }
}

const apiErrorMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiErrorAction) => {
    switch(action.meta.feature){

        case GET_DETAILS_AND_OPEN_MODAL:
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot get the document details. Please refresh the page.')}));
            break;

        case GET_DOCUMENT_REVISIONS:
            dispatch(LoaderCommandActions.setLoadingContent({ feature: 'document', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'document', show: true, type: MessageBarType.error, message: t('Cannot get document revisions. Please refresh the page.')}));
            break;

        case GET_DOCUMENT_AND_OPEN_MODAL:
            dispatch(LoaderCommandActions.setLoadingContent({ feature: 'document', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'document', show: true, type: MessageBarType.error, message: t('Cannot get the document. Please refresh the page.')}));
            break;

        case ADD_COMMENT: {
            dispatch(LoaderCommandActions.setLoadingContent({ feature: 'document', loading: false}));  
            next(NotificationDocumentActions.setNotificationStatus({name: 'document', show: true, type: MessageBarType.error, message: t('Cannot add new comment. Please retry')}));
            break;
        }

        case DOWNLOAD_FILE: {
            dispatch(LoaderCommandActions.setLoading({ feature: 'document', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'document', show: true, type: MessageBarType.error, message: t('Cannot download this file. Please retry.')}));
            break;
        }


        default:
            break;
    }
}