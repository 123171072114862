import { mergeStyleSets } from '@fluentui/react';

export interface IComponentClassNames {
    navbar: string
}
  
export const getClassNames = (darkModeEnabled: boolean): IComponentClassNames => {
    return mergeStyleSets({
        navbar: {
            width: 228,
            zIndex: 10000,
            boxSizing: 'border-box',
            border: '1px solid #eee',
            overflowY: 'auto',
            height: 'calc(100vh - 28px)',
            boxShadow: (!darkModeEnabled) ? '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)' : '0px 2px 4px -1px rgba(255,255,255,0.2), 0px 4px 5px 0px rgba(255,255,255,0.14), 0px 1px 10px 0px rgba(255,255,255,0.12)', 
            flexShrink: 0,
            backgroundColor: (darkModeEnabled) ? '#1c1c1c' : 'rgb(243, 242, 241)'
        }
    });
};