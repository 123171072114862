import React from 'react';
import { PrimaryButton } from '@fluentui/react';
import WhiteSadCloud from './../../images/white_sad_cloud.png';
import GreySadCloud from './../../images/grey_sad_cloud.png';
import { getClassNames } from './index.classNames';

export interface ErrorBoxProps { text?: string, onRetryClick?(): void, grey?: any, noImage?: boolean, marginTop?: number }

class ErrorBox extends React.Component<ErrorBoxProps> {
    constructor(props: ErrorBoxProps) {
        super(props);   
    }

    render(){
        const { text, onRetryClick, grey, noImage, marginTop } = this.props;
        const styles = getClassNames();
        const mt = marginTop ? marginTop : 0
        return (
            <div className={styles.container}>
                {!noImage && 
                    <div className={styles.child} style={{marginTop: mt}}>
                        <img src={!grey ? WhiteSadCloud : GreySadCloud} alt="Can't load data" />
                    </div>           
                }
                <div className={styles.child}>
                    {text}
                </div>
                {
                    onRetryClick
                        ?   <div className={styles.child}>
                                  <PrimaryButton text="TRY AGAIN" onClick={onRetryClick}/>
                            </div>
                        :   <div></div>
                }
            </div>
        );
    }
}

export default (ErrorBox);