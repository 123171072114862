import {getDrawerOpened, getTooltips} from '../reducers/ui'
import _ from 'lodash';
import { ModalIds } from '../../models/modal';

export const getTooltip = (name: string) => (state: any) => {
    let tooltips = getTooltips(state);
    if(tooltips[name])    
        return tooltips[name];
    else
        throw "error";
}

export const getNotificationWidth = (state: any): string => {
    let drawerOpened = getDrawerOpened(state);
   return 'calc(100%' + ((drawerOpened) ? ' - 263px)' : ' - 35px)');
}