import buildQuery from 'odata-query'
import _, { filter } from 'lodash'
import { Filters } from '../models/filters';
import { documentsTop, HistoryPathType, isExternalApp } from './costants';
import { Status } from '../models/status';
import { addSupplierHiddenStatus } from './statusFunctions';
import { PermissionElement } from '../models/permissions';

const BuildQuery: any = buildQuery;

export const getProjectDocumentsOdataQuery = 
(   projectId: number, 
    filters: Filters, 
    orderColumn: {name: string, direction: string},
    skip: number, allStatus: Status[], 
    historyName: HistoryPathType, 
    documentIds?: number[], 
    onlyIds?: boolean, 
    supplierCompany?: string, 
    partnerId?: number,
    customerId?: number,
) => 
{   
    let query = undefined; 
    const statusOptions = (!filters.ignorePurposes || historyName !== 'pending') ? addSupplierHiddenStatus([...filters.status, ...filters.defaultStatusList], historyName, allStatus) : [];
    partnerId = (!partnerId && filters.partner) ? filters.partner.stakeHolderId : partnerId; 
    customerId = (!customerId && filters.customer) ? filters.customer.stakeHolderId : customerId; 
    supplierCompany = (!supplierCompany && filters.supplier) ? filters.supplier.companyName : supplierCompany;


    let filtersDocuments: any = [ 
        //Documents 
        { projectid: { eq: projectId } },
        ...(documentIds && documentIds.length > 0 ? [{ documentId: { in: [...documentIds]} }] : []),
        ...(filters.documentNumber.length > 0 ? [{or: _.map(filters.documentNumber, dn => {return {documentNumber : {contains: dn}}})}] : []), //[{ documentNumber: { or: [ _.map(filters.documentNumber, dn => {return {contains: dn}}) ]  }  }]
        ...(filters.sheet ? [{ sheet: { eq: filters.sheet} }] : []),
        ...(filters.revision ? [{ revision: { contains: filters.revision} }] : []),
        ...(filters.agileNumber ? [{ agileNumber: { contains: filters.agileNumber} }] : []),
        ...(filters.clientNumber ? [{ clientNumber: { contains: filters.clientNumber} }] : []),
        ...(filters.tdNumber ? [{ tdNumber: { contains: filters.tdNumber}  }] : []),
        ...(filters.publication ? [{ DocumentStakeHolders: {any: {publication: { eq: filters.publication}}}}] : []),
        ...(filters.type ? [{ typeId: { eq: filters.type.id} }] : []),
        ...(filters.entity ? [{ entityId: { eq: filters.entity.id} }] : []),
        ...(filters.engPhase ? [{ engineeringPhaseId: { eq: filters.engPhase.id} }] : []),
        ...(filters.discipline ? [{ disciplineId: { eq: filters.discipline.id} }] : []),
        ...(filters.docType ? [{ docTypeId: { eq: filters.docType.id} }] : []),
        ...(filters.pbs ? ["startswith(pbs, '" + filters.pbs.name + "')"] : []),
        ...(!isExternalApp && filters.isForInternalUse === false ? [{ isForInternalUse: { eq: false }}] : []),
        ...(filters.isEngineeringOrder === true ? [{ engineeringOrderId: { ne: null }}] : ((historyName === 'createRevision') ? [{ engineeringOrderId: { eq: null }}] : [])),
        ...(filters.showAllVersions === false ? [{ isCurrent: { eq: true }}] : []),
        ...(filters.titleLine4Pl ? [{ titleLine4Pl: { contains: filters.titleLine4Pl} }] : []),
        ...(filters.titleLine5Pl ? [{ titleLine5Pl: { contains: filters.titleLine5Pl} }] : []),
        ...(filters.titleLine6Pl ? [{ titleLine6Pl: { contains: filters.titleLine6Pl} }] : []),
        ...(filters.partnerNumber ? [{ partnerNumber: { contains: filters.partnerNumber} }] : []),
        ...(filters.approvalDate ? [{ approvalDate: { ge: filters.approvalDate} }] : []),
        ...(filters.supplierDocumentNumber ? [{ supplierDocumentNumber: { contains: filters.supplierDocumentNumber} }] : []),
        //Purpose
        ...(filters.purpose && historyName !== "pending" ? [{ purposeId: { eq: filters.purpose.id} }] : []),
        ...(filters.purpose && historyName === "pending" && filters.defaultPurposesList.length > 0 ? [{ purposeId: { eq: filters.purpose.id} }] : []),
        ...(!filters.purpose && filters.defaultPurposesList.length > 0 && historyName !== 'createRevision' && !filters.ignorePurposes ? [{ purposeId: { in: [..._.map(filters.defaultPurposesList, p => p.id)] }}] : []),
        ...(filters.defaultPurposesList.length === 0 && historyName === "pending" ? [{ purposeId: { eq: 899111998} }] : []),
        //ExodItems
        ...(filters.pwFilename ? [{ ExodItems: { any: {fileName: { contains: filters.pwFilename}, isParent: true} } }] : []),
        ...(filters.commented === true ? [{ or: [ { ExodItems: { any: {isComment: true} } }, { Comments: { any: {commentid: {ne: null}} } }]}] : []),
        //LastStatusNavigation
        ...(historyName !== 'createRevision' && statusOptions.length > 0 ? [{ LastStatusNavigation: {statusId: { in: [..._.map(statusOptions, s => s.id)]}} }] : []),
        ...(filters.from ? [{ LastStatusNavigation: { date: { ge: filters.from} } }] : []),
        ...(filters.to ? [{ LastStatusNavigation: { date: { le: filters.to} } }] : []),
        //Status
        ...(filters.statusCategory && !partnerId ? [{ LastStatusNavigation: { Status: {category: { eq: filters.statusCategory}} }}] : []),
        ...(partnerId ? [{ LastStatusNavigation: { Status: {category: { eq: 'Customer'}} }}] : []),
        ...(partnerId ? [{ LastStatusNavigation: { Status: {internalStatus: { eq: false}} }}] : []),
        //EngineeringOrder
        ...(supplierCompany ? [{ EngineeringOrder: { Supplier: {companyName: { eq: supplierCompany}} }}] : []),
        //Partner
        ...(partnerId ? [{ partnerId: { eq: partnerId }}] : []),
        //Customer
        ...(customerId ? [{ DocumentStakeHolders: {any: {stakeHolderId: { eq: customerId}}}}] : []),
        //Folders
        ...(filters.folder ? [{ Folders: {any: [{folderId:  {in: { type: 'guid', value: [filters.folder.id]}}}]}}] : []),
        //Generic field
        ...(filters._generic ? [{ 
            or: [
                { documentNumber: { contains: filters._generic} },
                { agileNumber: { contains: filters._generic} },
                { revision: { contains: filters._generic} },
                { tdNumber: { contains: filters._generic} },
                { titleLine4Pl: { contains: filters._generic} },
                { titleLine5Pl: { contains: filters._generic} },
                { titleLine6Pl: { contains: filters._generic} },
                { clientNumber: { contains: filters._generic} },
                { supplierDocumentNumber: { contains: filters._generic }}
            ] 
        }] : []),
    ]; 

    if(historyName === 'createRevision'){
        const statusWithoutPurpose = _.filter(statusOptions, so => so.name !== 'published');
        const statusWithPurpose = _.filter(statusOptions, so => so.name === 'published');
        filtersDocuments = [...filtersDocuments, 
            {
                or: [
                    ...(statusWithoutPurpose.length > 0 ? [{ LastStatusNavigation: {statusId: { in: [..._.map(statusWithoutPurpose, s => s.id)]}} }] : []),
                    ...(statusWithPurpose.length > 0 && filters.defaultPurposesList.length > 0 ? [
                        { 
                            and: [
                                { LastStatusNavigation: {statusId: { in: [..._.map(statusWithPurpose, s => s.id)]}} },  
                                { purposeId: { in: [..._.map(filters.defaultPurposesList, p => p.id)] }}
                            ]
                        }                       
                    ] : []),  
                ]
            }
        ];
    }
    const exodItems = {ExodItems: {select: ['FileName', 'ExodItemId', 'IsComment', 'IsParent', 'CreatedDate', 'CreatedBy'] }};
    const comments = {Comments: {select: ['CommentId'] }};
    const folders = {Folders: {select: ['FolderId'], ...(filters.folder ? {filter: {any: [{folderId:  {in: { type: 'guid', value: [filters.folder.id]}}}]} } : {}) }};
    const status = {Status: {select: ['Category'], filter: {category: { eq: filters.statusCategory}}}};
    const lastStatusNavigation = {LastStatusNavigation: {select: ['StatusId', 'Date', 'DocumentId'], ...(statusOptions.length > 0 ? {filter: {statusId: { in: [..._.map(statusOptions, s => s.id)]}} } : {}), ...(filters.statusCategory ? {expand: status } : {})}};
    const engOrders = {EngineeringOrder: {select: ['SupplierId'], expand: {Supplier: {select: ['CompanyName'], ...(supplierCompany ? {filter: {companyName: {eq: supplierCompany}}} : {}) }} }};
    const documentStakeholders = customerId ? {DocumentStakeHolders: {filter: {stakeHolderId: {eq: customerId}}}} : 'DocumentStakeHolders';
    let expandDocuments: any[] = [ exodItems, lastStatusNavigation, comments, folders, documentStakeholders];     
    expandDocuments = (supplierCompany || (!isExternalApp && (historyName === 'reservedNumbers' || historyName === 'supplierMaterial'))) ? [...expandDocuments, {...engOrders}] : expandDocuments;
    const count = true;
    query = BuildQuery(
        {
            count, 
            filter: filtersDocuments, 
            ...((!onlyIds && !documentIds) ? {top: documentsTop, skip} : {}),
            ...((!onlyIds) ? {expand: expandDocuments} : {}),
            ...((onlyIds) ? {select: ['documentId']} : {}),
            ...(orderColumn && orderColumn.name && orderColumn.name !== 'date' ? { orderBy: [orderColumn.name + ' ' + orderColumn.direction]} : {}),
            ...(orderColumn && orderColumn.name && orderColumn.name === 'date' ? { orderBy: ['LastStatusNavigation/' + orderColumn.name + ' ' + orderColumn.direction]} : {}),
        }
    );
    return query;
}

export const getDocumentDetailsOdataQuery = (projectId: number, documentId: number) => {   
    let query = undefined; 
    let select = ['Comments', 'StatusHistories'];
    let filter = [
        { projectid: { eq: projectId } },
        { documentId: { eq: documentId } },
    ]
    const expand = {Comments: {select: ['CommentId', 'CommentText', 'CreationDate', 'CreatedBy', 'CommentText'], orderBy: ['CreationDate desc'] }, StatusHistories: {select: ['StatusHistoryId', 'StatusId', 'Date', 'StatusUser']}};
    query = BuildQuery({expand, select, filter});
    return query;
}

export const getDocumentRevisionOdataQuery = (projectId: number, currentRevision: string,  agileNumber: string, sheet: number, isCustomer: boolean) => {   
    let query = undefined; 
    let select = ['documentId', 'documentNumber', 'agileNumber', 'sheet', 'revision', 'isCurrent'];
    let filter = [
        { projectid: { eq: projectId } },
        { revision: { ne: currentRevision } },
        { agileNumber: { eq: agileNumber } },
        { sheet: { eq: sheet } },
        ...(isCustomer ? [{ engineeringOrderId: {eq: null} }] : []),
    ];   
    query = BuildQuery({select, filter});
    return query;
}

export const getDocumentFilesOdataQuery = (projectId: number, documentId: number) => { 
    let query = undefined; 
    let select = ['ExodItems'];
    let expand = {ExodItems: {select: ['FileName', 'ExodItemId', 'IsComment', 'IsParent', 'CreatedDate', 'CreatedBy'] }};
    let filter = [
        { projectid: { eq: projectId } },
        { documentId: { eq: documentId } },
    ];
    query = BuildQuery({expand, select, filter});
    return query;
}

export const getEngOrderIdByIdsOdataQuery = (projectId: number, documentNumbers: string[]) => {   
    let query = undefined; 
    let select = ['DocumentId', 'DocumentNumber'];
    let filter = [
        { projectid: { eq: projectId } },
        { documentNumber: { in: [...documentNumbers] } },
        { engineeringOrderId: {ne: null}}
    ];
    query = BuildQuery({select, filter});
    return query;
}

export const getAllStatusOdataQuery = () => {   
    let query = undefined; 
    query = BuildQuery({select: ['StatusId', 'StatusName', 'Category', 'InternalStatus', 'AgileStatus']});
    return query;
}

export const getPurposesOdataQuery = () => {   
    let query = undefined; 
    query = BuildQuery({select: ['PurposeId', 'PurposeName', 'PurposeCode']});
    return query;
}

export const getPurposesConfigOdataQuery = (projectId: number) => {   
    let query = undefined; 
    query = BuildQuery({
        expand: ['ProjectPurposes'],
        select: ['ProjectPurposes'], 
        filter: [ { projectid: { eq: projectId } }]
    }); 
    return query;
}

// export const getPermissionsOdataQuery = (projectId: number) => {   
//     let query = undefined; 
//     query = BuildQuery({
//         filter: [ { projectid: { eq: projectId } }]
//     }); 
//     return query;
// }

export const getProjectFormOdataQuery = (projectId: number) => {   
    let query = undefined; 
    let projectStakeHolders = {ProjectStakeHolders: {expand: ['StakeHolder']}};
    query = BuildQuery({
        expand: ['ProjectPurposes', [projectStakeHolders], 'Reports'],
        select: ['ProjectPurposes', 'ProjectStakeHolders', 'Reports'], 
        filter: [ { projectid: { eq: projectId } }]
    }); 
    return query;
}


export const getAllStakeHoldersOdataQuery = () => {   
    let query = undefined; 
    query = BuildQuery({ expand: ['ProjectStakeHolders']}); 
    return query;
}

export const getStakeHoldersOdataQuery = (projectId: number) => {   
    let query = undefined; 
    let projectStakeHolders = {ProjectStakeHolders: {expand: ['StakeHolder']}};
    query = BuildQuery({
        expand: [((projectStakeHolders) ? [projectStakeHolders] : [])],
        select: ['ProjectStakeHolders'], 
        filter: [ { projectid: { eq: projectId } }]
    }); 
    return query;
}

export const getStakeHolderIdOdataQuery = (projectId: number, company: string) => {   
    let query = undefined; 
    query = BuildQuery({
        expand: [{ProjectStakeHolders: {filter: {projectId: {eq: projectId}}}}],
        select: ['StakeHolderId', 'CompanyName', 'ProjectStakeHolders'], 
        filter: [ { companyName: { eq: company } }]
    }); 
    return query;
}


export const getEntitiesOdataQuery = (projectId: number) => {   
    let query = undefined; 
    query = BuildQuery({expand: ['Entities'], select: ['Entities'], filter: {projectId: {eq: projectId}}});
    return query;
}

export const getEngPhasesOdataQuery = () => {   
    let query = undefined; 
    query = BuildQuery({select: ['EngineeringPhaseId', 'EngineeringPhaseName', 'EngineeringPhaseCode']});
    return query;
}

export const getDisciplinesOdataQuery = () => {   
    let query = undefined; 
    query = BuildQuery({expand: ['DocTypes']});
    return query;
}

export const getProjectDataOdataQuery = (projectId: number) => {   
    let query = undefined; 
    query = BuildQuery({
        filter: {projectId: {eq: projectId}}, 
        select: ['Plants', 'Entities', 'ProjectStakeHolders', 'Reports'], 
        expand: ['Entities', 'Reports', {Plants: {expand: {PlantUnits: {expand: {PlantSections: {expand: ['Equipment']}}}}}}, {ProjectStakeHolders: {expand: ['StakeHolder']}}]
    });
    return query;
}

export const getPbsOdataQuery = (projectId: number) => {   
    let query = undefined; 
    query = BuildQuery({filter: {projectId: {eq: projectId}}, select: ['Plants'], expand: {Plants: {expand: {PlantUnits: {expand: {PlantSections: {expand: ['Equipment']}}}}}}});
    return query;
}

export const getProjectsQuery = (isConfigurationPage?: boolean) => {   
    let query = undefined; 
    query = BuildQuery({expand: ['ProjectConfigurations', 'EeoConfigurations'], ...((!isConfigurationPage) ? {filter: {isEnabled: {eq: true}}} : {})});
    return query;
}

export const getProjectConfigurationOdataQuery = (projectId: string) => {   
    let query = undefined; 
    query = BuildQuery({filter: {projectId: {eq: projectId}}});
    return query;
}

export const getInternalUsersOdataQuery = (projectId: string, filter?: string) => {   
    let query = undefined; 
    query = BuildQuery({filter: {projectId: {eq: projectId}}});
    return query;
}

export const getFoldersOdataQuery = (projectId: number) => {   
    let query = undefined; 
    query = BuildQuery({filter: {projectId: {eq: projectId}}});
    return query;
}

export const getProjectReportsOdataQuery = (projectId: number) => {   
    let query = undefined; 
    query = BuildQuery({select: ['Reports'], expand: ['Reports'], filter: {projectId: {eq: projectId}}});
    return query;
}

export const getStakeHolderIdsByCompaniesQuery = (companies: string[]) => {   
    let query = undefined; 
    let select = ['StakeHolderId', 'CompanyName'];
    let filter = [
        { companyName: { in: [...companies] } },
    ];
    query = BuildQuery({select, filter});
    return query;
}

export const getUserRulesOdataQuery = (projectId: number, email: string) => {   
    let query = undefined; 
    let projectString = `'project':'${projectId}'`;
    const filter = {
        userId: {eq: email},
        EventTypeRule: { ruleJson: { contains: projectString}, EventType: {EventApplication: {application: {eq: 'EXOD2'}}}},
    };
    const expand = {
        EventTypeRule: {
            expand: {
                EventType: {
                    expand: {
                        EventApplication: {
                            filter: {application: {eq: 'EXOD2'}}
                        }
                    }
                }
            }, 
            filter: {
                ruleJson: {contains: projectString},
            }
        }
    };
    query = BuildQuery({expand, filter});
    return query;
}

export const getUsersRulesOdataQuery = (projectId: number, emails: string[]) => {   
    let query = undefined; 
    let projectString = `'project':'${projectId}'`;
    const filter = {
        //userId: {in: [...emails]},
        EventTypeRule: { ruleJson: { contains: projectString}, EventType: {EventApplication: {application: {eq: 'EXOD2'}}}},
    };
    const expand = {
        EventTypeRule: {
            expand: {
                EventType: {
                    expand: {
                        EventApplication: {
                            filter: {application: {eq: 'EXOD2'}}
                        }
                    }
                }
            }, 
            // filter: {
            //     ruleJson: {contains: projectString},
            // }
        }
    };
    query = BuildQuery({expand, filter});
    return query;
}