import {createAction, ActionsUnion} from '@pwbapps/reduxcore';
import { Discipline } from '../../models/discipline';
import { DocType } from '../../models/docType';
import { Type } from '../../models/type';
import { Status, StatusEnum } from '../../models/status';
import { EngPhase } from '../../models/engPhase';
import { Entity } from '../../models/entity';
import { Pbs } from '../../models/pbs';
import { Purpose } from '../../models/purpose';
import { FiltersType } from '../../utils/costants';
import { Filters } from '../../models/filters';
import { Folder } from '../../models/folder';
import { StakeHolder } from '../../models/stakeHolder';

export const SET_VALUE = `[FILTERS] SET VALUE`;
export const SET_FILTER_VALUE = `[FILTERS] SET FILTER VALUE`;
export const RESET_FILTERS = `[FILTERS] RESET FILTERS`;
export const SHOW_OR_HIDE_CALLOUT = `[FILTERS] SHOW OR HIDE CALLOUT`;
export const SET_DEFAULT_FILTERS = `[FILTERS] SET DEFAULT FILTERS`;

export const FiltersCommandActions = {
    setLargerPanel: ({ value }: { value: boolean }) => createAction(SET_VALUE, { name: 'largerPanel', value }),
    showOrHideCallout: ({show, filter, ref }: {show: boolean, filter?: FiltersType, ref?: HTMLElement }) => createAction(SHOW_OR_HIDE_CALLOUT, {show, filter, ref }),
    setShowCallout: ({ value }: { value: boolean }) => createAction(SET_VALUE, { name: 'showCallout', value }),
    setElementRef: ({ value }: { value?: HTMLElement }) => createAction(SET_VALUE, { name: 'elementRef', value }),
    setSelectedFilter: ({ value }: { value?: FiltersType }) => createAction(SET_VALUE, { name: 'selectedFilter', value }),
    setFilters: ({ value }: { value: Filters }) => createAction(SET_VALUE, { name: 'filters', value }),
    setDefaultFilters: ({reset}: {reset: boolean}) => createAction(SET_DEFAULT_FILTERS, {reset}),
    resetFilters: () => createAction(RESET_FILTERS),

    setDocumentNumber: ({ value }: { value: string[] }) => createAction(SET_FILTER_VALUE, {name: 'documentNumber', value }),
    setAgileNumber: ({ value }: { value?: string }) => createAction(SET_FILTER_VALUE, {name: 'agileNumber', value }),
    setStatus: ({ value }: { value: Status[] }) => createAction(SET_FILTER_VALUE, {name: 'status', value }),
    setSheet: ({ value }: { value?: number }) => createAction(SET_FILTER_VALUE, {name: 'sheet', value }),
    setRevision: ({ value }: { value?: string }) => createAction(SET_FILTER_VALUE, {name: 'revision', value }),
    setClientNumber: ({ value }: { value?: string }) => createAction(SET_FILTER_VALUE, {name: 'clientNumber', value }),
    setTdNumber: ({ value }: { value?: string }) => createAction(SET_FILTER_VALUE, {name: 'tdNumber', value }),
    setTransmittalNumber: ({ value }: { value?: number }) => createAction(SET_FILTER_VALUE, {name: 'publication', value }),
    setType: ({ value }: { value?: Type }) => createAction(SET_FILTER_VALUE, {name: 'type', value }),
    setDiscipline: ({ value }: { value?: Discipline }) => createAction(SET_FILTER_VALUE, {name: 'discipline', value }),
    setDocType: ({ value }: { value?: DocType }) => createAction(SET_FILTER_VALUE, {name: 'docType', value }),
    setPurpose: ({ value }: { value?: Purpose }) => createAction(SET_FILTER_VALUE, {name: 'purpose', value }),
    setEntity: ({ value }: { value?: Entity }) => createAction(SET_FILTER_VALUE, {name: 'entity', value }),
    setEngPhase: ({ value }: { value?: EngPhase }) => createAction(SET_FILTER_VALUE, {name: 'engPhase', value }),
    setFrom: ({ value }: { value?: Date }) => createAction(SET_FILTER_VALUE, {name: 'from', value }),
    setTo: ({ value }: { value?: Date }) => createAction(SET_FILTER_VALUE, {name: 'to', value }),
    setPwFilename: ({ value }: { value?: string }) => createAction(SET_FILTER_VALUE, {name: 'pwFilename', value }),
    setCommented: ({ value }: { value: boolean }) => createAction(SET_FILTER_VALUE, {name: 'commented', value }),
    setPbs: ({ value }: { value?: Pbs }) => createAction(SET_FILTER_VALUE, {name: 'pbs', value }),
    setFolder: ({ value }: { value?: Folder }) => createAction(SET_FILTER_VALUE, {name: 'folder', value }),
    setIsForInternalUse: ({ value }: { value: boolean }) => createAction(SET_FILTER_VALUE, {name: 'isForInternalUse', value }),
    setShowAllVersions: ({ value }: { value: boolean }) => createAction(SET_FILTER_VALUE, {name: 'showAllVersions', value }),
    setDefaultPurposesList: ({ value }: { value?: Purpose[] }) => createAction(SET_FILTER_VALUE, {name: 'defaultPurposesList', value }),
    setIsEngineeringOrder: ({ value }: { value: boolean }) => createAction(SET_FILTER_VALUE, {name: 'isEngineeringOrder', value }),
    setStatusCategory: ({ value }: { value?: 'Customer' | 'Supplier' }) => createAction(SET_FILTER_VALUE, {name: 'statusCategory', value }),
    setDefaultStatusList: ({ value }: { value?: Status[] }) => createAction(SET_FILTER_VALUE, {name: 'defaultStatusList', value }),
    setPublicationNumber: ({ value }: { value?: string }) => createAction(SET_FILTER_VALUE, {name: 'publication', value }),
    setPartner: ({ value }: { value?: StakeHolder }) => createAction(SET_FILTER_VALUE, {name: 'partner', value }),
    setCustomer: ({ value }: { value?: StakeHolder }) => createAction(SET_FILTER_VALUE, {name: 'customer', value }),
    setTitleLine4Pl: ({ value }: { value?: string }) => createAction(SET_FILTER_VALUE, {name: 'titleLine4Pl', value }),
    setTitleLine5Pl: ({ value }: { value?: string }) => createAction(SET_FILTER_VALUE, {name: 'titleLine5Pl', value }),
    setTitleLine6Pl: ({ value }: { value?: string }) => createAction(SET_FILTER_VALUE, {name: 'titleLine6Pl', value }),
    setPartnerNumber: ({ value }: { value?: string }) => createAction(SET_FILTER_VALUE, {name: 'partnerNumber', value }),
    setGeneric: ({ value }: { value?: string }) => createAction(SET_FILTER_VALUE, {name: '_generic', value }),
    setIgnorePurposes: ({ value }: { value: boolean }) => createAction(SET_FILTER_VALUE, {name: 'ignorePurposes', value }),
    setApprovalDate: ({ value }: { value?: Date }) => createAction(SET_FILTER_VALUE, {name: 'approvalDate', value }),
    setSupplier: ({ value }: { value?: StakeHolder }) => createAction(SET_FILTER_VALUE, {name: 'supplier', value }),
    setSupplierDocumentNumber: ({ value }: { value?: string }) => createAction(SET_FILTER_VALUE, {name: 'supplierDocumentNumber', value })
}

export const FiltersEventActions = {
}

export const FiltersDocumentActions = {
    setFilterValue: ({ name, value }: { name: string, value?: any }) => createAction(SET_FILTER_VALUE, { name, value }),
    setValue: ({ name, value }: { name: string, value?: any }) => createAction(SET_VALUE, { name, value }),
}


export const CommandActions = {...FiltersCommandActions};
export const EventActions = {...FiltersEventActions};
export const DocumentActions = {...FiltersDocumentActions};
export const Actions = {...CommandActions, ...EventActions, ...DocumentActions};

// group actions
export type CommandActions = ActionsUnion<typeof CommandActions>
export type EventActions = ActionsUnion<typeof EventActions>
export type DocumentActions = ActionsUnion<typeof DocumentActions>
export type Actions = ActionsUnion<typeof Actions>
