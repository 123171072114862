import {createAction, ActionsUnion} from '@pwbapps/reduxcore';
import { MessageBarType } from '@fluentui/react';

export const SET_NOTIFICATION_STATUS = `[NOTIFICATION] SET STATUS`;

export const SetNotificationStatusAction = {
    setNotificationStatus: ({name, show, type, message, permanent, onRetry, linkText, url}: {name: string, show: boolean, type?: MessageBarType, message: string, permanent?: boolean, onRetry?: any, linkText?: string, url?: string}) => createAction(SET_NOTIFICATION_STATUS, {name, show, type, message, permanent, onRetry, linkText, url}),
}


// single actions
export type SetNotificationStatusAction = ActionsUnion<typeof SetNotificationStatusAction>

export const CommandActions = {};
export const DocumentActions = {...SetNotificationStatusAction};
export const Actions = {...CommandActions, ...DocumentActions};

// group actions
export type CommandActions = ActionsUnion<typeof CommandActions>
export type DocumentActions = ActionsUnion<typeof DocumentActions>
export type Actions = ActionsUnion<typeof Actions>