import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";
import { DirectionalHint, FontIcon, IRenderFunction, ITextFieldProps, TagItem, TooltipHost, Text, FontSizes, DefaultButton, Spinner, SpinnerSize, ComboBox, IComboBoxOption } from '@fluentui/react';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { getFolders } from '../../../redux/reducers/sharedData';
import { HierarchyExplorer } from '@pwbapps/hierarchyexplorer';
import { Folder } from '../../../models/folder';
import { getLoader } from '../../../redux/selectors/loader';
import { getSelectedDocuments } from '../../../redux/selectors/documents';
import Scrollbars from 'react-custom-scrollbars-2';
import { Document } from '../../../models/document';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface FolderProps extends RouteComponentProps, WithTranslation {
    folderForm?: {
        folder?: Folder,
        isAdding?: boolean,
        isRemoving?: boolean,
    },
    folders: Folder[] ,
    loadingContent: boolean,
    selectedDocuments: Document[],
    setContextItemProperty: typeof ModalActions.DocumentActions.setContextItemProperty,
    setContextItem: typeof ModalActions.DocumentActions.setContextItem,
    setOptional: typeof ModalActions.DocumentActions.setOptional,
    openGenericModal: typeof ModalActions.CommandActions.openModal,
}
class Folders extends React.Component<FolderProps> {
    constructor(props: FolderProps) {
        super(props);   
    }

    setFolderValueFunction = (item?: any, checked?: any) =>{
        const { setContextItemProperty } = this.props;
        if(item)
            setContextItemProperty({id: 'folder', name: 'folder', value: (checked) ? item : undefined});
        else 
            setContextItemProperty({id: 'folder', name: 'folder', value: undefined});
    }

    isSelectableFolder = (item: 'root' | 'subfolder'): boolean => {
        return (item !== 'root') ? true : false;
    }

    render(){
        const { folderForm, folders, loadingContent, selectedDocuments, t, setContextItemProperty, openGenericModal, setOptional } = this.props;
        const folder = (folderForm) ? folderForm.folder : undefined;
        const folderIds = (folderForm && folderForm.isRemoving && selectedDocuments && selectedDocuments.length > 0) ? selectedDocuments[0].folderIds : [];
        const removeFolders = (folderIds.length > 0) ? _.filter(folders, f => _.some(folderIds, id => id === f.id)) : [];
        const folder_options: IComboBoxOption[] = (removeFolders.length > 0) ?  _.map(removeFolders, (c) => {return {key: c.id as string, text: c.folderName as string}}) : [];
        return (
            <>  
                {!loadingContent && folderForm && 
                    <>
                        { (folderForm.isAdding || folderForm.isRemoving) &&
                            <>
                                {folderForm.isAdding && <Text variant='mediumPlus' nowrap>{'You are going to add to a folder the following ' + ((selectedDocuments.length === 1) ? 'document:' : 'documents:')}</Text>}
                                {folderForm.isRemoving && <Text variant='mediumPlus' nowrap>{'You are going to remove from a folder the following ' + ((selectedDocuments.length === 1) ? 'document:' : 'documents:')}</Text>}
                                <Scrollbars style={{ marginTop: 10, marginBottom: 20}} autoHeight autoHeightMax={280}>
                                    {_.map(selectedDocuments, (sd, index) => {
                                        return <div key={index}><Text nowrap>{sd.documentNumber}</Text></div>
                                    })}
                                </Scrollbars>
                            </>
                        }
                        <div style={{height: 40, display: 'flex', gap: 15}}>
                            {!!folder && folder.id && !folderForm.isRemoving && 
                                <>
                                    <FontIcon iconName={"Cancel"} style={{cursor: "pointer", color: 'red', fontSize: FontSizes.large}}  onClick={() => { setContextItemProperty({id: 'folder', name: 'folder', value: undefined}); }} /> 
                                    <div style={{display: 'flex', gap: 5}}>
                                        <Text>{folder.description}</Text>
                                    </div>
                                </>
                            }
                        </div>
                        { (folderForm.isAdding || (!folderForm.isAdding && !folderForm.isRemoving)) &&
                            <HierarchyExplorer 
                                items={folders} 
                                selected={(folder) ? [folder.id] : []}
                                selectedProperty={"id"} 
                                onSelect={(item, checked) => this.setFolderValueFunction(item, checked)}     
                                isSelectable={this.isSelectableFolder} 
                            />
                        }
                        { folderForm.isRemoving &&
                            <ComboBox
                                label='Folder'
                                disabled={folder_options.length === 0}
                                placeholder={t("Select a folder") + '...'}
                                selectedKey={folderForm && folderForm.folder ? folderForm.folder.id : undefined}
                                options={folder_options}
                                onChange={(e, value) => { setContextItemProperty({id: 'folder', name: 'folder', value: (value) ? _.find(folders, f => f.id === value.key) as Folder : undefined}) }}                          
                                useComboBoxAsMenuWidth={true}         
                                calloutProps={{calloutMaxHeight: 320}}
                            /> 
                        }
                        {!folderForm.isAdding && !folderForm.isRemoving &&
                            <div style={{marginTop: 10, display: 'flex', gap: 15}}>
                                <DefaultButton
                                    disabled={!folderForm.folder}
                                    primary
                                    text={t('New subfolder')}
                                    onClick={ () => {
                                        if(folderForm.folder)
                                            openGenericModal({id: 'newEditFolder', contextItem: new Folder({description: folderForm.folder.description, projectId: folderForm.folder.projectId, parentId: folderForm.folder.id})})
                                    } }
                                />
                                <DefaultButton
                                    disabled={!folderForm.folder || !folderForm.folder.parentId}
                                    primary
                                    text={t('Edit subfolder')}
                                    onClick={ () => {openGenericModal({id: 'newEditFolder', contextItem: new Folder(undefined, folderForm.folder)})} }
                                />
                                <DefaultButton
                                    disabled={!folderForm.folder || !folderForm.folder.parentId || _.some(folders, f => folderForm.folder && f.parentId === folderForm.folder.id) || folderForm.folder.count > 0}
                                    primary
                                    text={t('Remove subfolder')}
                                    onClick={ () => {openGenericModal({id: 'removeFolder', contextItem: new Folder(undefined, folderForm.folder)})} }
                                />
                            </div> 
                        }
                    </>
                }  
                { loadingContent && <Spinner size={SpinnerSize.large}></Spinner> }   
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    folderForm: (state) => (getGenericModal('folder')(state)) ? (getGenericModal('folder')(state) as any).contextItem : undefined,
    folders: getFolders,
    selectedDocuments: getSelectedDocuments,
    loadingContent: (state) => getLoader('dashboard')(state).loadingContent,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItem: ModalActions.DocumentActions.setContextItem,
        setContextItemProperty: ModalActions.DocumentActions.setContextItemProperty,
        openGenericModal: ModalActions.CommandActions.openModal,
        setOptional: ModalActions.DocumentActions.setOptional,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Folders)));