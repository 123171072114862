import _ from "lodash";
import { ExternalRoleActionsType, HistoryPathType, InternalRoleActionsType } from "./costants";

export const getCanRead = (historyName: HistoryPathType, userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[]): boolean => {
    if(historyName === 'dashboard' && _.find(userProjectActions, a => a === 'ReadDashboard'))    
        return true;
    else if(historyName === 'pending' && _.find(userProjectActions, a => a === 'ReadPending')) 
        return true;
    else if(historyName === 'publish' && _.find(userProjectActions, a => a === 'ReadPublish')) 
        return true;
    else if(historyName === 'reservedNumbers' && _.find(userProjectActions, a => a === 'ReadReservedNumbers')) 
        return true;
    else if(historyName === 'publishMaterial' && _.find(userProjectActions, a => a === 'ReadPublishMaterial')) 
        return true;
    else if(historyName === 'supplierMaterial' && _.find(userProjectActions, a => a === 'ReadSupplierMaterial')) 
        return true;
    else if(historyName === 'qualityCheck' && _.find(userProjectActions, a => a === 'ReadQualityCheck')) 
        return true;
    else if(historyName === 'createRevision' && _.find(userProjectActions, a => a === 'ReadCreateRevision')) 
        return true;
    else 
        return false;
}
export const getCanEdit = (historyName: HistoryPathType, userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[]): boolean => {
    if(historyName === 'dashboard' && _.find(userProjectActions, a => a === 'EditDashboard'))    
        return true;
    else if(historyName === 'publish' && _.find(userProjectActions, a => a === 'EditPublish')) 
        return true;
    else if(historyName === 'qualityCheck' && _.find(userProjectActions, a => a === 'EditQualityCheck')) 
        return true;
    else 
        return false;
}
export const getCanChangeStatus = (historyName: HistoryPathType, userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[]): boolean => {
    if(historyName === 'dashboard' && _.find(userProjectActions, a => a === 'ChangeStatusDashboard'))    
        return true;
    else  if(historyName === 'pending' && _.find(userProjectActions, a => a === 'ApprovePending' || a === 'DeclinePending'))    
        return true;
    else if(historyName === 'publish' && _.find(userProjectActions, a => a === 'PublishPublish')) 
        return true;
    else if(historyName === 'supplierMaterial' && _.find(userProjectActions, a => a === 'PublishSupplierMaterial' || a === 'DeclineSupplierMaterial')) 
        return true;
    else if(historyName === 'publishMaterial' && _.find(userProjectActions, a => a === 'PublishPublishMaterial' || a === 'DeclinePublishMaterial') ) 
        return true;
    else if(historyName === 'qualityCheck' && _.find(userProjectActions, a => a === 'ReadyForPublishQualityCheck') ) 
        return true;
    else 
        return false;
}
export const getCanUploadFiles = (historyName: HistoryPathType, userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[]): boolean => {
    if(historyName === 'dashboard' && _.find(userProjectActions, a => a === 'UploadAttachmentDashboard' || a === 'UploadCommentFileDashboard'))    
        return true;
    else if(historyName === 'pending' && _.find(userProjectActions, a => a === 'UploadAttachmentPending' || a === 'UploadCommentFilePending')) 
        return true;
    else if(historyName === 'publish' && _.find(userProjectActions, a => a === 'UploadAttachmentPublish' || a === 'UploadCommentFilePublish')) 
        return true;
    else if(historyName === 'qualityCheck' && _.find(userProjectActions, a => a === 'UploadAttachmentQualityCheck' || a === 'UploadCommentFileQualityCheck')) 
        return true;
    else 
        return false;
}
export const getCanDeleteFiles = (historyName: HistoryPathType, userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[]): boolean => {
    if(historyName === 'dashboard' && _.find(userProjectActions, a => a === 'UploadAttachmentDashboard' || a === 'UploadCommentFileDashboard'))    
        return true;
    else if(historyName === 'pending' && _.find(userProjectActions, a => a === 'UploadAttachmentPending' || a === 'UploadCommentFilePending')) 
        return true;
    else if(historyName === 'publish' && _.find(userProjectActions, a => a === 'UploadAttachmentPublish' || a === 'UploadCommentFilePublish')) 
        return true;
    else if(historyName === 'qualityCheck' && _.find(userProjectActions, a => a === 'UploadAttachmentQualityCheck' || a === 'UploadCommentFileQualityCheck')) 
        return true;
    else 
        return false;
}
export const getCanUploadAttachments = (historyName: HistoryPathType, userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[]): boolean => {
    if(historyName === 'dashboard' && _.find(userProjectActions, a => a === 'UploadAttachmentDashboard'))    
        return true;
    else if(historyName === 'pending' && _.find(userProjectActions, a => a === 'UploadAttachmentPending')) 
        return true;
    else if(historyName === 'publish' && _.find(userProjectActions, a => a === 'UploadAttachmentPublish')) 
        return true;
    else if(historyName === 'qualityCheck' && _.find(userProjectActions, a => a === 'UploadAttachmentQualityCheck')) 
        return true;
    else 
        return false;
}
export const getCanUploadFileComments = (historyName: HistoryPathType, userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[]): boolean => {
    if(historyName === 'dashboard' && _.find(userProjectActions, a => a === 'UploadCommentFileDashboard'))    
        return true;
    else if(historyName === 'pending' && _.find(userProjectActions, a => a === 'UploadCommentFilePending')) 
        return true;
    else if(historyName === 'publish' && _.find(userProjectActions, a => a === 'UploadCommentFilePublish')) 
        return true;
    else if(historyName === 'qualityCheck' && _.find(userProjectActions, a => a === 'UploadCommentFileQualityCheck')) 
        return true;
    else 
        return false;
}
export const getCanInsertTextComments = (canBeCommented: boolean, historyName: HistoryPathType, userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[]): boolean => {
    if(historyName === 'dashboard' && _.find(userProjectActions, a => a === 'InsertTextCommentDashboard'))    
        return canBeCommented;
    else if(historyName === 'pending' && _.find(userProjectActions, a => a === 'InsertTextCommentPending')) 
        return canBeCommented;
    else if(historyName === 'publish' && _.find(userProjectActions, a => a === 'InsertTextCommentPublish')) 
        return canBeCommented;
    else if(historyName === 'reservedNumbers' && _.find(userProjectActions, a => a === 'InsertTextCommentReservedNumbers')) 
        return canBeCommented;
    else if(historyName === 'supplierMaterial' && _.find(userProjectActions, a => a === 'InsertTextCommentSupplierMaterial')) 
        return canBeCommented;
    else if(historyName === 'qualityCheck' && _.find(userProjectActions, a => a === 'InsertTextCommentQualityCheck')) 
        return canBeCommented;
    else 
        return false;
}