import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react';
import { getClassNames } from './index.classNames';
import { DocumentActions as UiDocumentActions} from '../../../redux/actions/ui';
import { CommandActions as DocumentCommandActions} from '../../../redux/actions/document';
import { CommandActions as DocumentsCommandActions} from '../../../redux/actions/documents';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import _ from 'lodash'
import { getFilterPanelOpened } from '../../../redux/reducers/ui';
import { getTotal } from '../../../redux/reducers/documents';
import { getStatusValues } from '../../../redux/reducers/sharedData';
import { Status } from '../../../models/status';
import { InternalRoleActionsType, ExternalRoleActionsType, isExternalApp } from '../../../utils/costants';
import { getSelectedProject } from '../../../redux/selectors/project';
import { Project } from '../../../models/project';
import { Document, getChangeStatusEmptyFileForm, getEmptyCommentFileForm } from '../../../models/document';
import { getContextualDocuments } from '../../../redux/selectors/documents';
import { getSelectedProjectCustomerStakeholders } from '../../../redux/selectors/administration';
import { StakeHolder } from '../../../models/stakeHolder';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface ContactsProps extends WithTranslation  {
    filterPanelOpened: boolean, 
    selectedDocuments: Document[],
    total: number,
    loading: boolean,
    loadingContent: boolean,
    loadingScroll: boolean,
    statusValues: Status[],
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    selectedProject?: Project,
    customerStakeholders: StakeHolder[],
    setFilterPanelOpened: typeof UiDocumentActions.setFilterPanelOpened,
    setOpenModal: typeof DocumentCommandActions.setOpenModal,
    openGenericModal: typeof ModalActions.CommandActions.openModal,
    fetchDocuments: typeof DocumentsCommandActions.fetchDocuments,
}

class DocumentsCommandBar extends React.Component<ContactsProps> {
    constructor(props: ContactsProps) {
        super(props);
    }   


    getButtons = () => {
        const { selectedDocuments, total, selectedProject, openGenericModal, loadingContent, loading, loadingScroll, statusValues, userProjectActions, customerStakeholders, t, fetchDocuments } = this.props;
        let buttons: ICommandBarItemProps[] = [];
        let selectedDocumentIds = _.map(selectedDocuments, d => d.id as number);
        let supplierPublishedStatus = _.find(statusValues, a => a.name === 'supplier-published') as Status;
        let reservedStatus = _.find(statusValues, a => a.name === 'reserved') as Status;
        const commentsExist = (selectedDocuments && selectedDocuments.length > 0) ? _.some(selectedDocuments, d => d.comments && d.comments.length > 0) : true;
        const singleCustomer = (customerStakeholders.length === 1) ? customerStakeholders[0] : undefined;
        buttons = [        
            ...((_.find(userProjectActions, a => a === 'PublishPublishMaterial')) ? [{
                key: 'supplierPublish',
                name: t('Publish'),
                disabled: (selectedDocumentIds.length === 0 || (selectedDocuments && _.some(selectedDocuments, sd => !sd.editable)) || loadingContent || loading || loadingScroll),
                onClick: () => {openGenericModal({id: 'changeStatus', contextItem: {newStatus: {...supplierPublishedStatus}, fileForm: getChangeStatusEmptyFileForm(singleCustomer)}})},
                iconProps: {
                    iconName: (supplierPublishedStatus) ? supplierPublishedStatus.icon : undefined,
                    style: {color: (supplierPublishedStatus) ? supplierPublishedStatus.color : undefined} 
                }
            }] : []),  
            ...((isExternalApp && _.find(userProjectActions, a => a === 'DeclinePublishMaterial')) ? [{
                key: 'supplierDecline',
                name: t('Decline'),
                disabled: (selectedDocumentIds.length === 0 || (selectedDocuments && _.some(selectedDocuments, sd => !sd.editable)) || loadingContent || loading || loadingScroll),
                onClick: () => {openGenericModal({id: 'changeStatus', contextItem: {newStatus: {...reservedStatus}, fileForm: getEmptyCommentFileForm(), files: []}, optional: true})},
                iconProps: {
                    iconName: (reservedStatus) ? reservedStatus.icon : undefined,
                    style: {color: (reservedStatus) ? reservedStatus.color : undefined} 
                }
            }] : []),      
            ...(_.find(userProjectActions, a => a === 'ReadPublishMaterial') ? [{
                key: 'downloadComments',
                name: t('Download comments'),
                disabled: (total === 0 || !commentsExist || loadingContent || loading || loadingScroll),
                onClick: () => { openGenericModal({id: 'comments', optional: 'Documents Comments'}) },
                iconProps: {
                    iconName: 'Comment',
                },
            }] : []),   
            {
                key: 'refresh',
                name: t('Refresh'),
                disabled: (loadingContent || loading || loadingScroll),
                onClick: () => { fetchDocuments({}) },
                iconProps: {
                    iconName: 'Refresh',
                },
            }                           
        ]
        return buttons;
    };

    getFarButtons = () => {
      const {setFilterPanelOpened, filterPanelOpened, t} = this.props;
      return [
     
        {
          key: 'openPanel',
          name: t('Filters'),
          iconProps: {
            iconName: 'Filter'
          },
          ariaLabel: 'Filters',    
          onClick: () => {setFilterPanelOpened({open: !filterPanelOpened})}      
        },          
      ]; 
  };

    render() {
        const styles = getClassNames();
        return (
            <>
                <CommandBar
                    styles={{root: styles.root, secondarySet: {borderTop: 1}}}
                    items={this.getButtons()}
                    farItems={this.getFarButtons()}
                />
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    filterPanelOpened: getFilterPanelOpened,
    selectedDocuments: getContextualDocuments,
    total: getTotal,
    statusValues: getStatusValues,
    selectedProject: getSelectedProject,
    customerStakeholders: getSelectedProjectCustomerStakeholders
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setFilterPanelOpened: UiDocumentActions.setFilterPanelOpened,
        setOpenModal: DocumentCommandActions.setOpenModal,
        openGenericModal: ModalActions.CommandActions.openModal,
        fetchDocuments: DocumentsCommandActions.fetchDocuments
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(DocumentsCommandBar));
