import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getClassNames } from '../internal.classNames';
import { ComboBox, ConstrainMode, DefaultButton, DetailsList, DetailsListLayoutMode, Dropdown, IComboBoxOption, IDetailsHeaderProps, IRenderFunction, Label, ScrollablePane, ScrollbarVisibility, SearchBox, SelectionMode, Sticky, StickyPositionType, Text, TextField } from '@fluentui/react';
import _ from 'lodash';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";
import { getDisciplines, getAllPbs, getAllDisciplines, getPermissionIdsToRemove } from '../../../../../redux/reducers/sharedData';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { Permissions, PermissionElement } from '../../../../../models/permissions';
import { Pbs } from '../../../../../models/pbs';
import { Popup as HierarchyExplorer } from '@pwbapps/hierarchyexplorer';
import { getColumns } from './columnsPermissions';
import { Discipline, ReserveNumberDisciplineDocType } from '../../../../../models/discipline';
import { getLoader } from '../../../../../redux/selectors/loader';
import { CommandActions as SharedDataCommandActions } from '../../../../../redux/actions/sharedData';

export interface PermissionsProps extends RouteComponentProps, WithTranslation {
    permissionsForm?: Permissions,
    pbs: Pbs[],
    allDisciplines: ReserveNumberDisciplineDocType[],
    disciplines: Discipline[],
    loadingExternal: boolean,
    loadingAllPbsDisciplines: boolean,
    permissionIdsToRemove: number[],
    setContextItemProperty: typeof ModalActions.DocumentActions.setContextItemProperty,
    setPermissionIdsToRemove: typeof SharedDataCommandActions.setPermissionIdsToRemove,
}

class PermissionsClass extends React.Component<PermissionsProps> {
    constructor(props: PermissionsProps) {
        super(props);  
    }

    alreadyInPermissions = (pbs: string) =>{
        const { permissionsForm } = this.props;
        const alreadyIn = (permissionsForm && pbs) ? _.some(permissionsForm.permissions, (up: PermissionElement) => up.pbs === pbs) : undefined;
        return (alreadyIn) ? true : false;
    }

    setNewRow= () => {
        const { permissionsForm, setContextItemProperty } = this.props;
        if(permissionsForm){
            let maxIndex = _.max(_.map(permissionsForm.permissions, (p: PermissionElement) => p._index));
            maxIndex = (maxIndex === undefined) ? 0 : maxIndex + 1;
            setContextItemProperty({id: 'permissions', name: 'permissions', value: [...permissionsForm.permissions, new PermissionElement({_index: maxIndex,  id: undefined, pbs: undefined, discipline: undefined, disciplineCode: undefined, disciplineName: undefined, canWrite: true})]});
        }
    }

    onRenderDetailsHeader = (props?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element => {
        return (
          <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
            {(defaultRender && props) ? defaultRender({
              ...props,        
            }) : null}
          </Sticky>
        );
    }

    render(){
        const { permissionsForm, pbs, allDisciplines, disciplines, loadingExternal, loadingAllPbsDisciplines, permissionIdsToRemove, t, setContextItemProperty, setPermissionIdsToRemove } = this.props;
        const columns = getColumns(allDisciplines, disciplines, pbs, loadingAllPbsDisciplines, permissionIdsToRemove, setContextItemProperty, setPermissionIdsToRemove, permissionsForm);
        const styles = getClassNames();

        return (
            <>
                {permissionsForm &&    
                    <>  
                        <Text>User: <b>{permissionsForm.email}</b></Text>
                        <div style={{display: 'flex', gap: 15, alignItems: 'end', marginTop: 20}}>
                            <DefaultButton
                                primary
                                text="New row" 
                                disabled={loadingExternal || loadingAllPbsDisciplines}
                                onClick={() => {
                                    this.setNewRow()
                                }}
                                autoFocus
                            />
                        </div> 
                        {/* {selectedPbs && this.alreadyInPermissions(selectedPbs) && <span data-automation-id="error-message" style={{fontSize: 12, fontWeight: 400, fontFamily: "Segoe UI", color: '#A4262C', paddingTop: 5}} >PBS already selected</span>} */}
                        <div className={styles.wrapper}>   
                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                                <DetailsList                              
                                    items={permissionsForm.permissions}
                                    columns={columns}
                                    setKey="pbs"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    constrainMode={ConstrainMode.unconstrained}
                                    onRenderDetailsHeader={this.onRenderDetailsHeader}
                                    selectionPreservedOnEmptyClick={true}
                                    selectionMode={SelectionMode.none}                             
                                />
                            </ScrollablePane> 
                        </div>                     
                    </>             
                }
                
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    permissionsForm: (state) => (getGenericModal('permissions')(state)) ? (getGenericModal('permissions')(state) as any).contextItem : undefined,
    pbs: getAllPbs,
    allDisciplines: getAllDisciplines,
    disciplines: getDisciplines,
    loadingExternal: (state) => getLoader('administration')(state).loadingExternal,
    loadingAllPbsDisciplines: (state) => getLoader('global')(state).loadingAllPbsDisciplines,
    permissionIdsToRemove: getPermissionIdsToRemove
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItemProperty: ModalActions.DocumentActions.setContextItemProperty,
        setPermissionIdsToRemove: SharedDataCommandActions.setPermissionIdsToRemove,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(PermissionsClass)));