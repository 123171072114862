import { mergeStyleSets } from '@fluentui/react';

export interface IComponentClassNames {
    container: string,
    root: string,
    icon: string,
    iconHidden: string,
    userContainer: string,
    user: string,

    icon2: string,
    rootHovered: string,

    questionMark: string,
    questionMark2: string,
    root2: string,
    rootPersona: string,
    rootPersona2: string,
    dropDownItem: string
}
  
export const getClassNames = (theme: any): IComponentClassNames => {
    return mergeStyleSets({
        container: {
            display: 'flex',
            backgroundColor: theme.palette.themePrimary + "!important",
            borderColor: theme.palette.themePrimary + "!important",
            selectors: {
                '*, *:hover': {
                    backgroundColor: theme.palette.themePrimary + "!important",
                    borderColor: theme.palette.themePrimary + "!important",
                    color: 'white' + "!important",                         
                }
            }
        },
        root: {
            minWidth: 120,
            backgroundColor: theme.palette.themePrimary + "!important",
            borderColor: theme.palette.themePrimary + "!important",
            color: 'white',
            selectors:{
                '*, *:hover': {
                    backgroundColor: theme.palette.themePrimary + "!important",
                    borderColor: theme.palette.themePrimary + "!important",     
                    color: 'white' + "!important",                         
                },
            }
        },
        icon: {
            backgroundColor: theme.palette.themePrimary + "!important",
            borderColor: theme.palette.themePrimary + "!important",
            color: 'white',
            selectors:{
                '*, *:hover': {
                    backgroundColor: theme.palette.themePrimary + "!important",
                    borderColor: theme.palette.themePrimary + "!important",     
                    color: 'white' + "!important",                         
                }
            }
        },
        iconHidden: {
            display: 'none'
        },
        userContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        user: {
            marginRight: 15,
            marginLeft: 10,
            cursor: 'pointer'
        },
        icon2: {
            color: 'white',
        },
        questionMark: {
            color: 'white',
            fontSize: 22,
            marginBottom: 2
        },
        questionMark2: {
            color: '#02184B',
            fontSize: 22,
            marginBottom: 2
        },
        rootHovered: {
            backgroundColor: '#02184B' 
        },
        root2: {
            height: 48,
            width: 48,
            marginLeft: 20
        },
        rootPersona: {
            height: 48,
            width: 48,
            display: 'flex',
            justifyContent: 'center'
        },
        rootPersona2: {
            height: 48,
            width: 48,
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center'
        },
        dropDownItem: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: 10  
        },
    });
};