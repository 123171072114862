import _ from "lodash";

export class Permissions {
    email?: string
    stakeHolderId?: number
    permissions: PermissionElement[]
    constructor(object: any){
        this.permissions = [];
        if(object){
            this.email = object.email;
            this.stakeHolderId = object.stakeHolderId;
            let i = -1;
            this.permissions = _.map(object.permissions, p => new PermissionElement(p, ++i));
        }
    }
}

export class PermissionElement{
    _index?: number
    id?: number
    pbs?: string
    discipline?: number // id
    disciplineCode?: string // code
    disciplineName?: string // name
    canWrite: boolean

    constructor(object: any, _index?: number){
        this.canWrite = false;
        if(object){
            this._index = (object._index !== undefined) ? (object._index) : _index;
            this.id = object.permissionId;
            this.pbs = (object.pbs && object.pbs !== 'ALL') ? object.pbs : undefined;
            this.discipline = object.disciplineId;
            this.disciplineCode = (object.disciplineId !== 0) ? object.disciplineCode : undefined;
            this.disciplineName = object.disciplineName;
            this.canWrite = object.canWrite;    
        }
    }
}

export class PermissionElementPostData{
    id: number
    email: string
    pbs?: string
    discipline?: string // code-name
    canWrite: boolean
    projectId: number
    stakeHolderId?: number

    constructor(object: PermissionElement, email: string, projectId: number, stakeHolderId: number){
        this.id = (object.id) ? object.id : 0;
        this.email = email;
        this.pbs = (object.pbs) ? object.pbs : 'ALL';
        this.discipline = (object.disciplineCode) ? (object.disciplineCode + '-' +  object.disciplineName) : 'ALL';
        this.canWrite = object.canWrite;
        this.projectId = projectId;      
        this.stakeHolderId = stakeHolderId; 
    }    
}