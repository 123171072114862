import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getClassNames } from '../index.classNames';
import { Label, TextField, Text, Link } from '@fluentui/react';
import _ from 'lodash';
import {CommandActions as DocumentCommandActions} from '../../../../redux/actions/document';
import { Document, OtherRevision } from '../../../../models/document';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface OtherRevisionsProps extends RouteComponentProps, WithTranslation {
    otherRevisions: OtherRevision[],
    getDocumentAndOpenModal: typeof DocumentCommandActions.getDocumentAndOpenModal, 
}

class OtherRevisions extends React.Component<OtherRevisionsProps> {
    constructor(props: OtherRevisionsProps) {
        super(props);   
    }

    render(){
        const { otherRevisions, t, getDocumentAndOpenModal } = this.props;
        const styles = getClassNames();
        return (
            <>
                <div className={styles.tabContainer}>           
                    <div style={{marginBottom: 30}}><Text variant='xLarge' nowrap>Other Revisions</Text></div>    
                    <div style={{display: 'flex', flexDirection: 'column', gap: 15, marginLeft: 15}}>
                        {_.map(otherRevisions, (r, index) => {
                            return(
                                <Link key={index + '_revlink'} onClick={() => {getDocumentAndOpenModal({documentId: r.id as number, changingRevision: true})}}>{(r.isCurrent) ? (<div>{r.documentNumber} <Text style={{fontWeight: 600}}>{' (' + t('LATEST VERSION') + ')'}</Text></div>) : r.documentNumber}</Link>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({

});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        getDocumentAndOpenModal:  DocumentCommandActions.getDocumentAndOpenModal, 
    }, dispatch);
}


export default connect(undefined, matchDispatchToProps)(withTranslation()(withRouter(OtherRevisions)));