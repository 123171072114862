import React from 'react';
import { getClassNames } from './index.classNames';
import marked from "marked";
import Scrollbars from 'react-custom-scrollbars-2';

export interface ChangeLogProps {  }
export interface ChangeLogState { markdown: string }

class ChangeLog extends React.Component<ChangeLogProps, ChangeLogState> {
    constructor(props: ChangeLogProps) {
        super(props);   

        this.state = {
            markdown: ''
        }
    }

    componentDidMount = () => {
        const {REACT_APP_ENV} = process.env;
        let readmePath: string = require("../../CHANGELOG.md");
        // if(REACT_APP_ENV !== 'development')
        //     readmePath = readmePath.replace('.azurewebsites', '.scm.azurewebsites');
        fetch(readmePath)
          .then(response => {
            return response.text()
          })
          .then(text => {
            this.setState({
              markdown: marked(text)
            })
        })
    }

    render(){
        const styles = getClassNames();
        const { markdown } = this.state;
        return (
            <>
                <Scrollbars style={{ height: '90vh' }}>
                    <section className={styles.container}>
                        <article dangerouslySetInnerHTML={{__html: markdown}}></article>
                    </section>                
                </Scrollbars> 
            </>  
        );
    }
}

export default (ChangeLog);