import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";
import { Document, FileForm } from '../../../models/document';
import { Status } from '../../../models/status';
import { ComboBox, FontIcon, FontSizes, Icon, IDropdownOption, Text, TextField } from '@fluentui/react';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { getClassNames } from './index.classNames';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import Dropzone from 'react-dropzone'
import { getSelectedDocuments } from '../../../redux/selectors/documents';
import Scrollbars from 'react-custom-scrollbars-2';
import { getFileExtension, getStakeHoldersDropdownOptions } from '../../../utils/functions';
import { Purpose } from '../../../models/purpose';
import { getSelectedProjectCustomerStakeholders } from '../../../redux/selectors/administration';
import { StakeHolder } from '../../../models/stakeHolder';
import { withTranslation, WithTranslation  } from 'react-i18next';
export interface ChangeStatusProps extends RouteComponentProps, WithTranslation {
    changeStatusForm?: {
        newStatus: Status,
        fileForm?: FileForm,
        files?: any[]
    }
    selectedDocuments: Document[],
    customerStakeholders: StakeHolder[],
    loadingContent: boolean,
    setContextItemProperty: typeof ModalActions.DocumentActions.setContextItemProperty
}

export interface ChangeStatusState {

}

class ChangeStatus extends React.Component<ChangeStatusProps & RouteComponentProps, ChangeStatusState> {
    constructor(props: ChangeStatusProps) {
        super(props);   
        this.state = {

        }
    }

    _onRenderCategoryOption = (option?: IDropdownOption): JSX.Element => {
        const styles = getClassNames();
        return (
            <div key={(option) ? option.key : undefined} className={styles.dropDownItem}>
                {option && (option as any).icon && <FontIcon style={{marginRight: 10, fontSize: FontSizes.xLarge, color: (option) ? (option as any).color : undefined}} iconName={(option) ? (option as any).icon : undefined} />}
                <span>{(option) ? option.text : undefined}</span>
            </div>     
        );
    };

    _onRenderCategoryTitle = (options?: IDropdownOption[]): JSX.Element => {
        const option = (options && options.length > 0) ? options[0] : undefined;
        return this._onRenderCategoryOption(option);
    };

    onDrop = (accepted: any, rejected: any) => {
        const { changeStatusForm, setContextItemProperty } = this.props;
        if(changeStatusForm && accepted && accepted.length > 0){
            setContextItemProperty({id: 'changeStatus', name: 'files', value: accepted})
        }
    }

    render(){
        const { changeStatusForm, loadingContent, selectedDocuments, customerStakeholders, t, setContextItemProperty } = this.props;
        const documentPurposes = (selectedDocuments && selectedDocuments.length > 0 && !_.some(selectedDocuments, d => !d.purpose)) ? _.map(selectedDocuments, d => d.purpose as Purpose) : [];
        const noApprovalNeeded = (documentPurposes.length > 0) ? _.every(documentPurposes, p => !p.isWithApproval) : true;
        const customerSelection: 'multiple' | 'single' =  (noApprovalNeeded && customerStakeholders.length > 1) ? 'multiple' : 'single';
        const stakeholder_options = getStakeHoldersDropdownOptions(_.filter(customerStakeholders, c => c.isActive));
        const styles = getClassNames();
        return (
            <>
                { changeStatusForm && changeStatusForm.newStatus && 
                    <>
                        <Text variant='mediumPlus' nowrap>{t('You are going to change the status of the following ' + ((selectedDocuments.length === 1) ? 'document:' : 'documents:'))}</Text>
                        <Scrollbars style={{ marginTop: 10}} autoHeight autoHeightMax={420}>
                            {_.map(selectedDocuments, (sd, index) => {
                                return <div key={index}><Text nowrap>{sd.documentNumber}</Text></div>
                            })}
                        </Scrollbars>
                        <div style={{marginTop: 20, display: 'flex', gap: 10, alignItems: 'center'}}>
                            <Text nowrap variant='mediumPlus' >The new status will be: </Text>
                            <FontIcon iconName={changeStatusForm.newStatus.icon} style={{color: changeStatusForm.newStatus.color, fontSize: FontSizes.xLarge }} />
                            <Text nowrap variant='mediumPlus' style={{fontWeight: 600}}>{(changeStatusForm.newStatus.description) ? changeStatusForm.newStatus.description.toLocaleUpperCase() : ''}</Text>
                        </div>                                              
                        {(changeStatusForm.newStatus.name === 'approved-comments' || changeStatusForm.newStatus.name === 'declined-comments' || changeStatusForm.newStatus.name === 'supplier-declined') && 
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{display: 'flex', gap: 20, marginTop: 20}}>
                                    <Dropzone
                                        onDrop={this.onDrop}
                                        disabled={loadingContent}
                                        noDragEventsBubbling={true}
                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <div {...getRootProps()} className={styles.containerDropzone}>
                                                <input {...getInputProps()} />
                                            <div style={{padding: 5}}>Drop the files here, or click to select files to upload.</div>
                                            </div>
                                        )}
                                    </Dropzone>   
                                    {changeStatusForm && changeStatusForm.files && changeStatusForm.files.length > 0 &&
                                        <div style={{display: 'flex', flexDirection: 'column', gap: 5, maxHeight: 200, overflowY: 'auto'}}>
                                            {_.map(changeStatusForm.files, (f, index) => {
                                                return( 
                                                    <div key={index + '_imported_file'} style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                                                        <Icon {...getFileTypeIconProps({extension: (f.name) ? getFileExtension(f.name) : '', size: 16}) }/>
                                                        <Text  variant="smallPlus" style={{}}>{f.name}</Text> 
                                                        <FontIcon iconName={"Cancel"} style={{cursor: "pointer", color: 'red' }}  onClick={() => { setContextItemProperty({id: 'changeStatus', name: 'files', value: _.filter(changeStatusForm.files, of => of.name !== f.name)}) }} /> 
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    }
                                </div>
                                <TextField 
                                    autoAdjustHeight
                                    multiline
                                    rows={3}
                                    label="Comment" 
                                    autoComplete={'off'} 
                                    value={(changeStatusForm.newStatus && changeStatusForm.newStatus.comment) || ''} 
                                    onChange={(e, value) => { 
                                        setContextItemProperty({id: 'changeStatus', name: 'newStatus', value: {...changeStatusForm.newStatus, comment: value}})
                                    }}
                                    styles={{root: {minWidth: 260, marginTop: 15, width: '100%'}}}
                                />    

                            </div>
                        }
                        {changeStatusForm.fileForm && changeStatusForm.newStatus.name === 'published' &&
                            <div style={{marginTop: 20}}>
                                <ComboBox
                                    required={(customerSelection === 'multiple') ? false : true}
                                    label={(customerSelection === 'multiple') ? 'Customers' : 'Customer'}
                                    multiSelect={customerSelection === 'multiple'}
                                    key={'status'}
                                    disabled={customerSelection === 'single' && customerStakeholders.length === 1}
                                    placeholder={customerSelection === 'multiple' ? (t('Select the customers') + '...')   : (t('Select the customer') + '...')}
                                    options={stakeholder_options}
                                    onChange={(e, value) => {
                                        const alredyIn = (value && changeStatusForm.fileForm && changeStatusForm.fileForm.stakeholders) ? _.find(changeStatusForm.fileForm.stakeholders, s => s.stakeHolderId  === value.key) : undefined;
                                        setContextItemProperty(
                                            {   id: 'changeStatus', 
                                                name: 'fileForm', 
                                                value: {
                                                    ...changeStatusForm.fileForm, stakeholders: (!alredyIn && changeStatusForm.fileForm) ? 
                                                                                                [...changeStatusForm.fileForm.stakeholders as StakeHolder[], _.find(customerStakeholders, s => value && s.stakeHolderId === value.key) as StakeHolder ] : 
                                                                                                [... _.filter((changeStatusForm.fileForm as FileForm).stakeholders as StakeHolder[], s => alredyIn && s.stakeHolderId !== alredyIn.stakeHolderId) ]
                                                }
                                            })
                                    }}
                                    selectedKey={(changeStatusForm.fileForm && changeStatusForm.fileForm.stakeholders) ? _.map(changeStatusForm.fileForm.stakeholders, s => s.stakeHolderId as number) : []}    
                                    useComboBoxAsMenuWidth                                                  
                                    styles={{root: {minWidth: 260}}}
                                />
                                {customerSelection === 'multiple' && <div style={{marginTop: 10, textDecoration: 'underline'}}><Text variant='mediumPlus'>{t('If you do not select any customer') + ', ' + ((selectedDocuments.length > 1) ? t('the documents will be published to all the customer related to this project') : t('the document will be published to all the customer related to this project')) }</Text></div>}
                            </div>
                        }
                    </>
                }
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    changeStatusForm: (state) => (getGenericModal('changeStatus')(state)) ? (getGenericModal('changeStatus')(state) as any).contextItem : undefined,
    selectedDocuments: getSelectedDocuments,
    customerStakeholders: getSelectedProjectCustomerStakeholders
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItemProperty: ModalActions.DocumentActions.setContextItemProperty
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(ChangeStatus)));