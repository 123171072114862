import {RoleModels, AuthConfigReducer} from '@pwbapps/reduxcore'
import _ from 'lodash';
import { InternalRoleActionsType, ExternalRoleActionsType, isExternalApp } from '../../utils/costants';
import {getSelectedProjectId} from '../reducers/project'

export const getSelectedProjectUserRole = (state: any): RoleModels.Role | undefined => {
    const projectId = getSelectedProjectId(state);
    const roles = AuthConfigReducer.getRoles(state);
    const superAdmin = _.find(roles, r => r.name === 'SUPERADMIN');
    return  (roles && roles.length > 0) ? (superAdmin ? superAdmin : ((projectId) ? _.find(roles, r => r.name.includes(projectId.toString())) : undefined)) : undefined;  
}

export const getSelectedProjectUserActions = (state: any): InternalRoleActionsType[] | ExternalRoleActionsType[] => {
    const userProjectRole = getSelectedProjectUserRole(state);
    if(userProjectRole)
        return (!isExternalApp) ? userProjectRole.actions as InternalRoleActionsType[] : userProjectRole.actions as ExternalRoleActionsType[];
    return [];
}