import { mergeStyleSets } from '@fluentui/react';

export interface IComponentClassNames {
    inputField: string,
    tagInput: string,
    remove: string,
    tag: string,
    wrapper: string
}
  
export const getClassNames = (theme: any): IComponentClassNames => {
    return mergeStyleSets({
        inputField: {
            padding: 6,
            width: '100%',
            minWidth: 256,
            selectors:{
                ':focus': {
                    border: '2px solid ' + theme.palette.themePrimary + '!important',
                },
            }
        },
        tagInput: {
            marginTop: 5,
            display: 'flex',
            gap: 10,
            flexWrap: 'wrap'
        },
        tag:{
            border: '1px solid #ddd',
            background: theme.palette.themePrimary,
            color: 'white',
            display: 'inline-block',
            padding: 5,
        },
        remove: {
            border: 'none',
            cursor: 'pointer',
            background: 'none',
            color: 'white'
        },
        wrapper: {
            height: 360,
            position: 'relative',
        },
    });
};