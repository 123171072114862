import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Notification from '../notification/index';
import { getNotification } from '../../redux/selectors/notification';
import { getFilterPanelOpened } from '../../redux/reducers/ui';
import { getLoader } from '../../redux/selectors/loader';
import { MessageBarType, Text, Spinner, SpinnerSize } from '@fluentui/react';
import { getClassNames } from './index.classNames';
import { getProjects, getSelectedProjectId } from '../../redux/reducers/project';
import _ from 'lodash';
import Scrollbars from 'react-custom-scrollbars-2';
import { Project } from '../../models/project';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface LandingProps extends RouteComponentProps, WithTranslation {
    loading: boolean,
    showNotification: boolean, 
    type: MessageBarType, 
    message: string,
    selectedProjectId?: number
    filterPanelOpened: boolean,
    projects: Project[]
}

class Landing extends React.Component<LandingProps> {
    constructor(props: LandingProps) {
        super(props);   
    }

    render(){
        const {loading, showNotification, type, message, selectedProjectId, filterPanelOpened, projects, t} = this.props;
        const styles = getClassNames(filterPanelOpened);
        const bodyMessage = (!selectedProjectId) ? t("Please select a project") : t("Please select a page");
        return (
            <>
                <Notification name={'home'} show={showNotification} type={type} message={message}/>
                <Scrollbars id={'landing_scrollbar'} style={{ height: '85vh' }}>
                    <div className={styles.resultContainer}>
                        <div className={styles.mainContainer}>
                            {(loading || !projects || projects.length == 0) && <Spinner size={SpinnerSize.large} style={{marginTop: 50}}></Spinner>}    
                            {!loading && projects && projects.length > 0 && 
                                <Text variant="xLargePlus" style={{marginTop: 50}}>
                                    {bodyMessage}
                                </Text>                      
                            }                
                        </div>
                    </div>    
                </Scrollbars>
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    loading: (state) => getLoader('project')(state).loading,
    showNotification: (state) => getNotification('landing')(state).show,
    type: (state) => getNotification('landing')(state).type,
    message: (state) => getNotification('landing')(state).message,
    selectedProjectId: getSelectedProjectId,
    filterPanelOpened: getFilterPanelOpened,
    projects: getProjects
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Landing)));