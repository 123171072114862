import _ from "lodash"
import { EngPhase } from "./engPhase"
import { Status, StatusHistory } from "./status"
import { Comment } from "./comment"
import { Type } from "./type"
import { Purpose } from "./purpose"
import { Discipline, ReserveNumberDisciplineDocType } from "./discipline"
import { DocType } from "./docType"
import { Entity } from "./entity"
import moment from "moment"
import { HistoryPathType } from "../utils/costants"
import { setModelStatus } from "../utils/statusFunctions"
import { Pbs, PbsElement } from "./pbs"
import { StakeHolder } from "./stakeHolder"
import { PermissionElement } from "./permissions"

export class Document {
    id?: number
    documentNumber?: string
    projectId?: string
    agileNumber?: string
    sheet?: number
    totalSheet?: number
    revision?: string
    format?: string
    scale?: string
    customerRevision?: string
    supplierDocumentNumber?: string
    tdNumber?: string
    tdSubject?: string
    isForInternalUse: boolean
    engineeringElement?: string
    type?: Type
    purpose?: Purpose
    engineeringOrderId?: number
    pbs?: string
    titleLine1Pl?: string
    titleLine1En?: string
    titleLine2Pl?: string
    titleLine2En?: string
    titleLine3Pl?: string
    titleLine3En?: string
    titleLine4Pl?: string
    titleLine4En?: string
    titleLine5Pl?: string
    titleLine5En?: string
    titleLine6Pl?: string
    titleLine6En?: string
    publication?: number
    clientNumber?: string
    discipline?: Discipline
    docType?: DocType
    engPhase?: EngPhase
    itemNumber?: string
    materialNumber?: string
    designation?: string
    appendix?: string
    entity?: Entity
    internalOrderNumber?: string
    lastStatus?: number
    isCurrent?: boolean
    publishedTo: PublishedTo[]
    plant?: PbsElement
    plantUnit?: PbsElement
    plantSection?: PbsElement
    equipment?: PbsElement
    partnerName?: string
    partnerNumber?: string
    approvalDate?: Date
    supplierName?: string

    status?: Status
    files: FileGrid[]
    filesNumber?: number
    comments: Comment[]
    statusHistory: StatusHistory[]
    folderIds: string[]

    editable: boolean
    
    constructor(object: any, statusValues: Status[], types: Type[], purposes: Purpose[], disciplines: Discipline[], engPhases: EngPhase[], entities: Entity[], pbs: Pbs[], userPermissions: PermissionElement[], stakeHolders: StakeHolder[], historyName: HistoryPathType){
        if(object){
            this.id = object.documentId;
            this.documentNumber = object.documentNumber;
            this.projectId = object.projectId;
            this.agileNumber = object.agileNumber;
            this.sheet = object.sheet;
            this.totalSheet = object.totalSheet;
            this.revision = object.revision;
            this.format = object.format;
            this.scale = object.scale;
            this.customerRevision = object.customerRevision;
            this.supplierDocumentNumber = object.supplierDocumentNumber;
            this.tdNumber = object.tdNumber;
            this.tdSubject = object.tdSubject;
            this.isForInternalUse = object.isForInternalUse;
            this.engineeringElement = object.engineeringElement;
            this.type = (object.typeId) ? _.find(types, t => t.id === object.typeId) : undefined;
            this.purpose = (object.purposeId) ? _.find(purposes, t => t.id === object.purposeId) : undefined;
            this.engineeringOrderId = object.engineeringOrderId;
            this.pbs = object.pbs;
            if(this.pbs){
                if(!this.pbs.includes('-')){
                    let _pbs = _.find(pbs, p => p.id === this.pbs) as Pbs;
                    this.plant = new PbsElement({code: _pbs.name, description: _pbs.description});
                }
                else{
                    let _pbsElements = this.pbs.split('-');
                    let previousCode: string | undefined = undefined;
                    for(let i = 0; i < _pbsElements.length; i++){
                        if(previousCode){
                            let _pbs = _.find(pbs, p => p.id == (previousCode + '-' + _pbsElements[i])) as Pbs
                            if(i == 1) this.plantUnit = new PbsElement({code: _pbsElements[i], description: _pbs.description});
                            else if(i == 2) this.plantSection = new PbsElement({code: _pbsElements[i], description: _pbs.description});
                            else this.equipment = new PbsElement({code: _pbsElements[i], description: _pbs.description});
                            previousCode = _pbs.name;
                        }
                        else{
                            let _pbs = _.find(pbs, p => p.id === _pbsElements[i]) as Pbs;
                            this.plant = new PbsElement({code: _pbsElements[i], description: _pbs.description});
                            previousCode = _pbs.name;
                        }                                             
                    }
                }
            }   
            this.titleLine1Pl = object.titleLine1Pl;
            this.titleLine1En = object.titleLine1En;
            this.titleLine2Pl = object.titleLine2Pl;
            this.titleLine2En = object.titleLine2En;
            this.titleLine3Pl = object.titleLine3Pl;
            this.titleLine3En = object.titleLine3En;
            this.titleLine4Pl = object.titleLine4Pl;
            this.titleLine4En = object.titleLine4En;
            this.titleLine5Pl = object.titleLine5Pl;
            this.titleLine5En = object.titleLine5En;
            this.titleLine6Pl = object.titleLine6Pl;
            this.titleLine6En = object.titleLine6En;
            this.publication = (object.documentStakeHolders && object.documentStakeHolders.length > 0) ? object.documentStakeHolders[0].publication : undefined;
            this.clientNumber = object.clientNumber;
            this.discipline = (object.disciplineId) ? _.find(disciplines, t => t.id === object.disciplineId) : undefined;
            this.docType = (object.docTypeId) ? _.find(_.flatMapDeep(_.map(disciplines, d => d.docTypes)), dt => dt.id === object.docTypeId) : undefined;
            this.engPhase = (object.engineeringPhaseId) ? _.find(engPhases, t => t.id === object.engineeringPhaseId) : undefined;
            this.itemNumber = object.itemNumber;
            this.materialNumber = object.materialNumber;
            this.designation = object.designation;
            this.appendix = object.appendix;
            this.entity = (object.entityId) ? _.find(entities, t => t.id === object.entityId) : undefined;
            this.lastStatus = object.lastStatus;
            this.isCurrent = object.isCurrent;

            this.status = (object.lastStatusNavigation && statusValues && statusValues.length > 0) ? setModelStatus(object.lastStatusNavigation.statusId, object.lastStatusNavigation.date, historyName, statusValues, this.purpose ? !this.purpose.isWithApproval : undefined) : undefined;
            this.files = _.orderBy(_.map(object.exodItems, ei => new FileGrid(ei)), ['isParent', f => f.fileName.toLocaleLowerCase()], ['desc', 'asc']);
            this.filesNumber = this.files.length;
            this.comments = _.map(object.comments, ei => new Comment(ei))
            this.publishedTo = (object.documentStakeHolders && object.documentStakeHolders.length > 0) ? _.map(object.documentStakeHolders, ds => new PublishedTo(ds)) : [];
            this.folderIds = (object.folders && object.folders.length > 0) ? _.map(object.folders, f => f.folderId) : [];
            this.partnerName = (object.partnerId && stakeHolders.length > 0) ? (_.find(stakeHolders, (s: StakeHolder) => s.stakeHolderId === object.partnerId) as StakeHolder).companyName : undefined;
            this.partnerNumber = object.partnerNumber;
            this.supplierName = (object.engineeringOrder && object.engineeringOrder.supplier) ? object.engineeringOrder.supplier.companyName : undefined;
            this.approvalDate = (object.approvalDate) ? moment((object.approvalDate && object.approvalDate.includes('.')) ? object.approvalDate.split('.')[0] + 'Z' : object.approvalDate).toDate() : undefined;

            // check editable
            this.editable = true;
            if(userPermissions.length > 0){
                this.editable = false;
                let permissionFound = false;
                _.forEach(userPermissions, up => {
                    // permission con pbs e discipline
                    if(up.pbs && up.pbs !== 'ALL' && up.discipline && up.discipline !== 0 && this.pbs && this.discipline && this.pbs.startsWith(up.pbs) && this.discipline.id === up.discipline){
                        this.editable = (up.canWrite) ? true : false;  
                        if(!_.some(userPermissions, (upc: PermissionElement) => upc.id !== up.id && this.pbs && upc.pbs && up.pbs && this.pbs.startsWith(upc.pbs) && upc.pbs.length > up.pbs.length)){
                            permissionFound = true;    
                        }
                    }
                     // permission con pbs
                    else if((!up.discipline || up.discipline === 0) && up.pbs && up.pbs !== 'ALL' && this.pbs && this.pbs.startsWith(up.pbs)){
                        this.editable = (up.canWrite) ? true : false; 
                        if(!_.some(userPermissions, (upc: PermissionElement) => upc.id !== up.id && this.pbs && upc.pbs && up.pbs && this.pbs.startsWith(upc.pbs) && upc.pbs.length > up.pbs.length)){
                            permissionFound = true;       
                        }
                    }
                    // permission con discipline
                    else if((!up.pbs || up.pbs === 'ALL') && up.discipline && up.discipline !== 0 && this.discipline && this.discipline.id === up.discipline){
                        this.editable = (up.canWrite) ? true : false; 
                        permissionFound = true;      
                    }

                    if(permissionFound)
                        return false;
                });     
            }        
        }
        else {
            this.isForInternalUse = false;
            this.files = [];
            this.comments = [];
            this.publishedTo = [];
            this.folderIds = [];
            this.editable = false;
        }
        this.statusHistory = []
    }
}

export class FileGrid {
    id: string
    fileName: string
    isComment: boolean
    isParent: boolean
    creationDate?: Date
    createdBy: string
    type: string = 'file'

    constructor(object: any) {
        this.id = object.exodItemId;
        this.fileName = object.fileName;
        this.isComment = object.isComment;
        this.isParent = object.isParent;
        this.createdBy = object.createdBy;
        this.creationDate = (object.createdDate) ? moment((object.createdDate && object.createdDate.includes('.')) ? object.createdDate.split('.')[0] + 'Z' : object.createdDate).toDate() : undefined;
    }
}

export class FileForm {
    documentIds: number[]
    isParent: boolean
    isComment: boolean
    isAttachment: boolean
    fileName: string
    createdBy: string
    stakeholders?: StakeHolder[]

    // dynamic fields
    format?: string
    scale?: string
    // parent fields
    supplierDocumentNumber?: string
    title4?: string
    title4Pl?: string
    title5?: string
    title5Pl?: string
    title6?: string
    title6Pl?: string

    type?: Type
    purpose?: Purpose
    engPhase?: EngPhase
    entity?: Entity

    constructor(object: any) {
        this.stakeholders = (object) ? object.stakeholders : [];
        this.documentIds = object.documentIds;
        this.isParent = object.isParent;
        this.isComment = object.isComment;
        this.isAttachment = object.isAttachment;
        this.fileName = object.fileName;
        this.createdBy = object.createdBy;
        this.format = object.format;
        this.scale = object.scale;
        this.supplierDocumentNumber = object.supplierDocumentNumber;
        this.title4 = object.title4;
        this.title4Pl = object.title4Pl;
        this.title5 = object.title5;
        this.title5Pl = object.title5Pl;
        this.title6 = object.title6;
        this.title6Pl = object.title6Pl;
        this.type = object.type;
        this.purpose = object.purpose;
        this.engPhase = object.engPhase;
        this.entity = object.entity;
    }
}
export const getChangeStatusEmptyFileForm = (singleCustomer?: StakeHolder): FileForm => {
    let emptyFileForm: FileForm = { 
        documentIds: [], 
        stakeholders: (singleCustomer) ? [singleCustomer] : [],
        isParent: false,
        isAttachment: false,
        isComment: false,
        fileName: '',
        createdBy: ''
    };
    return emptyFileForm;
}
export const getEmptyFileForm = (canUploadAttachments: boolean, canUploadFileComments: boolean): FileForm => {
    let emptyFileForm: FileForm = { 
        documentIds: [], 
        isParent: false, 
        isComment: (!canUploadAttachments && canUploadFileComments) ? true : false, 
        isAttachment: (canUploadFileComments && !canUploadAttachments) ? false : true, 
        createdBy: '',
        fileName: ''
    };
    return emptyFileForm;
}
export const getEmptyCommentFileForm = (): FileForm => {
    let emptyFileForm: FileForm = { 
        documentIds: [], 
        isParent: false, 
        isComment: true, 
        isAttachment: false, 
        createdBy: '',
        fileName: ''
    };
    return emptyFileForm;
}
export const getEmptyParentFileForm = (fileName?: string, documentId?: number): FileForm => {
    let emptyFileForm: FileForm = { 
        documentIds: (documentId) ? [documentId] : [], 
        isParent: true, 
        isComment: false, 
        isAttachment: false, 
        createdBy: '',
        fileName: (fileName) ? fileName : '',
    };
    return emptyFileForm;
}
export const getParentFileForm = (document: Document, isPartnerUpload?: boolean): FileForm => {
    let emptyFileForm: FileForm = { 
        documentIds: (document.id) ? [document.id] : [], 
        isParent: true, 
        isComment: false, 
        isAttachment: false, 
        createdBy: '',
        fileName: '',
        format: (document.format) ? document.format : undefined,
        scale: (document.scale) ? document.scale : undefined,
        title4: (document.titleLine4En) ? document.titleLine4En : undefined,
        title4Pl: (document.titleLine4Pl) ? document.titleLine4Pl: undefined,
        title5: (document.titleLine5En) ? document.titleLine5En: undefined,
        title5Pl: (document.titleLine5Pl) ? document.titleLine5Pl: undefined,
        title6: (document.titleLine6En) ? document.titleLine6En : undefined,
        title6Pl: (document.titleLine6Pl) ? document.titleLine6Pl : undefined,
        supplierDocumentNumber: (document.supplierDocumentNumber) ? document.supplierDocumentNumber : undefined,
        type: (isPartnerUpload) ? document.type : undefined,
        purpose: (isPartnerUpload) ? document.purpose : undefined,
        engPhase: (isPartnerUpload) ? document.engPhase : undefined,
        entity: (isPartnerUpload) ? document.entity : undefined,
    };
    return emptyFileForm;
}

export class EditDocumentForm {
    documentIds: number[]
    clientNumber?: string
    supplierDocumentNumber?: string
    entity?: Entity
    engPhase?: EngPhase
    purpose?: Purpose
    isForInternalUse?: boolean

    constructor(object?: Document, documentIds?: number[]) {
        this.documentIds = [];
        if(object){
            this.documentIds = [object.id as number];
            this.clientNumber = object.clientNumber;
            this.supplierDocumentNumber = object.supplierDocumentNumber;
            this.entity = object.entity;
            this.engPhase = object.engPhase;
            this.purpose = object.purpose;
            this.isForInternalUse = object.isForInternalUse;
        }
        else if(documentIds && documentIds.length > 0){
            this.documentIds = [...documentIds];
        }
    }
}

export class FileToDelete {
    documentId?: number
    file?: FileGrid
}

export class OtherRevision {
    id?: number
    documentNumber?: string
    agileNumber?: string
    sheet?: number
    revision?: string
    isCurrent: boolean

    constructor(object: any){
        if(object){
            this.id = object.documentId;
            this.documentNumber = object.documentNumber;
            this.agileNumber = object.agileNumber;
            this.sheet = object.sheet;
            this.revision = object.revision;
            this.isCurrent = object.isCurrent;
        } 
        else  
            this.isCurrent = false;
    }
}

export class ReserveNumberForm {
    number?: number
    type?: Type
    engPhase?: EngPhase
    purpose?: Purpose
    entity?: Entity
    pbs?: Pbs
    discipline?: ReserveNumberDisciplineDocType
    docType?: ReserveNumberDisciplineDocType
    titleLine1Pl?: string
    titleLine1En?: string
    titleLine2Pl?: string
    titleLine2En?: string
    titleLine3Pl?: string
    titleLine3En?: string
    titleLine4Pl?: string
    titleLine4En?: string
    titleLine5Pl?: string
    titleLine5En?: string
    titleLine6Pl?: string
    titleLine6En?: string
    errors?: {
        number?: string
        type?: string
        engPhase?: string
        purpose?: string
        entity?: string
        pbs?: string
        discipline?: string
        docType?: string
    }
    constructor(number?: number){
        this.number = number;
    }
}

export class PublishedTo {
    stakeHolderId?: number
    publishDate?: Date

    constructor(object: any){
        this.stakeHolderId = object.stakeHolderId;
        this.publishDate = (object.publishDate) ? moment((object.publishDate && object.publishDate.includes('.')) ? object.publishDate.split('.')[0] + 'Z' : object.publishDate).toDate() : undefined;
    }
}