import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Scrollbars from 'react-custom-scrollbars-2';
import { Text } from '@fluentui/react';
import { getEventTypes, getUiRules } from '../../redux/reducers/notifySettings';
import { NotifyRule } from '../../models/notifyRule';
import { EventType } from '../../models/eventType';
import {CommandActions} from '../../redux/actions/notifySettings';
import _ from 'lodash';
import { Spinner, SpinnerSize, Toggle } from '@fluentui/react';
import { ExternalRoleActionsType, InternalRoleActionsType, isExternalApp } from '../../utils/costants';
import { getSelectedProjectUserActions } from '../../redux/selectors/auth';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getSelectedProjectId } from '../../redux/reducers/project';
export interface MainProps extends RouteComponentProps, WithTranslation { 
    selectedProjectId?: number,
    uiRules: NotifyRule[], 
    eventTypes: EventType[], 
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    saveAndSetMail: typeof CommandActions.saveAndSetMail, 
    saveAndSetTeams: typeof CommandActions.saveAndSetTeams
}
class Ringer extends React.Component<MainProps> {
    constructor(props: MainProps) {
        super(props);   
    }

    saveAndSetValue = (urs: NotifyRule[], value: boolean, type: 'mail' | 'teams') => {
        const { saveAndSetMail, saveAndSetTeams, userProjectActions } = this.props;
        _.forEach(urs, ur => {
            if(type === 'mail')
                saveAndSetMail({index: ur.index as number, value: value as boolean});
            else if(type === 'teams')
                saveAndSetTeams({index: ur.index as number, value: value as boolean});
        });
    }

    render(){
        const { eventTypes, uiRules, userProjectActions, selectedProjectId, t } = this.props;
        const isAdmin = _.find(userProjectActions, a => userProjectActions && userProjectActions.length > 0 && a === 'Enable') ? true : false;
        //let uiRulesFiltered = (isAdmin) ? uiRules : _.filter(uiRules, r => r.eventTypeRuleId) as NotifyRule[];
        let uiRulesFiltered = [...uiRules] as NotifyRule[];

        //TKSE FILTER
        if(isExternalApp && (selectedProjectId === 401143 || selectedProjectId === 999999))
            uiRulesFiltered = _.filter(uiRulesFiltered, r => r.eventTypeId === 23);
        else
            uiRulesFiltered = _.filter(uiRulesFiltered, r => r.eventTypeId !== 23);
        
        let htmlRules: any[] = [];
        let processedEventTypes: number[] = [];
        _.forEach(_.orderBy(uiRulesFiltered, u => u.index), (ur, index) => {
            var e = _.find(eventTypes, e => e.eventTypeId === ur.eventTypeId);           
            var currentRules = _.filter(uiRulesFiltered, (urf: NotifyRule) => e && urf.eventTypeId === e.eventTypeId) as NotifyRule[];
            var element =
                <div key={index} style={{marginTop: 20}}>
                    <div style={{marginLeft: 20}}>
                        <Text variant={"mediumPlus"}>{(e) ? e.description : ''}</Text>
                    </div>
                    {!isExternalApp &&
                        <div style={{display: 'flex', justifyContent: (ur.eventTypeId !== 9) ? 'space-around' : 'flex-start', marginTop: 10, marginLeft: (ur.eventTypeId !== 9) ? undefined : 32}}>
                            <Toggle checked={ur.mail} onText="Mail" offText="Mail" onChange={(e, value) => this.saveAndSetValue(currentRules, value as boolean, 'mail')} />
                            <Toggle checked={ur.msChat} onText="Teams Chat" offText="Teams Chat" onChange={(e, value) => this.saveAndSetValue(currentRules, value as boolean, 'teams')}/>                                       
                        </div>
                    }
                    {isExternalApp &&
                        <div style={{display: 'flex', justifyContent: (ur.eventTypeId !== 9) ? 'space-around' : 'flex-start', marginTop: 10, marginLeft: (ur.eventTypeId !== 9) ? undefined : 32}}>
                            <Toggle checked={ur.mail} onText="Mail" offText="Mail" onChange={(e, value) => this.saveAndSetValue(currentRules, value as boolean, 'mail')} />
                        </div>
                    }                 
                </div>                                     
            ;
            if(e && !_.some(processedEventTypes, pet => pet === ur.eventTypeId)){
                htmlRules = [...htmlRules, element];            
                processedEventTypes = [...processedEventTypes, e.eventTypeId as number]
            }
        });
    
        return (
            <Scrollbars style={{ height: '89vh' }}>
                {eventTypes && eventTypes.length > 0 && uiRulesFiltered && uiRulesFiltered.length > 0 && 
                    <>
                        <div style={{marginLeft: 20, marginTop: 20}}><Text variant={"medium"} >{t('I would like to receive notifications about')}:</Text></div>    
                        {htmlRules}       
                    </>        
                }
                {(!eventTypes || eventTypes.length === 0 || !uiRulesFiltered || uiRulesFiltered.length === 0) && 
                    <Spinner size={SpinnerSize.large} style={{marginTop: 50}}></Spinner>      
                }
            </Scrollbars>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    selectedProjectId: getSelectedProjectId,
    uiRules: getUiRules,
    eventTypes: getEventTypes,
    userProjectActions: getSelectedProjectUserActions,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        saveAndSetMail: CommandActions.saveAndSetMail, 
        saveAndSetTeams: CommandActions.saveAndSetTeams 
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Ringer)));