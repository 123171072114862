import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames} from '../index.classNames';
import { getClassNames as getTagStyles } from './index.classNames';
import { Checkbox, ComboBox, KeyCodes, Label, Text, TextField} from '@fluentui/react';
import _ from 'lodash';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { CommandActions as ProjectCommandActions } from '../../../../redux/actions/project';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getAllProjects, getIsEdit, getProjectFormId, getProjectFormIsActiveInAgile, getProjectFormIsEnabled, getProjectFormName, getProjectFormReports, getProjectPlants } from '../../../../redux/reducers/project';
import { Project } from '../../../../models/project';
import { NumericFormat } from 'react-number-format';
import { getAllReports, getReports } from '../../../../redux/reducers/sharedData';
import { getReportsDropdownOptions } from '../../../../utils/functions';
import { Report } from '../../../../models/report';
import { getTheme } from '../../../../utils/costants';
import { Tag, WithContext as ReactTags } from 'react-tag-input';

export interface BodyProps extends RouteComponentProps { 
    projectId?: number,
    projectName?: string,
    isActivatedInAgile: boolean,
    isEnabled: boolean,
    allProjects: Project[],
    isEdit: boolean,
    allReports: Report[],
    reports: Report[],
    plants: string[]
    openGenericModal: typeof ModalActions.CommandActions.openModal,
    setProjectFormId: typeof ProjectCommandActions.setProjectFormId,
    setProjectFormName: typeof ProjectCommandActions.setProjectFormName,
    setProjectFormIsEnabled: typeof ProjectCommandActions.setProjectFormIsEnabled,
    setProjectFormIsActivateInAgile: typeof ProjectCommandActions.setProjectFormIsActivateInAgile,
    setProjectFormReports: typeof ProjectCommandActions.setProjectFormReports,
    setProjectPlants: typeof ProjectCommandActions.setProjectPlants,
}

export interface BodyState  { 
    oldProjectId?: number,
}

class Body extends React.Component<BodyProps, BodyState> {
    constructor(props: BodyProps) {
        super(props); 
        this.state = {
            oldProjectId: undefined
        }
    }

    componentDidMount = () => {
        const { projectId, isEdit } = this.props;
        this.setState({oldProjectId: (isEdit) ? projectId : undefined});
    }

    
    addPlantTag = (tag: Tag) => {
        const { setProjectPlants, plants } = this.props;
        setProjectPlants({value: (tag && tag.id) ? [...plants, tag.id.toLocaleUpperCase()] : plants});
    }
    removePlantTag = (index: number) => {
        const { setProjectPlants, plants } = this.props;
        setProjectPlants({value: _.filter(plants, (dn, i) =>  index !== i)});
    }

    render(){
        const styles = getClassNames();
        const { projectId, allProjects, projectName, isActivatedInAgile, isEnabled, allReports, reports, plants, setProjectFormId, setProjectFormName, setProjectFormIsActivateInAgile, setProjectFormIsEnabled, setProjectFormReports, setProjectPlants, isEdit } = this.props;
        const { oldProjectId } = this.state;
        const alreadyIn = (!isEdit) ? _.some(allProjects, p => p.projectId === projectId) : ((oldProjectId && _.some(allProjects, p => p.projectId === projectId && projectId !== oldProjectId)));
        const report_options = getReportsDropdownOptions(allReports);
        const tagStyles = getTagStyles(getTheme(false));
        const plantTags: Tag[] = (plants.length > 0) ? _.map(plants, c => {return {id: c, text: c}}) : [];
        return ( 
            <>         
                <div className={styles.tabContainer}>    
                    <div style={{marginBottom: 30}}><Text variant='xLarge' nowrap >General</Text></div>   
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: 10, alignItems: 'center'}}>
                        <NumericFormat
                            disabled={isEdit}
                            required
                            label={'Project Id'}
                            customInput={TextField}
                            decimalScale={0}
                            value={(projectId) ? projectId : ''}
                            autoComplete={'off'}
                            onValueChange={(values) => { setProjectFormId({value: values.floatValue})}}
                            styles={{root: {minWidth: 256}}}
                            errorMessage={(alreadyIn) ? 'This project Id is already in Exod' : ''}
                        />     
                        <TextField 
                            required
                            label="Project Name" 
                            autoComplete={'off'} 
                            value={(projectName) || ''} 
                            onChange={(e, value) => {setProjectFormName({value})}}
                            styles={{root: {minWidth: 256, marginRight: 15}}}
                        />
                    </div>    
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: 10, alignItems: 'center'}}>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 28, marginRight: 15}}>
                            <Checkbox 
                                checked={(isActivatedInAgile) ? true : false} 
                                onChange={(e, value) => { setProjectFormIsActivateInAgile({value: (value) ? true : false})}} 
                            />  
                            <Label styles={{root: {marginLeft: 5}}}>Is activated in Agile</Label>
                        </div>  
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 28}}>
                            <Checkbox 
                                checked={(isEnabled) ? true : false} 
                                onChange={(e, value) => { setProjectFormIsEnabled({value: (value) ? true : false})}} 
                            />  
                            <Label styles={{root: {marginLeft: 5}}}>Is enabled</Label>
                        </div>                          
                    </div>  
                    <div style={{marginTop: 20, height: 98, maxWidth: 320}}>
                            <Label>Enabled Plants</Label>
                            <ReactTags
                                tags={plantTags}
                                delimiters={[KeyCodes.enter]}
                                handleDelete={this.removePlantTag}
                                handleAddition={this.addPlantTag}
                                placeholder="Press enter to add a new column"
                                inputFieldPosition="top"
                                allowDragDrop={false}
                                autofocus={false}
                                classNames={{
                                    tagInputField: tagStyles.inputField,
                                    selected: tagStyles.tagInput,
                                    tag: tagStyles.tag,
                                    remove: tagStyles.remove
                                }}
                            />
                        </div>    
                    <div style={{marginTop: 20}}>
                        <ComboBox
                            multiSelect
                            label={"Reports"} 
                            options={report_options}
                            placeholder={"Select a report..."}
                            onChange={(e, value) => {
                                const alredyIn = (value && reports) ? _.find(reports, s => s.id  === value.key) : undefined;
                                setProjectFormReports({value: (!alredyIn && reports) ? 
                                    [...reports as Report[], _.find(allReports, s => value && s.id === value.key) as Report ] : 
                                    [... _.filter(reports, s => alredyIn && s.id !== alredyIn.id) as Report[]]
                                })
                            }}
                            selectedKey={(reports) ? _.map(reports, s => s.id as number) : []}      
                            useComboBoxAsMenuWidth={true}         
                            calloutProps={{calloutMaxHeight: 220}}
                            styles={{root: {maxWidth: 845}}}
                        />                                                                                
                    </div>
                </div>                        
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    allProjects: getAllProjects,
    projectId: getProjectFormId,
    projectName: getProjectFormName,
    isActivatedInAgile: getProjectFormIsActiveInAgile,
    isEnabled: getProjectFormIsEnabled,
    isEdit: getIsEdit,
    allReports: getAllReports,
    reports: getProjectFormReports,
    plants: getProjectPlants
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        openGenericModal: ModalActions.CommandActions.openModal,
        setProjectFormId: ProjectCommandActions.setProjectFormId,
        setProjectFormName: ProjectCommandActions.setProjectFormName,
        setProjectFormIsEnabled: ProjectCommandActions.setProjectFormIsEnabled,
        setProjectFormIsActivateInAgile: ProjectCommandActions.setProjectFormIsActivateInAgile,
        setProjectFormReports: ProjectCommandActions.setProjectFormReports,
        setProjectPlants: ProjectCommandActions.setProjectPlants,
     }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Body));