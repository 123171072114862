import { EventType } from "./eventType"

export class NotifyRule {
    index?: number
    eventTypeRuleId?: number
    ruleJson?: string
    ruleJsonObject?: any
    mail: boolean
    msChat: boolean
    msNotificationTeam: boolean
    teams?: string
    channel?: string
    eventTypeId?: number | number[]
    userId?: string
    userName?: string

    constructor(object?: any, eventType?: EventType, userId?: string, userName?: string){
        if(object){
            this.eventTypeRuleId = object.EventTypeRuleId;
            this.ruleJson = object.EventTypeRule.RuleJson;
            this.mail = object.Mail;
            this.msChat = object.MsChat;
            this.msNotificationTeam = object.MsNotificationTeam;
            this.teams = object.Teams;
            this.channel = object.Channel;
            this.eventTypeId = object.EventTypeRule.EventTypeId;
            this.userId = object.UserId;
            this.userName = object.UserName;
        }
        else if (eventType){
            this.eventTypeRuleId = undefined;
            this.ruleJson = "";
            this.mail = false;
            this.msChat = false;
            this.msNotificationTeam = false;
            this.teams = "";
            this.channel = "";
            this.eventTypeId = eventType.eventTypeId;
            this.userId = userId;
            this.userName = userName;
        }
        else{
            this.mail = false;
            this.msChat = false;
            this.msNotificationTeam = false;
        }
    }
}