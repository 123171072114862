import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DirectionalHint, FontIcon, TooltipHost } from '@fluentui/react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {Event} from '../../models/event';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { DefaultButton, FontSizes, IconButton } from '@fluentui/react';
import { DocumentActions } from '../../redux/actions/ui';
import { filterEventsByInternalStatus } from '../../utils/statusFunctions';
import { formatDateTime, getHistoryName } from '../../utils/functions';
import { getSelectedProjectUserActions } from '../../redux/selectors/auth';
import { ExternalRoleActionsType, InternalRoleActionsType } from '../../utils/costants';

export interface LessonTimelineProps extends RouteComponentProps { 
    statusHistory?: Event[],
    hideNotes?: boolean,
    selectedProjectCode?: string,
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    darkModeEnabled?: boolean
}
                                                               
class LessonTimeline extends React.Component<LessonTimelineProps & RouteComponentProps> {
    constructor(props: LessonTimelineProps) {
        super(props);
    }

    commonStyle = {
        fontSize: '12px',
        fontWeight: 'normal',
    }
    iconStyle = {
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 11,
        paddingTop: 2,
        backgroundColor: '#F4F4F4',
        webkitBoxShadow: '0px 0px 0px 2px #C6C6C6'
    }

    getTimelineEvents = (): JSX.Element[] => {
        const { statusHistory, hideNotes, history, userProjectActions, darkModeEnabled } = this.props;
        if(statusHistory && statusHistory.length > 0){
            const statusHistoryOrdered = _.orderBy(filterEventsByInternalStatus(getHistoryName(history), statusHistory), sh => sh.date, 'desc');
            let isPartner = _.some(userProjectActions, a => a === 'FilterDocumentsByPartnerId');
            let events: JSX.Element[] = [];
            _.each(statusHistoryOrdered, (s, index) => {
                const eventFirstRow =  (s.status) ? (s.status.description + ((s.status.agileCode && !isPartner) ? (' (' + s.status.agileCode + ')') : '')) : '';
                const eventUser = (s.user && s.user.includes('@')) ? s.user.split('@')[0] : s.user;
                events = [...events,
                  
                        <VerticalTimelineElement
                            key={index}
                            date={s.date ? formatDateTime(s.date) : ""}
                            icon={<FontIcon iconName={(s.status) ? s.status.icon  : ''} style={{fontSize: FontSizes.large, color:  (s.status) ? s.status.color : undefined}} />}
                            iconStyle={{...this.iconStyle}}
                            contentStyle={{ background: '#F4F4F4', borderRadius: 5, padding: 10 }}
                            contentArrowStyle={{ borderRight: '7px solid #F4F4F4' }}                 
                        >
                            <TooltipHost directionalHint={DirectionalHint.rightCenter} content={eventFirstRow}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
                                    <div style={{fontWeight: 'bold'}}>{eventFirstRow}</div>
                                    <div>{eventUser}</div>
                                </div>
                            </TooltipHost> 
                          
                        </VerticalTimelineElement>                                 
                ]    
            });
            return events;
        }
        return [];
    }

    render() {
        const events = this.getTimelineEvents();
        const { } = this.props;
        return ( 
            <>
                {events.length > 0 &&
                     <div style={{overflowY: 'auto', flexGrow: 1, overflowX: 'hidden', height: 'calc(1vh)', paddingLeft: 2, paddingRight: 10}}>
                        <VerticalTimeline
                            layout={'1-column-left'}
                            lineColor={'#F0F0F0'}
                        >
                            {events}
                        </VerticalTimeline>                
                    </div>
                }
            </>
        );
    }
} 

const mapStateToProps = createStructuredSelector({
    userProjectActions: getSelectedProjectUserActions,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(LessonTimeline));