import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";
import { Text } from '@fluentui/react';

export interface WarningProps extends RouteComponentProps {
    message?: string,
}
class Warning extends React.Component<WarningProps> {
    constructor(props: WarningProps) {
        super(props);   
        this.state = {

        }
    }


    render(){
        const { message } = this.props;
        return ( <Text variant='mediumPlus'>{message}</Text> );
    }
}

const mapStateToProps = createStructuredSelector({
    message: (state) => (getGenericModal('warning')(state)) ? (getGenericModal('warning')(state) as any).contextItem as string : undefined,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Warning));