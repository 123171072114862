import { PermissionElement } from '../../models/permissions';
import {DocumentActions, SET_VALUE} from '../actions/initialization';
import _ from 'lodash';

interface InitializationState  {
    company?: string
    country?: string
    stakeHolderId?: number
    userPermissions: PermissionElement[]
}

const initialState: InitializationState = {
    company: undefined,
    country: undefined,
    stakeHolderId: undefined,
    userPermissions: []
} 

export const initializationReducer = (initialization = initialState, action : DocumentActions): InitializationState => {
    switch (action.type) {
        
        case SET_VALUE:
            return {...initialization, [action.payload.name]: action.payload.value };


        default:
            return initialization;
    }
};

//select from state (read) 
const selectInitializationState = (state: any) => state.initialization as InitializationState;
export const getCompany = (state: any) => selectInitializationState(state).company;
export const getCountry = (state: any) => selectInitializationState(state).country;
export const getStakeHolderId = (state: any) => selectInitializationState(state).stakeHolderId;
export const getUserPermissions = (state: any) => selectInitializationState(state).userPermissions;