import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import CommandBar from './command_bar/index'
import Notification from '../notification/index';
import { getNotification } from '../../redux/selectors/notification';
import { getFilterPanelOpened } from '../../redux/reducers/ui';
import { getLoader } from '../../redux/selectors/loader';
import { MessageBarType, ProgressIndicator, Spinner, SpinnerSize } from '@fluentui/react';
import { getClassNames } from './index.classNames';
import PublicationStatusPie from './graphs/publicationStatus_pie';
import ClientStatusPie from './graphs/clientStatus_pie';
import FinalPurposesPie from './graphs/finalPurposes_pie';
import { getPublicationStatusData } from '../../redux/reducers/main';
import _ from 'lodash';
import Scrollbars from 'react-custom-scrollbars-2';
import { ResponsivePie } from '@nivo/pie'
export interface MainProps extends RouteComponentProps {
    loading: boolean,
    loadingContent: boolean,
    filterPanelOpened: boolean, 
    showNotification: boolean, 
    type: MessageBarType, 
    message: string
}

class Main extends React.Component<MainProps> {
    constructor(props: MainProps) {
        super(props);   
    }

    render(){
        const {loading, loadingContent, showNotification, type, message, filterPanelOpened} = this.props;
        const styles = getClassNames(filterPanelOpened, loading);
        return (
            <>
                <Notification name={'home'} show={showNotification} type={type} message={message}/>
                <ProgressIndicator styles={{root: {height: 10}}} progressHidden={ !loadingContent || loading }/> 
                {loading && <Spinner size={SpinnerSize.large} style={{marginTop: 50}}></Spinner>}
                {!loading &&
                    <Scrollbars id={'home_scrollbar'} style={{ height: '90vh' }}>
                        <div className={styles.homeContainer}>
                            {/* <PublicationStatusPie/> */}
                            <ClientStatusPie/>
                            <FinalPurposesPie/>
                        </div>
                    </Scrollbars>
                    //<ResponsivePie arcLabelsTextColor={}/>
                }
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    loading: (state) => getLoader('home')(state).loading,
    loadingContent: (state) => getLoader('home')(state).loadingContent,
    showNotification: (state) => getNotification('home')(state).show,
    type: (state) => getNotification('home')(state).type,
    message: (state) => getNotification('home')(state).message,
    filterPanelOpened: getFilterPanelOpened,

});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Main));