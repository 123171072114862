import { mergeStyleSets } from '@fluentui/react';

export interface IComponentClassNames {
    font: string,
    font2: string,
    filtersContainer: string,
    notification: string,
    dropDownItem: string,
    clearButton: string,
    columns: string,
    containerDropzone: string
}
  
export const getClassNames = (): IComponentClassNames => {
    return mergeStyleSets({
        font: {
            fontWeight: 600,
            marginRight: 20,
            marginTop: 5,
            marginBottom: 5,
            width: 310
        },
        font2: {
            fontWeight: 600,
            marginTop: 5,
            marginBottom: 5,
        },
        filtersContainer: {
            paddingRight: 15, 
            paddingLeft: 10,
        },
        notification: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 10
        },
        dropDownItem: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'  
        },
        clearButton: {
            height: 26,
            marginLeft: 5,
            alignSelf: "center"
        },
        columns: {
            marginBottom: 5, 
            display: 'flex', 
            gap: 10, 
            flexWrap: 'wrap'
        },
        containerDropzone: {
            borderColor: 'rgb(102, 102, 102)',
            borderStyle: 'dashed',
            borderRadius: '5px',
            paddingTop: 60,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 60,
        }, 
    });
};