import { mergeStyleSets } from '@fluentui/react';

export interface IComponentClassNames {
 
}
  
export const getClassNames = (): IComponentClassNames => {
    return mergeStyleSets({

    });
};