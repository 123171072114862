import React, { Component } from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from './index.classNames';
import { CommandActions as ProjectCommandActions } from '../../redux/actions/project';
import { getDarkModeEnabled, getProjects, getSelectedProjectId } from '../../redux/reducers/project';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ComboBox, FontIcon, FontSizes, IComboBoxOption, IconButton, IIconProps, PanelType, Spinner, SpinnerSize } from '@fluentui/react';
import { Project } from '../../models/project';
import { Dropdown, IDropdown } from '@fluentui/react';
import _ from 'lodash';
import { getLoader } from '../../redux/selectors/loader';
import { getHistoryName } from '../../utils/functions';
import { getUserProjects } from '../../redux/selectors/project';
import { getRoles } from '@pwbapps/reduxcore/dist/redux/reducers/auth';
import { RoleModels } from '@pwbapps/reduxcore';
import { getSelectedProjectUserRole } from '../../redux/selectors/auth';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getTheme } from '../../utils/costants';

export interface ProjectSelectorProps extends RouteComponentProps, WithTranslation {
    selectedProjectId?: number,
    projects: Project[],
    loading: boolean,
    roles: RoleModels.Role[],
    selectedProjectUserRole?: RoleModels.Role,
    darkModeEnabled: boolean
    loadObjectsAndSetProject: typeof ProjectCommandActions.loadObjectsAndSetProject
}

class ProjectSelector extends React.Component<ProjectSelectorProps & RouteComponentProps> {

    render() {
        const { selectedProjectId, projects, selectedProjectUserRole, roles, loadObjectsAndSetProject, history, t, loading, darkModeEnabled} = this.props;
        const styles = getClassNames(getTheme(darkModeEnabled));
        const project_options: IComboBoxOption[] =  _.map(_.orderBy(projects, p => p.projectId), (p) => {return {key: p.projectId as number, text: `${p.projectId} ${(p.projectName) ?  ' - ' + p.projectName : ''}`}});
        const historyName = getHistoryName(history)
        return (  
            <>
                {roles && roles.length > 0 && projects && projects.length > 0 && 
                    <div style={{display: 'flex', alignItems: 'center'}}>   
                        <Dropdown
                            placeholder={t("Select a project") + "..."}
                            selectedKey={selectedProjectId}
                            disabled={false}
                            options={project_options}
                            onChange={(e, value) => loadObjectsAndSetProject({projectId: (value as IComboBoxOption).key as number})}                           
                            styles={{ root: styles.root, caretDown: styles.icon, dropdownItemsWrapper: {maxHeight: 360}}}
                            dropdownWidth={450}
                        />             
                    </div>                  
                }
                { (roles && roles.length > 0 && loading && (!projects || projects.length === 0)) && <Spinner size={SpinnerSize.medium} style={{marginLeft: 20}}></Spinner> }
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    selectedProjectId: getSelectedProjectId,
    projects: getUserProjects,
    loading: (state) => getLoader('project')(state).loading,
    roles: getRoles,
    selectedProjectUserRole: getSelectedProjectUserRole,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        loadObjectsAndSetProject: ProjectCommandActions.loadObjectsAndSetProject
    }, dispatch); 
}

export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(ProjectSelector)));