import { FEATURE, Actions, CommandActions, EventActions, NEW_EDIT_FOLDER, DELETE_FOLDER, ADD_DOCUMENTS_TO_FOLDERS, ADD_OR_REMOVE_DOCUMENTS_TO_FOLDERS, REMOVE_DOCUMENTS_FROM_FOLDERS} from "../../actions/documents";
import {ApiConfigActions} from "@pwbapps/reduxcore";
import _ from 'lodash';
import { MessageBarType } from '@fluentui/react';
import { getHistoryName } from "../../../utils/functions";
import { addDocumentsToFolderUrl, newEditDeleteFolderUrl, removeDocumentsFromFolderUrl } from "../../../utils/documentsUrls";
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";
import { Folder } from "../../../models/folder";
import { SharedDataCommandActions } from "../../actions/sharedData";
import { getSelectedProjectId } from "../../reducers/project";
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { getContextualDocuments } from "../../selectors/documents";
import { t } from "i18next";

export const documentsFoldersMiddlewareFunction = (history: any, dispatch: any, getState: any, next: any, action: Actions | ApiConfigActions.EventActions) => {

    switch (action.type) {
 
        case NEW_EDIT_FOLDER: {
            let folder: Folder | undefined = ((getGenericModal('newEditFolder')(getState())) ? (getGenericModal('newEditFolder')(getState()) as any).contextItem as Folder : undefined);
            if(folder && folder.folderName){
                dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true }));
                dispatch(ModalActions.CommandActions.closeModal({id: 'folder'}));
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: 
                    { 
                        method: (!folder.id) ? 'POST' : 'PUT', 
                        body : (!folder.id) ? 
                        {
                            name: folder.folderName,
                            parentId: folder.parentId,
                            projectId: getSelectedProjectId(getState())
                        } :
                        {
                            name: folder.folderName,
                            folderId: folder.id
                        },
                        url: newEditDeleteFolderUrl(), 
                        feature: NEW_EDIT_FOLDER, 
                        returnObject: {id: (folder.id) ? folder.id : undefined}
                    }
                }));  
            }
            break;
        }

        case DELETE_FOLDER: {
            let folder: Folder | undefined = ((getGenericModal('removeFolder')(getState())) ? (getGenericModal('removeFolder')(getState()) as any).contextItem as Folder : undefined);
            if(folder && folder.id){
                dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true }));
                dispatch(ModalActions.CommandActions.closeModal({id: 'folder'}));
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request:{ method: 'DELETE', url: newEditDeleteFolderUrl(folder.id), feature: DELETE_FOLDER, returnObject: {id: folder.id}}}));  
            }
            break;
        }

        case ADD_OR_REMOVE_DOCUMENTS_TO_FOLDERS: {
            if(action.payload.isAdding)
                dispatch(EventActions.addDocumentsToFolder());
            else
                dispatch(EventActions.removeDocumentsFromFolder());
            break;
        }

        case ADD_DOCUMENTS_TO_FOLDERS: {
            let folderForm: { folder?: Folder, isAdding?: boolean, isRemoving?: boolean } | undefined = ((getGenericModal('folder')(getState())) ? (getGenericModal('folder')(getState()) as any).contextItem as { folder?: Folder, isAdding?: boolean, isRemoving?: boolean } : undefined);
            let documents = getContextualDocuments(getState());
            let documentIds = _.map(documents, d => d.id as number);
            if(folderForm && folderForm.folder && folderForm.folder.id && documents && documents.length > 0){
                dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true }));
                dispatch(ModalActions.CommandActions.closeModal({id: 'folder'}));
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: 
                    { 
                        method: 'POST', 
                        body: {
                            folderId: folderForm.folder.id,
                            documentIds
                        },
                        url: addDocumentsToFolderUrl(), 
                        feature: ADD_DOCUMENTS_TO_FOLDERS, 
                        returnObject: {folderName: folderForm.folder.folderName, documentIds}
                    }
                }));  
            }
            break;
        }

        case REMOVE_DOCUMENTS_FROM_FOLDERS: {
            let folderForm: { folder?: Folder, isAdding?: boolean, isRemoving?: boolean } | undefined = ((getGenericModal('folder')(getState())) ? (getGenericModal('folder')(getState()) as any).contextItem as { folder?: Folder, isAdding?: boolean, isRemoving?: boolean } : undefined);
            let documents = getContextualDocuments(getState());
            let documentIds = _.map(documents, d => d.id as number);
            if(folderForm && folderForm.folder && folderForm.folder.id && documents && documents.length > 0){
                dispatch(EventActions.setGridOrModalLoadersAndNotification({value: true }));
                dispatch(ModalActions.CommandActions.closeModal({id: 'folder'}));
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: 
                    { 
                        method: 'POST', 
                        body: {
                            folderId: folderForm.folder.id,
                            documentIds
                        },
                        url: removeDocumentsFromFolderUrl(), 
                        feature: REMOVE_DOCUMENTS_FROM_FOLDERS, 
                        returnObject: {folderName: folderForm.folder.folderName, documentIds}
                    }
                }));  
            }
            break;
        }
              
        case ApiConfigActions.API_SUCCESS:
            apiSuccessMiddleware(history, dispatch, getState, next, action);         
            break;
    
        case ApiConfigActions.API_ERROR:
            apiErrorMiddleware(history, dispatch, getState, next, action);         
            break;

        default:
            break;
    }
};

const apiSuccessMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiSuccessAction) => {
    switch(action.meta.feature){
        case NEW_EDIT_FOLDER: {
            let id = action.meta.returnObject.id;
            dispatch(SharedDataCommandActions.fetchFolders());
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: ((id) ? t('Folder edited successfully') : t('Folder created successfully')), messageType: MessageBarType.success, closeAutomatically: true }));   
            break;
        }

        case DELETE_FOLDER: {
            dispatch(SharedDataCommandActions.fetchFolders());
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: t('Folder deleted successfully'), messageType: MessageBarType.success, closeAutomatically: true }));   
            break;
        }

        case ADD_DOCUMENTS_TO_FOLDERS: {
            let folderName = action.meta.returnObject.folderName;
            let documentIds = action.meta.returnObject.documentIds as number[];
            dispatch(CommandActions.getDocumentsByIdAndUpdateGrid({documentIds: [...documentIds]}));
            dispatch(SharedDataCommandActions.fetchFolders());
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: (t('Documents successfully added to the folder') + ' ' + folderName), messageType: MessageBarType.success, closeAutomatically: true }));   
            break;
        }

        case REMOVE_DOCUMENTS_FROM_FOLDERS: {
            let folderName = action.meta.returnObject.folderName;
            let documentIds = action.meta.returnObject.documentIds as number[];
            dispatch(CommandActions.getDocumentsByIdAndUpdateGrid({documentIds: [...documentIds]}));
            dispatch(SharedDataCommandActions.fetchFolders());
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: (t('Documents successfully removed from the folder') + ' ' + folderName), messageType: MessageBarType.success, closeAutomatically: true }));   
            break;
        }
        
  
        default:
            break;
    }
}

const apiErrorMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiErrorAction) => {
    switch(action.meta.feature){
        case NEW_EDIT_FOLDER: {
            let id = action.meta.returnObject.id;
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: ((id) ? t('Cannot edit this folder') : t('Cannot create this folder')), messageType: MessageBarType.error }));   
            break;
        }

        case DELETE_FOLDER: {
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: t('Cannot delete this folder'), messageType: MessageBarType.error }));   
            break;
        }

        case ADD_DOCUMENTS_TO_FOLDERS: {
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: t('Cannot add documents to this folder'), messageType: MessageBarType.error }));   
            break;
        }

        case REMOVE_DOCUMENTS_FROM_FOLDERS: {
            dispatch(EventActions.setGridOrModalLoadersAndNotification({value: false, message: t('Cannot remove documents from this folder'), messageType: MessageBarType.error }));   
            break;
        }
        
      
        default:
            break;
    }
}