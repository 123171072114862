import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {DocumentActions, FEATURE} from '../../redux/actions/documents';
import {CommandActions as DocumentCommandActions} from '../../redux/actions/document';
import {CommandActions as DocumentsCommandActions, DocumentActions as DocumentDocumentsActions} from '../../redux/actions/documents';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import {CommandActions as FiltersCommandActions} from '../../redux/actions/filters';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import CommandBar from './command_bar/index'
import Notification from '../notification/index';
import { getNotification } from '../../redux/selectors/notification';
import { getFilterPanelOpened } from '../../redux/reducers/ui';
import { getLoader } from '../../redux/selectors/loader';
import { IColumn, MessageBarType, ProgressIndicator, Spinner, SpinnerSize, Selection } from '@fluentui/react';
import { getClassNames } from './index.classNames';
import { getColumns } from '../documents/columns';
import SelectedFilters from '../filtersPanel/filters/selectedFilters';
import _ from 'lodash';
import { getOrderColumn, getTotal } from '../../redux/reducers/documents';
import DocumentModal from '../modals/document'
import DocumentsGrid from '../documents/index'
import { getSelectedProjectUserActions } from '../../redux/selectors/auth';
import { getFilters } from '../../redux/reducers/filters';
import { Filters } from '../../models/filters';
import { getNotificationWidth } from '../../redux/selectors/ui';
import CalloutFilters from '../filtersPanel/callout/index';
import { MainCompGenerator as GenericModalGenerator, Actions as GenericModalActions } from '@pwbapps/genericdialog'
import UploadFiles from '../documents/functionalities/uploadReplaceFile';
import ChangeStatus from '../documents/functionalities/changeStatus';
import EditDocument from '../documents/functionalities/editDocument';
import DownloadFiles from '../documents/functionalities/downloadFiles';
import DownloadFilesTest from '../documents/functionalities/downloadFilesTest';
import DownloadComments from '../documents/functionalities/downloadComments';
import DownloadExcelReport from '../documents/functionalities/downloadExcelReport';
import UploadParentFile from '../documents/functionalities/uploadParentFilePartner';
import ReserveNumbers from '../documents/functionalities/reserveNumbers';
import NewSheet from '../documents/functionalities/newSheet';
import ImportZip from '../documents/functionalities/importZip';
import FolderPopup from '../documents/functionalities/folder';
import NewEditFolder from '../documents/functionalities/newEditFolder';
import { InternalRoleActionsType, ExternalRoleActionsType, getTheme } from '../../utils/costants';
import { getHistoryName } from '../../utils/functions';
import { getContextualDocuments } from '../../redux/selectors/documents';
import { FileToDelete, EditDocumentForm, Document, FileForm } from '../../models/document';
import { Status } from '../../models/status';
import { getSelectedProjectIsFolderPageActive } from '../../redux/selectors/project';
import { Folder } from '../../models/folder';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getFolders, getPbs } from '../../redux/reducers/sharedData';
import { getEmail } from '@pwbapps/reduxcore/dist/redux/reducers/auth';
import { getDarkModeEnabled, getHasPartner, getSelectedProjectId } from '../../redux/reducers/project';
import { getSelectedProjectDaysToApproval } from '../../redux/selectors/project';
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";
import { Pbs } from '../../models/pbs';

let GenericModal = GenericModalGenerator();
export interface SearchProps extends RouteComponentProps, WithTranslation {
    loading: boolean,
    loadingContent: boolean,
    loadingScroll: boolean,
    documents: Document[]
    filterPanelOpened: boolean, 
    showNotification: boolean, 
    type: MessageBarType, 
    message: string,
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    filters: Filters,
    showCallout: boolean,
    orderColumn: {name: string, direction: string },
    fileToDelete?: FileToDelete,
    editDocumentForm: EditDocumentForm,
    total: number,
    changeStatusForm?: {
        newStatus: Status,
        fileForm?: FileForm,
        files?: any[]
    },
    folderForm?: {
        folder?: Folder,
        isAdding?: boolean,
        isRemoving?: boolean,
    },
    folderNameForm?: Folder,
    deleteFolderForm?: Folder,
    uploadFileForm: {files: any[], fileForm: FileForm},
    sheetNumber?: number,
    notificationWidth: string,
    isFolderPageActive: boolean,
    folders: Folder[],
    email?: string,
    darkModeEnabled: boolean,
    hasPartner: boolean,
    daysToApproval: number,
    selectedProjectid?: number,
    pbsList: Pbs[],
    setSelectedItemsIds: typeof DocumentActions.setSelectedItemsIds,
    getDocumentAndOpenModal: typeof DocumentCommandActions.getDocumentAndOpenModal,
    showOrHideCallout: typeof FiltersCommandActions.showOrHideCallout,
    openGenericModal: typeof ModalActions.CommandActions.openModal,
    closeGenericModal: typeof ModalActions.CommandActions.closeModal,
    downloadOrShowFile: typeof DocumentsCommandActions.downloadOrShowFile,
    changeStatus: typeof DocumentsCommandActions.changeStatus,
    deleteFile: typeof DocumentsCommandActions.deleteFile,
    fetchDocuments: typeof DocumentsCommandActions.fetchDocuments,
    setSortColumn: typeof DocumentDocumentsActions.setSortColumn,
    deleteDocuments: typeof DocumentsCommandActions.deleteDocuments,
    editDocument: typeof DocumentsCommandActions.editDocument,
    downloadFiles: typeof DocumentsCommandActions.downloadFiles,
    downloadFilesTest: typeof DocumentsCommandActions.downloadFilesTest,
    downloadComments: typeof DocumentsCommandActions.downloadComments,
    downloadExcelReport: typeof DocumentsCommandActions.downloadExcelReport,
    uploadDocuments: typeof DocumentsCommandActions.uploadDocuments,
    reserveNumbers: typeof DocumentsCommandActions.reserveNumbers,
    newRevision: typeof DocumentsCommandActions.newRevision,
    newSheet: typeof DocumentsCommandActions.newSheet,
    uploadSupplierFile: typeof DocumentsCommandActions.uploadSupplierFile,
    uploadPartnerFile: typeof DocumentsCommandActions.uploadPartnerFile,
    newEditFolder: typeof DocumentsCommandActions.newEditFolder,
    deleteFolder: typeof DocumentsCommandActions.deleteFolder,
    addOrRemoveDocumentsToFolder: typeof DocumentsCommandActions.addOrRemoveDocumentsToFolder,
}
class Search extends React.Component<SearchProps  & RouteComponentProps> {
    constructor(props: SearchProps) {
        super(props);   
    }

    getKey = (item: any, index?: number): string => {
        return item.id;
    }

    getFilters = (): Filters => {
        const { filters } = this.props;
        return filters;
    }

    getFilterPanel = (): boolean => {
        const { filterPanelOpened } = this.props;
        return filterPanelOpened;
    }

    onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { fetchDocuments, setSortColumn } = this.props; 
        setSortColumn({colName: column.fieldName as string, direction: (column.isSortedDescending) ? 'asc' : 'desc', feature: FEATURE});
        //this.selection.setAllSelected(false);    
        fetchDocuments({});
    };

    selection = new Selection({		
        getKey: this.getKey,
		onSelectionChanged: () => {
            let rows = this.selection.getSelection() as any;
            const {setSelectedItemsIds} = this.props;
            let ids = (rows) ? _.map(rows, (r: Document) => {return r.id as number}) : [];
            setSelectedItemsIds({ids: _.map(ids, id => id.toString()), feature: FEATURE}) 
		}
    });

    render(){
        const {loading, loadingContent, showNotification, type, message, filterPanelOpened, orderColumn, loadingScroll, userProjectActions, total, changeStatusForm, documents, sheetNumber, isFolderPageActive, t, folders, email, darkModeEnabled,
               history, fileToDelete, editDocumentForm, uploadFileForm, notificationWidth, folderForm, folderNameForm, deleteFolderForm, getDocumentAndOpenModal, showOrHideCallout, closeGenericModal, downloadOrShowFile, changeStatus, openGenericModal,
               hasPartner, daysToApproval, selectedProjectid, deleteFile, deleteDocuments, editDocument, downloadFiles, downloadComments, downloadExcelReport, uploadDocuments, reserveNumbers, newRevision, newSheet, uploadPartnerFile, newEditFolder, deleteFolder, addOrRemoveDocumentsToFolder, downloadFilesTest,pbsList} = this.props;
        const styles = getClassNames(filterPanelOpened);
        const historyName = getHistoryName(history);
        const disableDownloald = !((documents.length === 0 && total < 100) || (documents.length > 0 && documents.length < 100));
        const canRead = _.find(userProjectActions, a => a === 'ReadDashboard') ? true : false;
        const canDeleteFiles = _.find(userProjectActions, a => a === 'UploadAttachmentDashboard' || a === 'UploadCommentFileDashboard') ? true : false;
        const canUploadAttachments = _.find(userProjectActions, a => a === 'UploadAttachmentDashboard') ? true : false;
        const canUploadFileComments = _.find(userProjectActions, a => a === 'UploadCommentFileDashboard') ? true : false;
        const canUploadParentFile = _.find(userProjectActions, a => a === 'UploadParentDashboard') ? true : false;
        const singleSelectedDocument = (documents && documents.length === 1) ? documents[0] : undefined;
        const columns = getColumns(selectedProjectid || 0, email as string, t, canRead, canDeleteFiles, this.selection, this.getFilterPanel, getDocumentAndOpenModal, this.getFilters, showOrHideCallout, downloadOrShowFile, this.onColumnClick, orderColumn, historyName, openGenericModal, canUploadParentFile, folders, _.some(userProjectActions, a => a === 'FilterDocumentsByPartnerId'), getTheme(darkModeEnabled), hasPartner, daysToApproval, pbsList);
        return (
            <>
                <CommandBar userProjectActions={userProjectActions} loading={loading} loadingContent={loadingContent} loadingScroll={loadingScroll}/>
                <div style={{position: 'absolute', width: notificationWidth, top: 99, zIndex: 10}}>
                    {!loading && !loadingContent && <Notification name={'dashboard'} show={showNotification} type={type} message={message}/>}
                </div>
                {!loading && <SelectedFilters selection={this.selection}/>}
                <ProgressIndicator styles={{root: {height: 10}}} progressHidden={ !loadingContent || loading }/> 
                {loading && <Spinner size={SpinnerSize.large} style={{marginTop: 50}}></Spinner>}
                {!loading &&
                    <div className={styles.resultContainer}>
                        <DocumentsGrid selection={this.selection} columns={columns} loadingScroll={loadingScroll}/>
                        <DocumentModal userProjectActions={userProjectActions}/>
                    </div>
                }
                <CalloutFilters/>
                <GenericModal 
                    id={'uploadDocuments'}
                    title={(uploadFileForm && uploadFileForm.fileForm && uploadFileForm.fileForm.isParent) ? t('Replace file') : t('Upload files')}
                    message={''}                 
                    onClose={() => {closeGenericModal({id: 'uploadDocuments'})}}
                    btnCloseText={t('Cancel')}
                    btnConfirmText={t('Save')}
                    onConfirm={( )=> {uploadDocuments({})}}
                    disableConfirm={loadingContent || !uploadFileForm || !uploadFileForm.files || uploadFileForm.files.length === 0}
                    customComponent={<UploadFiles loadingContent={loadingContent} canUploadAttachments={canUploadAttachments} canUploadFileComments={canUploadFileComments}/>}
                    disableAutoModalClose 
                    disableClose={loadingContent}  
                    width={640}         
                />
                <GenericModal 
                    id={'changeStatus'}
                    title={t('Change Status')}
                    message={''}                 
                    onClose={() => {}}
                    btnCloseText={t('Cancel')}
                    onConfirm={() => {changeStatus({documents})}}
                    btnConfirmText={t('Save')}
                    customComponent={<ChangeStatus loadingContent={loadingContent}/>}
                    disableConfirm={!changeStatusForm || (changeStatusForm.newStatus && (changeStatusForm.newStatus.name === 'approved-comments' || changeStatusForm.newStatus.name === 'declined-comments') && (!changeStatusForm.newStatus.comment && (!changeStatusForm.files || changeStatusForm.files.length === 0)))}
                    width={640}
                />
                <GenericModal 
                    id={'editDocument'}
                    title={t('Edit document')}
                    message={''}                 
                    onClose={() => {}}
                    btnCloseText={t('Cancel')}
                    onConfirm={() => {editDocument({editForm: editDocumentForm})}}
                    btnConfirmText={t('Save')}
                    customComponent={<EditDocument loadingContent={loadingContent}/>}
                    disableConfirm={loadingContent || (!editDocumentForm || !editDocumentForm.purpose || !editDocumentForm.clientNumber)}         
                />
                <GenericModal 
                    id={'fileName'}
                    title={(!disableDownloald) ? t('Download files') : t('Attention')}
                    message={(!disableDownloald) ? '' : t('There are too many documents to download. Please select a number of documents less than 100')}                 
                    onClose={() => {}}
                    onConfirm={() => {downloadFiles()}}
                    btnConfirmText={(!disableDownloald) ? t('Download') : undefined}
                    btnCloseText={t('Cancel')}   
                    customComponent={<DownloadFiles disableDownloald={disableDownloald}/>}
                    disableConfirm={loadingContent || disableDownloald}  
                />
                  <GenericModal 
                    id={'fileNameTest'}
                    title={(!disableDownloald) ? t('Download files') : t('Attention')}
                    message={(!disableDownloald) ? '' : t('There are too many documents to download. Please select a number of documents less than 100')}                 
                    onClose={() => {}}
                    onConfirm={() => {downloadFilesTest()}}
                    btnConfirmText={(!disableDownloald) ? t('Download') : undefined}
                    btnCloseText={t('Cancel')}   
                    customComponent={<DownloadFilesTest disableDownloald={disableDownloald}/>}
                    disableConfirm={loadingContent || disableDownloald}  
                />
                <GenericModal 
                    id={'comments'}
                    title={t('Download comments')}
                    message={''}                 
                    onClose={() => {}}
                    onConfirm={() => {downloadComments()}}
                    btnConfirmText={t('Download')}
                    btnCloseText={t('Cancel')}   
                    customComponent={<DownloadComments/>}
                    disableConfirm={loadingContent}     
                />
                <GenericModal 
                    id={'excelReport'}
                    title={t('Download Excel report')}
                    message={''}                 
                    onClose={() => {}}
                    onConfirm={() => {downloadExcelReport()}}
                    btnConfirmText={t('Download')}
                    btnCloseText={t('Cancel')}   
                    customComponent={<DownloadExcelReport/>}
                    disableConfirm={loadingContent}          
                />
                { fileToDelete &&
                    <GenericModal 
                        id={'deleteFile'}
                        title={t('Attention')}
                        message={t('The file') + ' ' + (fileToDelete.file ? fileToDelete.file.fileName : '') + " " + t("will be deleted. Are you sure?")}                             
                        onClose={() => {}}
                        onConfirm={() => {deleteFile({documentId: fileToDelete.documentId , file: fileToDelete.file})}}
                        btnConfirmText={t('Delete')}
                        btnCloseText={t('Cancel')}   
                        disableConfirm={loadingContent}     
                    />
                }
                { documents.length > 0 && 
                    <GenericModal 
                        id={'deleteDocument'}
                        title={t('Attention')}
                        message={((documents.length === 1) ? (t('The document') + documents[0].documentNumber) : (t('The documents') + _.join(_.map(documents, d => d.documentNumber as string), ', '))) + " " + t('will be deleted. Are you sure?')}                 
                        onClose={() => {}}
                        onConfirm={() => {deleteDocuments()}}
                        btnConfirmText={t('Delete')}
                        btnCloseText={t('Cancel')}   
                        disableConfirm={loadingContent}     
                        width={640}
                    />
                }
                { isFolderPageActive && folderForm &&
                    <>
                        <GenericModal 
                            id={'folder'}
                            title={t('Manage Folders')}
                            message={''}                 
                            onClose={() => {}}
                            onConfirm={() => {addOrRemoveDocumentsToFolder({isAdding: (folderForm.isAdding) ? true : false})}}
                            btnConfirmText={(folderForm && folderForm.isAdding) ? 'Save' : ((folderForm && folderForm.isRemoving) ? 'Remove' : undefined)}
                            btnCloseText={t('Cancel')} 
                            customComponent={<FolderPopup/>}  
                            disableConfirm={loadingContent || !folderForm || !folderForm.folder || !folderForm.folder.id}     
                            width={840}
                        />
                        {folderNameForm && 
                            <GenericModal 
                                id={'newEditFolder'}
                                title={(folderNameForm && folderNameForm.id) ? t('Edit folder name') : t('New subfolder')}
                                message={''}                 
                                onClose={() => {}}
                                onConfirm={() => {newEditFolder()}}
                                btnConfirmText={t('Save')}
                                btnCloseText={t('Cancel')} 
                                customComponent={<NewEditFolder/>}  
                                disableConfirm={loadingContent}     
                                width={640}
                            />                       
                        }
                    </>
                }
                { deleteFolderForm &&
                    <GenericModal 
                        id={'removeFolder'}
                        title={t('Delete subfolder')}
                        message={t('You are going to delete the folder') +  ' ' + deleteFolderForm.folderName + '. ' + t('Are you sure?')}                 
                        onClose={() => {}}
                        onConfirm={() => {deleteFolder()}}
                        btnConfirmText={t('Delete')}
                        btnCloseText={t('Cancel')} 
                        disableConfirm={loadingContent}     
                    />      
                }
                {_.some(userProjectActions, a => a === 'FilterDocumentsByPartnerId') &&
                    <>
                        <GenericModal 
                            id={'reserveNumber'}
                            title={t('Reserve numbers')}
                            message={''}                 
                            onClose={() => {closeGenericModal({id: 'reserveNumber'})}}
                            onConfirm={() => {reserveNumbers()}}
                            btnConfirmText={t('Save')}
                            btnCloseText={t('Cancel')}   
                            customComponent={<ReserveNumbers/>}
                            disableConfirm={loadingContent}    
                            disableAutoModalClose 
                            width={640}
                        />
                        <GenericModal 
                            id={'revision'}
                            title={t('New revision')}
                            message={t('Do you want to create a new revision for this document?')}                 
                            onClose={() => {}}
                            onConfirm={() => {newRevision()}}
                            btnConfirmText={t('Yes')}
                            btnCloseText={t('No')}   
                            disableConfirm={loadingContent}    
                        />
                        <GenericModal 
                            id={'sheet'}
                            title={t('New sheet')}
                            message={''}                 
                            onClose={() => {}}
                            onConfirm={() => {newSheet()}}
                            btnConfirmText={t('Save')}
                            btnCloseText={t('Cancel')}   
                            customComponent={<NewSheet/>}
                            disableConfirm={loadingContent || sheetNumber === undefined || sheetNumber <= 0}    
                        />
                        <GenericModal 
                            id={'importZip'}
                            title={t('Import ZIP file')}
                            message={''}                 
                            onClose={() => {closeGenericModal({id: 'importZip'})}}
                            btnCloseText={t('Cancel')}
                            customComponent={<ImportZip loadingContent={loadingContent} isPartnerFile={true}/>}
                            disableAutoModalClose 
                            disableClose={loadingContent}        
                        />   
                        {singleSelectedDocument && 
                            <GenericModal 
                                id={'uploadParent'}
                                title={t('Upload file')}
                                message={''}                 
                                onClose={() => {closeGenericModal({id: 'uploadParent'})}}
                                btnCloseText={t('Cancel')}
                                onConfirm={() => {uploadPartnerFile()}}
                                btnConfirmText={t('Save')}
                                customComponent={<UploadParentFile loadingContent={loadingContent}/>}
                                disableAutoModalClose 
                                disableClose={loadingContent}     
                                width={640}    
                            />      
                        }
                    </>                    
                }
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    loading: (state) => getLoader('dashboard')(state).loading,
    loadingContent: (state) => getLoader('dashboard')(state).loadingContent,
    loadingScroll: (state) => getLoader('dashboard')(state).loadingScroll,
    showNotification: (state) => getNotification('dashboard')(state).show,
    type: (state) => getNotification('dashboard')(state).type,
    message: (state) => getNotification('dashboard')(state).message,
    filterPanelOpened: getFilterPanelOpened,
    userProjectActions: getSelectedProjectUserActions,
    filters: getFilters,
    changeStatusForm: (state) => (getGenericModal('changeStatus')(state)) ? (getGenericModal('changeStatus')(state) as any).contextItem : undefined,
    orderColumn: getOrderColumn,
    fileToDelete: (state) => (getGenericModal('deleteFile')(state)) ? (getGenericModal('deleteFile')(state) as any).optional as FileToDelete : undefined,
    editDocumentForm: (state) => (getGenericModal('editDocument')(state)) ? (getGenericModal('editDocument')(state) as any).contextItem : undefined,
    uploadFileForm: (state) => (getGenericModal('uploadDocuments')(state)) ? (getGenericModal('uploadDocuments')(state) as any).contextItem : undefined,
    sheetNumber: (state) => (getGenericModal('sheet')(state)) ? (getGenericModal('sheet')(state) as any).contextItem as number : undefined,
    folderForm: (state) => (getGenericModal('folder')(state)) ? (getGenericModal('folder')(state) as any).contextItem : undefined,
    folderNameForm: (state) => (getGenericModal('newEditFolder')(state)) ? (getGenericModal('newEditFolder')(state) as any).contextItem : undefined,
    deleteFolderForm: (state) => (getGenericModal('removeFolder')(state)) ? (getGenericModal('removeFolder')(state) as any).contextItem : undefined,
    total: getTotal,
    documents: getContextualDocuments,
    notificationWidth: getNotificationWidth,
    isFolderPageActive: getSelectedProjectIsFolderPageActive,
    folders: getFolders,
    email: getEmail,
    darkModeEnabled: getDarkModeEnabled,
    hasPartner: getHasPartner,
    daysToApproval: getSelectedProjectDaysToApproval,
    selectedProjectid: getSelectedProjectId,
    pbsList: getPbs
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setSelectedItemsIds: DocumentActions.setSelectedItemsIds,
        getDocumentAndOpenModal: DocumentCommandActions.getDocumentAndOpenModal,
        showOrHideCallout: FiltersCommandActions.showOrHideCallout,
        openGenericModal: ModalActions.CommandActions.openModal,
        closeGenericModal: ModalActions.CommandActions.closeModal,
        downloadOrShowFile: DocumentsCommandActions.downloadOrShowFile,
        changeStatus: DocumentsCommandActions.changeStatus,
        fetchDocuments: DocumentsCommandActions.fetchDocuments,
        setSortColumn: DocumentDocumentsActions.setSortColumn,
        deleteFile: DocumentsCommandActions.deleteFile,
        deleteDocuments: DocumentsCommandActions.deleteDocuments,
        editDocument: DocumentsCommandActions.editDocument,
        downloadFiles: DocumentsCommandActions.downloadFiles,
        downloadComments: DocumentsCommandActions.downloadComments,
        downloadExcelReport: DocumentsCommandActions.downloadExcelReport,
        uploadDocuments: DocumentsCommandActions.uploadDocuments,
        reserveNumbers: DocumentsCommandActions.reserveNumbers,
        newRevision: DocumentsCommandActions.newRevision,
        newSheet: DocumentsCommandActions.newSheet,
        uploadPartnerFile: DocumentsCommandActions.uploadPartnerFile,
        newEditFolder: DocumentsCommandActions.newEditFolder,
        deleteFolder: DocumentsCommandActions.deleteFolder,
        addOrRemoveDocumentsToFolder: DocumentsCommandActions.addOrRemoveDocumentsToFolder,
        downloadFilesTest: DocumentsCommandActions.downloadFilesTest,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Search)));