import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {CommandActions as DocumentCommandActions} from '../../redux/actions/document';
import {CommandActions as ProjectCommandActions} from '../../redux/actions/project';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getFilterPanelOpened } from '../../redux/reducers/ui';
import { ConstrainMode, DetailsList, DetailsListLayoutMode, ScrollablePane, ScrollbarVisibility, SelectionMode, IDetailsHeaderProps, IRenderFunction, Sticky, StickyPositionType, DetailsRow, DetailsRowFields, IDetailsRowFieldsProps, Text, IDetailsListProps, Spinner, SpinnerSize, FontIcon, ProgressIndicator, MessageBarType } from '@fluentui/react';
import { getClassNames } from './index.classNames';
import _ from 'lodash';
import { MainCompGenerator as GenericModalGenerator, Actions as GenericModalActions } from '@pwbapps/genericdialog'
import Warning from '../documents/functionalities/warning';
import { getAllProjects, getDarkModeEnabled } from '../../redux/reducers/project';
import { Project } from '../../models/project';
import { getLoader } from '../../redux/selectors/loader';
import { getColumns } from './columns';
import ProjectsModal from '../modals/project/index';
import CommandBar from './command_bar/index';
import { getNotification } from '../../redux/selectors/notification';
import { getNotificationWidth } from '../../redux/selectors/ui';
import Notification from '../notification/index';
export interface ProjectsGridProps extends RouteComponentProps {
    filterPanelOpened: boolean, 
    allProjects: Project[],
    loadingContent: boolean,
    loading: boolean,
    showNotification: boolean, 
    type: MessageBarType, 
    message: string,
    notificationWidth: string,
    darkModeEnabled: boolean,
    setOpenModal: typeof DocumentCommandActions.setOpenModal,
    openOrCloseProjectModal: typeof ProjectCommandActions.openOrCloseProjectModal,
}

let GenericModal = GenericModalGenerator();

class ProjectsGrid extends React.Component<ProjectsGridProps> {
    constructor(props: ProjectsGridProps) {
        super(props);   
    }


    onRenderDetailsHeader = (props?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element => {
        return (
          <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
            {(defaultRender && props) ? defaultRender({
              ...props,        
            }) : null}
          </Sticky>
        );
    }

    onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        return <DetailsRow {...props as any}  rowFieldsAs={this.renderRowFields} />;
    }
    
    renderRowFields = (props: IDetailsRowFieldsProps) => {
        return (
          <span data-selection-disabled={true}>
            <DetailsRowFields {...props} />
          </span>
        );
    }


    render(){
        const {filterPanelOpened, allProjects, loadingContent, loading, notificationWidth, showNotification, type, message, darkModeEnabled, openOrCloseProjectModal} = this.props;
        const styles = getClassNames(filterPanelOpened);
        const columns = getColumns(openOrCloseProjectModal);
        return (
            <>
                <CommandBar/>
                <div style={{position: 'absolute', width: notificationWidth, top: 105, zIndex: 10}}>
                    {!loading && !loadingContent && <Notification name={'publish'} show={showNotification} type={type} message={message}/>}
                </div>
                {loadingContent && <Spinner size={SpinnerSize.large} style={{marginTop: 50}}></Spinner>}
                {!loadingContent &&
                    <div className={styles.resultContainer}>
                        {allProjects && 
                            <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                                 <Text variant='large' style={{marginLeft: 20, color: (darkModeEnabled) ? '#f7f7f7' : undefined}}>
                                    {allProjects.length + '/' + allProjects.length + ' projects'}
                                </Text>
                            </div>
                        }
                        <div className={styles.wrapper}>
                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                                <DetailsList                              
                                    items={allProjects}
                                    columns={columns}
                                    setKey="projectId"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    constrainMode={ConstrainMode.unconstrained}
                                    //onRenderRow={this.onRenderRow}
                                    onRenderDetailsHeader={this.onRenderDetailsHeader}
                                    selectionPreservedOnEmptyClick={true}
                                    selectionMode={SelectionMode.none}  
                                    styles={{headerWrapper: {paddingTop: 0}}}
                                    //columnReorderOptions={columnReorderOptions}                            
                                />
                            </ScrollablePane>               
                        </div>  
                        <ProjectsModal />                         
                    </div>
                }
                <GenericModal 
                    id={'warning'}
                    title={'Attention'}        
                    onClose={() => {}}
                    btnCloseText={'Cancel'} 
                    customComponent={<Warning/>}
                    width={420}  
                />        
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    filterPanelOpened: getFilterPanelOpened,
    allProjects: getAllProjects,
    loadingContent: (state) => getLoader('project')(state).loadingContent,
    loading: (state) => getLoader('project')(state).loading,
    showNotification: (state) => getNotification('project')(state).show,
    type: (state) => getNotification('project')(state).type,
    message: (state) => getNotification('project')(state).message,
    notificationWidth: getNotificationWidth,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setOpenModal: DocumentCommandActions.setOpenModal,
        openOrCloseProjectModal: ProjectCommandActions.openOrCloseProjectModal
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withRouter(ProjectsGrid));