import {configuration} from '../configuration'
import { isExternalApp } from './costants';

// GET
export const getPublicationChartUrl = (projectId: number): string => {
     return `${configuration.api_endpoint}/GetPublicationStatus?projectId=${projectId}` ;
}; 
export const getClientChartUrl = (projectId: number): string => {
    return `${configuration.api_endpoint}/GetStatusReportChart?projectId=${projectId}&isInternal=${!isExternalApp}` ;
}; 
export const getFinalPurposeChartUrl = (projectId: number): string => {
    return `${configuration.api_endpoint}/GetFinalPurposes?projectId=${projectId}` ;
}; 