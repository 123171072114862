import { mergeStyleSets } from '@fluentui/react';

export interface IComponentClassNames {
    container: string;
    child: string;
}
  
export const getClassNames = (): IComponentClassNames => {
    return mergeStyleSets({
        container: {
            display: "flex",
            flexDirection: "column",
            paddingTop: "50px",
            paddingBottom: "50px",
        },
        child: {
            flex: "1 1 100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
        }
    });
};