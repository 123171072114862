import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {DocumentActions, FEATURE} from '../../redux/actions/documents';
import {CommandActions as DocumentCommandActions} from '../../redux/actions/document';
import {CommandActions as DocumentsCommandActions, DocumentActions as DocumentDocumentsActions} from '../../redux/actions/documents';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import {CommandActions as FiltersCommandActions} from '../../redux/actions/filters';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import CommandBar from './command_bar/index'
import Notification from '../notification/index';
import { getNotification } from '../../redux/selectors/notification';
import { getFilterPanelOpened } from '../../redux/reducers/ui';
import { getLoader } from '../../redux/selectors/loader';
import { IColumn, MessageBarType, ProgressIndicator, Spinner, SpinnerSize, Selection } from '@fluentui/react';
import { getClassNames } from './index.classNames';
import { getColumns } from '../documents/columns';
import SelectedFilters from '../filtersPanel/filters/selectedFilters';
import _ from 'lodash';
import { getOrderColumn } from '../../redux/reducers/documents';
import { Document, FileForm, getEmptyFileForm } from '../../models/document';
import DocumentModal from '../modals/document'
import DocumentsGrid from '../documents/index'
import { getSelectedProjectUserActions } from '../../redux/selectors/auth';
import { getFilters } from '../../redux/reducers/filters';
import { Filters } from '../../models/filters';
import { getNotificationWidth } from '../../redux/selectors/ui';
import CalloutFilters from '../filtersPanel/callout/index';
import { MainCompGenerator as GenericModalGenerator, Actions as GenericModalActions } from '@pwbapps/genericdialog'
import UploadFiles from '../documents/functionalities/uploadReplaceFile';
import ChangeStatus from '../documents/functionalities/changeStatus';
import { InternalRoleActionsType, ExternalRoleActionsType, isExternalApp, getTheme } from '../../utils/costants';
import { getHistoryName } from '../../utils/functions';
import { Status } from '../../models/status';
import { getContextualDocuments } from '../../redux/selectors/documents';
import DownloadComments from '../documents/functionalities/downloadComments';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getEmail } from '@pwbapps/reduxcore/dist/redux/reducers/auth';
import { getDarkModeEnabled, getHasPartner, getSelectedProjectId } from '../../redux/reducers/project';
import { getSelectedProjectDaysToApproval } from '../../redux/selectors/project';
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";

let GenericModal = GenericModalGenerator();

export interface SupplierPublishProps extends RouteComponentProps, WithTranslation {
    loading: boolean,
    loadingContent: boolean,
    loadingScroll: boolean,
    documents: Document[]
    filterPanelOpened: boolean, 
    showNotification: boolean, 
    type: MessageBarType, 
    message: string,
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    filters: Filters,
    showCallout: boolean,
    orderColumn: {name: string, direction: string },
    changeStatusForm?: {
        newStatus: Status,
        fileForm?: FileForm,
        files?: any[]
    },
    uploadFileForm: {files: any[], fileForm: FileForm},
    notificationWidth: string,
    email?: string,
    darkModeEnabled: boolean,
    hasPartner: boolean,
    daysToApproval: number,
    selectedProjectid?: number,
    setSelectedItemsIds: typeof DocumentActions.setSelectedItemsIds,
    getDocumentAndOpenModal: typeof DocumentCommandActions.getDocumentAndOpenModal,
    showOrHideCallout: typeof FiltersCommandActions.showOrHideCallout,
    openGenericModal: typeof ModalActions.CommandActions.openModal,
    closeGenericModal: typeof ModalActions.CommandActions.closeModal,
    downloadOrShowFile: typeof DocumentsCommandActions.downloadOrShowFile,
    changeStatus: typeof DocumentsCommandActions.changeStatus,
    fetchDocuments: typeof DocumentsCommandActions.fetchDocuments,
    setSortColumn: typeof DocumentDocumentsActions.setSortColumn,
    setData: typeof DocumentDocumentsActions.setData,
    downloadComments: typeof DocumentsCommandActions.downloadComments,
    uploadDocuments: typeof DocumentsCommandActions.uploadDocuments,
}
class SupplierPublish extends React.Component<SupplierPublishProps & RouteComponentProps> {
    constructor(props: SupplierPublishProps) {
        super(props);   
    }

   

    getFilters = (): Filters => {
        const { filters } = this.props;
        return filters;
    }

    getFilterPanel = (): boolean => {
        const { filterPanelOpened } = this.props;
        return filterPanelOpened;
    }

    onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { fetchDocuments, setSortColumn } = this.props; 
        setSortColumn({colName: column.fieldName as string, direction: (column.isSortedDescending) ? 'asc' : 'desc', feature: FEATURE});
        this.selection.setAllSelected(false);    
        fetchDocuments({});
    };

    selection = new Selection({		
		onSelectionChanged: () => {
            let rows = this.selection.getSelection() as any;
            const {setSelectedItemsIds} = this.props;
            let ids = (rows) ? _.map(rows, (r: Document) => {return r.id as number}) : [];
            setSelectedItemsIds({ids: _.map(ids, id => id.toString()), feature: FEATURE}) 
		}
    });

    render(){
        const {loading, loadingContent, showNotification, type, message, filterPanelOpened, orderColumn, loadingScroll, userProjectActions, history, changeStatusForm, documents, notificationWidth, t, email, darkModeEnabled,
               uploadFileForm, hasPartner, daysToApproval, selectedProjectid, getDocumentAndOpenModal, showOrHideCallout, openGenericModal, downloadOrShowFile, changeStatus, closeGenericModal, downloadComments, uploadDocuments} = this.props;
        const styles = getClassNames(filterPanelOpened);
        const historyName = getHistoryName(history);
        const canRead = _.find(userProjectActions, a => a === 'ReadPublishMaterial') ? true : false;
        const columns = getColumns(selectedProjectid || 0, email as string, t, canRead, (isExternalApp) ? true : false, this.selection, this.getFilterPanel, getDocumentAndOpenModal, this.getFilters, showOrHideCallout, downloadOrShowFile, this.onColumnClick, orderColumn, historyName, openGenericModal, undefined, undefined, _.some(userProjectActions, a => a === 'FilterDocumentsByPartnerId'), getTheme(darkModeEnabled), hasPartner, daysToApproval);
        return (
            <>
                <CommandBar userProjectActions={userProjectActions} loading={loading} loadingContent={loadingContent} loadingScroll={loadingScroll}/>
                <div style={{position: 'absolute', width: notificationWidth, top: 99, zIndex: 10}}>
                    {!loading && !loadingContent && <Notification name={'publishMaterial'} show={showNotification} type={type} message={message}/>}
                </div>
                {!loading && <SelectedFilters selection={this.selection}/>}
                <ProgressIndicator styles={{root: {height: 10}}} progressHidden={ !loadingContent || loading }/> 
                {loading && <Spinner size={SpinnerSize.large} style={{marginTop: 50}}></Spinner>}
                {!loading &&
                    <div className={styles.resultContainer}>
                        <DocumentsGrid selection={this.selection} columns={columns} loadingScroll={loadingScroll}/>
                        <DocumentModal userProjectActions={userProjectActions}/>
                    </div>
                }
                <CalloutFilters/>
                <GenericModal 
                    id={'changeStatus'}
                    title={t('Change Status')}
                    message={''}                 
                    onClose={() => {}}
                    btnCloseText={t('Cancel')}
                    onConfirm={() => {changeStatus({documents})}}
                    btnConfirmText={t('Save')}
                    customComponent={<ChangeStatus loadingContent={loadingContent}/>}
                    width={640}
                    disableConfirm={!changeStatusForm || (changeStatusForm.newStatus && (changeStatusForm.newStatus.name === 'approved-comments' || changeStatusForm.newStatus.name === 'declined-comments') && (!changeStatusForm.newStatus.comment && (!changeStatusForm.files || changeStatusForm.files.length === 0)))}
                />
                { uploadFileForm &&
                    <GenericModal 
                        id={'uploadDocuments'}
                        title={t('Replace file')}
                        message={''}                 
                        onClose={() => {closeGenericModal({id: 'uploadDocuments'})}}
                        btnCloseText={t('Cancel')}
                        btnConfirmText={t('Save')}
                        onConfirm={( )=> {uploadDocuments({})}}
                        disableConfirm={loadingContent || !uploadFileForm || !uploadFileForm.files || uploadFileForm.files.length === 0}
                        customComponent={<UploadFiles loadingContent={loadingContent} canUploadAttachments={false} canUploadFileComments={false}/>}
                        disableAutoModalClose 
                        disableClose={loadingContent}   
                        width={640}           
                    />
                }
                <GenericModal 
                    id={'comments'}
                    title={t('Download comments')}
                    message={''}                 
                    onClose={() => {}}
                    onConfirm={() => {downloadComments()}}
                    btnConfirmText={t('Download')}
                    btnCloseText={t('Cancel')}   
                    customComponent={<DownloadComments/>}
                    disableConfirm={loadingContent}     
                />
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    loading: (state) => getLoader('publishMaterial')(state).loading,
    loadingContent: (state) => getLoader('publishMaterial')(state).loadingContent,
    loadingScroll: (state) => getLoader('publishMaterial')(state).loadingScroll,
    showNotification: (state) => getNotification('publishMaterial')(state).show,
    type: (state) => getNotification('publishMaterial')(state).type,
    message: (state) => getNotification('publishMaterial')(state).message,
    filterPanelOpened: getFilterPanelOpened,
    userProjectActions: getSelectedProjectUserActions,
    filters: getFilters,
    changeStatusForm: (state) => (getGenericModal('changeStatus')(state)) ? (getGenericModal('changeStatus')(state) as any).contextItem : undefined,
    orderColumn: getOrderColumn,
    documents : getContextualDocuments,
    uploadFileForm: (state) => (getGenericModal('uploadDocuments')(state)) ? (getGenericModal('uploadDocuments')(state) as any).contextItem : undefined,
    notificationWidth: getNotificationWidth,
    email: getEmail,
    darkModeEnabled: getDarkModeEnabled,
    hasPartner: getHasPartner,
    daysToApproval: getSelectedProjectDaysToApproval,
    selectedProjectid: getSelectedProjectId
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setSelectedItemsIds: DocumentActions.setSelectedItemsIds,
        getDocumentAndOpenModal: DocumentCommandActions.getDocumentAndOpenModal,
        showOrHideCallout: FiltersCommandActions.showOrHideCallout,
        openGenericModal: ModalActions.CommandActions.openModal,
        downloadOrShowFile: DocumentsCommandActions.downloadOrShowFile,
        changeStatus: DocumentsCommandActions.changeStatus,
        fetchDocuments: DocumentsCommandActions.fetchDocuments,
        setSortColumn: DocumentDocumentsActions.setSortColumn,
        setData: DocumentDocumentsActions.setData,
        closeGenericModal: ModalActions.CommandActions.closeModal,
        downloadComments: DocumentsCommandActions.downloadComments,
        uploadDocuments: DocumentsCommandActions.uploadDocuments,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(SupplierPublish)));