import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from './index.classNames';
import { DefaultButton} from '@fluentui/react';
import { Text } from '@fluentui/react';
import { ProgressIndicator } from '@fluentui/react';
import { IContextualMenuItem, IContextualMenuProps, MessageBarType, Spinner, SpinnerSize } from '@fluentui/react';
import { Panel, PanelType, IPanelProps } from '@fluentui/react';
import { IRenderFunction } from '@fluentui/react';
import ScrollablePaneHistory from '../../../components/scrollablePaneHistory/index';
import Notification from '../../../containers/notification/index';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getNotification } from '../../../redux/selectors/notification';
import { getLoader } from '../../../redux/selectors/loader';
import _ from 'lodash';
import Configurations from './content/configuration';
import General from './content/body';
import EEO from './content/eeo';
import Purposes from './content/purposes';
import Stakeholders from './content/stakeHolders';
import { CommandActions } from '../../../redux/actions/project';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { getHistoryName } from '../../../utils/functions';
import { ProjectForm } from '../../../models/project';
import { getProjectForm, getModalOpened } from '../../../redux/reducers/project';
import { MainCompGenerator as GenericModalGenerator, Actions as GenericModalActions } from '@pwbapps/genericdialog'
import NewStakeholder from './functionalities/newStakeHolder';
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";
import { StakeHolder } from '../../../models/stakeHolder';

const GenericModal = GenericModalGenerator();

export interface ModalProps extends RouteComponentProps {
    modalOpened: boolean,
    projectForm?: ProjectForm,
    showNotification: boolean, 
    type: MessageBarType, 
    message: string, 
    loading: boolean,
    stakeHolderForm?: StakeHolder,
    openOrCloseProjectModal: typeof CommandActions.openOrCloseProjectModal,
    openGenericModal: typeof ModalActions.CommandActions.openModal,
    closeGenericModal: typeof ModalActions.CommandActions.closeModal,
    saveProject: typeof CommandActions.saveProject,
    addStakeHolder: typeof CommandActions.addStakeHolder,
}

class ModalDocument extends React.Component<ModalProps> {
    closeModal = (e?: any) => {
        const { openOrCloseProjectModal } = this.props;
        if(e === true || e.type === 'click')
            openOrCloseProjectModal({open: false});
    }

    onRenderNavigationContent: IRenderFunction<IPanelProps> = ((props, defaultRender) => {
        //const { document } = this.props;
       
        return (      
        <>    
            <div style={{display: 'flex', marginLeft: 10, marginTop: 24}}>
                {/* <Text variant='xLarge' nowrap styles={{root: {marginTop: 9, marginLeft: 5}}}>{(document) ? (document.documentNumber) : 'Document'}</Text> */}
            </div> 
            {// This custom navigation still renders the close button (defaultRender).
            // If you don't use defaultRender, be sure to provide some other way to close the panel.
            defaultRender!(props)}
        </>
        )
    });
  
    onRenderFooterContent = () => {
        const { loading, history, projectForm, saveProject} = this.props;
       
        return (
            <div style={{display: 'flex', gap: 15}}>
                    <>
                        { projectForm &&
                            <DefaultButton
                                primary        
                                text="Save" 
                                onClick={() => saveProject()} 
                                disabled={loading || !projectForm.projectId || !projectForm.projectName || !projectForm.template}
                            />       
                        }
                    </>
                <DefaultButton disabled={loading} onClick={() => this.closeModal(true)} text="Close" />
            </div>
        );
    };


    
    render() {
        const styles = getClassNames();
        const { modalOpened, showNotification, message, type, loading, history, projectForm, stakeHolderForm, closeGenericModal, addStakeHolder } = this.props; 
        const historyName = getHistoryName(history);
        return modalOpened && projectForm && (
            <>
                <Panel
                    isOpen={modalOpened}
                    onDismiss={(e: any) => this.closeModal(e)} 
                    hasCloseButton={!loading}
                    type={PanelType.large}
                    closeButtonAriaLabel="Close"
                    // headerText={title}
                    styles={{navigation: styles.header, content: styles.content, scrollableContent: styles.scrollableContent, footer: styles.footer, contentInner: styles.contentInner, closeButton: {height: 40} }}
                    onRenderNavigationContent={this.onRenderNavigationContent}
                    onRenderFooterContent={this.onRenderFooterContent}
                >
        
                <ProgressIndicator progressHidden={ !loading }/>
                
                <ScrollablePaneHistory className={styles.notifyHeader} items={[ 
                    { text: "General", body: <div className={styles.tabContainer}><General/></div> },
                    { text: "Configurations", body: <div className={styles.tabContainer}><Configurations/></div> },
                    { text: "EEO", body: <div className={styles.tabContainer}><EEO/></div> },
                    { text: "Purposes", body: <div className={styles.tabContainer}><Purposes/></div> },
                    { text: "Stakeholders", body: <div className={styles.tabContainer}><Stakeholders/></div> },
                ]} />
                
                {stakeHolderForm && 
                    <GenericModal 
                        id={'newStakeHolder'}
                        title={(stakeHolderForm && !stakeHolderForm.stakeHolderId) ? 'New Stakeholder' : 'Edit Stakeholder'}
                        message={''}        
                        onClose={() => {closeGenericModal({id: 'newStakeHolder'})}}
                        onConfirm={() => {addStakeHolder()}}
                        btnCloseText={'Cancel'}   
                        btnConfirmText={(stakeHolderForm && !stakeHolderForm.stakeHolderId) ? 'Add' : 'Edit'}   
                        customComponent={<NewStakeholder/>}        
                        disableAutoModalClose
                    />
                }

                </Panel>
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    loading: (state) => getLoader('project')(state).loadingContent,
    showNotification: (state) => getNotification('project')(state).show,
	type: (state) => getNotification('project')(state).type,
    message: (state) => getNotification('project')(state).message,
    modalOpened: getModalOpened,
    projectForm: getProjectForm,
    stakeHolderForm: (state) => (getGenericModal('newStakeHolder')(state)) ? (getGenericModal('newStakeHolder')(state) as any).contextItem : undefined,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        openOrCloseProjectModal: CommandActions.openOrCloseProjectModal,
        saveProject: CommandActions.saveProject,
        addStakeHolder: CommandActions.addStakeHolder,
        openGenericModal: ModalActions.CommandActions.openModal,
        closeGenericModal: ModalActions.CommandActions.closeModal,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withRouter(ModalDocument));
