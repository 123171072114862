import {createAction, ActionsUnion} from '@pwbapps/reduxcore';
import {AppUser, ExternalUserForm, Role} from '../../models/administration';
import { PermissionElement, Permissions } from '../../models/permissions';

export const SET_INTERNAL_USER_FIELD_VALUE = `[ADMINISTRATION_MODAL] SET INTERNAL USER FIELD VALUE`;
export const SET_EXTERNAL_USER_FIELD_VALUE = `[ADMINISTRATION_MODAL] SET EXTERNAL USER FIELD VALUE`;
export const SET_USERS_ARRAY = `[ADMINISTRATION_MODAL] SET USERS ARRAY`;
export const RESET_AND_CLOSE_MODAL = `[ADMINISTRATION_MODAL] RESET AND CLOSE MODAL`;
export const FETCH_INTERNAL_USERS = `[ADMINISTRATION_MODAL] FETCH INTERNAL USERS`;
export const FETCH_USERS = `[ADMINISTRATION_MODAL] FETCH USERS`;
export const FETCH_EXTERNAL_USERS = `[ADMINISTRATION_MODAL] FETCH EXTERNAL USERS`;
export const FETCH_INTERNAL_ROLES = `[ADMINISTRATION_MODAL] FETCH INTERNAL ROLES`;
export const FETCH_EXTERNAL_ROLES = `[ADMINISTRATION_MODAL] FETCH EXTERNAL ROLES`;
export const SET_OPEN_MODAL = `[ADMINISTRATION_MODAL] SET OPEN MODAL`;
export const SAVE_USERS = `[ADMINISTRATION_MODAL] SAVE USERS`;
export const SAVE_INTERNAL_USERS = `[ADMINISTRATION_MODAL] SAVE INTERNAL USERS`;
export const REMOVE_INTERNAL_USERS = `[ADMINISTRATION_MODAL] REMOVE INTERNAL USERS`;
export const SAVE_EXTERNAL_USERS = `[ADMINISTRATION_MODAL] SAVE EXTERNAL USERS`;
export const REMOVE_EXTERNAL_USERS = `[ADMINISTRATION_MODAL] REMOVE EXTERNAL USERS`;
export const SAVE_NEW_EXTERNAL_USER= `[ADMINISTRATION_MODAL] SAVE NEW EXTERNAL USER`;
export const SET_VALUE = `[ADMINISTRATION_MODAL] SET VALUE`;
export const DOWNLOAD_USERS = `[ADMINISTRATION_MODAL] DOWNLOAD USERS`;
export const CREATE_PROJECT_ROLES = `[ADMINISTRATION_MODAL] CREATE PROJECT ROLES`;
export const GET_STAKEHOLDER_IDS = `[ADMINISTRATION_MODAL] GET STAKEHOLDER IDS`;
export const CHECK_EXTERNAL_USER = `[ADMINISTRATION_MODAL] CHECK EXTERNAL USER`;
export const SET_PERMISSIONS_FORM_AND_OPEN_MODAL = `[ADMINISTRATION_MODAL] SET PERMISSIONS FORM AND OPEN MODAL`;
export const SAVE_PERMISSIONS = `[ADMINISTRATION_MODAL] SAVE PERMISSIONS`;
export const ADD_GLOBAL_PERMISSION = `[ADMINISTRATION_MODAL] ADD GLOBAL PERMISSION`;
export const DELETE_PERMISSIONS = `[ADMINISTRATION_MODAL] DELETE PERMISSIONS`;
export const REMOVE_NOTIFICATIONS_RELATED_TO_PERMISSIONS = `[ADMINISTRATION_MODAL] REMOVE NOTIFICATIONS RELATED TO PERMISSIONS`;
export const ADD_NOTIFICATIONS_RELATED_TO_PERMISSIONS = `[ADMINISTRATION_MODAL] ADD NOTIFICATIONS RELATED TO PERMISSIONS`;

export const AdministrationCommandActions = {
    setOpenModal: ({value} : {value: boolean}) => createAction(SET_VALUE, {name: 'openModal', value}),
    setInternalFilter: ({value} : {value?: string}) => createAction(SET_VALUE, {name: 'internalFilter', value}),
    setExternalFilter: ({value} : {value?: string}) => createAction(SET_VALUE, {name: 'externalFilter', value}),
    setInternalUsers: ({value} : {value?: AppUser[]}) => createAction(SET_VALUE, {name: 'internalAppUsers', value}),
    setExternalUsers: ({value} : {value?: AppUser[]}) => createAction(SET_VALUE, {name: 'externalAppUsers', value}),
    setExternalEditUsers: ({value} : {value?: AppUser[]}) => createAction(SET_VALUE, {name: 'externalEditedUsers', value}),
    setInternalRoles: ({value} : {value: Role[]}) => createAction(SET_VALUE, {name: 'internalAppRoles', value}),
    setExternalRoles: ({value} : {value: Role[]}) => createAction(SET_VALUE, {name: 'externalAppRoles', value}),
    fetchUsers: ({openModal}: {openModal?: boolean}) => createAction(FETCH_USERS, {openModal}),
    fetchExternalUsers: () => createAction(FETCH_EXTERNAL_USERS),
    fetchInternalUsers: () => createAction(FETCH_INTERNAL_USERS),
    fetchInternalRoles: () => createAction(FETCH_INTERNAL_ROLES),
    fetchExternalRoles: () => createAction(FETCH_EXTERNAL_ROLES),
    saveUsers: () => createAction(SAVE_USERS),
    saveInternalUsers: ({editedUsers}: {editedUsers: AppUser[]}) => createAction(SAVE_INTERNAL_USERS, {editedUsers}),
    removeInternalUsers: ({editedUsers}: {editedUsers: AppUser[]}) => createAction(REMOVE_INTERNAL_USERS, {editedUsers}),
    saveExternalUsers:  ({editedUsers, dontReload}: {editedUsers: AppUser[], dontReload?: boolean}) => createAction(SAVE_EXTERNAL_USERS, {editedUsers, dontReload}),
    resetAndCloseModal: () => createAction(RESET_AND_CLOSE_MODAL),
    saveNewExternalUser: () => createAction(SAVE_NEW_EXTERNAL_USER),
    removeExternalUsers: ({editedUsers}: {editedUsers: AppUser[]}) => createAction(REMOVE_EXTERNAL_USERS, {editedUsers}),
    downloadUsers: () => createAction(DOWNLOAD_USERS),
    createProjectRoles: ({projectId}: {projectId: number}) => createAction(CREATE_PROJECT_ROLES, {projectId}),
    getStakeHolderIds: ({companies, updateEditUsers}: {companies: string[], updateEditUsers?: boolean}) => createAction(GET_STAKEHOLDER_IDS, {companies, updateEditUsers}),
    checkExternalUser: ({newUserForm}: {newUserForm: ExternalUserForm}) => createAction(CHECK_EXTERNAL_USER, {newUserForm}),
    setPermissionsFormAndOpenModal: ({email, permissionElements, stakeHolderId}: {email: string, permissionElements: PermissionElement[], stakeHolderId: number}) => createAction(SET_PERMISSIONS_FORM_AND_OPEN_MODAL, {email, permissionElements, stakeHolderId}),
    savePermissions: () => createAction(SAVE_PERMISSIONS),
    addGlobalPermission: ({email}: {email: string}) => createAction(ADD_GLOBAL_PERMISSION, {email}),
    deletePermissions: () => createAction(DELETE_PERMISSIONS),
}

export const AdministrationDocumentActions = {
    setInternalUserFieldValue: ({email, name, value}: {email: string, name: string, value: any}) => createAction(SET_INTERNAL_USER_FIELD_VALUE, {email: email, name: name, value: value}),
    setExternalUserFieldValue: ({email, name, value}: {email: string, name: string, value: any}) => createAction(SET_EXTERNAL_USER_FIELD_VALUE, {email: email, name: name, value: value}),
    setUsersArray: ({arrayName, users, cursor, totalCount, adding}: {arrayName: string, users: AppUser[], cursor?: number | null, totalCount?: number, adding?: boolean}) => createAction(SET_USERS_ARRAY, {arrayName: arrayName, users: users, cursor: cursor, totalCount: totalCount, adding: adding}),
    setOpenModal: ({openModal} : {openModal: boolean}) => createAction(SET_OPEN_MODAL, {openModal}),
    setValue: ({name, value} : {name: string, value?: string}) => createAction(SET_VALUE, {name, value}),
}


export const CommandActions = {...AdministrationCommandActions};
export const DocumentActions = {...AdministrationDocumentActions};
export const Actions = {...CommandActions, ...DocumentActions};

// group actions
export type CommandActions = ActionsUnion<typeof CommandActions>
export type DocumentActions = ActionsUnion<typeof DocumentActions>
export type Actions = ActionsUnion<typeof Actions>