import { mergeStyleSets } from '@fluentui/react';
import { FontWeights } from '@fluentui/react';

export interface IComponentClassNames {
    panel: string,
    scrollableContent: string,
    header: string,
    headerLabel: string,
    
    dateRangeInputCont: string,
    dateRangeDayPickCont: string,
    iconClickable: string
}
  
export const getClassNames = (theme: any): IComponentClassNames => {
    return mergeStyleSets({
        panel:{
            top: 48
        },
        scrollableContent: {
            flexGrow: 1,
            overflowY: 'hidden'
        },
        header:{
            justifyContent: 'space-between',
            alignItems: 'stretch', 
            paddingLeft: 15,
            paddingRight: 15 
        },
        headerLabel: {
            alignSelf: 'center'
        },
        
        dateRangeInputCont: {
            selectors: {
                'div': {
                    border: 'none'
                }
            }
        },
        dateRangeDayPickCont: {
            fontSize: "14px",
            fontWeight: "400",
            boxShadow: "none",
            marginTop: "20px",
            marginRight: "0px",
            marginBottom: "10px",
            marginLeft: "0px",
            paddingTop: "1px",
            paddingRight: "0px",
            paddingBottom: "1px",
            paddingLeft: "0px",
            boxSizing: "border-box",
            color: "rgb(50, 49, 48)",
            backgroundColor: "rgb(255, 255, 255)",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "stretch",
            height: "32px",
            borderRadius: "0px",
            borderStyle: "solid !important",
            borderColor: "rgb(138, 136, 134) !important",
            // borderImage: "initial",
            borderWidth: "0px 0px 1px !important",

            selectors: {
                'input': {
                    paddingLeft: '8px',
                    border: 'none',
                    width: '100%'
                }
            }
        },
        iconClickable: {
            color: theme.palette.themePrimary,
            fontSize: 36,
            fontWeight: FontWeights.regular,
            cursor: 'pointer',
            marginTop: 6
        },
    });
};