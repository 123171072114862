import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from '../index.classNames';
import { Checkbox, Label, Text, TextField} from '@fluentui/react';
import _ from 'lodash';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { CommandActions as ProjectCommandActions } from '../../../../redux/actions/project';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getAllProjects, getIsEdit, getProjectFormAutomaticApprovalDays, getProjectFormDaysToApproval, getProjectFormId, getProjectFormIsActiveInAgile, getProjectFormIsAgileTdCoverPageInternalUse, getProjectFormIsApproveWithCommentsStatusActive, getProjectFormIsClientNumGeneratorPageActive, getProjectFormIsEnabled, getProjectFormIsExternalCheckActive, getProjectFormIsFolderPageActive, getProjectFormIsInternalCheckActive, getProjectFormName } from '../../../../redux/reducers/project';
import { Project } from '../../../../models/project';
import { NumericFormat } from 'react-number-format';

export interface BodyProps extends RouteComponentProps { 
    isFolderPageActive: boolean,
    isClientNumGeneratorPageActive: boolean,
    isInternalCheckActive: boolean,
    isExternalCheckActive: boolean,
    isApproveWithCommentsStatusActive: boolean,
    isAgileTdCoverPageInternalUse: boolean,
    automaticApprovalDays?: number,
    allProjects: Project[],
    isEdit: boolean,
    daysToApproval: number,
    setProjectFormIsFolderPageActive: typeof ProjectCommandActions.setProjectFormIsFolderPageActive,
    setProjectFormIsClientNumGeneratorPageActive: typeof ProjectCommandActions.setProjectFormIsClientNumGeneratorPageActive,
    setProjectFormIsInternalCheckActive: typeof ProjectCommandActions.setProjectFormIsInternalCheckActive,
    setProjectFormIsExternalCheckActive: typeof ProjectCommandActions.setProjectFormIsExternalCheckActive,
    setProjectFormIsApproveWithCommentsStatusActive: typeof ProjectCommandActions.setProjectFormIsApproveWithCommentsStatusActive,
    setProjectFormIsAgileTdCoverPageInternalUse: typeof ProjectCommandActions.setProjectFormIsAgileTdCoverPageInternalUse,
    setProjectFormAutomaticApprovalDays: typeof ProjectCommandActions.setProjectFormAutomaticApprovalDays,
    setProjectDaysToApproval: typeof ProjectCommandActions.setProjectDaysToApproval
}

class Body extends React.Component<BodyProps> {
    constructor(props: BodyProps) {
        super(props); 
    }


    render(){
        const styles = getClassNames();
        const { isFolderPageActive, isClientNumGeneratorPageActive, isInternalCheckActive, isExternalCheckActive, isApproveWithCommentsStatusActive, isAgileTdCoverPageInternalUse, automaticApprovalDays, daysToApproval,
                setProjectFormAutomaticApprovalDays, setProjectFormIsAgileTdCoverPageInternalUse, setProjectFormIsApproveWithCommentsStatusActive, setProjectFormIsClientNumGeneratorPageActive, setProjectFormIsExternalCheckActive,
                setProjectFormIsFolderPageActive, setProjectFormIsInternalCheckActive, setProjectDaysToApproval } = this.props;
        return ( 
            <>         
                <div className={styles.tabContainer}>    
                    <div style={{marginBottom: 30}}><Text variant='xLarge' nowrap >Configurations</Text></div>   
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: 10, alignItems: 'center'}}>
                        <NumericFormat
                            label={'Automatic approval days'}
                            customInput={TextField}
                            decimalScale={0}
                            value={(automaticApprovalDays) ? automaticApprovalDays : 0}
                            autoComplete={'off'}
                            onValueChange={(values) => { setProjectFormAutomaticApprovalDays({value: values.floatValue})}}
                            styles={{root: {minWidth: 256}}}
                        />     
                        <NumericFormat
                            label={'Days to approval'}
                            customInput={TextField}
                            decimalScale={0}
                            value={(daysToApproval) ? daysToApproval : 0}
                            autoComplete={'off'}
                            onValueChange={(values) => { setProjectDaysToApproval({value: values.floatValue})}}
                            styles={{root: {minWidth: 256}}}
                        />     
                    </div>     
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: 10, alignItems: 'center'}}>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 28, marginRight: 15}}>
                            <Checkbox 
                                checked={(isFolderPageActive) ? true : false} 
                                onChange={(e, value) => { setProjectFormIsFolderPageActive({value: (value) ? true : false})}} 
                            />  
                            <Label styles={{root: {marginLeft: 5}}}>Is folder page active</Label>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 28, marginRight: 15}}>
                            <Checkbox 
                                checked={(isClientNumGeneratorPageActive) ? true : false} 
                                onChange={(e, value) => { setProjectFormIsClientNumGeneratorPageActive({value: (value) ? true : false})}} 
                            />  
                            <Label styles={{root: {marginLeft: 5}}}>Is client numb. generator page active</Label>
                        </div> 
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 28, marginRight: 15}}>
                            <Checkbox 
                                checked={(isInternalCheckActive) ? true : false} 
                                onChange={(e, value) => { setProjectFormIsInternalCheckActive({value: (value) ? true : false})}} 
                            />  
                            <Label styles={{root: {marginLeft: 5}}}>Is internal check active</Label>
                        </div>  
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 28, marginRight: 15}}>
                            <Checkbox 
                                checked={(isExternalCheckActive) ? true : false} 
                                onChange={(e, value) => { setProjectFormIsExternalCheckActive({value: (value) ? true : false})}} 
                            />  
                            <Label styles={{root: {marginLeft: 5}}}>Is external check active</Label>
                        </div>  
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 28, marginRight: 15}}>
                            <Checkbox 
                                checked={(isApproveWithCommentsStatusActive) ? true : false} 
                                onChange={(e, value) => { setProjectFormIsApproveWithCommentsStatusActive({value: (value) ? true : false})}} 
                            />  
                            <Label styles={{root: {marginLeft: 5}}}>Is approve with comments status active</Label>
                        </div> 
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 28, marginRight: 15}}>
                            <Checkbox 
                                checked={(isAgileTdCoverPageInternalUse) ? true : false} 
                                onChange={(e, value) => { setProjectFormIsAgileTdCoverPageInternalUse({value: (value) ? true : false})}} 
                            />  
                            <Label styles={{root: {marginLeft: 5}}}>Is agile Td cover page internal use</Label>
                        </div>  
                    </div>                  
                </div>                                                                                                                 
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    allProjects: getAllProjects,
    isEdit: getIsEdit,
    isFolderPageActive: getProjectFormIsFolderPageActive,
    isClientNumGeneratorPageActive: getProjectFormIsClientNumGeneratorPageActive,
    isInternalCheckActive: getProjectFormIsInternalCheckActive,
    isExternalCheckActive: getProjectFormIsExternalCheckActive,
    isApproveWithCommentsStatusActive: getProjectFormIsApproveWithCommentsStatusActive,
    isAgileTdCoverPageInternalUse: getProjectFormIsAgileTdCoverPageInternalUse,
    automaticApprovalDays: getProjectFormAutomaticApprovalDays,
    daysToApproval: getProjectFormDaysToApproval
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        setProjectFormIsFolderPageActive: ProjectCommandActions.setProjectFormIsFolderPageActive,
        setProjectFormIsClientNumGeneratorPageActive: ProjectCommandActions.setProjectFormIsClientNumGeneratorPageActive,
        setProjectFormIsInternalCheckActive: ProjectCommandActions.setProjectFormIsInternalCheckActive,
        setProjectFormIsExternalCheckActive: ProjectCommandActions.setProjectFormIsExternalCheckActive,
        setProjectFormIsApproveWithCommentsStatusActive: ProjectCommandActions.setProjectFormIsApproveWithCommentsStatusActive,
        setProjectFormIsAgileTdCoverPageInternalUse: ProjectCommandActions.setProjectFormIsAgileTdCoverPageInternalUse,
        setProjectFormAutomaticApprovalDays: ProjectCommandActions.setProjectFormAutomaticApprovalDays,
        setProjectDaysToApproval: ProjectCommandActions.setProjectDaysToApproval
     }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Body));