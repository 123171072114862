import React from 'react';
import { IColumn } from '@fluentui/react';
import { getId } from '@fluentui/react';
import _ from 'lodash';
import { PermissionElement, Permissions } from '../../models/permissions';

const _hostId: string = getId('tooltipHost');

export const getColumns = (permissions: Permissions[], email: string, canCheckOtherPermissions: boolean) => {

      const otherPermissions = _.filter(permissions, (p: Permissions) => p.email !== email);
 
      const columns: IColumn[] = [
        {
          key: 'pbs',
          name: 'PBS',
          fieldName: 'pbs',
          minWidth: 220,
          maxWidth: 220,
          isResizable: true,
          data: 'string',
         //  onColumnClick: (handleClick) ? handleClick : () => {},
          isPadded: true,        
          // isSorted: (orderedColumn && orderedColumn.name === 'name') ? true : false,
          // isSortedDescending: (orderedColumn && orderedColumn.name === 'name' && orderedColumn.type === 'desc') ? true : false,
        },
        {
          key: 'discipline',
          name: 'Discipline',
          fieldName: 'discipline',
          minWidth: 260,
          maxWidth: 260,
          isResizable: true,
        //  onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: true,
          onRender: (item: PermissionElement) => {
            return(
              <>
                {item.disciplineCode && <div>{item.disciplineCode + '-' + item.disciplineName}</div>}
              </>
            );
          }
          
        },
        {
          key: 'canWrite',
          name: 'Can write',
          fieldName: 'canWrite',
          minWidth: 70,
          maxWidth: 70,
          isResizable: true,
        //  onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: true,
          onRender: (item: PermissionElement) => {
            return(
              <>
                {item.canWrite === true && <div> Yes</div>}
                {item.canWrite === false && <div> No</div>}
              </>
            );
          }
        },
        ...(canCheckOtherPermissions ? [{
          key: 'others',
          name: 'Other Users',
          fieldName: 'others',
          minWidth: 110,
          maxWidth: 110,
          isResizable: true,
        //  onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: true,
          onRender: (item: PermissionElement) => {
            let otherEmailRows: string[] = [];
            _.forEach(otherPermissions, op => {
              let rows = _.filter(op.permissions, (p: PermissionElement) => p.pbs === item.pbs && p.disciplineCode === item.disciplineCode);
              otherEmailRows = (rows.length > 0) ? [...otherEmailRows, op.email as string] : otherEmailRows;
            });
            return(
              <>
                <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
                    {_.map(otherEmailRows, e => {return (<div>{e}</div>)})}
                </div>
              </>
            );
          }        
        }] : []),   

    ];

    return columns;
}




