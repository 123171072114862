import { mergeStyleSets } from '@fluentui/react';

export interface IComponentClassNames {
    toggle: string,
    rowContainer: string,
    inputWithMargin: string,
    input: string,
    tabContainer: string,
    sipContainer: string,
    wrapper: string

}
  
export const getClassNames = (): IComponentClassNames => {
    return mergeStyleSets({
        toggle: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        rowContainer: {
            display: 'flex',
            flexWrap: 'wrap'           
        },
        sipContainer: {
            display: 'flex',     
        },
        inputWithMargin: {
            minWidth: 120,
            marginRight: 20
        },
        input: {
            minWidth: 180,
            maxWidth: 480
        },
        tabContainer: {
            //display: 'flex',
            //flexDirection: 'column',
            paddingLeft: 60,
            paddingRight: 'calc(8%)'//360
        },
        wrapper: {
            height: '30vh',
            position: 'relative',
        },
    });
};