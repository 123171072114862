import { mergeStyleSets } from '@fluentui/react';

export interface IComponentClassNames {
    container: string,
    flexContainer: string,
    buttonsContainer: string,
    button: string,
    formContainer: string,
    textBox: string,
    notification: string
}
  
export const getClassNames = (): IComponentClassNames => {
    return mergeStyleSets({
        container: {
            marginTop: 15,
            marginLeft: 15
        },
        flexContainer: {
            textAlign: 'left'
        },
        buttonsContainer: {
            marginTop: 20
        },
        button: {
            marginBottom: 20,
            width: 205
        },
        formContainer: {
            marginTop: 15,
        },
        textBox: {
            marginTop: 15,
            marginRight: 15,
        },
        notification: {
            marginRight: 15,
            marginBottom: 10
        }
    });
};