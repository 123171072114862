import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {DocumentActions, FEATURE} from '../../redux/actions/documents';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Selection, Text, FontIcon} from '@fluentui/react';
import _ from 'lodash';
import { getDocuments, getFetchGuid, getSelectedItemsIds, getSkip, getTotal } from '../../redux/reducers/documents';
import { Document } from '../../models/document';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getDarkModeEnabled } from '../../redux/reducers/project';

export interface DocumentsGridProps extends RouteComponentProps, WithTranslation {
    documents: Document[],
    selectedItemIds: string[],
    fetchGuid: string,
    total: number,
    selection: Selection,
    darkModeEnabled: boolean,
    setSelectedItemsIds: typeof DocumentActions.setSelectedItemsIds,
}

class DocumentsGrid extends React.Component<DocumentsGridProps> {
    constructor(props: DocumentsGridProps) {
        super(props);   
    }

    componentDidUpdate = (previousProps: DocumentsGridProps) => {
        const { fetchGuid, setSelectedItemsIds, selection } = this.props;
        if(fetchGuid !== previousProps.fetchGuid){
            setSelectedItemsIds({ids: [], feature: FEATURE});
            selection.setAllSelected(false);
        }
    }
    componentDidMount = () => {
        const { setSelectedItemsIds, selection } = this.props;
        setSelectedItemsIds({ids: [], feature: FEATURE});
        selection.setAllSelected(false);    
    }

 
    resetSelection = () => {
        const {setSelectedItemsIds, selection} = this.props;
        setSelectedItemsIds({ids: [], feature: FEATURE});
        selection.setAllSelected(false);
    }

    render(){
        const {documents, total, selectedItemIds, darkModeEnabled, t} = this.props;
        return (
            <>
                {documents && 
                    <div style={{display: 'flex', alignItems: 'center', gap: 10,}}>
                        <Text variant='large' style={{marginLeft: 20, color: (darkModeEnabled) ? '#f7f7f7' : undefined}}>
                            {documents.length + '/' + total + ' documents' + ((selectedItemIds.length > 0) ? (' (' + selectedItemIds.length + ' selected)') : '') }
                        </Text>
                        {selectedItemIds.length > 0 && <FontIcon iconName={"Cancel"} style={{cursor: "pointer", color: 'red', marginTop: 2 }}  onClick={() => {this.resetSelection()}}/> }
                    </div>
                }            
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    documents: getDocuments,
    selectedItemIds: getSelectedItemsIds,
    fetchGuid: getFetchGuid,
    total: getTotal,
    skip: getSkip,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setSelectedItemsIds: DocumentActions.setSelectedItemsIds,       
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(DocumentsGrid)));