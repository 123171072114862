import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getFilterPanelOpened } from '../../../redux/reducers/ui';
import { getLoader } from '../../../redux/selectors/loader';
import { Text, Spinner, SpinnerSize } from '@fluentui/react';
import { getClassNames } from '../index.classNames';
import { ResponsivePie, PieDatum } from '@nivo/pie';
import { getFinalPurposesData } from '../../../redux/reducers/main';
import _ from 'lodash';
import { colors } from '../../../utils/costants';
import { FinalPurposesChart } from '../../../models/main';
import ErrorBox from '../../../components/error_box/index';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getDarkModeEnabled } from '../../../redux/reducers/project';

export interface PieProps extends RouteComponentProps, WithTranslation {
    loading: boolean,
    filterPanelOpened: boolean, 
    pieValues?: FinalPurposesChart,
    darkModeEnabled: boolean
}

class Pie extends React.Component<PieProps> {
    constructor(props: PieProps) {
        super(props);   
    }

    getColor = (pie: PieDatum) => this.getColors()[pie.id];

    getColors = (): Record<string, string> => {
        const { pieValues } = this.props;
        let data: Record<string, string> = {};
        let i = 0;
        if(pieValues){
            _.forOwn(pieValues, (element, key) => {
               data = {...data, [element.id] : colors[i]};
               i++;
           });
        }
        return data;
    }

    getPiedata = (): PieDatum[] => {
        const { pieValues } = this.props;
        let data: PieDatum[] = [];
        if(pieValues){
            _.forOwn(pieValues, (element, key) => {
               var datum: PieDatum = {id: element.id, value: element.value};
               data = (datum.value > 0) ? [...data, datum] : data;
           });
        }
        return data;
    }

    render(){
        const {loading, filterPanelOpened, t, darkModeEnabled} = this.props;
        const styles = getClassNames(filterPanelOpened, loading, darkModeEnabled);
        const pieDatums: PieDatum[] = this.getPiedata();
        return (
            <div className={styles.pieBox}>
                {!loading && pieDatums.length > 0 &&
                    <>
                        <Text styles={{root: {}}} style={{color: (darkModeEnabled) ? '#ffffff' : undefined}} variant='medium' nowrap>{t('Final Purposes')}</Text>
                        <Text variant="medium" style={{color: (darkModeEnabled) ? '#ffffff' : undefined}} styles={{root: styles.totalText}}>
                            {_.sumBy(pieDatums, pd => pd.value)}
                        </Text>
                        <ResponsivePie
                            //onClick={this.selectSlice}
                            enableArcLinkLabels={false}
                            arcLabelsTextColor="#FFFFFF"
                            data={pieDatums}
                            margin={{right: 180, bottom: 30, left: 20, top: 10}}
                            innerRadius={0.4}
                            padAngle={0.7}
                            cornerRadius={3}
                            borderWidth={1}
                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                            radialLabelsSkipAngle={10}
                            radialLabelsTextXOffset={6}
                            radialLabelsTextColor="#333333"
                            radialLabelsLinkOffset={0}
                            radialLabelsLinkDiagonalLength={16}
                            radialLabelsLinkHorizontalLength={24}
                            radialLabelsLinkStrokeWidth={1}
                            radialLabelsLinkColor={{ from: 'color' }}
                            slicesLabelsSkipAngle={10}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            colors={this.getColor}
                            legends={[
                                {
                                    //onClick: (slice: any) => {this.selectSlice(slice)},
                                    anchor: 'right',
                                    direction: 'column',
                                    itemWidth: 160,
                                    itemHeight: 16,
                                    itemTextColor: '#999',
                                    itemsSpacing: 8,
                                    symbolSize: 16,
                                    symbolShape: 'circle',
                                    translateX: 170,
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </>
                }
                {!loading && pieDatums.length === 0 &&
                    <>
                        <Text styles={{root: {}}} variant='medium' nowrap>{t('Final Purposes')}</Text>
                        <ErrorBox text='No data found' grey={true} />
                    </>
                }
                {loading && <Spinner size={SpinnerSize.large}/>}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    loading: (state) => getLoader('home')(state).loadingPurpose,
    filterPanelOpened: getFilterPanelOpened,
    pieValues: getFinalPurposesData,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        openModal: ModalActions.CommandActions.openModal
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Pie)));