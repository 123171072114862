import React from 'react';
import { IColumn } from '@fluentui/react';
import {AppUser, Role} from '../../../../models/administration';
import { ComboBox, IComboBoxOption } from '@fluentui/react';
import { getClassNames } from './internal.classNames';
import { FontIcon } from '@fluentui/react';
import { getId } from '@fluentui/react';
import { CommandActions, DocumentActions } from '../../../../redux/actions/administrationModal';
import { getAppRolesDropdownOptions, getNotificationsDropdownOptions, getPossibleExternalRolesByCompany } from '../../../../utils/functions';
import _ from 'lodash';
import { StakeHolder } from '../../../../models/stakeHolder';
import { ExternalRolesType } from '../../../../utils/costants';
import { NotifyRule } from '../../../../models/notifyRule';
import { EventType } from '../../../../models/eventType';
import { Link } from '@fluentui/react';
import { Spinner, SpinnerSize } from '@fluentui/react';

const _hostId: string = getId('tooltipHost');

export const getColumns = (loadingAllPbsDisciplines: boolean, setExternalUserFieldValue: typeof DocumentActions.setExternalUserFieldValue, selectedProjectRoles: Role[], stakeHolders: StakeHolder[], allNotifications: NotifyRule[], eventTypes: EventType[], setPermissionsFormAndOpenModal: typeof CommandActions.setPermissionsFormAndOpenModal, selectedProjectId?: number) => {
      const styles = getClassNames();
    
      const columns: IColumn[] = [
        {
          key: 'column0',
          name: 'Email',
          fieldName: 'email',
          minWidth: 220,
          maxWidth: 220,
          isResizable: true,
          data: 'string',
         //  onColumnClick: (handleClick) ? handleClick : () => {},
          isPadded: true,        
          // isSorted: (orderedColumn && orderedColumn.name === 'name') ? true : false,
          // isSortedDescending: (orderedColumn && orderedColumn.name === 'name' && orderedColumn.type === 'desc') ? true : false,
        },

        {
          key: 'column2',
          name: 'Company',
          fieldName: 'company',
          minWidth: 90,
          maxWidth: 90,
          isResizable: true,
          data: 'string',
         //  onColumnClick: (handleClick) ? handleClick : () => {},
          isPadded: true,        
          // isSorted: (orderedColumn && orderedColumn.name === 'name') ? true : false,
          // isSortedDescending: (orderedColumn && orderedColumn.name === 'name' && orderedColumn.type === 'desc') ? true : false,
        },

          {
            key: 'column1',
            name: 'Role',
            fieldName: 'role',
            minWidth: 220,
            maxWidth: 220,
            isResizable: true,
          //  onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'string',
            isPadded: true,
            onRender: (item: AppUser) => {
              let role_options: IComboBoxOption[] = getAppRolesDropdownOptions(selectedProjectRoles);
              if(stakeHolders && stakeHolders.length > 0 && item.company){
                let acceptedRoles = getPossibleExternalRolesByCompany(item.company as string, stakeHolders, (item.role) ? item.role.name : undefined);
                role_options = _.filter(role_options, ro => acceptedRoles.includes(ro.text as ExternalRolesType) || (ro.text === 'NONE' && (!item.role || item.role.name !== 'NONE')));
              }
              return(
                <ComboBox
                    className={styles.input}
                    options={role_options}
                    placeholder={'Role...'}
                    onChange={(e, value) => {
                      let role = _.find(selectedProjectRoles, r => r.azureName === (value as any).azureName );
                      setExternalUserFieldValue({email: item.email as string, name: 'role', value: {..._.find(selectedProjectRoles, r => r.azureName === (value as any).azureName ), userRoleId: (item.role) ? item.role.userRoleId : undefined}});
                      if(!item.role || (item.role && item.role.name === 'NONE') || (item.role && (!role || role.name === 'NONE'))){
                        setExternalUserFieldValue({
                          email: item.email as string,
                          name: 'notifications', 
                          value: (allNotifications && allNotifications.length > 0 && (!item.role || (item.role && item.role.name === 'NONE'))) ? [...allNotifications] : []
                        })
                      }
                    }}
                    selectedKey={(item.role) ? item.role.id : null}  
                    useComboBoxAsMenuWidth={true}         
                    calloutProps={{calloutMaxHeight: 220}}
                    //text={rootCauses.toString()}          
                />
              );
            }
          },
          {
            key: 'permissions',
            name: 'PBS and Disciplines',
            fieldName: 'permissions',
            minWidth: 140,
            maxWidth: 140,
            isResizable: true,
            data: 'string',
           //  onColumnClick: (handleClick) ? handleClick : () => {},
            isPadded: true,        
            // isSorted: (orderedColumn && orderedColumn.name === 'name') ? true : false,
            // isSortedDescending: (orderedColumn && orderedColumn.name === 'name' && orderedColumn.type === 'desc') ? true : false,
            onRender: (item: AppUser) => {
              let permissions = (item.userPermissions ) ? item.userPermissions : [];
              return(
                <>
                  {/* {!loadingAllPbsDisciplines &&  */}
                    <Link onClick={(e) => {setPermissionsFormAndOpenModal({email: item.email as string, permissionElements: permissions, stakeHolderId: item.stakeHolderId as number})}} underline>
                      {(!permissions || permissions.length === 0) ? "All" : "Details..."}
                    </Link>
                  {/* }
                  {loadingAllPbsDisciplines && 
                    <Spinner size={SpinnerSize.large}></Spinner>
                  } */}
                </>
              );
            }
          },
          ...((selectedProjectId) ? [{
            key: 'column3',
            name: 'Notifications',
            fieldName: 'Notifications',
            minWidth: 160,
            maxWidth: 160,
            isResizable: true,
          //  onColumnClick: (handleClick) ? handleClick : () => {},
            data: 'string',
            isPadded: true,
            onRender: (item: AppUser) => {
              let notifications_options = getNotificationsDropdownOptions(allNotifications, eventTypes, true);
              notifications_options = (item && item.role && item.role.name !== 'SUPPLIER' && selectedProjectId === 401143) ? _.filter(notifications_options, (n: {key: number, text: string}) => n.text === 'TkseReport') : _.filter(notifications_options, (n: {key: number, text: string}) => n.text !== 'TkseReport');
              let selectedOptionNames = _.filter(notifications_options, o => _.map(item.notifications, n => n.eventTypeId as number).includes(o.key));
              return(
                <ComboBox
                    multiSelect
                    className={styles.input}
                    options={notifications_options}
                    placeholder={'Notifications...'}
                    title={(selectedOptionNames.length > 0) ? _.join(_.map(selectedOptionNames, o => o.text), ', ') : undefined}
                    onChange={(e, value) => 
                      {
                        let userNotification = (item.notifications) ? _.find(item.notifications, r => value && r.eventTypeId === value.key ) as NotifyRule : undefined;
                        let newNotification = (!userNotification) ?  _.find(allNotifications, r => value && r.eventTypeId === value.key ) as NotifyRule : undefined;
  
                        setExternalUserFieldValue({
                          email: item.email as string,
                          name: 'notifications', 
                          value: (!userNotification && newNotification) ? [...item.notifications as NotifyRule[], newNotification] : [... _.filter(item.notifications, n => userNotification && n.eventTypeId !== userNotification.eventTypeId) as NotifyRule[]]
                        })

                        setExternalUserFieldValue({
                          email: item.email as string,
                          name: 'notificationsToBeRemoved', 
                          value: (userNotification && !newNotification && userNotification.eventTypeRuleId) ? [...item.notificationsToBeRemoved as NotifyRule[], userNotification] : [... _.filter(item.notificationsToBeRemoved, n => newNotification && n.eventTypeId !== newNotification.eventTypeId) as NotifyRule[]]
                        })
                      }
                    }
                    selectedKey={(item.notifications) ? _.map(item.notifications, n => n.eventTypeId as number) : []}       
                    useComboBoxAsMenuWidth={true}         
                    calloutProps={{calloutMaxHeight: 220}}       
                />
              );
            }
          }] : []), 

    ];

    return columns;
}




