import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from './index.classNames';
import { DefaultButton} from '@fluentui/react';
import { Text } from '@fluentui/react';
import { ProgressIndicator } from '@fluentui/react';
import { ConstrainMode, DetailsList, DetailsListLayoutMode, IContextualMenuItem, IContextualMenuProps, IDetailsHeaderProps, MessageBarType, ScrollablePane, ScrollbarVisibility, Spinner, SpinnerSize, Sticky, StickyPositionType } from '@fluentui/react';
import { Panel, PanelType, IPanelProps } from '@fluentui/react';
import { IRenderFunction, SelectionMode } from '@fluentui/react';
import ScrollablePaneHistory from '../../../components/scrollablePaneHistory/index';
import Notification from '../../../containers/notification/index';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getNotification } from '../../../redux/selectors/notification';
import { getLoader } from '../../../redux/selectors/loader';
import _ from 'lodash';
import {Event} from '../../../models/event';
import {Document, OtherRevision} from '../../../models/document';
import Details from './content/details';
import Files from './content/files';
import Comments from './content/comments';
import Titles from './content/titles';
import OtherRevisions from './content/otherRevisions';
import PublishedTo from './content/publishedTo';
import { getDocument, getOpenModal, getOtherRevisions } from '../../../redux/reducers/document';
import { CommandActions } from '../../../redux/actions/document';
import { getAllZeroUsers, getPermissions, getStatusValues } from '../../../redux/reducers/sharedData';
import { Status } from '../../../models/status';
import { getStatusSubMenuProps } from '../../../utils/statusFunctions';
import { getCanChangeStatus, getCanEdit, getCanRead, getCanDeleteFiles, getCanUploadAttachments, getCanUploadFileComments } from '../../../utils/permissionFunctions';
import {Actions as ModalActions} from '@pwbapps/genericdialog';
import { CommandActions as DocumentsCommandActions } from '../../../redux/actions/documents';
import { ExternalRoleActionsType, InternalRoleActionsType, isExternalApp } from '../../../utils/costants';
import { getHistoryName } from '../../../utils/functions';
import { getGenericModal } from "@pwbapps/genericdialog/dist/redux/selectors";
import { getSelectedProject } from '../../../redux/selectors/project';
import { Project } from '../../../models/project';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getDarkModeEnabled } from '../../../redux/reducers/project';
import { getColumns } from '../administration/content/columnsInternal';
import permissions from '../administration/content/functionalities/permissions';
import { getUserPermissions } from '../../../redux/reducers/initialization';
import { PermissionElement, Permissions } from '../../../models/permissions';
import { getColumns as getColumnsPermissions } from './columnsPermissions';
import { getEmail } from '@pwbapps/reduxcore/dist/redux/reducers/auth';
import { MainCompGenerator as GenericModalGenerator, Actions as GenericModalActions } from '@pwbapps/genericdialog'
import { getSelectedProjectCustomerStakeholders } from '../../../redux/selectors/administration';
import { StakeHolder } from '../../../models/stakeHolder';
export interface ModalProps extends RouteComponentProps, WithTranslation {
    openModal: boolean,
    showNotification: boolean, 
    type: MessageBarType, 
    message: string, 
    loading: boolean,
    loadingUi: boolean,
    document?: Document,
    otherRevisions: OtherRevision[],
    statusValues: Status[],
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    selectedProject?: Project,
    darkModeEnabled: boolean,
    userPermissions: PermissionElement[],
    email: string,
    permissions: Permissions[],
    allZeroUsers: string[],
    customerStakeholders: StakeHolder[],
    resetAndCloseModal: typeof CommandActions.resetAndCloseModal,
    openGenericModal: typeof ModalActions.CommandActions.openModal,
    openEditModal: typeof DocumentsCommandActions.openEditModal,
}

const GenericModal = GenericModalGenerator();

class ModalDocument extends React.Component<ModalProps & RouteComponentProps> {
    private _elementRef2 = React.createRef<HTMLDivElement>();
    closeModal = (e?: any) => {
        const { resetAndCloseModal } = this.props;
        if(e === true || e.type === 'click')
            resetAndCloseModal();
    }

    onRenderNavigationContent: IRenderFunction<IPanelProps> = ((props, defaultRender) => {
        const { document, openGenericModal, userProjectActions } = this.props;
        const styles = getClassNames();
        const canCheckOtherPermissions = _.find(userProjectActions, a => a === 'CheckOtherPermissions') ? true : false;
        const permissionsElement = this.getDocumentPermissions();
        return (      
        <>    
            {canCheckOtherPermissions && 
                <div style={{display: 'flex', justifyContent: 'space-between', width:'100%', marginLeft: 10, marginTop: 24, marginRight: 120, alignItems: 'baseline'}}>
                    <Text variant='xLarge' nowrap styles={{root: {marginTop: 9, marginLeft: 5}}}>{(document) ? (document.documentNumber + ' - ' + document.id) : 'Document'}</Text>
                        <div style={{display: 'flex', gap: 20}}>
                            <DefaultButton 
                                onClick={() => {openGenericModal({id: 'otherDocumentPermissions'})}} 
                                text="Other approvers" 
                                className={styles.button}
                                disabled={!permissionsElement}
                                title={!permissionsElement ? 'There are no other approvers' : undefined}
                            />
                        </div>
                </div> 
            }
            {!canCheckOtherPermissions && 
                <>
                    <div style={{display: 'flex', marginLeft: 10, marginTop: 24}}>
                        <Text variant='xLarge' nowrap styles={{root: {marginTop: 9, marginLeft: 5}}}>{(document) ? (document.documentNumber + ' - ' + document.id) : 'Document'}</Text>
                    </div> 
                    {// This custom navigation still renders the close button (defaultRender).
                    defaultRender!(props)}
                </>
            }
        </>
        )
    });
  
    onRenderFooterContent = () => {
        const { loading, loadingUi, statusValues, userProjectActions, history, document, selectedProject, customerStakeholders, t, openGenericModal, openEditModal} = this.props;
        const historyName = getHistoryName(history);
        const singleCustomer = (customerStakeholders.length === 1) ? customerStakeholders[0] : undefined;
        let canChangeStatus: boolean = getCanChangeStatus(historyName, userProjectActions);
        let statusSubMenuProps: IContextualMenuProps | undefined = (canChangeStatus && document && document.status && selectedProject) ? getStatusSubMenuProps(document.status, statusValues, selectedProject, historyName, openGenericModal, singleCustomer ) : undefined;
        let canEdit: boolean = getCanEdit(historyName, userProjectActions);
        return (
            <div style={{display: 'flex', gap: 15}}>
                    <>
                        { document && document.isCurrent && canEdit && document.status && document.status.name !== 'canceled' && document.editable &&
                            <DefaultButton
                                primary
                                text={t("Edit")} 
                                disabled={loading || loadingUi}
                                iconProps={{iconName: 'Edit'}}
                                onClick={() => {openEditModal()}}
                            />
                        }
                        { document && document.isCurrent && canChangeStatus && document.editable && statusSubMenuProps && statusSubMenuProps.items.length > 0 && (!isExternalApp || (isExternalApp && document.purpose && document.purpose.isWithApproval)) &&
                            <DefaultButton
                                primary
                                split 
                                text={t("Change Status")} 
                                menuProps={(statusSubMenuProps) ? statusSubMenuProps : undefined}
                                disabled={loading  || loadingUi }
                                iconProps={{iconName: 'Sync'}}
                            />       
                        }
                    </>
                <DefaultButton disabled={loading} onClick={() => this.closeModal(true)} text="Close" />
            </div>
        );
    };

    renderTitlesSections = (): boolean => {
        const {document} = this.props;
        return (document && (document.titleLine1En || document.titleLine1Pl || document.titleLine2En || document.titleLine2Pl || document.titleLine3En || 
                             document.titleLine3Pl || document.titleLine4En || document.titleLine4Pl || document.titleLine5En || document.titleLine5Pl || 
                             document.titleLine6En || document.titleLine6Pl)) 
        ? true : false;
    }

    onRenderDetailsHeader = (props?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element => {
        return (
          <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
            {(defaultRender && props) ? defaultRender({
              ...props,        
            }) : null}
          </Sticky>
        );
    }

    getDocumentPermissions = (): JSX.Element | undefined => {
        const styles = getClassNames();
        const { document, userProjectActions, email , permissions, allZeroUsers} = this.props; 
        const canCheckOtherPermissions = _.find(userProjectActions, a => a === 'CheckOtherPermissions') ? true : false;     
        const otherPermissions = _.filter(permissions, (p: Permissions) => p && p.email !== email);
        //let otherEmailRows: string[] = (allZeroUsers.length > 0) ? [...allZeroUsers] : [];
        let otherEmailRows: string[] = [];

        let userPermissionsToCheck: {user: string, permissionElements: PermissionElement[] }[] = [];

        _.forEach(otherPermissions, op => {
            const user = op.email;
            const otherPermissionsElements = (op.permissions.length > 0) ? op.permissions : [];
            var userPermissionsDocumentPbsAndDiscipline = (document && otherPermissionsElements.length > 0) ? _.filter(otherPermissionsElements, (up: PermissionElement) => up.canWrite && up.pbs && document.pbs && document.pbs.startsWith(up.pbs) && document.discipline && up.disciplineCode === document.discipline.code) as PermissionElement[] : [];
            var userPermissionsDocumentPbs = (document && otherPermissionsElements.length > 0) ? _.filter(otherPermissionsElements, (up: PermissionElement) => up.canWrite &&  up.pbs && document.pbs && document.pbs.startsWith(up.pbs) && !up.discipline) as PermissionElement[] : [];
            var userPermissionsDocumentDiscipline = (document && otherPermissionsElements.length > 0) ? _.filter(otherPermissionsElements, (up: PermissionElement) => up.canWrite &&  !up.pbs && document.discipline && up.disciplineCode === document.discipline.code ) as PermissionElement[] : [];
            userPermissionsToCheck = (userPermissionsDocumentPbsAndDiscipline.length > 0 || userPermissionsDocumentPbs.length > 0 || userPermissionsDocumentDiscipline.length > 0) ? [...userPermissionsToCheck, {user: user as string, permissionElements: [...userPermissionsDocumentPbsAndDiscipline, ...userPermissionsDocumentPbs, ...userPermissionsDocumentDiscipline]}] : userPermissionsToCheck;  
        });
              
        otherEmailRows = [...otherEmailRows, ..._.uniq(_.map(userPermissionsToCheck, u => u.user))];
        
        var permissionsElement = (canCheckOtherPermissions) ? 
            <div ref={this._elementRef2} style={{padding: 5}}>
                <div className={styles.wrapper}>   
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <DetailsList                              
                            items={otherEmailRows}
                            columns={getColumnsPermissions()}
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderDetailsHeader={this.onRenderDetailsHeader}
                            selectionPreservedOnEmptyClick={true}
                            selectionMode={SelectionMode.none}                             
                        />
                    </ScrollablePane> 
                </div> 
            </div>
        : undefined;
        return permissionsElement;
    }
    
    render() {
        const styles = getClassNames();
        const { openModal, showNotification, message, type, loading, document, statusValues, userProjectActions, history, otherRevisions, loadingUi, darkModeEnabled, t, userPermissions, email } = this.props; 
        let canRead: boolean = getCanRead(getHistoryName(history), userProjectActions);
        let canDeleteFiles: boolean = getCanDeleteFiles(getHistoryName(history), userProjectActions);
        let canUplaodAttachments: boolean = getCanUploadAttachments(getHistoryName(history), userProjectActions);
        let canUploadFileComments: boolean = getCanUploadFileComments(getHistoryName(history), userProjectActions);
        const events: Event[] = (canRead && document && document.statusHistory.length > 0) ? _.orderBy(_.map(document.statusHistory, sh => {return new Event(sh, statusValues)}), s => s.date) : [];
        const historyName = getHistoryName(history);
        const permissionsElement = this.getDocumentPermissions();
        
        return openModal && document && (
            <>
                <Panel
                    isOpen={openModal}
                    onDismiss={(e: any) => this.closeModal(e)} 
                    hasCloseButton={!loading}
                    type={PanelType.large}
                    closeButtonAriaLabel="Close"
                    // headerText={title}
                    styles={{navigation: styles.header, content: styles.content, scrollableContent: styles.scrollableContent, footer: styles.footer, contentInner: styles.contentInner, closeButton: {height: 40} }}
                    onRenderNavigationContent={this.onRenderNavigationContent}
                    onRenderFooterContent={this.onRenderFooterContent}
                >
                    <div className={styles.notify}>
                        <Notification name={'document'} show={showNotification} type={type} message={message}/>
                    </div>
                    
                    <ProgressIndicator progressHidden={ !loading }/>
                    {!loadingUi &&
                        <ScrollablePaneHistory statusHistory={events} className={styles.notifyHeader} darkModeEnabled={darkModeEnabled} items={[ 
                            { text: t("Details"), body: <div className={styles.tabContainer}><Details document={document}/></div> },
                            ...((this.renderTitlesSections()) ? [ { text: t("Titles"), body: <div className={styles.tabContainer}><Titles document={document}/></div> } ]: []), 
                            ...((document.files.length > 0 || (document.files.length === 0 && (canUplaodAttachments || canUploadFileComments))) ? [ { text: t("Files"), body: <div className={styles.tabContainer}><Files document={document} documentId={document.id as number} clientNumber={document.clientNumber} isCurrent={document.isCurrent ? true : false} editabled={document.editable} files={document.files} status={document.status as Status} canDeleteFiles={canDeleteFiles} canUplaodAttachments={canUplaodAttachments} canUploadFileComments={canUploadFileComments}/></div> } ]: []),       
                            { text: t("Comments"), body: <div className={styles.tabContainer}><Comments document={document} userProjectActions={userProjectActions} status={document.status as Status}/></div> },
                            ...(historyName === 'dashboard' && otherRevisions.length > 0 ? [ { text: t("Other Revisions"), body: <div className={styles.tabContainer}><OtherRevisions otherRevisions={otherRevisions}/></div> } ]: []),   
                            ...(!_.some(userProjectActions, a => a === 'FilterDocumentsByCustomerId') && document.publishedTo.length > 0 ? [ { text: t("Published to"), body: <div className={styles.tabContainer}><PublishedTo publishedTo={document.publishedTo}/></div> } ]: []),   
                        ]} />
                    }
                    {loadingUi && <Spinner size={SpinnerSize.large}/>}
                </Panel>
                {permissionsElement && 
                    <GenericModal 
                        id={'otherDocumentPermissions'}
                        title={'Other approvers'}
                        message={''}        
                        onClose={() => {}}
                        btnCloseText={'Cancel'}   
                        customComponent={permissionsElement}                                                           
                        width={1024}                          
                    />               
                }
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    loading: (state) => getLoader('document')(state).loading,
    loadingUi: (state) => getLoader('document')(state).loadingUi,
    showNotification: (state) => getNotification('document')(state).show,
	type: (state) => getNotification('document')(state).type,
    message: (state) => getNotification('document')(state).message,
    openModal: getOpenModal,
    document: getDocument,
    otherRevisions: getOtherRevisions,
    statusValues: getStatusValues,
    selectedProject: getSelectedProject,
    darkModeEnabled: getDarkModeEnabled,
    userPermissions: getUserPermissions,
    email: getEmail,
    permissions: getPermissions,
    allZeroUsers: getAllZeroUsers,
    customerStakeholders: getSelectedProjectCustomerStakeholders
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        resetAndCloseModal: CommandActions.resetAndCloseModal,
        openGenericModal: ModalActions.CommandActions.openModal,
        openEditModal: DocumentsCommandActions.openEditModal,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(ModalDocument)));
